import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_DEPARTMENTS, GET_THIRD_PARTY_CONTRACTORS } from "./actionTypes"
import {
  getDepartments,
  getDepartmentsSuccess,
  getDepartmentsFail,
  // getThirdPartyContractors,
  getThirdPartyContractorsSuccess,
  getThirdPartyContractorsFail,
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { getProviders, getThirdPartyContractors, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchDepartments({params,types}) {
  try {
    const response = yield call(getProviders, params, types )
    console.log(response);
    yield put(getDepartmentsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getDepartmentsFail(error))
  }
}

// function* fetchProviderDetails({params,types}) {
//   try {
//     const response = yield call(actionUrl,data)
//     console.log(response);
//     yield put(getProviderDetailSuccess(response))
//   } catch (error) {
//     console.log( 'error is'+ error );
//     yield put(getProviderDetailFail(error))
//   }
// }

function* fetchThirdPartyProviders({params,types}) {
  try {
    const response = yield call(getThirdPartyContractors,params,types)
    console.log(response);
    yield put(getThirdPartyContractorsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getThirdPartyContractorsFail(error))

  }
}

// function* onAddNewDepartment({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(addDepartmentSuccess(response))
//       toast.success("Details Added Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(addDepartmentFail(error))
//       toast.error("Details Add Failed", { autoClose: 2000 });
//   }
// }

// function* onUpdateDepartment({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(updateDepartmentSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateDepartmentFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* departmentsSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments)
  yield takeEvery(GET_THIRD_PARTY_CONTRACTORS, fetchThirdPartyProviders)
  // yield takeEvery(GET_PROVIDER_DETAIL, fetchProviderDetails)
  // yield takeEvery(ADD_DEPARTMENT, onAddNewDepartment)
  // yield takeEvery(UPDATE_DEPARTMENT_DETAIL, onUpdateDepartment)
}

export default departmentsSaga
