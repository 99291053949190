import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, GET_CONTRACTORS, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { Name } from "components/Common/TableColumns";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";
import TableContainer from "components/Common/Bkp-TableContainer";


  const CaseMangerClientsModal = ({CaseManagerID, CaseMangerName, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CaseManagerClients, setCaseManagerClients] = useState([]);
    const [ClientDetails, setClientDetails] = useState([]);
    const [ClientDetailsModal, setClientDetailsModal] = useState(false);
    const [ClientsProfileActivity, setClientsProfileActivity ] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);

    const toggleClientDetailsModal = () => {
        if( ClientDetailsModal ) {
            setClientDetailsModal(false)
        } else {
            setClientDetailsModal(true)
        }
    }


    useEffect(() => {
        if( show ) { 
            setCaseManagerClients([]);
            const formData = {
                case_manager_id : CaseManagerID
            }
            setModalLoading(true);
            axios.post(APP_URL+GET_CONTRACTORS,{'data_for':'load_cm_clients', 'formData': formData, 'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                setCaseManagerClients(fetchedData.clients)
                setHiddenColumns(fetchedData.columns_hidden);
            })
        }
    }, [show])


    const handleViewClientDetails = (userData) => {

        // console.log(userData);
        toggleClientDetailsModal()
    
        const formData = {
            client_id: userData.user_id
        }
        setModalLoading(true);
        axios.post(APP_URL+GET_CONTRACTORS,{'data_for':'load_client_details','formData':formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            setClientDetails(res.data.client_details);
            setClientsProfileActivity(res.data.clients_activity);
        })
    
    }

    var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

    const case_manager_client_columns = useMemo(
        () => [
            {
                Header: "Client Name",
                accessor: "client_name",
                //disableFilters: true, 
                filterable: true,
                Cell: cellProps => {
                return <Name {...cellProps} />;
                },
            },
            {
                Header: "Email",
                accessor: "user_email",
                //disableFilters: true, 
                filterable: true,
                Cell: cellProps => {
                  return <Name {...cellProps} />;
                },
            },
            {
                Header: "Address",
                accessor: "client_address",
                //disableFilters: true, 
                filterable: true,
                Cell: cellProps => {
                return <Name {...cellProps} />;
                },
            },
            {
                Header: "Phone No.",
                accessor: "client_mobile_no",
                //disableFilters: true, 
                filterable: true,
                Cell: cellProps => {
                return <Name {...cellProps} />;
                },
            },
            {
                Header: "Action",
                Cell: cellProps => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleViewClientDetails(userData);
                                }}
                                id={`viewprofiletooltip-${cellProps.row.original.id}`}
                            >
                                <i className="mdi mdi-eye font-size-18" id="edittooltip"  />
                                <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                                    View
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                    </ul>
                );
                },
            },
        ],
        []
    );
    
    return (
        <>
        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={onCloseModal} tag="h4">
                {CaseMangerName} Case Manger Clients List{ ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>

                <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>

                        <TableContainer
                            columns={case_manager_client_columns}
                            data={CaseManagerClients}
                            isGlobalFilter={true}
                            isDateSelecteOptions={true}
                            showSearchBtn={true}
                            customPageSize={50}
                            columnsHide={hiddenColumns}
                            />
                        </CardBody>
                    </Card>
                </Col>
                </Row>

            </ModalBody>
        </Modal>


        <Modal isOpen={ClientDetailsModal} toggle={toggleClientDetailsModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={toggleClientDetailsModal} tag="h4">
                Client Details{ ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>

            <ModalBody style={{ minHeight: '400px' }}>
            <Row>
                <Col md={8}>
                <Form>

                    <Row>
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Account Status
                        </Label>
                        <Col sm={9}>

                            <Badge color={ClientDetails.user_status == 1 ? 'success' : 'danger' } className="me-1 font-size-18">
                            {ClientDetails.user_status == 1 ? 'Active' : 'Inactive'}
                            </Badge>
                        </Col>
                        
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Title
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.user_title}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        First Name
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_fname}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Last Name
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_lname}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.user_email}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Gender
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_gender}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        DOB
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_dob}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Mobile NO
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_mobile_no}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Home Contact No
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_home_no}
                          readOnly
                        />
                      </Col>
                    </Row>

                    { loggedUser.role == 'admin' && 
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Care Instructions
                      </Label>
                      <Col sm={9}>
                        <div dangerouslySetInnerHTML={ClientDetails.care_instruction}></div>
                      </Col>
                    </Row>
                    }
                    
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Address
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_address}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Client Department
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.department_name}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Case Manager
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.case_manager_name}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <CardTitle>Emergency Contact</CardTitle>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Contact name
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_emergency_contact_name}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Contact Relation
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_emergency_contact_relation}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Client Contact No.
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_emergency_contact_mobile}
                          readOnly
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Contact Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          value={ClientDetails.client_emergency_contact_email}
                          readOnly
                        />
                      </Col>
                    </Row>

                </Form>

                </Col>
                
                <Col md={4}>
                    <CardTitle>Activiry Log</CardTitle>

                    <ul className="order_notes">
                        { ClientsProfileActivity.map(activity => (
                        <li key={activity.id} rel={activity.id} className="note">
                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                            <strong>
                            {activity.activity_by}
                            </strong>
                            </abbr></p>
                        </li>
                        ))}
                    </ul>
                
                </Col>
            </Row>

            </ModalBody>
        </Modal>

        </>
    )

}

CaseMangerClientsModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default CaseMangerClientsModal;