import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_STAFF_LEAVES_PROCESSED,GET_STAFF_LEAVES_APPROVED, GET_STAFF_LEAVES_APPROVED_ARCHIVED, GET_STAFF_LEAVES_DELETED, GET_STAFF_LEAVES_UNAPPROVED } from "./actionTypes"
import {
  getStaffLeavesProcessedSuccess,
  getStaffLeavesProcessedFail,
  getStaffLeavesApprovedSuccess,
  getStaffLeavesApprovedFail,
  getStaffLeavesApprovedArchivedSuccess,
  getStaffLeavesApprovedArchivedFail,
  getStaffLeavesUnApprovedSuccess,
  getStaffLeavesUnApprovedFail,
  getStaffLeavesDeletedSuccess,
  getStaffLeavesDeletedFail
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { staffLeavesPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchStaffLeavesProcessed({params,types}) {
  try {
    const response = yield call(staffLeavesPage, params, types )
    console.log(response);
    yield put(getStaffLeavesProcessedSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getStaffLeavesProcessedFail(error))
  }
}

function* fetchStaffLeavesApproved({params,types}) {
  try {
    const response = yield call(staffLeavesPage, params, types )
    console.log(response);
    yield put(getStaffLeavesApprovedSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getStaffLeavesApprovedFail(error))
  }
}

function* fetchStaffLeavesApprovedArchived({ params,type }) {
  try {
    const response = yield call(staffLeavesPage,params,type)
    console.log(response);
    yield put(getStaffLeavesApprovedArchivedSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getStaffLeavesApprovedArchivedFail(error))
  }
}

function* fetchStaffLeavesUnApproved({ params,type }) {
  try {
      const response = yield call(staffLeavesPage, params,type)
      yield put(getStaffLeavesUnApprovedSuccess(response))
      //toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(getStaffLeavesUnApprovedFail(error))
      //toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

function* fetchStaffLeavesDeleted({ params,type }) {
  try {
      const response = yield call(staffLeavesPage, params,type)
      yield put(getStaffLeavesDeletedSuccess(response))
      //toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(getStaffLeavesDeletedFail(error))
      //toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

// function* updateCasemanager({ payload: data }) {
//   try {
//       const response = yield call(staffLeavesPage, data)
//       yield put(updateCasemanagerSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateCasemanagerFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* staffLeavesSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_STAFF_LEAVES_PROCESSED, fetchStaffLeavesProcessed)
  yield takeEvery(GET_STAFF_LEAVES_APPROVED, fetchStaffLeavesApproved)
  yield takeEvery(GET_STAFF_LEAVES_APPROVED_ARCHIVED, fetchStaffLeavesApprovedArchived)
  yield takeEvery(GET_STAFF_LEAVES_UNAPPROVED, fetchStaffLeavesUnApproved)
  yield takeEvery(GET_STAFF_LEAVES_DELETED, fetchStaffLeavesDeleted)
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default staffLeavesSaga
