import {
  GET_ALERTS,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAIL,
  GET_ALERT_ROSTER_DETAILS,
  GET_ALERT_ROSTER_DETAILS_SUCCESS,
  GET_ALERT_ROSTER_DETAILS_FAIL,
  UPDATE_ALERT_ROSTER_DETAILS,
  UPDATE_ALERT_ROSTER_DETAILS_SUCCESS,
  UPDATE_ALERT_ROSTER_DETAILS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  rosterAlertsList: [],
  alertRosterDetails: [],
  staffsDropdown: [],
  supportTypeDropdown: [],
  payCategoryDropdown: [],
  staffNotes: [],
  clientNotes: [],
  error: {},
  loading: false,
  detailsLoading: false,
  hiddenColumns: []
}

const AlertRostersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALERTS:
      
      return {
        ...state,
        //alertRosterDetails: [],
        rosterAlertsList: [], //state.rosterAlertsList,
        hiddenColumns:[],
        loading: true
     }
    case GET_ALERTS_SUCCESS:
      
      return {
        ...state,
        staffsDropdown: action.payload.staffs_dropdown,
        supportTypeDropdown: action.payload.support_type_dropdown,
        payCategoryDropdown: action.payload.pay_category_dropdown,
        rosterAlertsList: action.payload.data,
        hiddenColumns: action.payload.hiddenColumns,
        loading: false
     }

    case GET_ALERTS_FAIL:
    
      return {
        ...state,
        error: action.payload.error,
      }

    case GET_ALERT_ROSTER_DETAILS:
    
      return {
        alertRosterDetails: [],
        rosterAlertsList : state.rosterAlertsList,
        supportTypeDropdown: state.supportTypeDropdown,
        staffsDropdown: state.staffsDropdown,
        payCategoryDropdown: state.payCategoryDropdown,
        detailsLoading: true
      }

    case GET_ALERT_ROSTER_DETAILS_SUCCESS:
      return {
        // ...state,
        alertRosterDetails: action.payload.data,
        rosterAlertsList : state.rosterAlertsList,
        supportTypeDropdown: state.supportTypeDropdown,
        staffsDropdown: state.staffsDropdown,
        payCategoryDropdown: state.payCategoryDropdown,
        detailsLoading: false
      }

    case GET_ALERT_ROSTER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.error,
        detailsLoading: false
      }
      case UPDATE_ALERT_ROSTER_DETAILS:
        return {
          ...state,
          loading: true
      };

      case UPDATE_ALERT_ROSTER_DETAILS_SUCCESS:
        return {
            ...state,
            rosterAlertsList: state.rosterAlertsList.map(rosters =>
              rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
      };

      case UPDATE_ALERT_ROSTER_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

      default:
        return state
  }
}

export default AlertRostersReducer
