export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAILED = "GET_CLIENTS_FAILED"

export const GET_ARCHIVED_CLIENTS = "GET_ARCHIVED_CLIENTS"
export const GET_ARCHIVED_CLIENTS_SUCCESS = "GET_ARCHIVED_CLIENTS_SUCCESS"
export const GET_ARCHIVED_CLIENTS_FAILED = "GET_ARCHIVED_CLIENTS_FAILED"

export const GET_CLIENTS_ROSTERS = "GET_CLIENTS_ROSTERS"
export const GET_CLIENTS_ROSTERS_SUCCESS = "GET_CLIENTS_ROSTERS_SUCCESS"
export const GET_CLIENTS_ROSTERS_FAIL = "GET_CLIENTS_ROSTERS_FAIL"

export const GET_CLIENTS_ROSTERS_DETAIL = "GET_CLIENTS_ROSTERS_DETAIL"
export const GET_CLIENTS_ROSTERS_DETAIL_SUCCESS = "GET_CLIENTS_ROSTERS_DETAIL_SUCCESS"
export const GET_CLIENTS_ROSTERS_DETAIL_FAIL = "GET_CLIENTS_ROSTERS_DETAIL_FAIL"

export const GET_CLIENTS_SERVICES_TYPES = "GET_CLIENTS_SERVICES_TYPES"
export const GET_CLIENTS_SERVICES_TYPES_SUCCESS = "GET_CLIENTS_SERVICES_TYPES_SUCCESS"
export const GET_CLIENTS_SERVICES_TYPES_FAIL = "GET_CLIENTS_SERVICES_TYPES_FAIL"

export const GET_CLIENTS_SERVICES_LIST = "GET_CLIENTS_SERVICES_LIST"
export const GET_CLIENTS_SERVICES_LIST_SUCCESS = "GET_CLIENTS_SERVICES_LIST_SUCCESS"
export const GET_CLIENTS_SERVICES_LIST_FAIL = "GET_CLIENTS_SERVICES_LIST_FAIL"

export const GET_CLIENTS_ISOLATION_LIST = "GET_CLIENTS_ISOLATION_LIST"
export const GET_CLIENTS_ISOLATION_LIST_SUCCESS = "GET_CLIENTS_ISOLATION_LIST_SUCCESS"
export const GET_CLIENTS_ISOLATION_LIST_FAIL = "GET_CLIENTS_ISOLATION_LIST_FAIL"

export const GET_CLIENTS_DELETED_ROSTERS = "GET_CLIENTS_DELETED_ROSTERS"
export const GET_CLIENTS_DELETED_ROSTERS_SUCCESS = "GET_CLIENTS_DELETED_ROSTERS_SUCCESS"
export const GET_CLIENTS_DELETED_ROSTERS_FAIL = "GET_CLIENTS_DELETED_ROSTERS_FAIL"

export const GET_CLIENTS_NOTES = "GET_CLIENTS_NOTES"
export const GET_CLIENTS_NOTES_SUCCESS = "GET_CLIENTS_NOTES_SUCCESS"
export const GET_CLIENTS_NOTES_FAIL = "GET_CLIENTS_NOTES_FAIL"

export const ADD_CLIENT_NOTES = "ADD_CLIENT_NOTES"
export const ADD_CLIENT_NOTES_SUCCESS = "ADD_CLIENT_NOTES_SUCCESS"
export const ADD_CLIENT_NOTES_FAIL = "ADD_CLIENT_NOTES_FAIL"

export const UPDATE_CLIENT_NOTES = "UPDATE_CLIENT_NOTES"
export const UPDATE_CLIENT_NOTES_SUCCESS = "UPDATE_CLIENT_NOTES_SUCCESS"
export const UPDATE_CLIENT_NOTES_FAIL = "UPDATE_CLIENT_NOTES_FAIL"

export const ADD_CLIENTS_SERVICES_LIST = "ADD_CLIENTS_SERVICES_LIST"
export const ADD_CLIENTS_SERVICES_LIST_SUCCESS = "ADD_CLIENTS_SERVICES_LIST_SUCCESS"
export const ADD_CLIENTS_SERVICES_LIST_FAIL = "ADD_CLIENTS_SERVICES_LIST_FAIL"

export const ADD_CLIENTS_SERVICES_TYPES = "ADD_CLIENTS_SERVICES_TYPES"
export const ADD_CLIENTS_SERVICES_TYPES_SUCCESS = "ADD_CLIENTS_SERVICES_TYPES_SUCCESS"
export const ADD_CLIENTS_SERVICES_TYPES_FAIL = "ADD_CLIENTS_SERVICES_TYPES_FAIL"

export const ADD_CLIENTS_ISOLATION_LIST = "ADD_CLIENTS_ISOLATION_LIST"
export const ADD_CLIENTS_ISOLATION_LIST_SUCCESS = "ADD_CLIENTS_ISOLATION_LIST_SUCCESS"
export const ADD_CLIENTS_ISOLATION_LIST_FAIL = "ADD_CLIENTS_ISOLATION_LIST_FAIL"


export const UPDATE_CLIENTS_SERVICES_LIST = "UPDATE_CLIENTS_SERVICES_LIST"
export const UPDATE_CLIENTS_SERVICES_LIST_SUCCESS = "UPDATE_CLIENTS_SERVICES_LIST_SUCCESS"
export const UPDATE_CLIENTS_SERVICES_LIST_FAIL = "UPDATE_CLIENTS_SERVICES_LIST_FAIL"

export const UPDATE_CLIENTS_SERVICES_TYPES = "UPDATE_CLIENTS_SERVICES_TYPES"
export const UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS = "UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS"
export const UPDATE_CLIENTS_SERVICES_TYPES_FAIL = "UPDATE_CLIENTS_SERVICES_TYPES_FAIL"

export const UPDATE_CLIENTS_ISOLATION_LIST = "UPDATE_CLIENTS_ISOLATION_LIST"
export const UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS = "UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS"
export const UPDATE_CLIENTS_ISOLATION_LIST_FAIL = "UPDATE_CLIENTS_ISOLATION_LIST_FAIL"