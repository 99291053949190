/* Rosters */
export const GET_PAYCATEGORY_LIST = "GET_PAYCATEGORY_LIST"
export const GET_PAYCATEGORY_LIST_SUCCESS = "GET_PAYCATEGORY_LIST_SUCCESS"
export const GET_PAYCATEGORY_LIST_FAIL = "GET_PAYCATEGORY_LIST_FAIL"

export const UPDATE_PAYCATEGORY_LIST = "UPDATE_PAYCATEGORY_LIST"
export const UPDATE_PAYCATEGORY_LIST_SUCCESS = "UPDATE_PAYCATEGORY_LIST_SUCCESS"
export const UPDATE_PAYCATEGORY_LIST_FAIL = "UPDATE_PAYCATEGORY_LIST_FAIL"

export const ADD_PAYCATEGORY = "ADD_PAYCATEGORY"
export const ADD_PAYCATEGORY_SUCCESS = "ADD_PAYCATEGORY_SUCCESS"
export const ADD_PAYCATEGORY_FAIL = "ADD_PAYCATEGORY_FAIL"

