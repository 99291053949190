/* Rosters */
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS"
export const GET_PROVIDERS_FAIL = "GET_PROVIDERS_FAIL"


// export const GET_PROVIDER_DETAIL = "GET_PROVIDER_DETAIL"
// export const GET_PROVIDER_DETAIL_SUCCESS = "GET_PROVIDER_DETAIL_SUCCESS"
// export const GET_PROVIDER_DETAIL_FAIL = "GET_PROVIDER_DETAIL_FAIL"

export const GET_THIRD_PARTY_CONTRACTORS = "GET_THIRD_PARTY_CONTRACTORS"
export const GET_THIRD_PARTY_CONTRACTORS_SUCCESS = "GET_THIRD_PARTY_CONTRACTORS_SUCCESS"
export const GET_THIRD_PARTY_CONTRACTORS_FAIL = "GET_THIRD_PARTY_CONTRACTORS_FAIL"

// export const GET_THIRD_PARTY_CONTRACTOR_DETAIL = "GET_THIRD_PARTY_CONTRACTOR_DETAIL"
// export const GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS = "GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS"
// export const GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL = "GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL"


// export const ADD_DEPARTMENT = "ADD_DEPARTMENT"
// export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS"
// export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL"

export const ADD_DEPARTMENTS = "ADD_DEPARTMENTS"
export const ADD_THIRD_PARTY_CONTRACTORS = "ADD_THIRD_PARTY_CONTRACTORS"
// export const ADD_DEPARTMENTS_SUCCESS = "ADD_DEPARTMENTS_SUCCESS"
// export const ADD_DEPARTMENTS_FAIL = "ADD_DEPARTMENTS_FAIL"

export const UPDATE_DEPARTMENT_DETAIL = "UPDATE_DEPARTMENT_DETAIL"
export const UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL = "UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL"

// export const UPDATE_DEPARTMENT_DETAIL_SUCCESS = "UPDATE_DEPARTMENT_DETAIL_SUCCESS"
// export const UPDATE_DEPARTMENT_DETAIL_FAIL = "UPDATE_DEPARTMENT_DETAIL_FAIL"
