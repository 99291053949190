import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_INCIDENT_REPORT_STAFFS,GET_STAFFS_ROSTERS,GET_INCIDENT_REPORT_ACTIVE, GET_INCIDENT_REPORT_COMPLETED,ADD_INCIDENT_REPORT,UPDATE_INCIDENT_REPORT } from "./actionTypes"
import {
  getIncidentReportActiveSuccess,
  getIncidentReportActiveFail,
  getIncidentReportCompletedSuccess,
  getIncidentReportCompletedFail,
  getIncidentReportStaffsSuccess,
  getIncidentReportStaffsFail,
  addIncidentReportSuccess,
  addIncidentReportFail,
  updateIncidentReportSuccess,
  updateIncidentReportFail,
  getStaffsRostersSuccess,
  getStaffsRostersFail,
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { incidentReportPage, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchIncidentReportActive({params,types}) {
  try {
    const response = yield call(incidentReportPage, params, types )
    console.log(response);
    yield put(getIncidentReportActiveSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getIncidentReportActiveFail(error))
  }
}

function* fetchIncidentReportCompleted({ params,type }) {
  try {
      const response = yield call(incidentReportPage, params,type)
      yield put(getIncidentReportCompletedSuccess(response))
  } catch (error) {
      yield put(getIncidentReportCompletedFail(error))
  }
}

function* fetchStaffs({ params,type }) {
  try {
      const response = yield call(actionUrl, params,type)
      yield put(getIncidentReportStaffsSuccess(response))
  } catch (error) {
      yield put(getIncidentReportStaffsFail(error))
  }
}

function* fetchStaffsRosters({ params,type }) {
  try {
      const response = yield call(incidentReportPage, params,type)
      yield put(getStaffsRostersSuccess(response))
  } catch (error) {
      yield put(getStaffsRostersFail(error))
  }
}

function* updateIncidentReport({ payload: data }) {
  try {
      const response = yield call(incidentReportPage, data)
      yield put(updateIncidentReportSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateIncidentReportFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* addIncidentReport({ payload: data }) {
    try {
        const response = yield call(incidentReportPage, data)
        yield put(addIncidentReportSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addIncidentReportFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
  }

function* incidentReportSage() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_INCIDENT_REPORT_ACTIVE, fetchIncidentReportActive)
  yield takeEvery(GET_INCIDENT_REPORT_COMPLETED, fetchIncidentReportCompleted)
  yield takeEvery(UPDATE_INCIDENT_REPORT, updateIncidentReport)
  yield takeEvery(ADD_INCIDENT_REPORT, addIncidentReport)
  yield takeEvery(GET_INCIDENT_REPORT_STAFFS, fetchStaffs)
  yield takeEvery(GET_STAFFS_ROSTERS, fetchStaffsRosters)
  
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default incidentReportSage
