import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CLIENT_AWAY_ALERT_APPROVED,GET_CLIENT_AWAY_ALERT_UNAPPROVED, GET_CLIENT_AWAY_ALERT_DELETED } from "./actionTypes"
import {
  getClientAwayAlertApprovedSuccess,
  getClientAwayAlertApprovedFail,
  getClientAwayAlertUnApprovedSuccess,
  getClientAwayAlertUnApprovedFail,
  getClientAwayAlertDeletedSuccess,
  getClientAwayAlertDeletedFail,
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { clientAwayAlertPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchClientAwayAlertApproved({params,types}) {
  try {
    const response = yield call(clientAwayAlertPage, params, types )
    console.log(response);
    yield put(getClientAwayAlertApprovedSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getClientAwayAlertApprovedFail(error))
  }
}

function* fetchClientAwayAlertUnApproved({ params,type }) {
  try {
      const response = yield call(clientAwayAlertPage, params,type)
      yield put(getClientAwayAlertUnApprovedSuccess(response))
      //toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(getClientAwayAlertUnApprovedFail(error))
      //toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

function* fetchClientAwayAlertDeleted({ params,type }) {
  try {
      const response = yield call(clientAwayAlertPage, params,type)
      yield put(getClientAwayAlertDeletedSuccess(response))
      //toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(getClientAwayAlertDeletedFail(error))
      //toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

// function* updateCasemanager({ payload: data }) {
//   try {
//       const response = yield call(staffLeavesPage, data)
//       yield put(updateCasemanagerSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateCasemanagerFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* clientAwayAlertSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_CLIENT_AWAY_ALERT_APPROVED, fetchClientAwayAlertApproved)
  yield takeEvery(GET_CLIENT_AWAY_ALERT_UNAPPROVED, fetchClientAwayAlertUnApproved)
  yield takeEvery(GET_CLIENT_AWAY_ALERT_DELETED, fetchClientAwayAlertDeleted)
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default clientAwayAlertSaga
