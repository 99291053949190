import {
    GET_HR_DOCUMENT_NAME_LIST,
    GET_HR_DOCUMENT_NAME_LIST_SUCCESS,
    GET_HR_DOCUMENT_NAME_LIST_FAIL,
    UPDATE_HR_DOCUMENT_NAME_LIST,
    UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS,
    UPDATE_HR_DOCUMENT_NAME_LIST_FAIL,
    ADD_HR_DOCUMENT_NAME_LIST,
    ADD_HR_DOCUMENT_NAME_LIST_SUCCESS,
    ADD_HR_DOCUMENT_NAME_LIST_FAIL,
  } from "./actionTypes"
  
  export const getHrDocumentsNameList = (params,types) => ({
    type: GET_HR_DOCUMENT_NAME_LIST,
    params,
    types,
  })
  
  export const getHrDocumentsNameListSuccess = data => ({
    type: GET_HR_DOCUMENT_NAME_LIST_SUCCESS,
    payload: data,
  })
  
  export const getHrDocumentsNameListFail = error => ({
    type: GET_HR_DOCUMENT_NAME_LIST_FAIL,
    payload: error,
  })

  export const updateHrDocumentsNameList = (params,types) => ({
    type: UPDATE_HR_DOCUMENT_NAME_LIST,
    payload: params,
    types
  })

  export const updateHrDocumentsNameListSuccess = data => ({
    type: UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS,
    payload: data,
  })

  export const updateHrDocumentsNameListFail = error => ({
    type: UPDATE_HR_DOCUMENT_NAME_LIST_FAIL,
    payload: error,
  })

  export const addHrDocumentsNameList = (params,types) => ({
    type: ADD_HR_DOCUMENT_NAME_LIST,
    payload: params,
    types
  })

  export const addHrDocumentsNameListSuccess = data => ({
    type: ADD_HR_DOCUMENT_NAME_LIST_SUCCESS,
    payload: data,
  })

  export const addHrDocumentsNameListFail = error => ({
    type: ADD_HR_DOCUMENT_NAME_LIST_FAIL,
    payload: error,
  })