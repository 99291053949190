import {
    GET_FEEDBACKS,
    GET_FEEDBACKS_SUCCESS,
    GET_FEEDBACKS_FAIL,
    ADD_FEEDBACK,
    UPDATE_FEEDBACK,
  } from "./actionTypes"
  
  const INIT_STATE = {
    feedbacks: [],
    clientsDropdown: [],
    staffsDropdown: [],
    error: {},
    loading: false,
  }
  
  const FeedbacksReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_FEEDBACKS:

        return {
          ...state,
          feedbacks: state.feedbacks,
          clientsDropdown: state.clientsDropdown,
          staffsDropdown: state.staffsDropdown,
          loading: true
       }
      case GET_FEEDBACKS_SUCCESS:

        return {
          ...state,
          feedbacks: action.payload.data,
          clientsDropdown: action.payload.clientsDropdown,
          staffsDropdown: action.payload.staffsDropdown,
          loading: false
       }
  
      case GET_FEEDBACKS_FAIL:

      return {
        ...state,
        error: action.payload,
  
      }

      case ADD_FEEDBACK:
        return {
          ...state,
          feedbacks: [...state.feedbacks, action.payload],
          loading: false
      }
  
      case UPDATE_FEEDBACK:
        return {
          ...state,
          feedbacks: state.feedbacks.map(rosters =>
            rosters.id.toString() === action.payload.id.toString()
                    ? { rosters, ...action.payload }
                    : rosters
            ),
          loading: false
      }
  
        default:
          return state
    }
  }
  
  export default FeedbacksReducer
  