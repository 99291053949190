/* Rosters */
export const GET_CASEMANAGERS = "GET_CASEMANAGERS"
export const GET_CASEMANAGERS_SUCCESS = "GET_CASEMANAGERS_SUCCESS"
export const GET_CASEMANAGERS_FAIL = "GET_CASEMANAGERS_FAIL"

export const GET_CASEMANAGER_DETAILS = "GET_CASEMANAGER_DETAILS"
export const GET_CASEMANAGER_DETAILS_SUCCESS = "GET_CASEMANAGER_DETAILS_SUCCESS"
export const GET_CASEMANAGER_DETAILS_FAIL = "GET_CASEMANAGER_DETAILS_FAIL"

export const UPDATE_CASEMANAGER = "UPDATE_CASEMANAGER"
export const UPDATE_CASEMANAGER_SUCCESS = "UPDATE_CASEMANAGER_SUCCESS"
export const UPDATE_CASEMANAGER_FAIL = "UPDATE_CASEMANAGER_FAIL"

export const GET_CASEMANAGERS_CLIENTS = "GET_CASEMANAGERS_CLIENTS"
export const GET_CASEMANAGERS_CLIENTS_SUCCESS = "GET_CASEMANAGERS_CLIENTS_SUCCESS"
export const GET_CASEMANAGERS_CLIENTS_FAIL = "GET_CASEMANAGERS_CLIENTS_FAIL"

export const ADD_CASEMANAGER = "ADD_CASEMANAGER"
export const ADD_CASEMANAGER_SUCCESS = "ADD_CASEMANAGER_SUCCESS"
export const ADD_CASEMANAGER_FAIL = "ADD_CASEMANAGER_FAIL"

