import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from "react-toastify";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as moment from "moment";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import Spinners from "components/Common/Spinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle,
    ModalFooter
} from "reactstrap";


const ClientAssementCarePlanModal = ({clientID, clientFname, clientLname, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [modalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [clientAssesmentPlan, setClientAssesmentPlan] = useState([]);
    const [ndisPlanDate, setNdisPlanDate] = useState('');
    const [dateSigned, setDateSigned] = useState('');
    const [dateCommenced, setDateCommenced] = useState('');
    const [createDate, setCreateDate] = useState('');
    const [Aids, setAids] = useState([])
    const [clientCommunityAccess, setClientCommunityAccess] = useState([]);
    const [type, setType] = useState('add');
    const [loading, setLoading] = useState(false);
    const [planID, setPlanID] = useState('');
    const [activityLog, setActivityLog] = useState([]);



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            prepared_by: ( clientAssesmentPlan && clientAssesmentPlan.prepared_by ) || "",
            living_arrangement: ( clientAssesmentPlan && clientAssesmentPlan.living_arrangement) || "" ,
            carer_name : ( clientAssesmentPlan && clientAssesmentPlan.carer_name) || "" ,
            carer_relationship : ( clientAssesmentPlan && clientAssesmentPlan.carer_relationship) || "" ,
            ndis_number: ( clientAssesmentPlan && clientAssesmentPlan.ndis_number) || "",
            ndis_plan_date: ( clientAssesmentPlan && clientAssesmentPlan.ndis_plan_date) || "",
            preferred_lang: ( clientAssesmentPlan && clientAssesmentPlan.preferred_lang) || "",
            ndis_plan_organisation: ( clientAssesmentPlan && clientAssesmentPlan.ndis_plan_organisation) || "",
            ndis_plan_cm: ( clientAssesmentPlan && clientAssesmentPlan.ndis_plan_cm) || "",
            ndis_plan_cm_contact: ( clientAssesmentPlan && clientAssesmentPlan.ndis_plan_cm_contact) || "",
            date_signed : ( clientAssesmentPlan && clientAssesmentPlan.date_signed) || "",
            date_commenced : ( clientAssesmentPlan && clientAssesmentPlan.date_commenced) || "",
            lang_interpreter_required : ( clientAssesmentPlan && clientAssesmentPlan.lang_interpreter_required) || 0,
            lang_interpreter_lang : ( clientAssesmentPlan && clientAssesmentPlan.lang_interpreter_lang) || "",
            indigenous_status : ( clientAssesmentPlan && clientAssesmentPlan.indigenous_status) || 0,
            general_practitioner: ( clientAssesmentPlan && clientAssesmentPlan.general_practitioner) || "",
            general_practitioner_ph_no: ( clientAssesmentPlan && clientAssesmentPlan.general_practitioner_ph_no) || "",
            history_first: ( clientAssesmentPlan && clientAssesmentPlan.history_first) || "",
            history_second: ( clientAssesmentPlan && clientAssesmentPlan.history_second) || "",
            history_third: ( clientAssesmentPlan && clientAssesmentPlan.history_third) || "",
            history_fourth: ( clientAssesmentPlan && clientAssesmentPlan.history_fourth) || "",
            history_fifth: ( clientAssesmentPlan && clientAssesmentPlan.history_fifth) || "",
            history_sixth: ( clientAssesmentPlan && clientAssesmentPlan.history_sixth) || "",
            allergies: ( clientAssesmentPlan && clientAssesmentPlan.allergies) || "",
            manage_own_medication: ( clientAssesmentPlan && clientAssesmentPlan.manage_own_medication) || 'No',
            manage_own_medication_notes : ( clientAssesmentPlan && clientAssesmentPlan.manage_own_medication_notes) || '',
            other_medication_management: ( clientAssesmentPlan && clientAssesmentPlan.other_medication_management) || 'No',
            other_medication_management_notes: ( clientAssesmentPlan && clientAssesmentPlan.other_medication_management_notes) || '',
            pain_interfere_everyday_life: ( clientAssesmentPlan && clientAssesmentPlan.pain_interfere_everyday_life) || 'No',
            pain_interfere_everyday_life_notes: ( clientAssesmentPlan && clientAssesmentPlan.pain_interfere_everyday_life_notes) || "",
            foot_problems: ( clientAssesmentPlan && clientAssesmentPlan.foot_problems) || 'No',
            foot_problems_notes: ( clientAssesmentPlan && clientAssesmentPlan.foot_problems_notes) || "",
            falls_last_12_months: ( clientAssesmentPlan && clientAssesmentPlan.falls_last_12_months) || 'No',
            falls_last_12_months_notes: ( clientAssesmentPlan && clientAssesmentPlan.falls_last_12_months_notes) || "",
            something_done_reduce_fall: ( clientAssesmentPlan && clientAssesmentPlan.something_done_reduce_fall) || 'No',
            something_done_reduce_fall_notes: ( clientAssesmentPlan && clientAssesmentPlan.something_done_reduce_fall_notes) || "",

            mobility_limiting: ( clientAssesmentPlan && clientAssesmentPlan.mobility_limiting) || 'No',
            mobility_limiting_notes: ( clientAssesmentPlan && clientAssesmentPlan.mobility_limiting_notes) || "",
            aids_other: ( clientAssesmentPlan && clientAssesmentPlan.aids_other) || "",
            problems_eating_meals: ( clientAssesmentPlan && clientAssesmentPlan.problems_eating_meals) || 'No',
            problems_eating_meals_notes: ( clientAssesmentPlan && clientAssesmentPlan.problems_eating_meals_notes) || "",
    
            enteral_feeding: ( clientAssesmentPlan && clientAssesmentPlan.enteral_feeding) || 'No',
            enteral_feeding_notes: ( clientAssesmentPlan && clientAssesmentPlan.enteral_feeding_notes) || "",
            continence: ( clientAssesmentPlan && clientAssesmentPlan.continence) || 'No',
            continence_notes: ( clientAssesmentPlan && clientAssesmentPlan.continence_notes) || "",
            cognition: ( clientAssesmentPlan && clientAssesmentPlan.cognition) || 'No',
            cognition_notes: ( clientAssesmentPlan && clientAssesmentPlan.cognition_notes) || "",
            referral_considered: ( clientAssesmentPlan && clientAssesmentPlan.referral_considered) || 'No',
            referral_considered_notes: ( clientAssesmentPlan && clientAssesmentPlan.referral_considered_notes) || "",
            mention_thoughts_death_sucide: ( clientAssesmentPlan && clientAssesmentPlan.mention_thoughts_death_sucide) || '',
            worried_upset: ( clientAssesmentPlan && clientAssesmentPlan.worried_upset) || 'No',
            worried_upset_notes: ( clientAssesmentPlan && clientAssesmentPlan.worried_upset_notes) || "",
            involved_activites: ( clientAssesmentPlan && clientAssesmentPlan.involved_activites) || 'No',
            involved_activites_notes: ( clientAssesmentPlan && clientAssesmentPlan.involved_activites_notes) || "",
            breathing_difficulties: ( clientAssesmentPlan && clientAssesmentPlan.breathing_difficulties) || 'No',
            breathing_difficulties_notes: ( clientAssesmentPlan && clientAssesmentPlan.breathing_difficulties_notes) || "",
            continual_user_oxygen: ( clientAssesmentPlan && clientAssesmentPlan.continual_user_oxygen) || 'No',
            continual_user_oxygen_notes: ( clientAssesmentPlan && clientAssesmentPlan.continual_user_oxygen_notes) || "",
            bathing_showering: ( clientAssesmentPlan && clientAssesmentPlan.bathing_showering) || "",
            undressing_dressing: ( clientAssesmentPlan && clientAssesmentPlan.undressing_dressing) || "",
            toileting_continence: ( clientAssesmentPlan && clientAssesmentPlan.toileting_continence) || "",
            self_care_notes: ( clientAssesmentPlan && clientAssesmentPlan.self_care_notes) || "",
            community_access: ( clientAssesmentPlan && clientAssesmentPlan.community_access) || 'No',
            community_access_notes: ( clientAssesmentPlan && clientAssesmentPlan.community_access_notes) || "",
            support_service_present: ( clientAssesmentPlan && clientAssesmentPlan.support_service_present) || 'No',
            support_service_present_notes: ( clientAssesmentPlan && clientAssesmentPlan.support_service_present_notes) || "",
            nurse_currently_visit: ( clientAssesmentPlan && clientAssesmentPlan.nurse_currently_visit) || 'No',
            nurse_currently_visit_notes: ( clientAssesmentPlan && clientAssesmentPlan.nurse_currently_visit_notes) || "",
            cognitively_impaired: ( clientAssesmentPlan && clientAssesmentPlan.cognitively_impaired) || 'No',
            cognitively_impaired_notes: ( clientAssesmentPlan && clientAssesmentPlan.cognitively_impaired_notes) || "",
            dependent_on_life_support_systems: ( clientAssesmentPlan && clientAssesmentPlan.dependent_on_life_support_systems) || 'No',
            dependent_on_life_support_systems_notes: ( clientAssesmentPlan && clientAssesmentPlan.dependent_on_life_support_systems_notes) || "",
            living_independently: ( clientAssesmentPlan && clientAssesmentPlan.living_independently) || 'No',
            living_independently_notes: ( clientAssesmentPlan && clientAssesmentPlan.living_independently_notes) || "",
            additional_information: ( clientAssesmentPlan && clientAssesmentPlan.additional_information) || "",
            additional_information_more: ( clientAssesmentPlan && clientAssesmentPlan.additional_information_more) || "",
        },
        validationSchema: Yup.object({
            // vaccination_flag: Yup.string().required("Required"),
        }),
        onSubmit: values => {
    
            // if( ClientDob == "" ) {
            //   toast.error("Please provide client date of birth", { autoClose: 5000 });
            //   return false;
            // }
    
            const formData = {
                type: type,
                plan_id: clientAssesmentPlan.id,
                user_id: clientID,
                create_date: createDate,
                prepared_by: values.prepared_by,
                living_arrangement: values.living_arrangement,
                carer_name : values.carer_name,
                carer_relationship : values.carer_relationship,
                ndis_number: values.ndis_number,
                ndis_plan_date: ndisPlanDate,
                preferred_lang: values.preferred_lang,
                ndis_plan_organisation: values.ndis_plan_organisation,
                ndis_plan_cm: values.ndis_plan_cm,
                ndis_plan_cm_contact: values.ndis_plan_cm_contact,
                date_signed : dateSigned,
                date_commenced : dateCommenced,
                lang_interpreter_required : values.lang_interpreter_required,
                lang_interpreter_lang :values.lang_interpreter_lang,
                indigenous_status : values.indigenous_status,
                general_practitioner: values.general_practitioner,
                general_practitioner_ph_no: values.general_practitioner_ph_no,
                history_first: values.history_first,
                history_second: values.history_second,
                history_third: values.history_third,
                history_fourth: values.history_fourth,
                history_fifth: values.history_fifth,
                history_sixth: values.history_sixth,
                allergies: values.allergies,
                manage_own_medication: values.manage_own_medication,
                manage_own_medication_notes: values.manage_own_medication_notes,
                other_medication_management: values.other_medication_management,
                other_medication_management_notes: values.other_medication_management_notes,
                pain_interfere_everyday_life: values.pain_interfere_everyday_life,
                pain_interfere_everyday_life_notes: values.pain_interfere_everyday_life_notes,
                foot_problems: values.foot_problems,
                foot_problems_notes: values.foot_problems_notes,
                falls_last_12_months: values.falls_last_12_months,
                falls_last_12_months_notes: values.falls_last_12_months_notes,
                something_done_reduce_fall: values.something_done_reduce_fall,
                something_done_reduce_fall_notes: values.something_done_reduce_fall_notes,
                mobility_limiting: values.mobility_limiting,
                mobility_limiting_notes: values.mobility_limiting_notes,
                aids: Aids,
                aids_other: values.aids_other,
                problems_eating_meals: values.problems_eating_meals,
                problems_eating_meals_notes: values.problems_eating_meals_notes,
        
                enteral_feeding: values.enteral_feeding,
                enteral_feeding_notes: values.enteral_feeding_notes,
                continence: values.continence,
                continence_notes: values.continence_notes,
                cognition: values.cognition,
                cognition_notes: values.cognition_notes,
                referral_considered: values.referral_considered,
                referral_considered_notes: values.referral_considered_notes,
                mention_thoughts_death_sucide: values.mention_thoughts_death_sucide,
                worried_upset: values.worried_upset,
                worried_upset_notes: values.worried_upset_notes,
                involved_activites: values.involved_activites,
                involved_activites_notes: values.involved_activites_notes,
                breathing_difficulties: values.breathing_difficulties,
                breathing_difficulties_notes: values.breathing_difficulties_notes,
                continual_user_oxygen: values.continual_user_oxygen,
                continual_user_oxygen_notes: values.continual_user_oxygen_notes,
                bathing_showering: values.bathing_showering,
                undressing_dressing: values.undressing_dressing,
                toileting_continence: values.toileting_continence,
                self_care_notes: values.self_care_notes,
                community_access: values.community_access,
                community_access_options: clientCommunityAccess,
                community_access_notes: values.community_access_notes,
                support_service_present: values.support_service_present,
                support_service_present_notes: values.support_service_present_notes,
                nurse_currently_visit: values.nurse_currently_visit,
                nurse_currently_visit_notes: values.nurse_currently_visit_notes,
                cognitively_impaired: values.cognitively_impaired,
                cognitively_impaired_notes: values.cognitively_impaired_notes,
                dependent_on_life_support_systems: values.dependent_on_life_support_systems,
                dependent_on_life_support_systems_notes: values.dependent_on_life_support_systems_notes,
                living_independently: values.living_independently,
                living_independently_notes: values.living_independently_notes,
                additional_information: values.additional_information,
                additional_information_more: values.additional_information_more,   
            }
    
            console.log( formData);

            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'update_assesment_emergency_plan','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {
                    // setType('edit');
                    setActivityLog(activityLog => [fetchedData.activity_log,...activityLog]);
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }
    
                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.error, { autoClose: 5000 });
                }
    
            })
    
        }
    })

    useEffect(() => {

        if( show ) {

        const formData = {
            uid: clientID
        }
        setModalLoading(true)
        axios.post(APP_URL+ACTION_URL,{'data_for':'client_assesment_emergency_plan','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {

                setType('edit');
                setClientAssesmentPlan(fetchedData.data);
                setAids(fetchedData.aids);
                setClientCommunityAccess(fetchedData.client_community_options);
                setActivityLog(fetchedData.activity_log);
                toast.success(fetchedData.html, { autoClose: 5000 });
            }

            if( fetchedData.status == 'error' ) {
                setType('add');
                setPlanID('');
                // toast.error(fetchedData.error, { autoClose: 5000 });
            }

        })
        }
    },[show])


    // function textareachange(event) {
    //     const count = event.target.value.length;
    //     if (count > 0) {
    //       settextareabadge(true);
    //     } else {
    //       settextareabadge(false);
    //     }
    //     settextcount(event.target.value.length);
    // }

    const handleAids = (event) => {
        const checkedId = event.target.value;
        console.log(checkedId);
        if( event.target.checked ) {
            setAids([...Aids, checkedId]);
        } else{
            setAids( Aids.filter(id =>id != checkedId));
        }
    }

    const handleCommunity = (event) => {
        const checkedId = event.target.value;
        if( event.target.checked ) {
            setClientCommunityAccess([...clientCommunityAccess, checkedId]);
        } else{
            setClientCommunityAccess( clientCommunityAccess.filter(id =>id != checkedId));
        }
    }

    const handleSubmitBtn = () => {
        validation.handleSubmit();
    }

    return (
        <>

        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable  s>
            <ModalHeader toggle={onCloseModal} tag="h4">
                Client Assesment Care Plan{ modalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={8}>
                    <Form >
                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label>Client First Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // onChange={validation.handleChange}
                                        value={validation.values.client_fname || clientFname}
                                        readonly
                                    />
                                </div>
                                </Col>
                                <Col md={6}>
                                <div className="mb-3">
                                    <Label>Client Last Name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // onChange={validation.handleChange}
                                        value={validation.values.client_lname || clientLname}
                                        readonly
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="mb-3">
                                    <Label>Date Created</Label>
                                    <InputGroup>
                                    <Flatpickr
                                        name="create_date"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setCreateDate(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.create_date || createDate}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                    </InputGroup>
                                </div>
                                </Col>
                                <Col md={6}>
                                <div className="mb-3">
                                    <Label>Prepared By</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        value={validation.values.prepared_by}
                                        name="prepared_by"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br /> <br/><CardTitle>Living Arrangements</CardTitle>

                        <div className="mb-3">
                            {/* <Label>First Name</Label> */}
                            <Input
                                type="radio"
                                onChange={validation.handleChange}
                                value="resident_carer"
                                name="living_arrangement"
                            /> &nbsp;Resident Carer
                            &nbsp;&nbsp;

                            <Input
                                type="radio"
                                onClick={validation.handleChange}
                                value="no_co_resident_carer"
                                name="living_arrangement"
                            /> &nbsp;No Co Resident Carer
                            &nbsp;&nbsp;

                            <Input
                                type="radio"
                                
                                onClick={validation.handleChange}
                                value="no_carer"
                                name="living_arrangement"
                            /> &nbsp;No Carer
                            &nbsp;&nbsp;
                        </div>

                        <br /> <br/><CardTitle>Client Information</CardTitle>

                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Carer's Name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.carer_name}
                                    name="carer_name"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Relationship</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.carer_relationship}
                                    name="carer_relationship"
                                />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>NDIS Number</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.ndis_number}
                                    name="ndis_number"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>NDIS Plan Date</Label>
                                <InputGroup>
                                    <Flatpickr
                                        name="ndis_plan_date"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setNdisPlanDate(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ndis_plan_date || ndisPlanDate}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Preferred Language</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.preferred_lang}
                                    name="preferred_lang"
                                />
                            </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>NDIS Plan Manager/Support Coordinator</CardTitle>

                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Organisation</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.ndis_plan_organisation}
                                    name="ndis_plan_organisation"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Case Manager</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.ndis_plan_cm}
                                    name="ndis_plan_cm"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Contact Details</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.ndis_plan_cm_contact}
                                    name="ndis_plan_cm_contact"
                                />
                            </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Date Signed</Label>
                                <InputGroup>
                                    <Flatpickr
                                        name="date_signed"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setDateSigned(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.date_signed || dateSigned}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Date Commenced</Label>
                                <InputGroup>
                                    <Flatpickr
                                        name="date_commenced"
                                        type="text"
                                        className="form-control d-block"
                                        placeholder="dd-mm-YYYY"
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setDateCommenced(dateStr);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.date_commenced || dateCommenced}
                                        options={{
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: 'today',
                                        "locale": {
                                                "firstDayOfWeek": 1 // start week on Monday
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Language</CardTitle>

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="1"
                                        name="lang_interpreter_required"
                                        checked={validation.values.lang_interpreter_required == 1 ? true : false }
                                    /> &nbsp;Interpreter Required 
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="0"
                                        name="lang_interpreter_required"
                                        checked={validation.values.lang_interpreter_required == 0 ? true : false }
                                    /> &nbsp;Interpreter Not Required
                                    &nbsp;&nbsp;
                                </div>

                                <div className="mb-3">
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.lang_interpreter_lang}
                                    name="lang_interpreter_lang"
                                    placeholder="Language(specify)"
                                />
                                </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Indigenous Status</CardTitle>

                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="aboriginal"
                                        name="indigenous_status"
                                        checked={validation.values.indigenous_status == 'aboriginal' ? true : false }
                                    /> &nbsp;Aboriginal 
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="torres_strait_islander"
                                        name="indigenous_status"
                                        checked={validation.values.indigenous_status == 'torres_strait_islander' ? true : false }
                                    /> &nbsp;Torres Strait Islander
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="does_no_identity"
                                        name="indigenous_status"
                                    /> &nbsp;Does Not Identify
                                    checked={validation.values.indigenous_status == 'does_no_identity' ? true : false }
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Client Health Professional</CardTitle>

                        <Row>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>General Practitioner</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.general_practitioner}
                                    name="general_practitioner"
                                />
                            </div>
                            </Col>
                            <Col lg={4}>
                            <div className="mb-3">
                                <Label>Phone No</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.general_practitioner_ph_no}
                                    name="general_practitioner_ph_no"
                                />
                            </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Tell us about your recent Health and Medical History</CardTitle>
                        <Row>
                            <Col lg={4}>
                            <label>1</label>
                            <Input
                                type="textarea"
                                name="history_first"
                                value={validation.values.history_first}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.history_first.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_first.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>2</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="history_second"
                                value={validation.values.history_second}
                                rows="3"
                                />
                                {validation.values.history_second.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_second.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>3</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="history_third"
                                value={validation.values.history_third}
                                rows="3"
                                />
                                {validation.values.history_third.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_third.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>
                            <label>4</label>
                            <Input
                                type="textarea"
                                name="history_fourth"
                                value={validation.values.history_fourth}
                                onChange={validation.handleChange}
                                maxLength="225"
                                rows="3"
                                />
                                {validation.values.history_fourth.length > 0 ? ( 
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_fourth.length} / 225{" "}
                                </span>
                                ) : null }
                            </Col>
                            <Col lg={4}>
                            <label>5</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="history_fifth"
                                value={validation.values.history_fifth}
                                rows="3"
                                />
                                {validation.values.history_fifth.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_fifth.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>

                            <Col lg={4}>
                            <label>6</label>
                            <Input
                                type="textarea"
                                onChange={validation.handleChange}
                                maxLength="225"
                                name="history_sixth"
                                value={validation.values.history_sixth}
                                rows="3"
                                />
                                {validation.values.history_sixth.length > 0 ? (
                                <span className="badgecount badge bg-success">
                                    {" "}
                                    {validation.values.history_sixth.length} / 225{" "}
                                </span>
                                ) : null}
                            
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Allergies</label>
                                <em>(Medication, foods, chemicals, latex)</em>
                                <Input
                                    type="textarea"
                                    name="allergies"
                                    value={validation.values.allergies}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.allergies.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.allergies.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/><CardTitle>Medication</CardTitle>

                        <Row>
                            <Col md={12}>
                            <label>Do you manage your own medication?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="manage_own_medication"
                                        checked={validation.values.manage_own_medication == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="manage_own_medication"
                                        checked={validation.values.manage_own_medication == 'No' ? true : false }
                                    /> &nbsp;No
                                    
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="manage_own_medication_notes"
                                    value={validation.values.manage_own_medication_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.manage_own_medication_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.manage_own_medication_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/>
                        {/* <CardTitle>Medication</CardTitle> */}

                        <Row>
                            <Col md={12}>
                            <label>Do you or others in your life, have any concerns about medication management and effects?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="other_medication_management"
                                        checked={validation.values.other_medication_management == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="other_medication_management"
                                        checked={validation.values.other_medication_management == 'No' ? true : false }
                                    /> &nbsp;No
                                    
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="other_medication_management_notes"
                                    value={validation.values.other_medication_management_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.other_medication_management_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.other_medication_management_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/><CardTitle>Body Functions and Structures</CardTitle>
                        <p>Any concerns with body functions and structures, consider referrals to Occupational Therapy, Physiotherapy, Podiatry, Nursing Services and/ or GP review.</p>

                        <Row>
                            <Col md={12}>
                            <label>Does your pain interfere with your everyday life?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="pain_interfere_everyday_life"
                                        checked={validation.values.pain_interfere_everyday_life == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="pain_interfere_everyday_life"
                                        checked={validation.values.pain_interfere_everyday_life == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                    
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="pain_interfere_everyday_life_notes"
                                    value={validation.values.pain_interfere_everyday_life_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.pain_interfere_everyday_life_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.pain_interfere_everyday_life_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                         <br /> <br/>{/*<CardTitle>Body Functions and Structures</CardTitle> */}
                        
                        <Row>
                            <Col md={12}>
                            <label>Any foot problems?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="foot_problems"
                                        checked={validation.values.foot_problems == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="foot_problems"
                                        checked={validation.values.foot_problems == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="foot_problems_notes"
                                    value={validation.values.foot_problems_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.foot_problems_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.foot_problems_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/><CardTitle>Falls and Balance</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label>Have you had any falls in the last 12 months?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="falls_last_12_months"
                                        checked={validation.values.falls_last_12_months == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="falls_last_12_months"
                                        checked={validation.values.falls_last_12_months == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>If yes, provide details</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="falls_last_12_months_notes"
                                    value={validation.values.falls_last_12_months_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.falls_last_12_months_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.falls_last_12_months_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/><CardTitle>Could there be something done to reduce the risk of fall?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            {/* <label>Could there be something done to reduce the risk of fall?</label> */}
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="something_done_reduce_fall"
                                        checked={validation.values.something_done_reduce_fall == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="something_done_reduce_fall"
                                        checked={validation.values.something_done_reduce_fall == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>What?</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="something_done_reduce_fall_notes"
                                    value={validation.values.something_done_reduce_fall_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.something_done_reduce_fall_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.something_done_reduce_fall_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/><CardTitle>Mobility</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label>Is your mobility limiting things you do in your everyday life?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="mobility_limiting"
                                        checked={validation.values.mobility_limiting == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="mobility_limiting"
                                        checked={validation.values.mobility_limiting == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>If yes, provide details.</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="mobility_limiting_notes"
                                    value={validation.values.mobility_limiting_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.mobility_limiting_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.mobility_limiting_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>

                        <br /> <br/>
                        <CardTitle>Do you use any of the following aids?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label>Is your mobility limiting things you do in your everyday life?</label>
                                <div className="mb-3">
                                    <Input
                                        type="checkbox"
                                        onChange={(event) => handleAids(event)}
                                        value="rails_in_place"
                                        name="aids"
                                        // defaultChecked={Aids.includes('rails_in_place')}
                                    /> &nbsp;Rails in place
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(event) => handleAids(event)}
                                        value="4WW"
                                        name="aids"
                                        // defaultChecked={Aids.includes('4WW')}
                                    /> &nbsp;4WW
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(event) => handleAids(event)}
                                        value="shower_chair"
                                        name="aids"
                                        // defaultChecked={Aids.includes('shower_chair')}

                                    /> &nbsp;Shower Chair
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(event) => handleAids(event)}
                                        value="wheelchair"
                                        name="aids"
                                        // defaultChecked={Aids.includes('wheelchair')}
                                    /> &nbsp;Wheelchair
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(event) => handleAids(event)}
                                        value="lifting_machine"
                                        name="aids"
                                        // defaultChecked={Aids.includes('lifting_machine')}
                                    /> &nbsp;Lifting Machine
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Other?</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="aids_other"
                                    value={validation.values.aids_other}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.aids_other.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.aids_other.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>


                        <br /> <br/><CardTitle>Feeding</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label>Do you have any problems eating meals?</label>
                            <em>(swallowing difficulties, weight loss, and reduced appetite)</em>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="problems_eating_meals"
                                        checked={validation.values.problems_eating_meals == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="problems_eating_meals"
                                        checked={validation.values.problems_eating_meals == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>What?</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="problems_eating_meals_notes"
                                    value={validation.values.problems_eating_meals_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.problems_eating_meals_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.problems_eating_meals_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Do you have an ongoing medical need that requires enteral feeding? (PEG feeding)</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="enteral_feeding"
                                        checked={validation.values.enteral_feeding == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="enteral_feeding"
                                        checked={validation.values.enteral_feeding == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="enteral_feeding_notes"
                                    value={validation.values.enteral_feeding_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.enteral_feeding_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.enteral_feeding_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Continence</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                                <label>Any concerns with continence?</label>
                                <em>(urinary leakage, bowel concerns, frequent urination, urinary accidents)</em>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="continence"
                                        checked={validation.values.continence == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="continence"
                                        checked={validation.values.continence == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="continence_notes"
                                    value={validation.values.continence_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.continence_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.continence_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Cognition</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                                <label>Do you experience problems with memory or thinking?</label>
                                
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="cognition"
                                        checked={validation.values.cognition == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="cognition"
                                        checked={validation.values.cognition == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="cognition_notes"
                                    value={validation.values.cognition_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.cognition_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.cognition_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Should Referral be considered for this person for Assessment Scale?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="referral_considered"
                                        checked={validation.values.referral_considered == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="referral_considered"
                                        checked={validation.values.referral_considered == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="referral_considered_notes"
                                    value={validation.values.referral_considered_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.referral_considered_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.referral_considered_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>If a client mentions thoughts of death or suicide, contact mental health team appropriate for client immediately (on return to office).</CardTitle>

                        <Row>
                            <Col lg={12}>
                                <label>Additional notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="mention_thoughts_death_sucide"
                                    value={validation.values.mention_thoughts_death_sucide}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.mention_thoughts_death_sucide.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.mention_thoughts_death_sucide.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Wellbeing</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label>Do you experience times when you persistently feel worried or upset about things?</label>
                            <em>(Depression or Anxiety)</em>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="worried_upset"
                                        checked={validation.values.worried_upset == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="worried_upset"
                                        checked={validation.values.worried_upset == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="worried_upset_notes"
                                    value={validation.values.worried_upset_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.worried_upset_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.worried_upset_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Would you be more involved in activities if your sense of wellbeing improved?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="involved_activites"
                                        checked={validation.values.involved_activites == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="involved_activites"
                                        checked={validation.values.involved_activites == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="involved_activites_notes"
                                    value={validation.values.involved_activites_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.involved_activites_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.involved_activites_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Breathing</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label htmlFor="">Any concerns with breathing difficulties?</label>
                            <em>Document any findings eg. shortness of breath on exertion</em>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="breathing_difficulties"
                                        checked={validation.values.breathing_difficulties == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="breathing_difficulties"
                                        checked={validation.values.breathing_difficulties == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="breathing_difficulties_notes"
                                    value={validation.values.breathing_difficulties_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.breathing_difficulties_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.breathing_difficulties_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Does this person require an ongoing medical need that requires the continual use of Oxygen?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="continual_user_oxygen"
                                        checked={validation.values.continual_user_oxygen == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="continual_user_oxygen"
                                        checked={validation.values.continual_user_oxygen == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="continual_user_oxygen_notes"
                                    value={validation.values.continual_user_oxygen_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.continual_user_oxygen_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.continual_user_oxygen_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        

                        <br /> <br/><CardTitle>Self-care</CardTitle>
                        
                        <Row>
                            
                            <label htmlFor="">Level of Assistance required</label>

                                <Col md={3}>
                                    <label>Bathing/Showering</label><br />
                                    <label>Undressing/Dressing</label><br />
                                    <label>Toileting Continence</label><br />
                                </Col>
                                <Col md={3}>
                                <Input
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value="independent"
                                    name="bathing_showering"
                                    checked={validation.values.bathing_showering == 'independent' ? true : false }
                                    /> &nbsp;Independent
                                    &nbsp;&nbsp;<br />

                                <Input
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value="independent"
                                    name="undressing_dressing"
                                    checked={validation.values.undressing_dressing == 'independent' ? true : false }
                                /> &nbsp;Independent
                                &nbsp;&nbsp;<br />

                                <Input
                                    type="radio"
                                    onClick={validation.handleChange}
                                    value="independent"
                                    name="toileting_continence"
                                    checked={validation.values.toileting_continence == 'independent' ? true : false }
                                /> &nbsp;Independent
                                &nbsp;&nbsp;<br />

                                </Col>
                                <br />
                                <Col md={3}>
                                    <div className="mb-3">
                                        <Input
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value="supervised"
                                            name="bathing_showering"
                                            checked={validation.values.bathing_showering == 'supervised' ? true : false }
                                        /> &nbsp;Supervised
                                        &nbsp;&nbsp;<br />

                                        <Input
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value="supervised"
                                            name="undressing_dressing"
                                            checked={validation.values.undressing_dressing == 'supervised' ? true : false }
                                        /> &nbsp;Supervised
                                        &nbsp;&nbsp;<br />

                                        <Input
                                            type="radio"
                                            onClick={validation.handleChange}
                                            value="supervised"
                                            name="toileting_continence"
                                            checked={validation.values.toileting_continence == 'supervised' ? true : false }
                                        /> &nbsp;Supervised
                                        &nbsp;&nbsp;
                                    </div>
                                </Col>
                                <br />

                                <Col md={3}>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="full_assist"
                                        name="bathing_showering"
                                        checked={validation.values.bathing_showering == 'full_assist' ? true : false }
                                    /> &nbsp;Full Assist
                                    &nbsp;&nbsp;<br />

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="full_assist"
                                        name="undressing_dressing"
                                        checked={validation.values.undressing_dressing == 'full_assist' ? true : false }
                                    /> &nbsp;Full Assist
                                    &nbsp;&nbsp;<br />

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="full_assist"
                                        name="toileting_continence"
                                        checked={validation.values.toileting_continence == 'full_assist' ? true : false }
                                    /> &nbsp;Full Assist
                                    &nbsp;&nbsp;
                                </div>
                                </Col>

                        
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Additional notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="self_care_notes"
                                    value={validation.values.self_care_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.self_care_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.self_care_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/>
                        <CardTitle>Do you access the community?</CardTitle>

                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="community_access"
                                        checked={validation.values.community_access == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="community_access"
                                        checked={validation.values.community_access == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Other?</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="community_access_notes"
                                    value={validation.values.community_access_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.community_access_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.community_access_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                            
                        </Row>
                        
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Input
                                        type="checkbox"
                                        onChange={(e) => handleCommunity(e)}
                                        value="currently_driving"
                                        name="community_access_options"
                                    /> &nbsp;Currently Driving
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(e) => handleCommunity(e)}
                                        value="taxi_assistance"
                                        name="community_access_options"
                                    /> &nbsp;Taxi Assistance
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(e) => handleCommunity(e)}
                                        value="multi_purpose_taxi_card"
                                        name="community_access_options"
                                    /> &nbsp;Multi-purpose Taxi Card
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(e) => handleCommunity(e)}
                                        value="family_assistance"
                                        name="community_access_options"
                                    /> &nbsp;Family/Carer Assistance
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onChange={(e) => handleCommunity(e)}
                                        value="use_public_transport"
                                        name="community_access_options"
                                    /> &nbsp;Uses Public Transport
                                    &nbsp;&nbsp;

                                    <Input
                                        type="checkbox"
                                        onChange={(e) => handleCommunity(e)}
                                        value="disability_parking_permit"
                                        name="community_access_options"
                                    /> &nbsp;Disability Parking Permit
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Current Support Services</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            <label htmlFor="">Do you have any support services in place at present?</label>
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="support_service_present"
                                        checked={validation.values.support_service_present == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="support_service_present"
                                        checked={validation.values.support_service_present == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="support_service_present_notes"
                                    value={validation.values.support_service_present_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.support_service_present_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.support_service_present_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Does a nurse currently visit?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="nurse_currently_visit"
                                        checked={validation.values.nurse_currently_visit == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="nurse_currently_visit"
                                        checked={validation.values.nurse_currently_visit == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>List services that they provide</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="nurse_currently_visit_notes"
                                    value={validation.values.nurse_currently_visit_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.nurse_currently_visit_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.nurse_currently_visit_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br /> <br/><CardTitle>Vulnerable Clients Register</CardTitle>
                        <em>(Client to be added to At Risk Register)</em>
                    
                        
                        <Row>
                            <Col md={12}>
                            <label htmlFor="">Is client cognitively impaired with no identified support person?</label>
                                <div className="mb-3">
                                    
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="cognitively_impaired"
                                        checked={validation.values.cognitively_impaired == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="cognitively_impaired"
                                        checked={validation.values.cognitively_impaired == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="cognitively_impaired_notes"
                                    value={validation.values.cognitively_impaired_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.cognitively_impaired_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.cognitively_impaired_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>


                        <br /> <br/><CardTitle>Is client living independently, socially isolated, no identified support person and considered extreme risk due to lack of insight?</CardTitle>
                        
                        <Row>
                            <Col md={12}>
                            
                                <div className="mb-3">
                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="Yes"
                                        name="living_independently"
                                        checked={validation.values.living_independently == 'Yes' ? true : false }
                                    /> &nbsp;Yes
                                    &nbsp;&nbsp;

                                    <Input
                                        type="radio"
                                        onClick={validation.handleChange}
                                        value="No"
                                        name="living_independently"
                                        checked={validation.values.living_independently == 'No' ? true : false }
                                    /> &nbsp;No
                                    &nbsp;&nbsp;
                                </div>
                            </Col>
                        </Row>

                        <br/>

                        <Row>
                            <Col lg={12}>
                                <label>Notes</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="living_independently_notes"
                                    value={validation.values.living_independently_notes}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.living_independently_notes.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.living_independently_notes.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br/>

                        <Row>
                            <Col lg={12}>
                                <label>Additional Information</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="additional_information"
                                    value={validation.values.additional_information}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.additional_information.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.additional_information.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        <br/>

                        <Row>
                            <Col lg={12}>
                                <label>Additional Information More</label>
                                <em></em>
                                <Input
                                    type="textarea"
                                    name="additional_information_more"
                                    value={validation.values.additional_information_more}
                                    onChange={validation.handleChange}
                                    maxLength="225"
                                    rows="3"
                                    />
                                    {validation.values.additional_information_more.length > 0 ? ( 
                                    <span className="badgecount badge bg-success">
                                        {" "}
                                        {validation.values.additional_information_more.length} / 225{" "}
                                    </span>
                                    ) : null }
                            </Col>
                        </Row>

                        </Form>
                    </Col>
                    <Col md={4}>
                    <CardTitle>Activity Log</CardTitle>

                        <div className="order_notes_div">
            
                            <ul className="order_notes">
                                { activityLog.map(activity => (
                                <li key={activity.id} rel={activity.id} className="note">
                                    <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                    <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                    <strong>
                                    {activity.activity_by}
                                    </strong>
                                    </abbr></p>
                                </li>
                                ))}
                            </ul>
                        
                        </div>

                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div>
                    <button type="btn" disabled={modalLoading} onClick={handleSubmitBtn} className="btn btn-primary w-md">
                    { type == 'edit' ? 'Update' : 'Add' }
                    </button>
                </div>
            </ModalFooter>
        
    </Modal>

        </> 
    )

}

    ClientAssementCarePlanModal.propTypes = {
        onCloseModal: PropTypes.func,
        show: PropTypes.any,
        handleSubmitBtn: PropTypes.func
    }

export default ClientAssementCarePlanModal;