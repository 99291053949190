import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast , ToastContainer} from "react-toastify";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { ACTION_URL, APP_URL, GET_CHECKLIST_ALERTS_PAGE, } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";


  const ChecklistAlertsModal = ({ID, show, onCloseModal}) => {


    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [roster, setRoster] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);

    //roster notes section
    const [RosterNotesList, setRosterNotesList] = useState([]);
    const [RosterNotes, setRosterNotes] = useState('');

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            feeling_unvell : ( roster && roster.feeling_unvell) || "",
            symptoms: ( roster && roster.symptoms) || "",
            contact_unvell_person: ( roster && roster.contact_unvell_person) || "",
            contact_positive_person: ( roster && roster.contact_positive_person) || "",
            visited_doctor: ( roster && roster.visited_doctor) || "",
            flu_vaccination: ( roster && roster.flu_vaccination) || "",
            annual_flu_vaccination: ( roster && roster.annual_flu_vaccination) || "",
            visited_covid_hotspots: ( roster && roster.visited_covid_hotspots) || "",
            is_isolate: ( roster && roster.is_isolate) || "",
        },
        validationSchema: Yup.object({

        }),
        onSubmit: values => {

        //   console.log(values);

        //   console.log(verifiedStatus);
    

            const updateUser = {
                roster_id: roster.roster_id,
                feeling_unvell: values.feeling_unvell,
                symptoms: values.symptoms,
                contact_unvell_person: values.contact_unvell_person,
                contact_positive_person: values.contact_positive_person,
                visited_doctor: values.visited_doctor,
                flu_vaccination: values.flu_vaccination,
                annual_flu_vaccination: values.annual_flu_vaccination,
                visited_covid_hotspots: values.visited_covid_hotspots,
                is_isolate: values.is_isolate,
            };

            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'edit_checklist_alert','formData': updateUser,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;

                if( fetchedData.status == 'success' ) {
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }

                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                }
            })
            
        },
    })

    useEffect(() => {
        if( show ) { 
          setRoster([]);
          setRosterNotesList([]);
            const formData = {
                checklist_id : ID
            }
            setModalLoading(true);
            axios.post(APP_URL+GET_CHECKLIST_ALERTS_PAGE,{'data_for':'load_checklist_alert_details', 'formData': formData, 'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;

                setRoster({
                    id: fetchedData.checklistDetails.id,
                    roster_id: fetchedData.checklistDetails.roster_id,
                    roster_date: fetchedData.checklistDetails.roster_date,
                    client_name: fetchedData.checklistDetails.client_name,
                    staff_name: fetchedData.checklistDetails.staff_name,
                    start_time: fetchedData.checklistDetails.start_time,
                    client_department: fetchedData.checklistDetails.client_department,
                    case_manager: fetchedData.checklistDetails.case_manager,

                    feeling_unvell: fetchedData.checklistDetails.feeling_unvell,
                    symptoms: fetchedData.checklistDetails.symptoms,
                    contact_unvell_person: fetchedData.checklistDetails.contact_unvell_person,
                    contact_positive_person: fetchedData.checklistDetails.contact_positive_person,
                    visited_doctor: fetchedData.checklistDetails.visited_doctor,
                    flu_vaccination: fetchedData.checklistDetails.flu_vaccination,
                    annual_flu_vaccination: fetchedData.checklistDetails.annual_flu_vaccination,
                    visited_covid_hotspots: fetchedData.checklistDetails.visited_covid_hotspots,
                    is_isolate: fetchedData.checklistDetails.is_isolate,
                })
                setRosterNotesList(fetchedData.rostersActivity);
            })
        }
    }, [show])

    const addRosterNotes = () => {

        if( RosterNotes == '' ) {
          toast.error("Please add notes", { autoClose: 5000 });
          return false;
        }
        
        const RosterNotesForm = {
            ros_id: roster.roster_id,
            rosterNotes: RosterNotes,
        }
    
        setModalLoading(true);
        axios.post(APP_URL+ACTION_URL,{'data_for':'add_roster_notes','formData': RosterNotesForm,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
              setRosterNotesList(RosterNotesList => [fetchedData.data,...RosterNotesList]);
              setRosterNotes('');
            }
    
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      }

    return (
        <>
        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={onCloseModal} tag="h4">
                Roster Details{ ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>
            
                <Row>
                    <Col md={8}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Roster ID & Date</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.roster_id + '-' + roster.roster_date}
                                    />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Client name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.client_name}
                                    />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Staff name</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.staff_name}
                                    />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Start Time</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.start_time}
                                    />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Client Department</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.client_department}
                                    />
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-password-Input">Case Manager</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={roster.case_manager}
                                    />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Are you or other household members feeling unwell?</Label>
                                    <Input
                                        name="feeling_unvell"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="feeling_unvell"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Symptons?</Label>

                                    <Input
                                        name="symptoms"
                                        type="radio"
                                        value="Fever"
                                        onClick={validation.handleChange}
                                        checked={validation.values.symptoms == 'Fever' ? true : false }
                                    />&nbsp;&nbsp;Fever
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="symptoms"
                                        type="radio"
                                         value="Cough"
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 'Cough' ? true : false }
                                    />&nbsp;&nbsp;Cough
                                    
                                    <Input
                                        name="symptoms"
                                        type="radio"
                                         value="Sore throat"
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 'Sore throat' ? true : false }
                                    />&nbsp;&nbsp;Sore throat
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="symptoms"
                                        type="radio"
                                         value="Shortness of breath"
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 'Shortness of breath' ? true : false }
                                    />&nbsp;&nbsp;Shortness of breath
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="symptoms"
                                        type="radio"
                                         value="Just generally feeling unwell"
                                        onClick={validation.handleChange}
                                        checked={validation.values.feeling_unvell == 'Just generally feeling unwell' ? true : false }
                                    />&nbsp;&nbsp;Just generally feeling unwell
                                    
                                    </div>
                                </Col>
                                
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you or other household member been in contact with another person who has been unwell?</Label>
                                    <Input
                                        name="contact_unvell_person"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.contact_unvell_person == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="contact_unvell_person"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.contact_unvell_person == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you been in contact with someone who has been confirmed positive with Covid-19?</Label>
                                    <Input
                                        name="contact_positive_person"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.contact_positive_person == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="contact_positive_person"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.contact_positive_person == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you visited or been visited by a doctor in the last week?</Label>
                                    <Input
                                        name="visited_doctor"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.visited_doctor == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="visited_doctor"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.visited_doctor == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you recently had your Flu Vaccination?</Label>
                                    <Input
                                        name="flu_vaccination"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.flu_vaccination == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="flu_vaccination"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.flu_vaccination == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you had your annual Flu Vaccination?</Label>
                                    <Input
                                        name="annual_flu_vaccination"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.annual_flu_vaccination == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="annual_flu_vaccination"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.annual_flu_vaccination == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Have you visited any known COVID-19 hotspots in the last 14 days?</Label>
                                    <Input
                                        name="visited_covid_hotspots"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.visited_covid_hotspots == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="visited_covid_hotspots"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.visited_covid_hotspots == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="mb-3">
                                    <Label htmlFor="formrow-email-Input">Are you currently under any government direction to isolate?</Label>
                                    <Input
                                        name="is_isolate"
                                        type="radio"
                                        value={1}
                                        onClick={validation.handleChange}
                                        checked={validation.values.is_isolate == 1 ? true : false }
                                    />&nbsp;&nbsp;Yes
                                    
                                    &nbsp;&nbsp;
                                    <Input
                                        name="is_isolate"
                                        type="radio"
                                        value={0}
                                        onClick={validation.handleChange}
                                        checked={validation.values.is_isolate == 0 ? true : false }
                                    />&nbsp;&nbsp;No
                                    
                                    </div>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                        disabled={ModalLoading}
                                    >
                                        Save
                                    </button>
                                    </div>
                                </Col>
                            </Row>

                        </Form>

                    </Col>
                    
                    <Col md={4}>
                        <CardTitle>Activiry Log</CardTitle>

                        <Row>

                            <Col xs={12}>

                                <div className="mb-3">
                                <Label className="form-label">Add Notes</Label>
                                <Input
                                    name="roster_notes"
                                    type="textarea"
                                    onChange={e => {
                                    
                                    setRosterNotes(e.target.value);
                                    }}
                                    value={RosterNotes}
                                />
                                
                                </div>
                            
                            </Col>
                            
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button onClick={addRosterNotes}
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <ul className="order_notes">
                            { RosterNotesList.map(activity => (
                            <li key={activity.id} rel={activity.id} className="note">
                                <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                <strong>
                                {activity.activity_by}
                                </strong>
                                </abbr></p>
                            </li>
                            ))}
                        </ul>
                    
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
        <ToastContainer />
        </>
    )

}

ChecklistAlertsModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default ChecklistAlertsModal;