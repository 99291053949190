import {
  GET_BROADCAST_ROSTERS,
  GET_BROADCAST_ROSTERS_FAIL,
  GET_BROADCAST_ROSTERS_SUCCESS,
} from "./actionTypes"

export const getBroadcastRosters = (params,types) => ({
  type: GET_BROADCAST_ROSTERS,
  params,
  types,
})

export const getBroadcastRostersSuccess = data => ({
  type: GET_BROADCAST_ROSTERS_SUCCESS,
  payload: data,
})

export const getBroadcastRostersFail = error => ({
  type: GET_BROADCAST_ROSTERS_FAIL,
  payload: error,
})