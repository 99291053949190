import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SUPPORT_WORKERS,
  GET_ARCHIVED_SUPPORT_WORKERS,
  GET_STAFFS_SERVICE_TYPES,
  ADD_STAFF_SERVICE_TYPES,
  UPDATE_STAFF_SERVICE_TYPE,

  ADD_STAFF,
  UPDATE_STAFF,
  GET_STAFF_LANGUAGES,
  ADD_STAFF_LANGUAGES,
  UPDATE_STAFF_LANGUAGE,

  GET_CSM_STAFFS,
  ADD_CSM_STAFFS,
  UPDATE_CSM_STAFF,

  GET_SUPPORT_STAFFS,
  ADD_SUPPORT_STAFF,
  UPDATE_SUPPORT_STAFF,

  GET_ADMIN_STAFFS,
  ADD_ADMIN_STAFF,
  UPDATE_ADMIN_STAFF,

  GET_HR_MANAGERS,
  ADD_HR_MANAGER,
  UPDATE_HR_MANAGER,

} from "./actionTypes"

import {
  getSupportWorkersSuccess,
  getSupportWorkersFail,
  addStaffSuccess,
  addStaffFail,
  updateStaffSuccess,
  updateStaffFail,


  getArchivedSupportWorkersSuccess,
  getArchivedSupportWorkersFail,



  getStaffsServiceTypesSuccess,
  getStaffsServiceTypesFail,
  addStaffsServiceTypesSuccess,
  addStaffsServiceTypesFail,
  updateStaffsServiceTypeSuccess,
  updateStaffsServiceTypeFail,



  getStaffLanguagesSuccess,
  getStaffLanguagesFail,
  addStaffLanguageSuccess,
  addStaffLanguageFail,
  updateStaffLanguageSuccess,
  updateStaffLanguageFail,

  getCsmStaffsSuccess,
  getCsmStaffsFail,
  addCsmStaffsSuccess,
  addCsmStaffsFail,
  updateCsmStaffSuccess,
  updateCsmStaffFail,

  getSupportStaffsSuccess,
  getSupportStaffsFail,
  addSupportStaffSuccess,
  addSupportStaffFail,
  updateSupportStaffSuccess,
  updateSupportStaffFail,

  getAdminStaffsSuccess,
  getAdminStaffsFail,
  addAdminStaffSuccess,
  addAdminStaffFail,
  updateAdminStaffSuccess,
  updateAdminStaffFail,

  getHrManagersSuccess,
  getHrManagersFail,
  addHrManagerSuccess,
  addHrManagerFail,
  updateHrManagerSuccess,
  updateHrManagerFail

} from "./actions"


//Include Both Helper File with needed methods
import { getStaffs, actionUrl } from "helpers/fakebackend_helper"

function* fetchAllStaffs({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getStaffs, params, types )
    yield put(getSupportWorkersSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getSupportWorkersFail(error))
  }
}

function* fetchArchivedStaffs({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getStaffs, params, types )
    yield put(getArchivedSupportWorkersSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getArchivedSupportWorkersFail(error))
  }
}

function* fetchStaffsServiceTypes({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getStaffsServiceTypesSuccess(response))
  } catch (error) {
    yield put(getStaffsServiceTypesFail(error))
  }
}

function* addStaffsServicesTypes(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addStaffsServiceTypesSuccess(response))
  } catch (error) {
    yield put(addStaffsServiceTypesFail(error))
  }
}

function* updateStaffsServiceType(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateStaffsServiceTypeSuccess(response))
  } catch (error) {
    yield put(updateStaffsServiceTypeFail(error))
  }
}


// Staffs Languages List
function* fetchStaffsLanguages({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getStaffLanguagesSuccess(response))
  } catch (error) {
    yield put(getStaffLanguagesFail(error))
  }
}

function* addStaffLanguage(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addStaffLanguageSuccess(response))
  } catch (error) {
    yield put(addStaffLanguageSuccess(error))
  }
}

function* updateStaffLanguage(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateStaffLanguageSuccess(response))
  } catch (error) {
    yield put(updateStaffLanguageFail(error))
  }
}

// CSM Staffs List
function* fetchCsmStaffs({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getCsmStaffsSuccess(response))
  } catch (error) {
    yield put(getCsmStaffsFail(error))
  }
}

function* addCsmStaffs(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addCsmStaffsSuccess(response))
  } catch (error) {
    yield put(addCsmStaffsFail(error))
  }
}

function* updateCsmStaff(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateCsmStaffSuccess(response))
  } catch (error) {
    yield put(updateCsmStaffFail(error))
  }
}

// SUPPORT Staffs List
function* fetchSupportStaffs({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getSupportStaffsSuccess(response))
  } catch (error) {
    yield put(getSupportStaffsFail(error))
  }
}

function* addSupportStaff(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addSupportStaffSuccess(response))
  } catch (error) {
    yield put(addSupportStaffFail(error))
  }
}

function* updateSupportStaff(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateSupportStaffSuccess(response))
  } catch (error) {
    yield put(updateSupportStaffFail(error))
  }
}

// Admin Staffs List
function* fetchAdminStaffs({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getAdminStaffsSuccess(response))
  } catch (error) {
    yield put(getAdminStaffsFail(error))
  }
}

function* addAdminStaff(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addAdminStaffSuccess(response))
  } catch (error) {
    yield put(addAdminStaffFail(error))
  }
}

function* updateAdminStaff(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateAdminStaffSuccess(response))
  } catch (error) {
    yield put(updateAdminStaffFail(error))
  }
}

// Hr Managers
function* fetchHrManagers({params,types}) {
  try {
    const response = yield call(getStaffs,params,types)
    yield put(getHrManagersSuccess(response))
  } catch (error) {
    yield put(getHrManagersFail(error))
  }
}

function* addHrManager(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(addHrManagerSuccess(response))
  } catch (error) {
    yield put(addHrManagerFail(error))
  }
}

function* updateHrManager(data) {
  try {
    const response = yield call(getStaffs,data)
    yield put(updateHrManagerSuccess(response))
  } catch (error) {
    yield put(updateHrManagerFail(error))
  }
}


function* StaffsSaga() {
  yield takeEvery(GET_SUPPORT_WORKERS, fetchAllStaffs)
  yield takeEvery(GET_ARCHIVED_SUPPORT_WORKERS, fetchArchivedStaffs)
  yield takeEvery(GET_STAFFS_SERVICE_TYPES, fetchStaffsServiceTypes)
  yield takeEvery(ADD_STAFF_SERVICE_TYPES, addStaffsServicesTypes)
  yield takeEvery(UPDATE_STAFF_SERVICE_TYPE, updateStaffsServiceType)

  yield takeEvery(GET_STAFF_LANGUAGES, fetchStaffsLanguages)
  yield takeEvery(ADD_STAFF_LANGUAGES, addStaffLanguage)
  yield takeEvery(UPDATE_STAFF_LANGUAGE, updateStaffLanguage)

  yield takeEvery(GET_CSM_STAFFS, fetchCsmStaffs)
  yield takeEvery(ADD_CSM_STAFFS, addCsmStaffs)
  yield takeEvery(UPDATE_CSM_STAFF, updateCsmStaff)

  yield takeEvery(GET_SUPPORT_STAFFS, fetchSupportStaffs)
  yield takeEvery(ADD_SUPPORT_STAFF, addSupportStaff)
  yield takeEvery(UPDATE_SUPPORT_STAFF, updateSupportStaff)

  yield takeEvery(GET_ADMIN_STAFFS, fetchAdminStaffs)
  yield takeEvery(ADD_ADMIN_STAFF, addAdminStaff)
  yield takeEvery(UPDATE_ADMIN_STAFF, updateAdminStaff)


  yield takeEvery(GET_HR_MANAGERS, fetchHrManagers)
  yield takeEvery(ADD_HR_MANAGER, addHrManager)
  yield takeEvery(UPDATE_HR_MANAGER, updateHrManager)

//   yield takeEvery(GET_CLIENTS_ISOLATION_LIST, fetchClientsIsolationList)
//   yield takeEvery(UPDATE_CLIENTS_ISOLATION_LIST, updateClientsIsolationList)
//   yield takeEvery(ADD_CLIENTS_ISOLATION_LIST, addClientsIsolationList)
  
}

export default StaffsSaga
