import React from "react";
import { Navigate } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat";

// File Manager
import FileManager from "../pages/FileManager/index";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// //Tasks
// import TasksList from "../pages/Tasks/tasks-list";
// import TasksCreate from "../pages/Tasks/tasks-create";
// import TasksKanban from "../pages/Tasks/tasks-kanban";

// //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid";
// import ProjectsList from "../pages/Projects/projects-list";
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview";
// import ProjectsCreate from "../pages/Projects/projects-create";

// //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
// import EmailInbox from "../pages/Email/email-inbox";
// import EmailRead from "../pages/Email/email-read";
// import EmailBasicTemplte from "../pages/Email/email-basic-templte";
// import EmailAlertTemplte from "../pages/Email/email-template-alert";
// import EmailTemplateBilling from "../pages/Email/email-template-billing";

//Invoices
// import InvoicesList from "../pages/Invoices/invoices-list";
// import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";

// //Job
// import JobGrid from "../pages/JobPages/JobGrid/index";
// import JobDetails from "../pages/JobPages/JobDetails";
// import JobCategories from "../pages/JobPages/JobCategories";
// import JobList from "../pages/JobPages/JobList";
// import ApplyJobs from "../pages/JobPages/ApplyJobs/index";
// import CandidateList from "../pages/JobPages/CandidateList";
// import CandidateOverview from "../pages/JobPages/CandidateOverview";

// // Forms
// import FormElements from "../pages/Forms/FormElements";
// import FormLayouts from "../pages/Forms/FormLayouts";
// import FormAdvanced from "../pages/Forms/FormAdvanced";
// import FormEditors from "../pages/Forms/FormEditors";
// import FormValidations from "../pages/Forms/FormValidations";
// import FormMask from "../pages/Forms/FormMask";
// import FormRepeater from "../pages/Forms/FormRepeater";
// import FormUpload from "../pages/Forms/FormUpload";
// import FormWizard from "../pages/Forms/FormWizard";

// //Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
// import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiColors from "../pages/Ui/UiColors";
// import UiDropdown from "../pages/Ui/UiDropdown";
// import UiGeneral from "../pages/Ui/UiGeneral";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UiOffCanvas from "pages/Ui/UiOffCanvas";
// import UiUtilitie from "../pages/Ui/UiUtilitie";
// import UiPlaceholders from "../pages/Ui/UiPlaceholders";
// import UiToasts from "../pages/Ui/UiToast";

// //Pages
// import PagesStarter from "../pages/Utility/pages-starter";
// import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import PagesTimeline from "../pages/Utility/pages-timeline";
// import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
// import Pages404 from "../pages/Utility/pages-404";
// import Pages500 from "../pages/Utility/pages-500";

//Contacts
// import ContactsGrid from "../pages/Contacts/contacts-grid";
// import ContactsList from "../pages/Contacts/ContactList/contacts-list";
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

// import Alerts from "../pages/Alerts/";

import AlertRosterPage from "../pages/Alerts/";
import EmergencyActiveAlerts from "../pages/EmergencyAlerts/ActiveAlerts";
import EmergencyArchivedAlerts from "../pages/EmergencyAlerts/ArchivedAlerts";
import BroadcastRosters from "../pages/BroadcastRosters/";


//Tacveiha Pages
import AllRosters from "../pages/Rosters/AllRosters";
import TodaysRosters from "../pages/Rosters/TodaysRosters";
import GroupRosters from "../pages/Rosters/GroupRosters";
import Providers from "../pages/Departments/Providers";
import ThirdPartyContractors from "../pages/Departments/ThirdPartyContractors";
// import AddRoster from "../pages/Rosters/AddRosters";

//case managers
import Casemanagers from "../pages/Casemanagers/Casemanagers";
import AddCasemanager from "../pages/Casemanagers/Add";

//clients
import Clients from "../pages/Clients/Clients";
import ArchivedClients from "../pages/Clients/ArchivedClients";
import ClientServiceTypes from "../pages/Clients/AddServiceTypes";
import ClientServiceList from "../pages/Clients/ClientServiceList";
import ClientIsolationList from "../pages/Clients/IsolationList";


//contractors
import Contractors from "../pages/Contractors/Contractors";
import ContractorsDocs from "../pages/Contractors/Documents";


//staff leaves
import StaffLeavesProcessed from "../pages/StaffLeaves/Processed";
import StaffLeavesApproved from "../pages/StaffLeaves/Approved";
import StaffLeavesApprovedArchived from "../pages/StaffLeaves/ApprovedArchived";
import StaffLeavesUnApproved from "../pages/StaffLeaves/UnApproved";
import StaffLeavesDeleted from "../pages/StaffLeaves/Deleted";

//client away
import ClientAwayAlertsApproved from "../pages/ClientAwayAlerts/Approved";
import ClientAwayAlertsUnApproved from "../pages/ClientAwayAlerts/UnApproved";
import ClientAwayAlertsDeleted from "../pages/ClientAwayAlerts/Deleted";


//Roster Reports
import RosterReport from "../pages/Reports/RosterReport";
import ReportWithNotes from "../pages/Reports/ReportWithNotes";
import ChecklistReport from "../pages/Reports/ChecklistReport";
import TwoHourRosterReport from "../pages/Reports/TwoHourRosterREport";
import GroupRosterReport from "../pages/Reports/GroupRosterReport";
import BuddyReport from "../pages/Reports/BuddyReports";
import SupportWorkerDocHrReport from "../pages/Reports/SupportWorkerDocHrReport";

//Incident Report
import IncidentReportActive from "../pages/IncidentReports/Active";
import IncidentReportCompleted from "../pages/IncidentReports/Completed";
import IncidentReportAdd from "../pages/IncidentReports/Add";

//News Feeds 
import NewsFeeds from "../pages/NewsFeed/Active";
import NewsFeedsArchived from "../pages/NewsFeed/Archived";
import NewsFeedsViewed from "../pages/NewsFeed/Viewed";


//Client Types 
import ClientTypes from "../pages/ClientTypes/ClientTypesActive";
import ClientPackageTypes from "../pages/ClientTypes/PackageTypes";
import ClientPackageTypesArchived from "../pages/ClientTypes/Archived";

import Settings from "pages/Settings";
import PackageReport from "pages/PackageReport";
import PayCatetory from "pages/PayCatetory";
import VaccinationDetails from "pages/VaccinationDetails";
import CovidQuestions from "pages/CovidQuestions";
import HrDocumentsName from "pages/HrDocumentsName";
import SendSms from "pages/SendSms";
import AddRosters from "pages/Rosters/AddRosters";
import ClientsProfile from "../pages/Clients/ClientsProfile";
import SupportWorkers from "pages/Staffs/SupportWorker";
import CsmStaffs from "pages/Staffs/CSMAdminLogins";
import StaffsServiceTypes from "pages/Staffs/ServiceTypes";
import StaffsLanguages from "pages/Staffs/Languages";
import AddStaffs from "pages/Staffs/AddStaffs";
import EditStaff from "pages/Staffs/EditStaff";
import SupportStaffs from "pages/Staffs/SupportStaffs";
import AdminStaffs from "pages/Staffs/AdminStaffs";
import HrManagers from "pages/Staffs/HumanResourceManager";
import ArchivedSupportWorkers from "pages/Staffs/ArchivedSupportWorker";
import EditSupportStaff from "pages/Staffs/EditSupportStaff";
import AddClient from "pages/Clients/Add";
import PublicHolidays from "pages/PublicHoliday";
import StaffLeavesAdd from "pages/StaffLeaves/Add";
import ClientsAwayAdd from "pages/ClientAwayAlerts/Add";
import Feedbacks from "pages/ Feedback";
import Questions from "pages/Recruitment/Questions";
import CandidateList from "pages/Recruitment/Candidates";
import EmployedLists from "pages/Recruitment/Employeds";
import ClientsPlan from "pages/Clients/ClientPlans";
import SupportWorkerAllDocHrReport from "pages/Reports/SupportWorkerAllDocHrReport";
import RosterDepartmentReport from "pages/Reports/RosterDepartmentReport";
import DepartmentProfile from "pages/Profile/Department";
import CaseManagerProfile from "pages/Profile/CaseManager";
import HRProfile from "pages/Profile/HRManager";
import AdminAlertRostersPage from "pages/CareNotesReview";
import CompletedRostersPage from "pages/CompletedRosters";
import NoClockInAllRostersActive from "pages/NoClockinRosters/Active";
import NoClockInAllRostersArchived from "pages/NoClockinRosters/Archived";
import ChecklistAlertsActive from "pages/ChecklistAlerts/Active";
import ChecklistAlertsArchived from "pages/ChecklistAlerts/Archived";




const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // { path: "/dashboard-saas", component: <DashboardSaas /> },
  // { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  // { path: "/blog", component: <Blog /> },
  // { path: "/dashboard-job", component: <DashboardJob /> },
  

  { path: "/public-holiday", component: <PublicHolidays /> },
  { path: "/send-sms", component: <SendSms /> },

  // { path: "/alerts", component: <Alerts /> },
  // { path: "/alerts", component: <Alerts /> },
  // { path: "/alerts", component: <Alerts /> },

  { path: "/alerts/:type", component: <AlertRosterPage />},
  { path: "/alerts/:type", component: <AlertRosterPage />},
  { path: "/alerts/:type", component: <AlertRosterPage />},

  { path: "/emergency-active-alerts", component: <EmergencyActiveAlerts /> },
  { path: "/emergency-archived-alerts", component: <EmergencyArchivedAlerts /> },

  { path: "/broadcast-rosters", component: <BroadcastRosters /> },

  //Tacveiha Routs
  { path: "/rosters/add-rosters", component: <AddRosters />},
  { path: "/rosters/all-rosters", component: <AllRosters />},
  { path: "/rosters/todays-rosters", component: <TodaysRosters />},
  { path: "/rosters/group-rosters", component: <GroupRosters />},
  // { path: "/add-roster", component: <AddRoster />},

  //departments
  { path: "/providers", component: <Providers />},
  { path: "/third-party-contractors", component: <ThirdPartyContractors />},

   //casemanagers
   { path: "/casemanagers", component: <Casemanagers />},
   { path: "/casemanager/add", component: <AddCasemanager />},

  //clients
  { path: "/clients", component: <Clients />},
  { path: "/clients/archived", component: <ArchivedClients />},
  { path: "/clients/add-client", component: <AddClient />},
  { path: "/clients/edit-clients/:id", component: <ClientsProfile />},
  { path: "/client-service-types", component: <ClientServiceTypes />},
  { path: "/client-service-lists", component: <ClientServiceList />},
  { path: "/client-isolation-lists", component: <ClientIsolationList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},



  //staffs
  { path: "/staffs/services-types", component: <StaffsServiceTypes />},
  { path: "/staffs/languages", component: <StaffsLanguages />},
  // { path: "/staffs/:type", component: <SupportWorkers />},
  { path: "/staffs/csm-staffs", component: <CsmStaffs />},
  { path: "/staffs/add-staff", component: <AddStaffs />},
  { path: "/staffs/edit-staff/:id", component: <EditStaff />},
  { path: "/staffs/edit-support-staff/:id", component: <EditSupportStaff />},
  { path: "/staffs/support-staffs", component: <SupportStaffs />},
  { path: "/staffs/admin-staffs", component: <AdminStaffs />},
  { path: "/staffs/hr-managers", component: <HrManagers />},
  { path: "/staffs", component: <SupportWorkers />},
  { path: "/archived-staffs", component: <ArchivedSupportWorkers />},
  // { path: "/client-service-lists", component: <ClientServiceList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},
  // { path: "/client-isolation-lists", component: <ClientIsolationList />},

  //contractors
  { path: "/contractors", component: <Contractors />},
  // { path: "/contractors/add", component: <ContractorsAdd />},
  { path: "/contractors/documents", component: <ContractorsDocs />},

   //Staff Leaves
   { path: "/staffleaves/add", component: <StaffLeavesAdd />},
   { path: "/staffleaves/processed", component: <StaffLeavesProcessed />},
   { path: "/staffleaves/approved", component: <StaffLeavesApproved />},
   { path: "/staffleaves/approved-archived", component: <StaffLeavesApprovedArchived />},
   { path: "/staffleaves/unapproved", component: <StaffLeavesUnApproved />},
   { path: "/staffleaves/deleted", component: <StaffLeavesDeleted />},

   //client away
   { path: "/clientsawayalerts/add", component: <ClientsAwayAdd />},
   { path: "/clientsawayalerts/approved", component: <ClientAwayAlertsApproved />},
   { path: "/clientsawayalerts/unapproved", component: <ClientAwayAlertsUnApproved />},
   { path: "/clientsawayalerts/deleted", component: <ClientAwayAlertsDeleted />},

   //Reports
   { path: "/reports/roster-report", component: <RosterReport />},
   { path: "/reports/report-with-notes", component: <ReportWithNotes />},
   { path: "/reports/checklist-report", component: <ChecklistReport />},
   { path: "/reports/two-hour-roster-report", component: <TwoHourRosterReport />},
   { path: "/reports/group-roster-report", component: <GroupRosterReport />},
   { path: "/reports/buddy-report", component: <BuddyReport />},
   { path: "/reports/support-worker-doc-hr-report", component: <SupportWorkerDocHrReport />},
   { path: "/reports/support-worker-all-doc-hr-report", component: <SupportWorkerAllDocHrReport />},
   { path: "/reports/roster-department-report", component: <RosterDepartmentReport />},

   //Incident Reports
   { path: "/incident-reports/active", component: <IncidentReportActive />},
   { path: "/incident-reports/completed", component: <IncidentReportCompleted />},
   { path: "/incident-reports/add", component: <IncidentReportAdd />},


   //News Feeds 
   { path: "/news-feeds/active", component: <NewsFeeds />},
   { path: "/news-feeds/archived", component: <NewsFeedsArchived />},
   { path: "/news-feeds-viewed/:id", component: <NewsFeedsViewed />},

  //Client Types 
  { path: "/client-types", component: <ClientTypes />},
  { path: "/client-package-types", component: <ClientPackageTypes />},
  { path: "/client-package-types/archived", component: <ClientPackageTypesArchived />},

  //Site Settings Types 
  { path: "/settings", component: <Settings />},


  //package report settings Settings Types 
  { path: "/package-report-settings", component: <PackageReport />},

  //pay category list
  { path: "/pay-category-list", component: <PayCatetory />},


  //Vacination Details
  { path: "/vaccination-details", component: <VaccinationDetails />},

  //Checklist Questions
  { path: "/checklist-questions", component: <CovidQuestions />},

  //Feedbacks
  { path: "/feedbacks", component: <Feedbacks />},

  //profiles
  { path: "/department-profile", component: <DepartmentProfile />},
  { path: "/case-manager-profile", component: <CaseManagerProfile />},
  { path: "/hr-manager-profile", component: <HRProfile />},

  //care notes review
  { path: "/admin-staff-rosters", component: <AdminAlertRostersPage />},
  { path: "/completed-rosters", component: <CompletedRostersPage />},
  
  
  //No clock in rosters
  { path: "/no-clockin-rosters-active", component: <NoClockInAllRostersActive />},
  { path: "/no-clockin-rosters-archived", component: <NoClockInAllRostersArchived />},

    //checklist alerts 
    { path: "/checklist-alerts-active", component: <ChecklistAlertsActive />},
    { path: "/checklist-alerts-archived", component: <ChecklistAlertsArchived />},

  // //chat
  // { path: "/chat", component: <Chat /> },

  // //File Manager
  // { path: "/apps-filemanager", component: <FileManager /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // Hr Documents
  { path: "/hr-documents-name-list", component: <HrDocumentsName /> },

  //Recruitments
  { path: "/recruitments/interview-questions", component: <Questions /> },
  { path: "/recruitments/candidate-lists", component: <CandidateList /> },
  { path: "/recruitments/employed-lists", component: <EmployedLists /> },

  { path: "/clients/clientsplan", component: <ClientsPlan /> },

  // //Ecommerce
  // { path: "/ecommerce-product-detail/:id", component: <EcommerceProductDetail /> },
  // { path: "/ecommerce-products", component: <EcommerceProducts /> },
  // { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  // { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  // { path: "/ecommerce-cart", component: <EcommerceCart /> },
  // { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  // { path: "/ecommerce-shops", component: <EcommerceShops /> },
  // { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  // //Email
  // { path: "/email-inbox", component: <EmailInbox /> },
  // { path: "/email-read", component: <EmailRead /> },
  // { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  // { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  // { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  // //Invoices
  // { path: "/invoices-list", component: <InvoicesList /> },
  // { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  // { path: "/invoices-detail", component: <InvoiceDetail /> },

  // // Tasks
  // { path: "/tasks-list", component: <TasksList /> },
  // { path: "/tasks-create", component: <TasksCreate /> },
  // { path: "/tasks-kanban", component: <TasksKanban />},

  // //Projects
  // { path: "/projects-grid", component: <ProjectsGrid /> },
  // { path: "/projects-list", component: <ProjectsList /> },
  // { path: "/projects-overview", component: <ProjectsOverview /> },
  // { path: "/projects-overview/:id", component: <ProjectsOverview /> },
  // { path: "/projects-create", component: <ProjectsCreate /> },

  // //Blog
  // { path: "/blog-list", component: <BlogList /> },
  // { path: "/blog-grid", component: <BlogGrid /> },
  // { path: "/blog-details", component: <BlogDetails /> },

  // //job
  // { path: "/job-grid", component: <JobGrid /> },
  // { path: "/job-details", component: <JobDetails /> },
  // { path: "/job-categories", component: <JobCategories /> },
  // { path: "/job-list", component: <JobList /> },
  // { path: "/job-apply", component: <ApplyJobs /> },
  // { path: "/candidate-list", component: <CandidateList /> },
  // { path: "/candidate-overview", component: <CandidateOverview /> },

  // // Contacts
  // { path: "/contacts-grid", component: <ContactsGrid /> },
  // { path: "/contacts-list", component: <ContactsList /> },
  // { path: "/contacts-profile", component: <ContactsProfile /> },

  // //Charts
  // { path: "/apex-charts", component: <ChartApex /> },
  // { path: "/chartjs-charts", component: <ChartjsChart /> },
  // { path: "/e-charts", component: <EChart /> },
  // { path: "/sparkline-charts", component: <SparklineChart /> },
  // { path: "/charts-knob", component: <ChartsKnob /> },
  // { path: "/re-charts", component: <ReCharts /> },

  // // Icons
  // { path: "/icons-boxicons", component: <IconBoxicons /> },
  // { path: "/icons-dripicons", component: <IconDripicons /> },
  // { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  // { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // // Tables
  // { path: "/tables-basic", component: <BasicTables /> },
  // { path: "/tables-datatable", component: <DatatableTables /> },

  // // Maps
  // { path: "/maps-google", component: <MapsGoogle /> },

  // // Forms
  // { path: "/form-elements", component: <FormElements /> },
  // { path: "/form-layouts", component: <FormLayouts /> },
  // { path: "/form-advanced", component: <FormAdvanced /> },
  // { path: "/form-editors", component: <FormEditors /> },
  // { path: "/form-mask", component: <FormMask /> },
  // { path: "/form-repeater", component: <FormRepeater /> },
  // { path: "/form-uploads", component: <FormUpload /> },
  // { path: "/form-wizard", component: <FormWizard /> },
  // { path: "/form-validation", component: <FormValidations /> },

  // Ui
  // { path: "/ui-alerts", component: <UiAlert /> },
  // { path: "/ui-buttons", component: <UiButtons /> },
  // { path: "/ui-cards", component: <UiCards /> },
  // { path: "/ui-carousel", component: <UiCarousel /> },
  // { path: "/ui-colors", component: <UiColors /> },
  // { path: "/ui-dropdowns", component: <UiDropdown /> },
  // { path: "/ui-general", component: <UiGeneral /> },
  // { path: "/ui-grid", component: <UiGrid /> },
  // { path: "/ui-images", component: <UiImages /> },
  // { path: "/ui-lightbox", component: <UiLightbox /> },
  // { path: "/ui-modals", component: <UiModal /> },
  // { path: "/ui-progressbars", component: <UiProgressbar /> },
  // { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  // { path: "/ui-typography", component: <UiTypography /> },
  // { path: "/ui-video", component: <UiVideo /> },
  // { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  // { path: "/ui-rating", component: <UiRating /> },
  // { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  // { path: "/ui-notifications", component: <UiNotifications /> },
  // { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  // { path: "/ui-utilities", component: <UiUtilitie /> },
  // { path: "/ui-placeholders", component: <UiPlaceholders /> },
  // { path: "/ui-toasts", component: <UiToasts /> },

  //Utility
  // { path: "/pages-starter", component: <PagesStarter /> },
  // { path: "/pages-timeline", component: <PagesTimeline /> },
  // { path: "/pages-faqs", component: <PagesFaqs /> },
  // { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-login-2", component: <Login2 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/pages-register-2", component: <Register2 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  // { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  // { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
  // { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  // { path: "/page-confirm-mail", component: <ConfirmMail /> },
  // { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  // { path: "/auth-email-verification", component: <EmailVerification /> },
  // { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  // { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  // { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

export { authProtectedRoutes, publicRoutes };
