import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { ACTION_URL, APP_URL } from "helpers/url_helper"
import axios from 'axios';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"

 
import {
    getSettings as onGetSettings,
    updateSettings as onUpdateSettings
} from "store/actions";

function Settings(){
  //meta title
  document.title="Site Settings | Tacveiha Panel";
  
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [modal, setModal] = useState(false);
  const [ctid, setCtid] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [checklistChecked, setChecklistChecked] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.SettingsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        settings: rosterReducer.settings,
        loading: rosterReducer.loading
      })
  );
  const {
    settings,loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (roster && roster.id) || "",
      site_name: (roster && roster.site_name) || "",
      site_url:(roster && roster.site_url) || "",
      site_email: (roster && roster.site_email) || "",
      is_checklist_enabled: ( roster && roster.is_checklist_enabled ) || 0,
      checklist_name: ( roster && roster.checklist_name ) || "",
      master_password: (roster && roster.master_password) || "",
      hr_document_email: (roster && roster.hr_document_email) || "",
      birthday_email: (roster && roster.birthday_email) || "",
      client_birthday_email: (roster && roster.client_birthday_email) || "",
      contractor_doc_email: (roster && roster.contractor_doc_email) || "",
      recruitment_bonus_email: (roster && roster.recruitment_bonus_email) || "",
      ms_admin_email: (roster && roster.ms_admin_email) || "",
      ms_admin_email_pass: (roster && roster.ms_admin_email_pass) || "",
      ms_hr_email: (roster && roster.ms_hr_email) || "",
      ms_hr_email_pass: (roster && roster.ms_hr_email_pass) || "",
      
      ms_st_email: (roster && roster.ms_st_email) || "",
      ms_st_email_pass: (roster && roster.ms_st_email_pass) || "",
      ms_subadmin_email: (roster && roster.ms_subadmin_email) || "",
      ms_subadmin_email_pass: (roster && roster.ms_subadmin_email_pass) || "",

    },
    validationSchema: Yup.object({
        // client_level_name: Yup.string().required("Please select client type"),
        site_name: Yup.string().required("Please enter site name"),
        site_url: Yup.string().required("Please enter site url"),
        site_email: Yup.string().required("Please enter site email"),
        master_password: Yup.string().required("Please provide master password"),
        hr_document_email: Yup.string().required("Please provide hr document repot email"),
        birthday_email: Yup.string().required("Please provide staff birthday report email"),
        client_birthday_email: Yup.string().required("Please provide client birthday report email"),
        contractor_doc_email: Yup.string().required("Please provide contractor document report email"),
        recruitment_bonus_email: Yup.string().required("Please provide recruitment report email "),
        ms_admin_email: Yup.string().required("Please provide super admin email"),
        ms_admin_email_pass: Yup.string().required("Please provide super admin email password"),
        ms_hr_email: Yup.string().required("Please provide hr email"),
        ms_hr_email_pass: Yup.string().required("Please provide hr email password"),
        ms_st_email: Yup.string().required("Please provide support team email."),
        ms_st_email_pass: Yup.string().required("Please provide support team email password"),
        ms_subadmin_email: Yup.string().required("Please provide admin email"),
        ms_subadmin_email_pass: Yup.string().required("Please provide admin email password"),
    }),
    onSubmit: values => {

        setModalLoading(true);

        const updateUser = {
            site_name: values.site_name,
            site_url: values.site_url,
            site_email: values.site_email,
            is_checklist_enabled: values.is_checklist_enabled,
            checklist_name: values.checklist_name,
            master_password: values.master_password,
            hr_document_email:values.hr_document_email,
            birthday_email: values.birthday_email,
            client_birthday_email: values.client_birthday_email,
            contractor_doc_email: values.contractor_doc_email,
            recruitment_bonus_email: values.recruitment_bonus_email,
            ms_admin_email: values.ms_admin_email,
            ms_admin_email_pass: values.ms_admin_email_pass,
            ms_hr_email: values.ms_hr_email,
            ms_hr_email_pass: values.ms_hr_email_pass,
            ms_st_email: values.ms_st_email,
            ms_st_email_pass: values.ms_st_email_pass,
            ms_subadmin_email: values.ms_subadmin_email,
            ms_subadmin_email_pass: values.ms_subadmin_email_pass
        }

        // update user

        // console.log(updateUser);
        axios.post(APP_URL+ACTION_URL,{'data_for':'update_site_settings','data': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                // handleUserClick(fetchedData.data);
                // setIsEdit(false);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

          }) 

        // dispatch(onUpdateSettings({'data_for':'update_site_settings','data': updateUser,'user': loggedUser}));
        // validation.resetForm();
        
    
    },
  });

  useEffect(() => {

    loadSiteSettings();
    // dispatch(onGetSettings({'data_for':'get_site_settings','user': loggedUser}));
  }, [dispatch]);


  const loadSiteSettings = () => {

    const formData = {

    }
    setModalLoading(true)
    axios.post(APP_URL+ACTION_URL,{'data_for':'get_site_settings','formData': formData,'user': loggedUser})
      .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;

      if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
          handleUserClick(fetchedData.data);
      }

      if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
      }

    })

  }

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
        site_name: user.site_name,
        site_url: user.site_url,
        site_email: user.site_email,
        is_checklist_enabled: user.is_checklist_enabled,
        checklist_name: user.checklist_name,
        master_password: user.master_password,
        hr_document_email:user.hr_document_email,
        birthday_email: user.birthday_email,
        client_birthday_email: user.client_birthday_email,
        contractor_doc_email: user.contractor_doc_email,
        recruitment_bonus_email: user.recruitment_bonus_email,
        ms_admin_email: user.ms_admin_email,
        ms_admin_email_pass: user.ms_admin_email_pass,
        ms_hr_email: user.ms_hr_email,
        ms_hr_email_pass: user.ms_hr_email_pass,
        ms_st_email: user.ms_st_email,
        ms_st_email_pass: user.ms_st_email_pass,
        ms_subadmin_email: user.ms_subadmin_email,
        ms_subadmin_email_pass: user.ms_subadmin_email_pass
    })
  }

  const handleOption = (arg) => {

    if( arg.target.checked ) {
      setChecklistChecked(1);
    } else {
      setChecklistChecked(0);
    }

  }


  return (
    <React.Fragment>
      {
        modalLoading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Site Settings" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                    <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    
                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Site Name
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="site_name"
                            value={validation.values.site_name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.site_name &&
                                  validation.errors.site_name
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.site_name &&
                              validation.errors.site_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.site_name}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Site Url
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="site_url"
                            value={validation.values.site_url}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.site_url &&
                                  validation.errors.site_url
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.site_url &&
                              validation.errors.site_url ? (
                              <FormFeedback type="invalid">
                                {validation.errors.site_url}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Contact Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="site_email"
                            value={validation.values.site_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.site_email &&
                                  validation.errors.site_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.site_email &&
                              validation.errors.site_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.site_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Master Password
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="master_password"
                            value={validation.values.master_password}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.master_password &&
                                  validation.errors.master_password
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.master_password &&
                              validation.errors.master_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.master_password}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>
                    
                    <Row form>


                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Checklist Form Enable/Disable
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="is_checklist_enabled"
                              type="checkbox"
                              onChange={validation.handleChange}
                              // onChange={handleOption}
                              value="1"
                              checked={validation.values.is_checklist_enabled == 1 ? true : false}
                            /> 
                            
                          </div>
                        </Row>
                      {/* </Col> */}
                    </Row>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Checklist Name
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="checklist_name"
                            value={validation.values.checklist_name}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.checklist_name &&
                                  validation.errors.checklist_name
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.checklist_name &&
                              validation.errors.checklist_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.checklist_name}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        HR Document Report Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="hr_document_email"
                            value={validation.values.hr_document_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.hr_document_email &&
                                  validation.errors.hr_document_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.hr_document_email &&
                              validation.errors.hr_document_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hr_document_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Staff Birthday Report Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="birthday_email"
                            value={validation.values.birthday_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.birthday_email &&
                                  validation.errors.birthday_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.birthday_email &&
                              validation.errors.birthday_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.birthday_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Client Birthday Report Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="client_birthday_email"
                            value={validation.values.client_birthday_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.client_birthday_email &&
                                  validation.errors.client_birthday_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.client_birthday_email &&
                              validation.errors.client_birthday_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.client_birthday_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Client Birthday Report Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="client_birthday_email"
                            value={validation.values.client_birthday_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.client_birthday_email &&
                                  validation.errors.client_birthday_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.client_birthday_email &&
                              validation.errors.client_birthday_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.client_birthday_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Contractor Document Report Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="contractor_doc_email"
                            value={validation.values.contractor_doc_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.contractor_doc_email &&
                                  validation.errors.contractor_doc_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.contractor_doc_email &&
                              validation.errors.contractor_doc_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contractor_doc_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Recruitment Email Weekly/Monthly
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="recruitment_bonus_email"
                            value={validation.values.recruitment_bonus_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.recruitment_bonus_email &&
                                  validation.errors.recruitment_bonus_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.recruitment_bonus_email &&
                              validation.errors.recruitment_bonus_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.recruitment_bonus_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>
                  <CardTitle>Microsoft 364 Email Settings</CardTitle>
                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Super Admin Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_admin_email"
                            value={validation.values.ms_admin_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_admin_email &&
                                  validation.errors.ms_admin_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_admin_email &&
                              validation.errors.ms_admin_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_admin_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Super Admin Email Password
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_admin_email_pass"
                            value={validation.values.ms_admin_email_pass}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_admin_email_pass &&
                                  validation.errors.ms_admin_email_pass
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_admin_email_pass &&
                              validation.errors.ms_admin_email_pass ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_admin_email_pass}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        HR Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_hr_email"
                            value={validation.values.ms_hr_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_hr_email &&
                                  validation.errors.ms_hr_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_hr_email &&
                              validation.errors.ms_hr_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_hr_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        HR Email Password
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_hr_email_pass"
                            value={validation.values.ms_hr_email_pass}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_hr_email_pass &&
                                  validation.errors.ms_hr_email_pass
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_hr_email_pass &&
                              validation.errors.ms_hr_email_pass ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_hr_email_pass}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Support Team Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_st_email"
                            value={validation.values.ms_st_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_st_email &&
                                  validation.errors.ms_st_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_st_email &&
                              validation.errors.ms_st_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_st_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Support Team Email Password
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_st_email_pass"
                            value={validation.values.ms_st_email_pass}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_st_email_pass &&
                                  validation.errors.ms_st_email_pass
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_st_email_pass &&
                              validation.errors.ms_st_email_pass ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_st_email_pass}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Admin Team Email
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_subadmin_email"
                            value={validation.values.ms_subadmin_email}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_subadmin_email &&
                                  validation.errors.ms_subadmin_email
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_subadmin_email &&
                              validation.errors.ms_subadmin_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_subadmin_email}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Col className="mb-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Admin Team Email Password
                        </label>
                        <div className="col-md-10">
                        <Input
                            type="text"
                            name="ms_subadmin_email_pass"
                            value={validation.values.ms_subadmin_email_pass}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                                validation.touched.ms_subadmin_email_pass &&
                                  validation.errors.ms_subadmin_email_pass
                                  ? true
                                  : false
                              }
                        />
                        {validation.touched.ms_subadmin_email_pass &&
                              validation.errors.ms_subadmin_email_pass ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ms_subadmin_email_pass}
                              </FormFeedback>
                            ) : null}
                        </div>
                      </Row>
                    </Col>

                    <Row>
                      <Col>
                        <div className="text-start">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={modalLoading}
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Settings;