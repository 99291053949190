import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_STAFFS,GET_CLIENT_DEPARTMENTS,GET_DEPARTMENTS_CLIENTS,GET_ROSTER_REPORT,GET_ROSTER_REPORT_WITH_NOTES, GET_CHECKLIST_REPORT, GET_TWO_HR_ROSTER_REPORT, GET_GROUP_ROSTER_REPORT, GET_BUDDY_REPORT, GET_SUPPORT_WORKER_DOC_HR_REPORT } from "./actionTypes"
import {
  getRosterReportSuccess,
  getRosterReportFail,
  getRosterReportWithNotesSuccess,
  getRosterReportWithNotesFail,
  getChecklistReportSuccess,
  getChecklistReportFail,
  getTwoHrRosterReportSuccess,
  getTwoHrRosterReportFail,
  getGroupRosterReportSuccess,
  getGroupRosterReportFail,
  getBuddyReportSuccess,
  getBuddyReportFail,
  getSupportWorkerDocHrReportSuccess,
  getSupportWorkerDocHrReportFail,
  getStaffsSuccess,
  getStaffsFail,
  getClientDepartmentsSuccess,
  getClientDepartmentsFail,
  getDepartmentsClientsSuccess,
  getDepartmentsClientsFail
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { rosterReportsPage, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchRosterReport({params,types}) {
  try {
    const response = yield call(rosterReportsPage, params, types )
    console.log(response);
    yield put(getRosterReportSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getRosterReportFail(error))
  }
}

function* fetchReportWithNotes({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getRosterReportWithNotesSuccess(response))
  } catch (error) {
      yield put(getRosterReportWithNotesFail(error))
  }
}

function* fetchChecklistReport({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getChecklistReportSuccess(response))
  } catch (error) {
      yield put(getChecklistReportFail(error))
  }
}

function* fetchTwoHrRosterReport({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getTwoHrRosterReportSuccess(response))
  } catch (error) {
      yield put(getTwoHrRosterReportFail(error))
  }
}

function* fetchGroupRosterReport({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getGroupRosterReportSuccess(response))
  } catch (error) {
      yield put(getGroupRosterReportFail(error))
  }
}

function* fetchBuddyReport({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getBuddyReportSuccess(response))
  } catch (error) {
      yield put(getBuddyReportFail(error))
  }
}

function* fetchSupportWorkerDocHrReport({ params,type }) {
  try {
      const response = yield call(rosterReportsPage, params,type)
      yield put(getSupportWorkerDocHrReportSuccess(response))
  } catch (error) {
      yield put(getSupportWorkerDocHrReportFail(error))
  }
}

function* fetchStaffs({ params,type }) {
  try {
      const response = yield call(actionUrl, params,type)
      yield put(getStaffsSuccess(response))
  } catch (error) {
      yield put(getStaffsFail(error))
  }
}

function* fetchDepartments({ params,type }) {
  try {
      const response = yield call(actionUrl, params,type)
      yield put(getClientDepartmentsSuccess(response))
  } catch (error) {
      yield put(getClientDepartmentsFail(error))
  }
}

function* fetchDepartmentsClients({ params,type }) {
  try {
      const response = yield call(actionUrl, params,type)
      yield put(getDepartmentsClientsSuccess(response))
  } catch (error) {
      yield put(getDepartmentsClientsFail(error))
  }
}

// function* updateCasemanager({ payload: data }) {
//   try {
//       const response = yield call(staffLeavesPage, data)
//       yield put(updateCasemanagerSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateCasemanagerFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* reportsSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_ROSTER_REPORT, fetchRosterReport)
  yield takeEvery(GET_ROSTER_REPORT_WITH_NOTES, fetchReportWithNotes)
  yield takeEvery(GET_CHECKLIST_REPORT, fetchChecklistReport)
  yield takeEvery(GET_TWO_HR_ROSTER_REPORT, fetchTwoHrRosterReport)
  yield takeEvery(GET_GROUP_ROSTER_REPORT, fetchGroupRosterReport)
  yield takeEvery(GET_BUDDY_REPORT, fetchBuddyReport)
  yield takeEvery(GET_SUPPORT_WORKER_DOC_HR_REPORT, fetchSupportWorkerDocHrReport)
  yield takeEvery(GET_STAFFS, fetchStaffs)
  yield takeEvery(GET_CLIENT_DEPARTMENTS, fetchDepartments)
  yield takeEvery(GET_DEPARTMENTS_CLIENTS, fetchDepartmentsClients)
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default reportsSaga
