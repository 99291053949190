import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAIL,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    settings: [],
    error: {},
    loading: false,
  }
  
  const SettingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SETTINGS:
        return {
            ...state,
            //settings: state.settings,
            loading: true
        }

        case GET_SETTINGS_SUCCESS:
        return {
            ...state,
            settings: action.payload.data,
            loading: false
        }
  
        case GET_SETTINGS_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_SETTINGS:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_SETTINGS_SUCCESS:
        return {
            ...state,
            settings: action.payload.data,
            loading: false
        };
  
        case UPDATE_SETTINGS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
        default:
            return state
        }
  }
  
  export default SettingsReducer
  