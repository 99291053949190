import {
  GET_CLIENT_AWAY_ALERT_APPROVED,
  GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_APPROVED_FAIL,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL,
  GET_CLIENT_AWAY_ALERT_DELETED,
  GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_DELETED_FAIL,
  UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST,
  UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST,
  UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST
} from "./actionTypes"

export const getClientAwayAlertApproved = (params,types) => ({
  type: GET_CLIENT_AWAY_ALERT_APPROVED,
  params,
  types,
})

export const getClientAwayAlertApprovedSuccess = data => ({
  type: GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS,
  payload: data,
})

export const getClientAwayAlertApprovedFail = error => ({
  type: GET_CLIENT_AWAY_ALERT_APPROVED_FAIL,
  payload: error,
})

export const getClientAwayAlertUnApproved = (params,types) => ({
  type: GET_CLIENT_AWAY_ALERT_UNAPPROVED,
  params,
  types,
})

export const getClientAwayAlertUnApprovedSuccess = data => ({
  type: GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS,
  payload: data,
})

export const getClientAwayAlertUnApprovedFail = error => ({
  type: GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL,
  payload: error,
})

export const getClientAwayAlertDeleted = (params,types) => ({
  type: GET_CLIENT_AWAY_ALERT_DELETED,
  params,
  types,
})

export const getClientAwayAlertDeletedSuccess = data => ({
  type: GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS,
  payload: data,
})

export const getClientAwayAlertDeletedFail = error => ({
  type: GET_CLIENT_AWAY_ALERT_DELETED_FAIL,
  payload: error,
})

export const updateClientAwayAlertApprovedList = (params) => ({
  type: UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST,
  payload: params,
})

export const updateClientAwayAlertUnApprovedList = (params) => ({
  type: UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST,
  payload: params,
})

export const updateClientAwayAlertDeletedList = (params) => ({
  type: UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST,
  payload: params,
})

// export const updateCasemanager = (params,types) => ({
//   type: UPDATE_CASEMANAGER,
//   payload: params,
//   types
// })

// export const updateCasemanagerSuccess = data => ({
//   type: UPDATE_CASEMANAGER_SUCCESS,
//   payload: data,
// })

// export const updateCasemanagerFail = error => ({
//   type: UPDATE_CASEMANAGER_FAIL,
//   payload: error,
// })