import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONS, GET_EMPLOYEDS, GET_CANDIDATES  } from "./actionTypes"
import {
getQuestionsSuccess,
getQuestionsFail,
getCandidatesSuccess,
getCandidatesFail,
getEmploydsSuccess,
getEmploydsFail
} from "./actions"

//Include Both Helper File with needed methods
import { recruitmentsPage } from "helpers/fakebackend_helper"

function* fetchQuestions({params,types}) {
  try {
    const response = yield call(recruitmentsPage, params, types )
    yield put(getQuestionsSuccess(response))
  } catch (error) {
    yield put(getQuestionsFail(error))
  }
}

function* fetchCandidates({params,types}) {
  try {
    const response = yield call(recruitmentsPage, params, types )
    yield put(getCandidatesSuccess(response))
  } catch (error) {
    yield put(getCandidatesFail(error))
  }
}

function* fetchEmployeds({params,types}) {
  try {
    const response = yield call(recruitmentsPage, params, types )
    yield put(getEmploydsSuccess(response))
  } catch (error) {
    yield put(getEmploydsFail(error))
  }
}

function* RecruitmentsSaga() {
  yield takeEvery(GET_QUESTIONS, fetchQuestions)
  yield takeEvery(GET_CANDIDATES, fetchCandidates)
  yield takeEvery(GET_EMPLOYEDS, fetchEmployeds)

}

export default RecruitmentsSaga
