import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

//rostars saas
import RostersReducer from "./rosters/reducer"

//departments
import DepartmentReducer from "./departments/reducer"

//casemanagers
import CasemanagerReducer from "./casemanagers/reducer"

//clients
import ClientsReducer from "./clients/reducer"

//alerts
import AlertRostersReducer from "./alerts/reducer"

//emergency alerts
import EmergencyAlertsReducer from "./emergency-alerts/reducer"

//broadcast rosters
import BroadcastRosterReducer from "./broadcast-rosters/reducer"

//broadcast rosters
import ContractorsReducer from "./contractors/reducer"

//staff leaves
import StaffLeavesReducer from "./staff-leaves/reducer"

//clients aways
import ClientAwayAlertReducer from "./client-away-alert/reducer"

//reports
import ReportsReducer from "./reports/reducer"

//incident reports
import IncidentReportReducer from "./incident-reports/reducer"
import NewsFeedReducer from "./news-feed/reducer";
import ClientTypesReducer from "./clientpackagetypes/reducer";
import SettingsReducer from "./settings/reducer";
import PackageReportReducer from "./package-reports/reducer";
import PayCategoryReducer from "./paycategory/reducer";
import VaccinationDetailsReducer from "./vaccinationdetails/reducer";
import ChecklistQuestionsReducer from "./covidquestions/reducer";
import HrDocumentsNameReducer from "./hrdocuments/reducer";
import SendSmsReducer from "./sendsms/reducer";

//import staffs
import StaffsReducer from "./staffs/reducer";
import PublicHolidaysReducer from "./publicholiday/reducer";
import FeedbacksReducer from "./feedbacks/reducer";
import RecruitmentsReducer from "./recruitments/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  RostersReducer,
  DepartmentReducer,
  CasemanagerReducer,
  ClientsReducer,
  AlertRostersReducer,
  EmergencyAlertsReducer,
  BroadcastRosterReducer,
  ContractorsReducer,
  StaffLeavesReducer,
  ClientAwayAlertReducer,
  ReportsReducer,
  IncidentReportReducer,
  NewsFeedReducer,
  ClientTypesReducer,
  SettingsReducer,
  PackageReportReducer,
  PayCategoryReducer,
  VaccinationDetailsReducer,
  ChecklistQuestionsReducer,
  HrDocumentsNameReducer,
  SendSmsReducer,
  StaffsReducer,
  PublicHolidaysReducer,
  FeedbacksReducer,
  RecruitmentsReducer
});

export default rootReducer;
