import {
    GET_SMS_STAFFS_DROPDOWN,
    GET_SMS_STAFFS_DROPDOWN_FAIL,
    GET_SMS_STAFFS_DROPDOWN_SUCCESS,
    SEND_SMS,
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL,
  } from "./actionTypes"
  
  export const getSmsStaffsDrowdown = (params,types) => ({
    type: GET_SMS_STAFFS_DROPDOWN,
    params,
    types,
  })
  
  export const getSmsStaffsDrowdownSuccess = data => ({
    type: GET_SMS_STAFFS_DROPDOWN_SUCCESS,
    payload: data,
  })
  
  export const getSmsStaffsDrowdownFail = error => ({
    type: GET_SMS_STAFFS_DROPDOWN_FAIL,
    payload: error,
  })
  
  
  export const sendSms = (params,types) => ({
    type: SEND_SMS,
    params,
    types,
  })
  
  export const sendSmsSuccess = data => ({
    type: SEND_SMS_SUCCESS,
    payload: data,
  })
  
  export const sendSmsFail = error => ({
    type: SEND_SMS_FAIL,
    payload: error,
  })