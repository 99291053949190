import {
  GET_ROSTER_REPORT,
  GET_ROSTER_REPORT_SUCCESS,
  GET_ROSTER_REPORT_FAIL,
  GET_ROSTER_REPORT_WITH_NOTES,
  GET_ROSTER_REPORT_WITH_NOTES_SUCCESS,
  GET_ROSTER_REPORT_WITH_NOTES_FAIL,
  GET_CHECKLIST_REPORT,
  GET_CHECKLIST_REPORT_SUCCESS,
  GET_CHECKLIST_REPORT_FAIL,
  GET_TWO_HR_ROSTER_REPORT,
  GET_TWO_HR_ROSTER_REPORT_SUCCESS,
  GET_TWO_HR_ROSTER_REPORT_FAIL,
  GET_GROUP_ROSTER_REPORT,
  GET_GROUP_ROSTER_REPORT_SUCCESS,
  GET_GROUP_ROSTER_REPORT_FAIL,
  GET_BUDDY_REPORT,
  GET_BUDDY_REPORT_SUCCESS,
  GET_BUDDY_REPORT_FAIL,
  GET_SUPPORT_WORKER_DOC_HR_REPORT,
  GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS,
  GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL,
  GET_STAFFS,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAIL,
  GET_CLIENT_DEPARTMENTS,
  GET_CLIENT_DEPARTMENTS_SUCCESS,
  GET_CLIENT_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_CLIENTS,
  GET_DEPARTMENTS_CLIENTS_SUCCESS,
  GET_DEPARTMENTS_CLIENTS_FAIL
} from "./actionTypes"

export const getRosterReport = (params,types) => ({
  type: GET_ROSTER_REPORT,
  params,
  types,
})

export const getRosterReportSuccess = data => ({
  type: GET_ROSTER_REPORT_SUCCESS,
  payload: data,
})

export const getRosterReportFail = error => ({
  type: GET_ROSTER_REPORT_FAIL,
  payload: error,
})

export const getRosterReportWithNotes = (params,types) => ({
  type: GET_ROSTER_REPORT_WITH_NOTES,
  params,
  types,
})

export const getRosterReportWithNotesSuccess = data => ({
  type: GET_ROSTER_REPORT_WITH_NOTES_SUCCESS,
  payload: data,
})

export const getRosterReportWithNotesFail = error => ({
  type: GET_ROSTER_REPORT_WITH_NOTES_FAIL,
  payload: error,
})

export const getTwoHrRosterReport = (params,types) => ({
  type: GET_TWO_HR_ROSTER_REPORT,
  params,
  types,
})

export const getTwoHrRosterReportSuccess = data => ({
  type: GET_TWO_HR_ROSTER_REPORT_SUCCESS,
  payload: data,
})

export const getTwoHrRosterReportFail = error => ({
  type: GET_TWO_HR_ROSTER_REPORT_FAIL,
  payload: error,
})

export const getGroupRosterReport = (params,types) => ({
  type: GET_GROUP_ROSTER_REPORT,
  params,
  types,
})

export const getGroupRosterReportSuccess = data => ({
  type: GET_GROUP_ROSTER_REPORT_SUCCESS,
  payload: data,
})

export const getGroupRosterReportFail = error => ({
  type: GET_GROUP_ROSTER_REPORT_FAIL,
  payload: error,
})

export const getBuddyReport = (params,types) => ({
  type: GET_BUDDY_REPORT,
  params,
  types,
})

export const getBuddyReportSuccess = data => ({
  type: GET_BUDDY_REPORT_SUCCESS,
  payload: data,
})

export const getBuddyReportFail = error => ({
  type: GET_BUDDY_REPORT_FAIL,
  payload: error,
})

export const getChecklistReport = (params,types) => ({
  type: GET_CHECKLIST_REPORT,
  params,
  types,
})

export const getChecklistReportSuccess = data => ({
  type: GET_CHECKLIST_REPORT_SUCCESS,
  payload: data,
})

export const getChecklistReportFail = error => ({
  type: GET_CHECKLIST_REPORT_FAIL,
  payload: error,
})

export const getSupportWorkerDocHrReport = (params,types) => ({
  type: GET_SUPPORT_WORKER_DOC_HR_REPORT,
  params,
  types,
})

export const getSupportWorkerDocHrReportSuccess = data => ({
  type: GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS,
  payload: data,
})

export const getSupportWorkerDocHrReportFail = error => ({
  type: GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL,
  payload: error,
})

export const getStaffs = (params,types) => ({
  type: GET_STAFFS,
  params,
  types,
})

export const getStaffsSuccess = data => ({
  type: GET_STAFFS_SUCCESS,
  payload: data,
})

export const getStaffsFail = error => ({
  type: GET_STAFFS_FAIL,
  payload: error,
})

export const getClientDepartments = (params,types) => ({
  type: GET_CLIENT_DEPARTMENTS,
  params,
  types,
})

export const getClientDepartmentsSuccess = data => ({
  type: GET_CLIENT_DEPARTMENTS_SUCCESS,
  payload: data,
})

export const getClientDepartmentsFail = error => ({
  type: GET_CLIENT_DEPARTMENTS_FAIL,
  payload: error,
})


export const getDepartmentsClients = (params,types) => ({
  type: GET_DEPARTMENTS_CLIENTS,
  params,
  types,
})

export const getDepartmentsClientsSuccess = data => ({
  type: GET_DEPARTMENTS_CLIENTS_SUCCESS,
  payload: data,
})

export const getDepartmentsClientsFail = error => ({
  type: GET_DEPARTMENTS_CLIENTS_FAIL,
  payload: error,
})
// export const updateCasemanager = (params,types) => ({
//   type: UPDATE_CASEMANAGER,
//   payload: params,
//   types
// })

// export const updateCasemanagerSuccess = data => ({
//   type: UPDATE_CASEMANAGER_SUCCESS,
//   payload: data,
// })

// export const updateCasemanagerFail = error => ({
//   type: UPDATE_CASEMANAGER_FAIL,
//   payload: error,
// })