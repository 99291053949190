import {
  GET_DEPARTMENTS,
  GET_PROVIDERS_FAIL,
  GET_PROVIDERS_SUCCESS,
  // GET_PROVIDER_DETAIL,
  // GET_PROVIDER_DETAIL_FAIL,
  // GET_PROVIDER_DETAIL_SUCCESS,
  GET_THIRD_PARTY_CONTRACTORS,
  GET_THIRD_PARTY_CONTRACTORS_SUCCESS,
  GET_THIRD_PARTY_CONTRACTORS_FAIL,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL,
  // ADD_DEPARTMENT,
  // ADD_DEPARTMENT_SUCCESS,
  // ADD_DEPARTMENT_FAIL,
  ADD_DEPARTMENTS,
  ADD_THIRD_PARTY_CONTRACTORS,
  // ADD_DEPARTMENTS_SUCCESS,
  // ADD_DEPARTMENTS_FAIL,
  UPDATE_DEPARTMENT_DETAIL,
  UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL
  // UPDATE_DEPARTMENT_DETAIL_SUCCESS,
  // UPDATE_DEPARTMENT_DETAIL_FAIL
} from "./actionTypes"

const INIT_STATE = {
  providers: [],
  thirdPartyContractors: [],
  providerDetails: {},
  thirdPartyContractorsDetails: {},
  staffsDropdown: [],
  error: {},
  loading: false,
}

const DepartmentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      console.log('loading');
      return {
        ...state,
        providers: state.providers,
        staffsDropdown: state.staffsDropdown,
        loading: true
     }
    case GET_PROVIDERS_SUCCESS:
      console.log('success');
      return {
        ...state,
        providers: action.payload.data,
        staffsDropdown: action.payload.staffsDropdown,
        loading: false
     }

    case GET_PROVIDERS_FAIL:
      console.log('failed');
      return {
        ...state,
        error: action.payload,

      }

    // case GET_PROVIDER_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     providerDetails: action.payload.data,
    //     loading: false
    //   }

    // case GET_PROVIDER_DETAIL_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //   }

    case GET_THIRD_PARTY_CONTRACTORS:
      return {
        ...state,
        thirdPartyContractors: state.thirdPartyContractors,
        loading: true
     }
    case GET_THIRD_PARTY_CONTRACTORS_SUCCESS:
      // console.log('success');
      return {
        ...state,
        thirdPartyContractors: action.payload.data,

        loading: false
     }

    case GET_THIRD_PARTY_CONTRACTORS_FAIL:
      // console.log('failed');
      return {
        ...state,
        error: action.payload.error,
      }

    // case GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS:
      
    //   return {
    //     ...state,
    //     thirdPartyContractorsDetails: action.payload.data,
    //     loading: false
    //  }
    //  case GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL:
     
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     loading: false
    //   }
    //   case ADD_DEPARTMENT:
    //     return {
    //         ...state,
    //         loading: true
    //   };
    //   case ADD_DEPARTMENT_SUCCESS:
    //     return {
    //       ...state,
    //       providers: [...state.providers, data],
    //       loading: false
    //   };
    //   case ADD_DEPARTMENT_FAIL:
    //     return {
    //       ...state,
    //       error: action.payload.error,
    //       loading: false
    //   };

      case ADD_DEPARTMENTS:
        return {
            ...state,
            providers: [...state.providers, action.payload],
      };

      case ADD_THIRD_PARTY_CONTRACTORS:
        return {
            ...state,
            thirdPartyContractors: [...state.providers, action.payload],
      };
      // case ADD_DEPARTMENTS_SUCCESS:
      //   return {
      //     ...state,
      //     providers: [...state.providers, action.payload],
      //     loading: false
      // };
      // case ADD_DEPARTMENTS_FAIL:
      //   return {
      //     ...state,
      //     error: action.payload.error,
      //     loading: false
      // };

      case UPDATE_DEPARTMENT_DETAIL:
        return {
          ...state,
          providers: state.providers.map(rosters =>
            rosters.user_id.toString() === action.payload.user_id.toString()
                  ? { rosters, ...action.payload }
                  : rosters
          ),
          // loading: true
      };

      case UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL:
        return {
          ...state,
          thirdPartyContractors: state.thirdPartyContractors.map(rosters =>
            rosters.user_id.toString() === action.payload.user_id.toString()
                  ? { rosters, ...action.payload }
                  : rosters
          ),
          // loading: true
      };

      // case UPDATE_DEPARTMENT_DETAIL_SUCCESS:
      //   return {
      //       ...state,
      //       providers: state.providers.map(rosters =>
      //         rosters.id.toString() === action.payload.data.id.toString()
      //               ? { rosters, ...action.payload.data }
      //               : rosters
      //       ),
      //       loading: false
      // };

      // case UPDATE_DEPARTMENT_DETAIL_FAIL:
      //       return {
      //           ...state,
      //           error: action.payload.error,
      //           loading: false
      //       };

    default:
      return state
  }
}

export default DepartmentReducer
