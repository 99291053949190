import {
  GET_CONTRACTORS,
  GET_CONTRACTORS_SUCCESS,
  GET_CONTRACTORS_FAIL,
  GET_CONTRACTORS_DOCUMENTS,
  GET_CONTRACTORS_DOCUMENTS_SUCCESS,
  GET_CONTRACTORS_DOCUMENTS_FAIL,
  ADD_CONTRACTOR_DOCUMENTS,
  ADD_CONTRACTOR_DOCUMENTS_SUCCESS,
  ADD_CONTRACTOR_DOCUMENTS_FAIL,
  UPDATE_CONTRACTOR_DOCUMENTS,
  UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS,
  UPDATE_CONTRACTOR_DOCUMENTS_FAIL,
  ADD_CONTRACTOR,
  UPDATE_CONTRACTOR
} from "./actionTypes"

export const getContractors = (params,types) => ({
  type: GET_CONTRACTORS,
  params,
  types,
})

export const getContractorsSuccess = data => ({
  type: GET_CONTRACTORS_SUCCESS,
  payload: data,
})

export const getContractorsFail = error => ({
  type: GET_CONTRACTORS_FAIL,
  payload: error,
})


export const getContractorsDocuments = (params,types) => ({
  type: GET_CONTRACTORS_DOCUMENTS,
  params,
  types,
})

export const getContractorsDocumentsSuccess = data => ({
  type: GET_CONTRACTORS_DOCUMENTS_SUCCESS,
  payload: data,
})

export const getContractorsDocumentsFail = error => ({
  type: GET_CONTRACTORS_DOCUMENTS_FAIL,
  payload: error,
})

export const addContractorDocuments = (params,types) => ({
  type: ADD_CONTRACTOR_DOCUMENTS,
  params,
  types
})

export const addContractorDocumentsSuccess = data => ({
  type: ADD_CONTRACTOR_DOCUMENTS_SUCCESS,
  payload: data,
})

export const addContractorDocumentsFail = error => ({
  type: ADD_CONTRACTOR_DOCUMENTS_FAIL,
  payload: error,
})

export const updateContractorDocuments = (params,types) => ({
  type: UPDATE_CONTRACTOR_DOCUMENTS,
  payload: params,
  types
})

export const updateContractorDocumentsSuccess = data => ({
  type: UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS,
  payload: data,
})

export const updateContractorDocumentsFail = error => ({
  type: UPDATE_CONTRACTOR_DOCUMENTS_FAIL,
  payload: error,
})

export const addContractor = (params) => ({
  type: ADD_CONTRACTOR,
  payload: params,
  // types
})

export const updateContractor = (params) => ({
  type: UPDATE_CONTRACTOR,
  payload: params,
  // types
})