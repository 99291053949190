import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";

import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getStaffsServiceTypes as onGetStaffsServiceTypes,
    addStaffsServiceTypes as onAddStaffsServiceTypes,
    updateStaffsServiceType as onUpdateStaffsServiceType
} from "store/actions";

function StaffsServiceTypes(){
  //meta title
  document.title="Staffs Services | Tacveiha Panel";
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      service_name: (roster && roster.service_name) || "",
    },
    validationSchema: Yup.object({
        service_name: Yup.string().required("Please Enter Service Name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: roster.id,
          service_name: values.service_name,
        };

        // update user
        dispatch(onUpdateStaffsServiceType('update_staff_service_list',updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newData = {
            service_name: values["service_name"],
        };
        // save new user
        dispatch(onAddStaffsServiceTypes('add_staff_service_list',newData,user));
        validation.resetForm();
      }
      toggle();
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        serviceLists: rosterReducer.staffsServiceTypes,
        loading: rosterReducer.loading
      })
  );
  const {
    serviceLists, loading
  } = useSelector(groupRostersProperties);

  const [Loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    if (serviceLists && !serviceLists.length) {
      dispatch(onGetStaffsServiceTypes({'data_for': 'get_staffs_service_list','user': user}));
    }
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      id: user.id,
      service_name: user.service_name,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Service Name",
        accessor: "service_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Staffs" breadcrumbItem="Service Types Lists" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <>
                    <TableContainer
                      columns={columns}
                      data={serviceLists}
                      isGlobalFilter={true}
                      isDateSelecteOptions={true}
                      showSearchBtn={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={50}
                      className="custom-header-css"
                      isAddBtn={true}
                      btnText={'Add Service List'}
                    />
                  </>
                }
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row form>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label"></Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default StaffsServiceTypes;