import {
    GET_HR_DOCUMENT_NAME_LIST,
    GET_HR_DOCUMENT_NAME_LIST_SUCCESS,
    GET_HR_DOCUMENT_NAME_LIST_FAIL,
    UPDATE_HR_DOCUMENT_NAME_LIST,
    UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS,
    UPDATE_HR_DOCUMENT_NAME_LIST_FAIL,
    ADD_HR_DOCUMENT_NAME_LIST,
    ADD_HR_DOCUMENT_NAME_LIST_SUCCESS,
    ADD_HR_DOCUMENT_NAME_LIST_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    hrDocuments: [],
    error: {},
    loading: false,
  }
  
  const HrDocumentsNameReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HR_DOCUMENT_NAME_LIST:
        return {
            ...state,
            //settings: state.settings,
            loading: true
        }

        case GET_HR_DOCUMENT_NAME_LIST_SUCCESS:
        return {
            ...state,
            hrDocuments: action.payload.data,
            loading: false
        }
  
        case GET_HR_DOCUMENT_NAME_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_HR_DOCUMENT_NAME_LIST:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS:
        return {
            ...state,
            hrDocuments: state.hrDocuments.map(rosters =>
                rosters.id.toString() === action.payload.data.id.toString()
                        ? { rosters, ...action.payload.data }
                        : rosters
            ),
            loading: false
        };
  
        case UPDATE_HR_DOCUMENT_NAME_LIST_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_HR_DOCUMENT_NAME_LIST:
        return {
            ...state,
            loading: true
        };

        case ADD_HR_DOCUMENT_NAME_LIST_SUCCESS:
        return {
            ...state,
            hrDocuments: [...state.hrDocuments, action.payload.data],
            loading: false
        }

        case ADD_HR_DOCUMENT_NAME_LIST_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        }
        default:
            return state
        }
  }
  
  export default HrDocumentsNameReducer
  