import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { toast } from "react-toastify";

import {Name, Email, Tags, Projects, Status } from "./columns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  ModalFooter
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
  getCasemanagers as onGetCasemanagers,
  addCasemanager as onAddCasemanager,
  updateCasemanager as onUpdateCasemanager
} from "store/actions";

function Casemanagers(){
  //meta title
  document.title="Casemanagers | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [ModalLoading, setModalLoading] = useState(false);
  const [StaffDepartment, setStaffDepartment] = useState([]);
  const [department_dropdown, setDepartment_dropdown] = useState([]);
  const [UserType, setUserType] = useState('');
  const [DepartmentCMDetails, setDepartmentCMDetails] = useState(false);
  const [activityLog, setActivityLog] = useState([]);

  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user_status: ( roster && roster.user_status ) || 0,
      user_title: ( roster && roster.user_title ) || "",
      case_manager_name: ( roster && roster.case_manager_name ) || "",
      user_email: ( roster && roster.user_email ) || "",
      user_gender: ( roster && roster.user_gender ) || "",
      user_mobile_no: ( roster && roster.user_mobile_no ) || "",
      user_address: ( roster && roster.user_address ) || "",
      user_pass: "",
      user_pass_confirm: "",
      provider_contact_name: ( roster && roster.provider_contact_name ) || "",
      provider_contact_relation: ( roster && roster.provider_contact_relation ) || "",
      provider_contact_no: ( roster && roster.provider_contact_no ) || "",
      provider_contact_email: ( roster && roster.provider_contact_email ) || "",
      report_weekly: (roster && roster.report_weekly) ? true : false ,
      report_weekly_email: (roster && roster.report_weekly_email) || "",
      report_monthly: (roster && roster.report_monthly) ? true : false,
      report_monthly_email: (roster && roster.report_monthly_email) || "",
      report_daily: (roster && roster.report_daily) ? true : false, 
      report_daily_email: (roster && roster.report_daily_email) || "",
    },
    validationSchema: Yup.object({
      // user_status: Yup.string().required("This field is required"),
      user_title: Yup.string().required("This field is required"),
      case_manager_name: Yup.string().required("This field is required"),
      user_email: Yup.string().required("This field is required"),
      user_gender: Yup.string().required("This field is required"),
      user_mobile_no:Yup.string().required("This field is required"),
      user_pass: Yup.string().oneOf([Yup.ref('user_pass_confirm'),null], 'Passwords much match'),
      user_pass_confirm: Yup.string().oneOf([Yup.ref('user_pass'),null], 'Passwords much match'),
      user_address:Yup.string().required("This field is required"),
      provider_contact_name: Yup.string().required("This field is required"),
      provider_contact_relation: Yup.string().required("This field is required"),
      provider_contact_no: Yup.string().required("This field is required"),
      provider_contact_email: Yup.string().required("This field is required"),
      report_weekly: Yup.boolean().default(false),
      report_weekly_email: Yup.string().when('report_weekly', {
        is: true,
        then: schema => schema.required("Please provide weekly report email"),
        otherwise: schema => schema.optional(),
      }),
      report_monthly: Yup.boolean().default(false),
      report_monthly_email: Yup.string().when('report_monthly', {
        is: true,
        then: schema => schema.required("Please provide monthly report email"),
        otherwise: schema => schema.optional(),
      }),
      report_daily: Yup.boolean().default(false),
      report_daily_email: Yup.string().when('report_daily', {
        is: true ,
        then: schema => schema.required("Please provide daily report email"),
        otherwise: schema => schema.optional(),
      })
    }),
    onSubmit: values => {

      if( UserType == '' ) {
        toast.error('Please select user type.', { autoClose: 5000 });
        return false;
      }

      if( StaffDepartment.value == '' ) {
        toast.error('Please select department.', { autoClose: 5000 });
        return false;
      }

      if (isEdit) {
        const updateUser = {
          user_id: roster.user_id,
          user_status: values.user_status,
          user_type: UserType,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
          report_weekly: (values.report_weekly === true) ? 1 : 0,
          report_weekly_email: values.report_weekly_email,
          report_monthly: (values.report_monthly === true) ? 1 : 0,
          report_monthly_email: values.report_monthly_email,
          report_daily: (values.report_daily === true) ? 1 : 0,
          report_daily_email: values.report_daily_email,
        };

        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'edit_case_manager','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              dispatch(onUpdateCasemanager(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              validation.resetForm();
              setIsEdit(false);
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      } else {
        const newData = {
          user_status: values.user_status,
          user_type: UserType,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
          report_weekly: (values.report_weekly === true) ? 1 : 0,
          report_weekly_email: values.report_weekly_email,
          report_monthly: (values.report_monthly === true) ? 1 : 0,
          report_monthly_email: values.report_monthly_email,
          report_daily: (values.report_daily === true) ? 1 : 0,
          report_daily_email: values.report_daily_email,
        };

        // save new user
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'add_case_manager','formData': newData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            
            if( fetchedData.status == 'success' ) {
              dispatch(onAddCasemanager(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.CasemanagerReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        casemanagers: rosterReducer.casemanagers,
        columns_hidden: rosterReducer.columns_hidden,
        loading: rosterReducer.loading
      })
  );
  const {
    casemanagers, columns_hidden, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {
    //if (isEmpty(todaysRosters)) {
      if (casemanagers && !casemanagers.length) {
      const todaysDate = moment().format("YYYY-MM-DD");
      dispatch(onGetCasemanagers({'data_for': 'get_casemanagers','user': loggedUser}));
      }
    //}
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  };

  const toggleDepartmentCMDetails = () => {
    if( DepartmentCMDetails ) {
      setDepartmentCMDetails(false);
    } else {
      setDepartmentCMDetails(true);
    }
  }

  const handleUserClick = arg => {
    setActivityLog([]);

    const user = arg;

    setRoster({
      user_id: user.user_id,
      user_status: user.user_status,
      user_type: user.user_type,
      // department_id: user.department_id,
      user_title: user.user_title,
      case_manager_name: user.case_manager_name,
      user_email: user.user_email,
      user_gender: user.user_gender,
      user_mobile_no: user.user_mobile_no,
      user_address: user.user_address,
      provider_contact_name: user.provider_contact_name,
      provider_contact_relation: user.provider_contact_relation,
      provider_contact_no: user.provider_contact_no,
      provider_contact_email: user.provider_contact_email,
      report_weekly: user.report_weekly,
      report_weekly_email: user.report_weekly_email,
      report_monthly: user.report_monthly,
      report_monthly_email: user.report_monthly_email,
      report_daily: user.report_daily,
      report_daily_email: user.report_daily_email,
    });

    if( loggedUser.role == 'department' ) {

      setUserType(user.user_type)
      // handleUserType(user.user_type);
      setStaffDepartment({label:user.department_name,value:user.dep_user_id});
      loadCmDetailsActivity(user.user_id);
      toggleDepartmentCMDetails();

    } else {

      setUserType(user.user_type)
      loadCmDetailsActivity(user.user_id);
      handleUserType(user.user_type);
      setStaffDepartment({label:user.department_name,value:user.dep_user_id});
      
      setIsEdit(true);
      toggle();

    }
  }

  const handleUserClicks = () => {
    setRoster([]);
    setUserType('');
    setActivityLog([]);
    setStaffDepartment([]);
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  }

  const loadCmDetailsActivity = (user_id) => {

    const formData = {
        uid: user_id
    }

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_activity_log','formData':formData})
    .then(res => {
      const fetchedData = res.data;
        setActivityLog(fetchedData.activityLog)
    })
  }

  const handleUserType = (type) => {

    validation.values.user_type = type;

    const formData = {
        type: type,
    }
    setUserType(type);
    setModalLoading(true);
    
    setStaffDepartment([]);
    // setStaffCasemanager([]);
    setDepartment_dropdown([]);
    // setCasemanager_dropdown([]);

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Department",
        accessor: "department_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Name",
        accessor: "case_manager_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return (
            <Link
                to="/clients"
                className="text-success"
              >
                <Name {...cellProps} /> 
              </Link>
          )
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "user_address",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Phone No.",
        accessor: "user_mobile_no",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      
      {
        Header: "Status",
        accessor: "user_status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  { loggedUser.role == 'admin' ?  'Edit' : 'View' }
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  const handleSubmitForm = () => {

    validation.handleSubmit();

  }

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }


      <Modal isOpen={DepartmentCMDetails} toggle={toggleDepartmentCMDetails} size="xl" backdrop="static" scrollable>
          <ModalHeader toggle={toggleDepartmentCMDetails} tag="h4">
            Case Manager Details
            { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
          </ModalHeader>
          <ModalBody>
          <Form>

            <Row className="mb-4">
              <Label
                  htmlFor="horizontal-firstname-Input"
                  className="col-sm-3 col-form-label"
              >
              Department
              </Label>
              <Col sm={9}>
                <Select
                    value={StaffDepartment}
                    name="staff_department"
                    className="select2-selection"
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Label
                  htmlFor="horizontal-firstname-Input"
                  className="col-sm-3 col-form-label"
              >
                  Case Manager Name
              </Label>
              <Col sm={9}>
              <Input
                  name="case_manager_name"
                  type="text"
                  value={roster.case_manager_name}
              />
              </Col>
            </Row>

            <Row className="mb-4">
              <Label
                  htmlFor="horizontal-firstname-Input"
                  className="col-sm-3 col-form-label"
              >
                  Case Manager Email
              </Label>
              <Col sm={9}>
              <Input
                  name="user_email"
                  type="email"
                  value={roster.user_email}
              />
              </Col>
            </Row>

            <Row className="mb-4">
                <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                >
                    Client Gender
                </Label>
                <Col sm={9}>
                    <Input
                        name="user_gender"
                        type="radio"
                        // onChange={validation.handleChange}
                        value='Male'
                        
                        checked={roster.user_gender ==="Male" ? true : false }
                    /> &nbsp;&nbsp;Male
                    &nbsp;&nbsp;<Input
                        name="user_gender"
                        type="radio"
                        // onChange={validation.handleChange}
                        value='Female'
                        
                        checked={roster.user_gender === "Female" ? true : false }
                    
                    />&nbsp;&nbsp;Female
                  
                </Col>
              </Row>
              <Row className="mb-4">
                <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                >
                    Mobile No
                </Label>
                <Col sm={9}>
                <Input
                    name="user_mobile_no"
                    type="text"
                    value={roster.user_mobile_no}
                />
                </Col>
              </Row>

              <Row className="mb-4">
                <Label
                    htmlFor="horizontal-firstname-Input"
                    className="col-sm-3 col-form-label"
                >
                    Address
                </Label>
                <Col sm={9}>
                <Input
                    name="user_address"
                    type="text"
                    value={roster.user_address}
                    
                />
                </Col>
              </Row>
          </Form>
        </ModalBody>
      </Modal>
            
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="CaseManagers" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={casemanagers}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    isAddBtn={loggedUser.role !='department' ? true : false }
                    btnText={'Add Casemanager'}
                    columnsHide={columns_hidden}
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Case Manager Details" : "Add Case Manager Details"}
                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col md={8}>
                          <Form>
                        
                        { isEdit && 
                          <Row className="mb-4">

                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          > User Status </Label>
                          <Col sm={9}>
                              <Input
                                  name="user_status"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='1'
                                  invalid={
                                      validation.touched.user_status &&
                                      validation.errors.user_status
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.user_status == 1 ? true : false }
                                  
                              /> &nbsp;&nbsp;Active
                              &nbsp;&nbsp;<Input
                                  name="user_status"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                      validation.touched.user_status &&
                                      validation.errors.user_status
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.user_status == 0 ? true : false }
                              
                              />&nbsp;&nbsp;Inactive
                              
                              {validation.touched.user_status &&
                              validation.errors.user_status ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_status}
                              </FormFeedback>
                              ) : null}
                          </Col>

                          </Row>
                        }
                        
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              For
                          </Label>
                          <Col sm={9}>
                          { loggedUser.role != 'human_resource' && 
                              <>
                              <Input
                                  name="user_type"
                                  type="radio"
                                  onChange={(e) => {
                                      handleUserType('provider');
                                  }}
                                  value='provider'
                                  checked={ UserType == 'provider' ? true : false }
                              /> &nbsp;&nbsp;Provider
                              </>
                              }
                              &nbsp;&nbsp;<Input
                                  name="user_type"
                                  type="radio"
                                  onChange={(e) => {
                                      handleUserType('contractors');
                                  }}
                                  value='contractors'
                                  checked={ UserType == 'contractors' ? true : false }
                              
                              />&nbsp;&nbsp;3rd Party Contractors
                              
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                          Department
                          </Label>
                          <Col sm={9}>
                            <Select
                                value={StaffDepartment}
                                name="staff_department"
                                options={department_dropdown}
                                onChange={(e) => setStaffDepartment(e)}
                                className="select2-selection"
                                defaultValue={department_dropdown}
                                styles={{
                                  menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-4">

                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Title
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="user_title"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='Mr'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title === "Mr" ? true : false }
                                    /> &nbsp;&nbsp;Mr
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='Mrs'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title === "Mrs" ? true : false }
                                    
                                    />&nbsp;&nbsp;Mrs
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='Miss'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title === "Miss" ? true : false }
                                    />&nbsp;&nbsp;Miss
                                    &nbsp;&nbsp;<Input
                                        name="user_title"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='Ms'
                                        invalid={
                                            validation.touched.user_title &&
                                            validation.errors.user_title
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.user_title === "Ms" ? true : false }
                                    />&nbsp;&nbsp;Ms
                                    {validation.touched.user_title &&
                                    validation.errors.user_title ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_title}
                                    </FormFeedback>
                                    ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Case Manager Name
                              </Label>
                              <Col sm={9}>
                              <Input
                                  name="case_manager_name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.case_manager_name}
                                  invalid={
                                  validation.touched.case_manager_name &&
                                      validation.errors.case_manager_name
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.case_manager_name &&
                                  validation.errors.case_manager_name ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.case_manager_name}
                                  </FormFeedback>
                              ) : null}
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Case Manager Email
                              </Label>
                              <Col sm={9}>
                              <Input
                                  name="user_email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.user_email}
                                  invalid={
                                  validation.touched.user_email &&
                                      validation.errors.user_email
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.user_email &&
                                  validation.errors.user_email ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.user_email}
                                  </FormFeedback>
                              ) : null}
                              </Col>
                            </Row>

                            { isEdit && 
                            <>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Password
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="user_pass"
                                    type="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.user_pass}
                                    invalid={
                                    validation.touched.user_pass &&
                                        validation.errors.user_pass
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.user_pass &&
                                    validation.errors.user_pass ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_pass}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Confirm Password
                                </Label>
                                <Col sm={9}>
                                <Input
                                    name="user_pass_confirm"
                                    type="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.user_pass_confirm}
                                    invalid={
                                    validation.touched.user_pass_confirm &&
                                        validation.errors.user_pass_confirm
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.user_pass_confirm &&
                                    validation.errors.user_pass_confirm ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_pass_confirm}
                                    </FormFeedback>
                                ) : null}
                                </Col>
                            
                            </Row>

                            </>
                            }

                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Client Gender
                              </Label>
                              <Col sm={9}>
                                  <Input
                                      name="user_gender"
                                      type="radio"
                                      onChange={validation.handleChange}
                                      value='Male'
                                      invalid={
                                          validation.touched.user_gender &&
                                          validation.errors.user_gender
                                          ? true
                                          : false
                                      }
                                      checked={validation.values.user_gender ==="Male" ? true : false }
                                  /> &nbsp;&nbsp;Male
                                  &nbsp;&nbsp;<Input
                                      name="user_gender"
                                      type="radio"
                                      onChange={validation.handleChange}
                                      value='Female'
                                      invalid={
                                          validation.touched.user_gender &&
                                          validation.errors.user_gender
                                          ? true
                                          : false
                                      }
                                      checked={validation.values.user_gender === "Female" ? true : false }
                                  
                                  />&nbsp;&nbsp;Female
                                  
                                  {validation.touched.user_gender &&
                                  validation.errors.user_gender ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.user_gender}
                                  </FormFeedback>
                              ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Mobile No
                              </Label>
                              <Col sm={9}>
                              <Input
                                  name="user_mobile_no"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.user_mobile_no}
                                  invalid={
                                  validation.touched.user_mobile_no &&
                                      validation.errors.user_mobile_no
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.user_mobile_no &&
                                  validation.errors.user_mobile_no ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.user_mobile_no}
                                  </FormFeedback>
                              ) : null}
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Address
                              </Label>
                              <Col sm={9}>
                              <Input
                                  name="user_address"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.user_address}
                                  invalid={
                                  validation.touched.user_address &&
                                      validation.errors.user_address
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.user_address &&
                                  validation.errors.user_address ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.user_address}
                                  </FormFeedback>
                              ) : null}
                              </Col>
                            </Row>

                            <CardTitle className="mb-4">Provider Contact</CardTitle>
                            
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Name
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="provider_contact_name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.provider_contact_name}
                                        invalid={
                                            validation.touched.provider_contact_name &&
                                            validation.errors.provider_contact_name
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.provider_contact_name &&
                                    validation.errors.provider_contact_name ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.provider_contact_name}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Relation
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="provider_contact_relation"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.provider_contact_relation}
                                        invalid={
                                            validation.touched.provider_contact_relation &&
                                            validation.errors.provider_contact_relation
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.provider_contact_relation &&
                                    validation.errors.provider_contact_relation ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.provider_contact_relation}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Mobile No
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="provider_contact_no"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.provider_contact_no}
                                        invalid={
                                            validation.touched.provider_contact_no &&
                                            validation.errors.provider_contact_no
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.provider_contact_no &&
                                    validation.errors.provider_contact_no ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.provider_contact_no}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                Contact Email
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="provider_contact_email"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.provider_contact_email}
                                        invalid={
                                            validation.touched.provider_contact_email &&
                                            validation.errors.provider_contact_email
                                            ? true
                                            : false
                                        }
                                    />
                                </Col>
                                {validation.touched.provider_contact_email &&
                                    validation.errors.provider_contact_email ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.provider_contact_email}
                                    </FormFeedback>
                                ) : null}
                            </Row>

                            <Row className="mb-4">
                              <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                              >
                                Auto Report Send
                              </Label>
                              <Col sm={9}>
                                    <Input
                                        type="checkbox"
                                        value="1"
                                        name="report_weekly"
                                        onChange={validation.handleChange}
                                        checked={validation.values.report_weekly == 1 ? true : false}
                                    /> Weekly

                                    { validation.values.report_weekly == 1 && 
                                    <>
                                    <Col sm={12}>
                                      <Input
                                          name="report_weekly_email"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.report_weekly_email}
                                          invalid={
                                            validation.touched.report_weekly_email &&
                                            validation.errors.report_weekly_email
                                            ? true
                                            : false
                                          }
                                      />
                                      <em>Add emails with (,) to send report to multiple emails</em>
                                    </Col>

                                    {validation.touched.report_weekly_email &&
                                    validation.errors.report_weekly_email ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.report_weekly_email}
                                    </FormFeedback>
                                    ) : null}
                                  </>
                                  }
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                              >
                                
                              </Label>
                              <Col sm={9}>
                                    <Input
                                        type="checkbox"
                                        value="1"
                                        name="report_monthly"
                                        onChange={validation.handleChange}
                                        checked={validation.values.report_monthly == 1 ? true : false}
                                    /> Monthly

                                    { validation.values.report_monthly  == 1 && 
                                    <>

                                    <Col sm={12}>
                                      <Input
                                          name="report_monthly_email"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.report_monthly_email}
                                          invalid={
                                            validation.touched.report_monthly_email &&
                                            validation.errors.report_monthly_email
                                            ? true
                                            : false
                                          }
                                      />
                                      <em>Add emails with (,) to send report to multiple emails</em>
                                    </Col>

                                    {validation.touched.report_monthly_email &&
                                      validation.errors.report_monthly_email ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.report_monthly_email}
                                      </FormFeedback>
                                      ) : null}

                                    </>
                                  }
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                              >
                                
                              </Label>
                              <Col sm={9}>
                                    <Input
                                        type="checkbox"
                                        value="1"
                                        name="report_daily"
                                        onChange={validation.handleChange}
                                        checked={validation.values.report_daily == 1 ? true : false}
                                    /> Daily

                                    { validation.values.report_daily == 1 && 
                                    <>
                                    <Col sm={12}>
                                      <Input
                                          name="report_daily_email"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.report_daily_email}
                                          invalid={
                                            validation.touched.report_daily_email &&
                                            validation.errors.report_daily_email
                                            ? true
                                            : false
                                          }
                                      />
                                      <em>Add emails with (,) to send report to multiple emails</em>
                                    </Col>

                                    {validation.touched.report_daily_email &&
                                      validation.errors.report_daily_email ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.report_daily_email}
                                      </FormFeedback>
                                      ) : null}
                                      
                                    </>

                                    }
                              </Col>
                            </Row>
                            
                          </Form>
                      </Col>

                      <Col md={4}>
                        <Row>
                        <CardTitle>Activity Log</CardTitle>
                          <div className="order_notes_div">
                              
                              <ul className="order_notes">
                                  { activityLog.map(activity => (
                                  <li key={activity.id} rel={activity.id} className="note">
                                      <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                      <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                      <strong>
                                      {activity.activity_by}
                                      </strong>
                                      </abbr></p>
                                  </li>
                                  ))}
                              </ul>
                          </div>
                      </Row>
                      
                      </Col>

                      </Row>
                    </ModalBody>
                    <ModalFooter>
                    <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="btn"
                                className="btn btn-success save-user"
                                disabled={ModalLoading}
                                onClick={handleSubmitForm}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Casemanagers;