import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import jobsSaga from "./jobs/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import dashboardCryptoSaga from "./dashboard-crypto/saga";
import dashboardBlogSaga from "./dashboard-blog/saga";
import dashboardJobSaga from "./dashboard-jobs/saga";
import rostersSaga from "./rosters/saga"
import departmentsSaga from "./departments/saga"
import casemanagersSaga from "./casemanagers/saga"
import clientsSage from "./clients/saga"
import emergencyAlertsSaga from "./emergency-alerts/saga"
import broadcastRosterSaga from "./broadcast-rosters/saga";
import contractorsSaga from "./contractors/saga";
import staffLeavesSaga from "./staff-leaves/saga";
import clientAwayAlertSaga from "./client-away-alert/saga";
import reportsSaga from "./reports/saga";
import incidentReportSaga from "./incident-reports/saga";
import NewsFeedSaga from "./news-feed/saga";
import ClientTypesSaga from "./clientpackagetypes/saga";
import SettingsSaga from "./settings/saga";
import PackageReportSaga from "./package-reports/saga";
import PayCategorySaga from "./paycategory/saga";
import VaccinationDetailsSaga from "./vaccinationdetails/saga";
import ChecklistQuestionSaga from "./covidquestions/saga";
import HrDocumentsNameSaga from "./hrdocuments/saga";
import SendSmsSaga from "./sendsms/saga";
import AlertRosterSaga from "./alerts/saga";
import StaffsSaga from "./staffs/saga";
import PublicHolidaysSaga from "./publicholiday/saga";
import FeedbacksSaga from "./feedbacks/saga";
import RecruitmentsSaga from "./recruitments/saga";


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga),
    fork(rostersSaga),
    fork(departmentsSaga),
    fork(casemanagersSaga),
    fork(AlertRosterSaga),
    fork(clientsSage),
    fork(emergencyAlertsSaga),
    fork(broadcastRosterSaga),
    fork(contractorsSaga),
    fork(staffLeavesSaga),
    fork(clientAwayAlertSaga),
    fork(reportsSaga),
    fork(incidentReportSaga),
    fork(NewsFeedSaga),
    fork(ClientTypesSaga),
    fork(SettingsSaga),
    fork(PackageReportSaga),
    fork(PayCategorySaga),
    fork(VaccinationDetailsSaga),
    fork(ChecklistQuestionSaga),
    fork(HrDocumentsNameSaga),
    fork(SendSmsSaga),
    fork(StaffsSaga),
    fork(PublicHolidaysSaga),
    fork(FeedbacksSaga),
    fork(RecruitmentsSaga)
  ]);
}
