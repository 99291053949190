import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CLIENTS,
  GET_CLIENTS_ROSTERS,
  GET_CLIENTS_ROSTERS_DETAILS,
  GET_CLIENTS_DELETED_ROSTERS,
  GET_CLIENTS_ISOLATION_LIST,

  GET_CLIENTS_SERVICES_LIST,
  GET_CLIENT_NOTES,
  ADD_CLIENT_NOTES,
  UPDATE_CLIENT_NOTES,
  GET_CLIENTS_SERVICES,
  ADD_CLIENTS_SERVICES_LIST,
  UPDATE_CLIENTS_SERVICES_LIST,
  GET_CLIENTS_SERVICES_TYPES,
  ADD_CLIENTS_SERVICES_TYPES,
  UPDATE_CLIENTS_SERVICES_TYPES,
  ADD_CLIENTS_ISOLATION_LIST,
  UPDATE_CLIENTS_ISOLATION_LIST,
  GET_ARCHIVED_CLIENTS,
  GET_ARCHIVED_CLIENTS_SUCCESS,
  GET_ARCHIVED_CLIENTS_FAIL
  
} from "./actionTypes"

import {
  getClientsSuccess,
  getClientsFail,
  getClientsRostersSuccess,
  getClientsRostersFail,
  getClientsRostersDetailSuccess,
  getClientsRostersDetailFail,
  getClientsDeletedRostersSuccess,
  getClientsDeletedRostersFail,
  getClientsServicesListSuccess,
  getClientsServicesListFail,
  getClientsServicesTypesSuccess,
  getClientsServicesTypesFail,

  addClientsNotesSuccess,
  addClientsNotesFail,

  updateClientsNotesSuccess,
  updateClientsNotesFail,

  addClientsIsolationListSuccess,
  addClientsIsolationListFail,

  updateClientsIsolationListSuccess,
  updateClientsIsolationListFail,

  addClientsServicesListSuccess,
  addClientsServicesListFail,

  updateClientsServicesListSuccess,
  updateClientsServicesListFail,

  addClientsServicesTypesSuccess,
  addClientsServicesTypesFail,

  updateClientsServicesTypesSuccess,
  updateClientsServicesTypesFail,
  getClientsIsolationListSuccess,

  
  getArchivedClientsSuccess,
  getArchivedClientsFail,

} from "./actions"


//Include Both Helper File with needed methods
import { getClients, getArchivedClients, actionUrl } from "helpers/fakebackend_helper"

function* fetchAllClients({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getClients, params, types )
    yield put(getClientsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getClientsFail(error))
  }
}

function* fetchArchivedClients({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getArchivedClients, params, types )
    yield put(getArchivedClientsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getArchivedClientsFail(error))
  }
}

function* fetchClientsServicesTypes({params,types}) {
  try {
    const response = yield call(actionUrl,params,types)
    yield put(getClientsServicesTypesSuccess(response))
  } catch (error) {
    yield put(getClientsServicesTypesFail(error))
  }
}

function* addClientsServicesTypes(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(addClientsServicesTypesSuccess(response))
  } catch (error) {
    yield put(addClientsServicesTypesFail(error))
  }
}

function* updateClientsServicesTypes(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(updateClientsServicesTypesSuccess(response))
  } catch (error) {
    yield put(updateClientsServicesTypesFail(error))
  }
}
// CLients Services List
function* fetchClientsServicesList({params,types}) {
  try {
    const response = yield call(actionUrl,params,types)
    yield put(getClientsServicesListSuccess(response))
  } catch (error) {
    yield put(getClientsServicesListFail(error))
  }
}

function* addClientsServicesList(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(addClientsServicesListSuccess(response))
  } catch (error) {
    yield put(addClientsServicesListFail(error))
  }
}

function* updateClientsServicesList(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(updateClientsServicesListSuccess(response))
  } catch (error) {
    yield put(updateClientsServicesListFail(error))
  }
}

// CLients Isolation List
function* fetchClientsIsolationList({params,types}) {
  try {
    const response = yield call(actionUrl,params,types)
    yield put(getClientsIsolationListSuccess(response))
  } catch (error) {
    yield put(getClientsIsolationListFail(error))
  }
}

function* addClientsIsolationList(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(addClientsIsolationListSuccess(response))
  } catch (error) {
    yield put(addClientsIsolationListFail(error))
  }
}

function* updateClientsIsolationList(data) {
  try {
    const response = yield call(actionUrl,data)
    yield put(updateClientsIsolationListSuccess(response))
  } catch (error) {
    yield put(updateClientsIsolationListFail(error))
  }
}


function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchAllClients)
  yield takeEvery(GET_ARCHIVED_CLIENTS, fetchArchivedClients)
  yield takeEvery(GET_CLIENTS_SERVICES_TYPES, fetchClientsServicesTypes)
  yield takeEvery(ADD_CLIENTS_SERVICES_TYPES, addClientsServicesTypes)
  yield takeEvery(UPDATE_CLIENTS_SERVICES_TYPES, updateClientsServicesTypes)

  yield takeEvery(GET_CLIENTS_SERVICES_LIST, fetchClientsServicesList)
  yield takeEvery(UPDATE_CLIENTS_SERVICES_LIST, updateClientsServicesList)
  yield takeEvery(ADD_CLIENTS_SERVICES_LIST, addClientsServicesList)

  yield takeEvery(GET_CLIENTS_ISOLATION_LIST, fetchClientsIsolationList)
  yield takeEvery(UPDATE_CLIENTS_ISOLATION_LIST, updateClientsIsolationList)
  yield takeEvery(ADD_CLIENTS_ISOLATION_LIST, addClientsIsolationList)
  
}

export default clientsSaga
