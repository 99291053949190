/* Rosters */
export const GET_INCIDENT_REPORT_ACTIVE = "GET_INCIDENT_REPORT_ACTIVE"
export const GET_INCIDENT_REPORT_ACTIVE_SUCCESS = "GET_INCIDENT_REPORT_ACTIVE_SUCCESS"
export const GET_INCIDENT_REPORT_ACTIVE_FAIL = "GET_INCIDENT_REPORT_ACTIVE_FAIL"

export const GET_INCIDENT_REPORT_COMPLETED = "GET_INCIDENT_REPORT_COMPLETED"
export const GET_INCIDENT_REPORT_COMPLETED_SUCCESS = "GET_INCIDENT_REPORT_COMPLETED_SUCCESS"
export const GET_INCIDENT_REPORT_COMPLETED_FAIL = "GET_INCIDENT_REPORT_COMPLETED_FAIL"

export const UPDATE_INCIDENT_REPORT = "UPDATE_INCIDENT_REPORT"
export const UPDATE_INCIDENT_REPORT_SUCCESS = "UPDATE_INCIDENT_REPORT_SUCCESS"
export const UPDATE_INCIDENT_REPORT_FAIL = "UPDATE_INCIDENT_REPORT_FAIL"

export const ADD_INCIDENT_REPORT = "ADD_INCIDENT_REPORT"
export const ADD_INCIDENT_REPORT_SUCCESS = "ADD_INCIDENT_REPORT_SUCCESS"
export const ADD_INCIDENT_REPORT_FAIL = "ADD_INCIDENT_REPORT_FAIL"

export const GET_INCIDENT_REPORT_STAFFS = "GET_STAFFS"
export const GET_INCIDENT_REPORT_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_INCIDENT_REPORT_STAFFS_FAIL = "GET_STAFFS_FAIL"

export const GET_STAFFS_ROSTERS = "GET_STAFFS_ROSTERS"
export const GET_STAFFS_ROSTERS_SUCCESS = "GET_STAFFS_ROSTERS_SUCCESS"
export const GET_STAFFS_ROSTERS_FAIL = "GET_STAFFS_ROSTERS_FAIL"

