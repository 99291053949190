import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"


import {
    sendSms as onSendSms,
    getSmsStaffsDrowdown as onGetSmsStaffsDropdown
} from "store/actions";

function SendSms(){
  //meta title
  document.title="Send Sms | Tacveiha Panel";

  // console.log(getLoggedInUser );
  

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  
  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.SendSmsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        staffsDropdown : rosterReducer.staffsDropdown,
        loading : rosterReducer.loading
      })
  );
  const {
    staffsDropdown,loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      subject: (roster && roster.sibject) || "",
      message:(roster && roster.message) || "",
      staffs: (roster && roster.staffs) || "",

    },
    validationSchema: Yup.object({
        subject: Yup.string().required("Please enter subject of message"),
        message: Yup.string().required("Please enter message"),
        // staffs: Yup.string().required("Please select staffs"),
    }),
    onSubmit: values => {
        // console.log( selectedStaff );
        if( selectedStaff == '' ) {
            toast.error("Please select staffs", { autoClose:32000 });
            return false;
        }

        const updateUser = {
            subject: values.status,
            message: values.repeat_type,
            staffs: selectedStaff,
        };

        // update user
        dispatch(onSendSms({'data_for':'send_sms','formData': updateUser,'user': loggedUser}));
        validation.resetForm();
    },
  });

  useEffect(() => {
    dispatch(onGetSmsStaffsDropdown({'data_for':'get_staffs_dropdown','user': loggedUser}));
  }, [dispatch]);


  const toggle = () => { 
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleSelectedValue = (e) => {
    // console.log(e);
    setSelectedStaff(e);
  }


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };
  const keyField = "id";

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Send Sms" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                    <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                    <Col className="mb-6">
                    <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Sms Subject
                        </label>
                        <div className="col-md-10">
                            <Label className="form-label"></Label>
                            <Input
                            name="subject"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subject || ""}
                            invalid={
                                validation.touched.subject &&
                                validation.errors.subject
                                ? true
                                : false
                            }
                            />
                            {validation.touched.subject &&
                            validation.errors.subject ? (
                            <FormFeedback type="invalid">
                                {validation.errors.subject}
                            </FormFeedback>
                            ) : null}
                        </div>  
                    </Row>
                    </Col>

                    <Col className="mb-6">
                    <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                        Sms Message
                        </label>
                        <div className="col-md-10">
                            <Label className="form-label"></Label>
                            <Input
                            name="message"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ""}
                            invalid={
                                validation.touched.message &&
                                validation.errors.message
                                ? true
                                : false
                            }
                            />
                            {validation.touched.message &&
                            validation.errors.message ? (
                            <FormFeedback type="invalid">
                                {validation.errors.message}
                            </FormFeedback>
                            ) : null}
                        </div>  
                        </Row>
                    </Col>


                    <Col className="mb-6">

                        <Row className="mb-3">
                        <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                        >
                        Staffs
                        </label>
                        <div className="col-md-10">

                        <Select
                            value={staffsDropdown.value}
                            onChange={(e) => {
                            setSelectedStaff(e);
                            }}
                            name="ctid"
                            options={staffsDropdown}
                            className="select2-selection"
                            // defaultValue={handleSelectedValue}
                        />
                        </div>
                        </Row>

                    </Col>
                
                    <Row>
                      <Col>
                        <div className="text-start">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                }
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(SendSms);