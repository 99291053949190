import {
    GET_VACCINATION_DETAILS,
    GET_VACCINATION_DETAILS_SUCCESS,
    GET_VACCINATION_DETAILS_FAIL,
    UPDATE_VACCINATION_DETAILS,
    UPDATE_VACCINATION_DETAILS_SUCCESS,
    UPDATE_VACCINATION_DETAILS_FAIL,
    ADD_VACCINATION_DETAILS,
    ADD_VACCINATION_DETAILS_SUCCESS,
    ADD_VACCINATION_DETAILS_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    vaccinationDetails: [],
    error: {},
    loading: false,
  }
  
  const VaccinationDetailsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VACCINATION_DETAILS:
        return {
            ...state,
            //settings: state.settings,
            loading: true
        }

        case GET_VACCINATION_DETAILS_SUCCESS:
        return {
            ...state,
            vaccinationDetails: action.payload.data,
            loading: false
        }
  
        case GET_VACCINATION_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_VACCINATION_DETAILS:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_VACCINATION_DETAILS_SUCCESS:
        return {
            ...state,
            vaccinationDetails: state.vaccinationDetails.map(rosters =>
                rosters.id.toString() === action.payload.data.id.toString()
                        ? { rosters, ...action.payload.data }
                        : rosters
            ),
            loading: false
        };
  
        case UPDATE_VACCINATION_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_VACCINATION_DETAILS:
        return {
            ...state,
            loading: true
        };

        case ADD_VACCINATION_DETAILS_SUCCESS:
        return {
            ...state,
            vaccinationDetails: [...state.vaccinationDetails, action.payload.data],
            loading: false
        }

        case ADD_VACCINATION_DETAILS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        }
        default:
            return state
        }
  }
  
  export default VaccinationDetailsReducer
  