import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SMS_STAFFS_DROPDOWN,SEND_SMS } from "./actionTypes"
import {
 
    getSmsStaffsDrowdownSuccess,
    getSmsStaffsDrowdownFail,
    sendSmsSuccess,
    sendSmsFail
  
} from "./actions"

//Include Both Helper File with needed methods
import { actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchSmsStaffsDropdown({params,types}) {
  try {
    const response = yield call(actionUrl, params, types )
    console.log(response);
    yield put(getSmsStaffsDrowdownSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getSmsStaffsDrowdownFail(error))
  }
}

function* sendSms({params,types}) {
  try {
    const response = yield call(actionUrl, params, types )
    console.log(response);
    yield put(sendSmsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(sendSmsFail(error))
  }
}

function* SendSmsSaga() {
  yield takeEvery(GET_SMS_STAFFS_DROPDOWN, fetchSmsStaffsDropdown)
  yield takeEvery(SEND_SMS, sendSms)
}

export default SendSmsSaga
