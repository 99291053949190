import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name} from "../../components/Common/TableColumns";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getPublicHolidays as onGetPublicHolidays,
    addPublicHoliday as onAddPublicHoliday,
    updatePublicHoliday as onUpdatePublicHoliday
} from "store/actions";

function PublicHolidays(){
  //meta title
  document.title="Public Holidays | Tacveiha Panel";

  // console.log(getLoggedInUser );
  

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [PHDate, setPHDate] = useState('');
  const [PHDatesArray, setPHDatesArray] = useState([]);
  const [PHMonth, setPHMonth] = useState('');
  const [PHYear, setPHYear] = useState('');
  const [Timezones, setTimezones] = useState([]);
  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectState = (state) => state.PublicHolidaysReducer;
  const groupProperties = createSelector(
    selectState,
      (rosterReducer) => ({
        PublicHolidaysData: rosterReducer.PublicHolidays,
        datesDropdown: rosterReducer.datesDropdown,
        monthDropdown: rosterReducer.monthDropdown,
        yearDropdown: rosterReducer.yearDropdown,
        timezonesDropdown: rosterReducer.timezonesDropdown,
        loading: rosterReducer.loading
      })
  );
  const {
    PublicHolidaysData, datesDropdown, monthDropdown, yearDropdown, timezonesDropdown, loading
  } = useSelector(groupProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (roster && roster.id) || "",
      holiday_dates : (roster && roster.holiday_dates) || "",
      holiday_text: (roster && roster.holiday_text) || "",
    },
    validationSchema: Yup.object({
        // name: Yup.string().required("Please enter client type"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateData = {
            id: values.id,
            holiday_dates: PHDate,
            // month: PHMonth,
            // year: PHYear,
            timezone: Timezones,
            holiday_text: values.holiday_text
        };

        // update user
        dispatch(onUpdatePublicHoliday({'data_for':'update_public_holiday','formData': updateData,'user': loggedUser}));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newData = {
            holiday_dates: PHDate,
            // month: PHMonth,
            // year: PHYear,
            timezone: Timezones,
            holiday_text: values.holiday_text
        };
        // // save new user
        dispatch(onAddPublicHoliday({'data_for':'add_public_holiday','formData': newData,'user': loggedUser}));
        
        validation.resetForm();
      }

      setTimezones([]);
        setPHDate([]);
      toggle();
    },
  });

  useEffect(() => {
    dispatch(onGetPublicHolidays({'data_for':'get_public_holidays','user': loggedUser}));
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const addPublicHoliday = arg => {
    setRoster("");
    setPHDate([]);
    setTimezones([]);
    validation.resetForm();
    setIsEdit(false);
    toggle();
  }

  const handleUserClick = arg => {
    const user = arg;
    // console.log(user);
    setRoster({
          id: user.phid,
          holiday_text: user.holiday_text,
          holiday_dates: user.holiday_dates
    });
    setPHDate(user.holiday_dates);
    setTimezones({label:user.name, value: user.timezone});
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    
  };

  // const clsoeModal = () => {
  //   toggle();
  // }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Dates",
        accessor: "holiday_dates",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Areas",
        accessor: "name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Holiday Text",
        accessor: "holiday_text",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
     
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-success"
                    onClick={() => {
                        const userData = cellProps.row.original;
                        handleUserClick(userData);
                    }}
                    id={`edittooltip-${cellProps.row.original.id}`}
                >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                        Edit
                    </UncontrolledTooltip>
                </Link>
            </li>
        </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Public Holidays" />

          <Row className="mb-6">
            <Col sm="12">
                <div className="text-sm-end">
                    <Button
                    type="button"
                    color="primary"
                    className="mb-2 me-2"
                    onClick={addPublicHoliday}
                    >
                    
                    Add Public Holiday
                    </Button>
                </div>
            </Col>
        </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={PublicHolidaysData}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    fileName=''
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Select Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                  name="holiday_dates"
                                  type="text"
                                  className="form-control d-block"
                                  placeholder="Select Holiday Date"
                                  onChange={(selectedDates, dateStr, instance) => {
                                    setPHDate(dateStr);
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={PHDate}
                                  options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    "locale": {
                                          "firstDayOfWeek": 1 // start week on Monday
                                      }
                                  }}
                                />
                              </InputGroup>
                            </Col>
                            
                        </Row>

                        {/* <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                           Select Month
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={PHMonth}
                                    name="dates"
                                    options={monthDropdown}
                                    onChange={(e) => setPHMonth(e)}
                                    className="select2-selection"
                                    defaultValue={PHMonth}
                                />
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                           Select Year
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={PHYear}
                                    name="dates"
                                    options={yearDropdown}
                                    onChange={(e) => setPHYear(e)}
                                    className="select2-selection"
                                    defaultValue={PHYear}
                                />
                            </Col>
                        </Row> */}

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                           Select Timezones
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={Timezones}
                                    name="dates"
                                    options={timezonesDropdown}
                                    onChange={(e) => setTimezones(e)}
                                    className="select2-selection"
                                    defaultValue={Timezones}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                           Holiday Text
                            </Label>
                            <Col sm={9}>
                                <Input
                                    value={validation.values.holiday_text}
                                    name="holiday_text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    className="select2-selection"
                                />
                            </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={loading}
                              >
                                {!!isEdit ? 'Edit' :  'Add' }
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default PublicHolidays;