import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CASEMANAGERS, GET_CASEMANAGER_DETAILS, GET_CASEMANAGERS_CLIENTS, ADD_CASEMANAGER, UPDATE_CASEMANAGER, GET_CASEMANAGERS_CLIENTS_FAIL } from "./actionTypes"
import {
  // getCasemanagers,
  getCasemanagersSuccess,
  getCasemanagersFail,
  // getCasemanagerDetails,
  getCasemanagerDetailsSuccess,
  getCasemanagerDetailsFail,
  getCasemanagersClients,
  getCasemanagersClientsSuccess,
  // addCasemanager,
  // addCasemanagerSuccess,
  // addCasemanagerFail,
  // updateCasemanager,
  // updateCasemanagerSuccess,
  // updateCasemanagerFail
} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { getCasemanagers, getCasemanagerDetails, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchCasemanagers({params,types}) {
  try {
    const response = yield call(getCasemanagers, params, types )
    console.log(response);
    yield put(getCasemanagersSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getCasemanagersFail(error))
  }
}

// function* fetchCasemanagerDetails({params,types}) {
//   try {
//     const response = yield call(actionUrl, params, types )
//     console.log(response);
//     yield put(getCasemanagerDetailsSuccess(response))
//   } catch (error) {
//     console.log( 'error is'+ error );
//     yield put(getCasemanagerDetailsFail(error))
//   }
// }

function* fetchCasemanagerClients({ payload: data }) {
  try {
    const response = yield call(actionUrl,data)
    console.log(response);
    yield put(getCasemanagersClientsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getCasemanagersClientsFail(error))
  }
}

// function* addCasemanager({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(addCasemanagerSuccess(response))
//       toast.success("Details Added Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(addCasemanagerFail(error))
//       toast.error("Details Add Failed", { autoClose: 2000 });
//   }
// }

// function* updateCasemanager({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(updateCasemanagerSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateCasemanagerFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* casemanagersSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_CASEMANAGERS, fetchCasemanagers)
  // yield takeEvery(GET_CASEMANAGER_DETAILS, fetchCasemanagerDetails)
  yield takeEvery(GET_CASEMANAGERS_CLIENTS, fetchCasemanagerClients)
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default casemanagersSaga
