import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"


function HRProfile (){
  //meta title
  document.title="Human Resource Profile | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [ModalLoading, setModalLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
     
        department_fname: ( roster && roster.department_fname ) || "",
        department_lname: ( roster && roster.department_lname ) || "",
        user_email: ( roster && roster.user_email ) || "",
        user_pass: "",
        user_pass_confirm: "",
        user_mobile_no: ( roster && roster.user_mobile_no ) || "",
        user_address: ( roster && roster.user_address ) || "",
        department_emergency_contact_name: ( roster && roster.department_emergency_contact_name ) || "",
        department_emergency_contact_mobile: ( roster && roster.department_emergency_contact_mobile ) || "",
        department_emergency_contact_email: ( roster && roster.department_emergency_contact_email ) || "",
    },
    validationSchema: Yup.object({
      // user_status: Yup.string().required("This field is required"),
      department_fname: Yup.string().required("This field is required"),
      department_lname: Yup.string().required("This field is required"),
      user_email: Yup.string().required("This field is required"),
      user_gender: Yup.string().required("This field is required"),
      user_mobile_no:Yup.string().required("This field is required"),
      user_pass: Yup.string().oneOf([Yup.ref('user_pass_confirm'),null], 'Passwords much match'),
      user_pass_confirm: Yup.string().oneOf([Yup.ref('user_pass'),null], 'Passwords much match'),
      user_address:Yup.string().required("This field is required"),
      department_emergency_contact_name: Yup.string().required("This field is required"),
      department_emergency_contact_mobile: Yup.string().required("This field is required"),
      department_emergency_contact_email: Yup.string().required("This field is required"),
      
    }),
    onSubmit: values => {

        const updateUser = {
          user_id: loggedUser.uid,
          department_fname: values.department_fname,
          department_lname: values.department_lname,
          user_email: values.user_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
          department_emergency_contact_name: values.department_emergency_contact_name,
          department_emergency_contact_mobile: values.department_emergency_contact_mobile,
          department_emergency_contact_email: values.department_emergency_contact_email,
        }

        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'edit_hr_profile','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
    },
  });

  useEffect(() => {
    setModalLoading(true);

    axios.post(APP_URL+ACTION_URL,{'data_for': 'get_hr_profile','user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            toast.success(fetchedData.html, { autoClose: 5000 });
            setRoster({
                user_id: loggedUser.uid,
                department_name: fetchedData.department_name,
                department_fname: fetchedData.department_fname,
                department_lname: fetchedData.department_lname,
                user_email: fetchedData.user_email,
                user_pass: fetchedData.user_pass,
                user_pass_confirm: fetchedData.user_pass_confirm,
                user_mobile_no: fetchedData.user_mobile_no,
                user_address: fetchedData.user_address,
                department_emergency_contact_name: fetchedData.department_emergency_contact_name,
                department_emergency_contact_mobile: fetchedData.department_emergency_contact_mobile,
                department_emergency_contact_email: fetchedData.department_emergency_contact_email,
            })
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })

  }, [dispatch])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="CaseManagers" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

                <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >


                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Department Name
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="department_name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.department_fname + ' ' + validation.values.department_lname}
                           readOnly
                        />
                       
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            First Name
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="department_fname"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.department_fname}
                            invalid={
                            validation.touched.department_fname &&
                                validation.errors.department_fname
                                ? true
                                : false
                            }
                        />
                        {validation.touched.department_fname &&
                            validation.errors.department_fname ? (
                            <FormFeedback type="invalid">
                            {validation.errors.department_fname}
                            </FormFeedback>
                        ) : null}
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Last Name
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="department_lname"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.department_lname}
                            invalid={
                            validation.touched.department_lname &&
                                validation.errors.department_lname
                                ? true
                                : false
                            }
                        />
                        {validation.touched.department_lname &&
                            validation.errors.department_lname ? (
                            <FormFeedback type="invalid">
                            {validation.errors.department_lname}
                            </FormFeedback>
                        ) : null}
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            User Email
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="user_email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_email}
                            invalid={
                            validation.touched.user_email &&
                                validation.errors.user_email
                                ? true
                                : false
                            }
                        />
                        {validation.touched.user_email &&
                            validation.errors.user_email ? (
                            <FormFeedback type="invalid">
                            {validation.errors.user_email}
                            </FormFeedback>
                        ) : null}
                        </Col>
                    </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass}
                                invalid={
                                validation.touched.user_pass &&
                                    validation.errors.user_pass
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass &&
                                validation.errors.user_pass ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Confirm Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass_confirm"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass_confirm}
                                invalid={
                                validation.touched.user_pass_confirm &&
                                    validation.errors.user_pass_confirm
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass_confirm &&
                                validation.errors.user_pass_confirm ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass_confirm}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Mobile No
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_mobile_no"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_mobile_no}
                              invalid={
                              validation.touched.user_mobile_no &&
                                  validation.errors.user_mobile_no
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_mobile_no &&
                              validation.errors.user_mobile_no ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_mobile_no}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Address
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_address}
                              invalid={
                              validation.touched.user_address &&
                                  validation.errors.user_address
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_address &&
                              validation.errors.user_address ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_address}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <CardTitle className="mb-4">Emergency Contact</CardTitle>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="department_emergency_contact_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.department_emergency_contact_name}
                                    invalid={
                                        validation.touched.department_emergency_contact_name &&
                                        validation.errors.department_emergency_contact_name
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.department_emergency_contact_name &&
                                validation.errors.department_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_name}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="department_emergency_contact_mobile"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.department_emergency_contact_mobile}
                                    invalid={
                                        validation.touched.department_emergency_contact_mobile &&
                                        validation.errors.department_emergency_contact_mobile
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.department_emergency_contact_mobile &&
                                validation.errors.department_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="department_emergency_contact_mobile"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.department_emergency_contact_mobile}
                                    invalid={
                                        validation.touched.department_emergency_contact_mobile &&
                                        validation.errors.department_emergency_contact_mobile
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.department_emergency_contact_mobile &&
                                validation.errors.department_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.department_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                        </Row>
                        
                        <Row>
                            <Col>
                                <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={ModalLoading}
                                >
                                    Save
                                </button>
                                </div>
                            </Col>
                        </Row>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default HRProfile;