import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = ({ setLoading }) => {

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 100)
    }, [setLoading]);

    const styles = {
        loadingScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', /* Semi-transparent background */
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '9999', /* Ensure it's on top of other content */
        },
        
      };
      
    return (
        <React.Fragment>
            <Spinner color="primary" className="position-fixed top-50 start-50 z-1" />
            {/* position-absolute top-50 start-50 */}
        </React.Fragment>
    )
}

export default Spinners;