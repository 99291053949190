import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Desc, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getNewsFeeds as onGetNewsFeeds,
    addNewsFeed as onAddNewsFeed,
    updateNewsFeed as onUpdateNewsFeed
} from "store/actions";

function NewsFeeds(){
  //meta title
  document.title="News Feeds Active| Tacveiha Panel";

  // console.log(getLoggedInUser );
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.NewsFeedReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        newsFeeds: rosterReducer.newsFeeds,
        loading: rosterReducer.loading
      })
  );
  const {
    newsFeeds, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      news_id: (roster && roster.news_id) || "",
      news_status: (roster && roster.news_status) || "",
      news_title: (roster && roster.news_title) || "",
      news_desc: (roster && roster.news_desc) || "",
      news_end: (roster && roster.news_end) || "",

    },
    validationSchema: Yup.object({
        news_status: Yup.string().required("Please select news status"),
        news_title: Yup.string().required("Please enter news title"),
        news_desc: Yup.string().required("Please enter news descriptions"),
        news_end: Yup.string().required("Please entere news end date "),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
            news_id: values.news_id,
            news_status: values.news_status,
            news_title: values.news_title,
            news_desc: values.news_desc,
            news_end: values.news_end
        };

        // update user
        dispatch(onUpdateNewsFeed({'data_for':'update_news','data': updateUser,'user': loggedUser}));
        validation.resetForm();
        setIsEdit(false);
        location.reload();
      } else {
        const newData = {
            news_status: values.news_status,
            news_title: values.news_title,
            news_desc: values.news_desc,
            news_end: values.news_end
        };
        // // save new user
        dispatch(onAddNewsFeed({'data_for':'add_news','data': newData,'user': loggedUser}));
        validation.resetForm();
      }
      toggle();
    },
  });

  useEffect(() => {
    if (newsFeeds && !newsFeeds.length) {
    dispatch(onGetNewsFeeds({'data_for':'news_feeds_active','user': loggedUser}));
    }
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const addNewsFeeds = arg => {
    setIsEdit(false);
    toggle();
  }

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
          news_id: user.id,
          news_status: user.news_status,
          news_title: user.news_title,
          news_desc: user.news_desc,
          news_end: user.news_end,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  // const clsoeModal = () => {
  //   toggle();
  // }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };
  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "News Title",
        accessor: "news_title",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "News Description",
        accessor: "news_desc",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Desc {...cellProps} />;
        },
      },
      {
        Header: "News End Date",
        accessor: "news_end",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "news_status",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-success"
                    onClick={() => {
                        const userData = cellProps.row.original;
                        handleUserClick(userData);
                    }}
                    id={`edittooltip-${cellProps.row.original.id}`}
                >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                        Edit
                    </UncontrolledTooltip>
                </Link>
            </li>

            <li>
                <Link
                     to={"/news-feeds-viewed/" +cellProps.row.original.id}
                    className="btn btn-sm btn-soft-info"
                    // onClick={() => {
                    //     const userData = cellProps.row.original;
                    //     viewNewsFeedRead(userData);
                    // }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                >
                    <i className="mdi mdi-eye-outline" />
                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                        View News Feed Read
                    </UncontrolledTooltip>
                </Link>
            </li>
        </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Active" breadcrumbItem="News Feeds" />

          <Row className="mb-6">
            <Col sm="12">
                <div className="text-sm-end">
                    <Button
                    type="button"
                    color="primary"
                    className="mb-2 me-2"
                    onClick={addNewsFeeds}
                    >
                    
                    Add News Feeds
                    </Button>
                </div>
            </Col>
        </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={newsFeeds}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    fileName=''
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                         
                        <Row form>


                            <Row className="mb-6">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                                Status
                              </label>
                              <div className="col-md-6">
                              <Input
                                  name="news_status"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="1"
                                  invalid={
                                    validation.touched.news_status &&
                                      validation.errors.news_status
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.news_status == 1 ? true : false}
                                />  Yes
                                &nbsp;&nbsp;&nbsp;<Input
                                  name="news_status"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='0'
                                  invalid={
                                    validation.touched.news_status &&
                                      validation.errors.news_status
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.news_status == 0 ? true : false }
                                />  No
                                {validation.touched.news_status &&
                                  validation.errors.news_status ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.news_status}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Row>
                          {/* </Col> */}
                        </Row>
                        

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              News Title
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="news_title"
                                type="text"
                                placeholder="News Title"
                                value={validation.values.news_title}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.news_title &&
                                      validation.errors.news_title
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.news_title &&
                                  validation.errors.news_title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.news_title}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             News Description
                            </label>
                            <div className="col-md-10">
                            <CKEditor
                              editor={ClassicEditor}
                              data={validation.values.news_desc}
                              name="news_desc"
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                validation.values.news_desc = data;
                              }}
                              onBlur={(event, editor) => {
                                const data = editor.getData();
                                validation.values.news_desc = data;
                              }}
                            />
                            {validation.touched.news_desc &&
                                  validation.errors.news_desc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.news_desc}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             News Expiry Date
                            </label>
                            <div className="col-md-10">
                            <Input
                                type="date"
                                name="news_end"
                                value={validation.values.news_end}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.news_end &&
                                      validation.errors.news_end
                                      ? true
                                      : false
                                  }
                            />
                            {validation.touched.news_end &&
                                  validation.errors.news_end ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.news_end}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Row>
                          <Col>
                            <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(NewsFeeds);