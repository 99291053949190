import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import {Button} from "reactstrap";
import { saveAs } from "file-saver";

const ExcelExport = (props) => {
    const data = props.data;
    const fileName = props.fileName;
    const handleDownload = () => {
        if( data ) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb,ws,fileName);
            XLSX.writeFile(wb,fileName+".xlsx");
        }
    }
    
    return (
        <>
            <Button
                type="button"
                color="primary"
                onClick={handleDownload}>
                    Excel Export
            </Button>
        </>
    )


}

export default ExcelExport;