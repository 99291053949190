import {
  GET_ROSTER_REPORT,
  GET_ROSTER_REPORT_SUCCESS,
  GET_ROSTER_REPORT_FAIL,
  GET_ROSTER_REPORT_WITH_NOTES,
  GET_ROSTER_REPORT_WITH_NOTES_SUCCESS,
  GET_ROSTER_REPORT_WITH_NOTES_FAIL,
  GET_CHECKLIST_REPORT,
  GET_CHECKLIST_REPORT_SUCCESS,
  GET_CHECKLIST_REPORT_FAIL,
  GET_TWO_HR_ROSTER_REPORT,
  GET_TWO_HR_ROSTER_REPORT_SUCCESS,
  GET_TWO_HR_ROSTER_REPORT_FAIL,
  GET_GROUP_ROSTER_REPORT,
  GET_GROUP_ROSTER_REPORT_SUCCESS,
  GET_GROUP_ROSTER_REPORT_FAIL,
  GET_BUDDY_REPORT,
  GET_BUDDY_REPORT_SUCCESS,
  GET_BUDDY_REPORT_FAIL,
  GET_SUPPORT_WORKER_DOC_HR_REPORT,
  GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS,
  GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL,
  GET_STAFFS,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAIL,
  GET_CLIENT_DEPARTMENTS,
  GET_CLIENT_DEPARTMENTS_SUCCESS,
  GET_CLIENT_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_CLIENTS,
  GET_DEPARTMENTS_CLIENTS_SUCCESS,
  GET_DEPARTMENTS_CLIENTS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  rosterReport: [],
  reportWithNotes: [],
  checklistReport: [],
  twoHrRosterReport: [],
  groupRosterReport: [],
  buddyReport: [],
  clientsDepartments: [],
  clients: [],
  staffs: [],
  supportWorkerHrDocReport: [],
  error: {},
  loading: false,
}

const ReportsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROSTER_REPORT:
      console.log('loading');
      return {
        ...state,
        rosterReport: [],
        loading: true
     }
    case GET_ROSTER_REPORT_SUCCESS:
      console.log('success');
      return {
        ...state,
        rosterReport: action.payload.data,
        loading: false
     }

    case GET_ROSTER_REPORT_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    case GET_ROSTER_REPORT_WITH_NOTES:
    console.log('loading');
    return {
      ...state,
      reportWithNotes: [],
      loading: true
    }

    case GET_ROSTER_REPORT_WITH_NOTES_SUCCESS:
      return {
        ...state,
        reportWithNotes: action.payload.data,
        loading: false
      }

    case GET_ROSTER_REPORT_WITH_NOTES_FAIL:
      return {
        ...state,
        reportWithNotes: [],
        error: action.payload.error,
      }

    case GET_CHECKLIST_REPORT:
      console.log('loading');
      return {
        ...state,
        checklistReport: [], //[...state.staffLeavesDeleted],
        loading: true
     }

    case GET_CHECKLIST_REPORT_SUCCESS:
      console.log('success');
      return {
        ...state,
        checklistReport: action.payload.data,
        loading: false
      }

    case GET_CHECKLIST_REPORT_FAIL:
    console.log('failed');
      return {
        ...state,
        error: action.payload,
      }
    
    case GET_TWO_HR_ROSTER_REPORT:
    console.log('loading');
    return {
      ...state,
      twoHrRosterReport: [], //[...state.staffLeavesDeleted],
      loading: true
    }
  
    case GET_TWO_HR_ROSTER_REPORT_SUCCESS:
      console.log('success');
      return {
        ...state,
        twoHrRosterReport: action.payload.data,
        loading: false
      }
  
      case GET_TWO_HR_ROSTER_REPORT_FAIL:
      console.log('failed');
        return {
          ...state,
          error: action.payload,
        }

      case GET_GROUP_ROSTER_REPORT:
        console.log('loading');
        return {
          ...state,
          groupRosterReport: [], //[...state.staffLeavesDeleted],
          loading: true
        }
    
      case GET_GROUP_ROSTER_REPORT_SUCCESS:
        console.log('success');
        return {
          ...state,
          groupRosterReport: action.payload.data,
          loading: false
        }
    
      case GET_GROUP_ROSTER_REPORT_FAIL:

        return {
          ...state,
          error: action.payload,
        }

      case GET_BUDDY_REPORT:
        console.log('loading');
        return {
          ...state,
          buddyReport: [], //[...state.staffLeavesDeleted],
          loading: true
        }
      
      case GET_BUDDY_REPORT_SUCCESS:
        console.log('success');
        return {
          ...state,
          buddyReport: action.payload.data,
          loading: false
        }
  
      case GET_BUDDY_REPORT_FAIL:

        return {
          ...state,
          error: action.payload,
        }

      case GET_SUPPORT_WORKER_DOC_HR_REPORT:
        console.log('loading');
        return {
          ...state,
          supportWorkerHrDocReport: [], //[...state.staffLeavesDeleted],
          loading: true
        }
      
      case GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS:
        console.log('success');
        return {
          ...state,
          supportWorkerHrDocReport: action.payload.data,
          loading: false
        }
  
      case GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL:

        return {
          ...state,
          error: action.payload,
        }
      case GET_STAFFS:
        console.log('loading');
        return {
          ...state,
          staffs: [], //[...state.staffs], //[...state.staffLeavesDeleted],
          loading: true
        }
      
      case GET_STAFFS_SUCCESS:
        console.log('success');
        return {
          ...state,
          staffs: action.payload.data,
          loading: false
        }
  
      case GET_STAFFS_FAIL:

        return {
          ...state,
          error: action.payload,
        }

    case GET_CLIENT_DEPARTMENTS:
        console.log('loading');
        return {
          ...state,
          clientsDepartments: [], //[...state.staffs], //[...state.staffLeavesDeleted],
          loading: true
        }
      
      case GET_CLIENT_DEPARTMENTS_SUCCESS:
        console.log('success');
        return {
          ...state,
          clientsDepartments: action.payload.data,
          loading: false
        }
  
      case GET_CLIENT_DEPARTMENTS_FAIL:

        return {
          ...state,
          error: action.payload,
        }

      case GET_DEPARTMENTS_CLIENTS:
        console.log('loading');
        return {
          ...state,
          clients: [], //[...state.staffs], //[...state.staffLeavesDeleted],
          loading: true
        }
      
      case GET_DEPARTMENTS_CLIENTS_SUCCESS:
        console.log('success');
        return {
          ...state,
          clients: action.payload.data,
          loading: false
        }
  
      case GET_DEPARTMENTS_CLIENTS_FAIL:

        return {
          ...state,
          error: action.payload,
        }

      // case UPDATE_CASEMANAGER:
      //   return {
      //     ...state,
      //     loading: true
      // };

      // case UPDATE_CASEMANAGER_SUCCESS:
      //   return {
      //       ...state,
      //       casemanager: state.providers.map(rosters =>
      //         rosters.id.toString() === action.payload.data.id.toString()
      //               ? { rosters, ...action.payload.data }
      //               : rosters
      //       ),
      //       loading: false
      // };

      // case UPDATE_CASEMANAGER_FAIL:
      //   return {
      //       ...state,
      //       error: action.payload.error,
      //       loading: false
      //   };

      default:
        return state
  }
}

export default ReportsReducer
