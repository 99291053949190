/* Rosters */
export const GET_CLIENT_TYPES = "GET_CLIENT_TYPES"
export const GET_CLIENT_TYPES_SUCCESS = "GET_CLIENT_TYPES_SUCCESS"
export const GET_CLIENT_TYPES_FAIL = "GET_CLIENT_TYPES_FAIL"

export const GET_CLIENT_PACKAGE_TYPES = "GET_CLIENT_PACKAGE_TYPES"
export const GET_CLIENT_PACKAGE_TYPES_SUCCESS = "GET_CLIENT_PACKAGE_TYPES_SUCCESS"
export const GET_CLIENT_PACKAGE_TYPES_FAIL = "GET_CLIENT_PACKAGE_TYPES_FAIL"

export const ADD_CLIENT_PACKAGE_TYPES = "ADD_CLIENT_PACKAGE_TYPES"
export const ADD_CLIENT_PACKAGE_TYPES_SUCCESS = "ADD_CLIENT_PACKAGE_TYPES_SUCCESS"
export const ADD_CLIENT_PACKAGE_TYPES_FAIL = "ADD_CLIENT_PACKAGE_TYPES_FAIL"

export const ADD_CLIENT_TYPES = "ADD_CLIENT_TYPES"
export const ADD_CLIENT_TYPES_SUCCESS = "ADD_CLIENT_TYPES_SUCCESS"
export const ADD_CLIENT_TYPES_FAIL = "ADD_CLIENT_TYPES_FAIL"

export const UPDATE_CLIENT_TYPES = "UPDATE_CLIENT_TYPES"
export const UPDATE_CLIENT_TYPES_SUCCESS = "UPDATE_CLIENT_TYPES_SUCCESS"
export const UPDATE_CLIENT_TYPES_FAIL = "UPDATE_CLIENT_TYPES_FAIL"


export const UPDATE_CLIENT_PACKAGE_TYPES = "UPDATE_CLIENT_PACKAGE_TYPES"
export const UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS = "UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS"
export const UPDATE_CLIENT_PACKAGE_TYPES_FAIL = "UPDATE_CLIENT_PACKAGE_TYPES_FAIL"

