/* Rosters */
export const GET_VACCINATION_DETAILS = "GET_VACCINATION_DETAILS"
export const GET_VACCINATION_DETAILS_SUCCESS = "GET_VACCINATION_DETAILS_SUCCESS"
export const GET_VACCINATION_DETAILS_FAIL = "GET_VACCINATION_DETAILS_FAIL"

export const UPDATE_VACCINATION_DETAILS = "UPDATE_VACCINATION_DETAILS"
export const UPDATE_VACCINATION_DETAILS_SUCCESS = "UPDATE_VACCINATION_DETAILS_SUCCESS"
export const UPDATE_VACCINATION_DETAILS_FAIL = "UPDATE_VACCINATION_DETAILS_FAIL"

export const ADD_VACCINATION_DETAILS = "ADD_VACCINATION_DETAILS"
export const ADD_VACCINATION_DETAILS_SUCCESS = "ADD_VACCINATION_DETAILS_SUCCESS"
export const ADD_VACCINATION_DETAILS_FAIL = "ADD_VACCINATION_DETAILS_FAIL"

