import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_SETTINGS,
    UPDATE_SETTINGS,
} from "./actionTypes"
import {
  getSettingsSuccess,
  getSettingsFail,
  updateSettingsSuccess,
  updateSettingsFail
} from "./actions"

//Include Both Helper File with needed methods
import { actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchSettings({params,types}) {
  try {
    const response = yield call(actionUrl, params, types )
    console.log(response);
    yield put(getSettingsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getSettingsFail(error))
  }
}


function* updateSettings({ payload: data }) {
  try {
      const response = yield call(actionUrl, data)
      yield put(updateSettingsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateSettingsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}


function* SettingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
  yield takeEvery(UPDATE_SETTINGS, updateSettings)
}

export default SettingsSaga
