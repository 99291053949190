import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAIL,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL,
  } from "./actionTypes"
  
  export const getSettings = (params,types) => ({
    type: GET_SETTINGS,
    params,
    types,
  })
  
  export const getSettingsSuccess = data => ({
    type: GET_SETTINGS_SUCCESS,
    payload: data,
  })
  
  export const getSettingsFail = error => ({
    type: GET_SETTINGS_FAIL,
    payload: error,
  })

  export const updateSettings = (params,types) => ({
    type: UPDATE_SETTINGS,
    payload: params,
    types
  })

  export const updateSettingsSuccess = data => ({
    type: UPDATE_SETTINGS_SUCCESS,
    payload: data,
  })

  export const updateSettingsFail = error => ({
    type: UPDATE_SETTINGS_FAIL,
    payload: error,
  })