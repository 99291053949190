import {
  GET_EMERGENCY_ACTIVE_ALERTS,
  GET_EMERGENCY_ACTIVE_ALERTS_FAIL,
  GET_EMERGENCY_ACTIVE_ALERTS_SUCCESS,
  GET_EMERGENCY_ARCHIVED_ALERTS,
  GET_EMERGENCY_ARCHIVED_ALERTS_SUCCESS,
  GET_EMERGENCY_ARCHIVED_ALERTS_FAIL,
} from "./actionTypes"

export const getEmergencyActiveAlerts = (params,types) => ({
  type: GET_EMERGENCY_ACTIVE_ALERTS,
  params,
  types,
})

export const getEmergencyActiveAlertsSuccess = data => ({
  type: GET_EMERGENCY_ACTIVE_ALERTS_SUCCESS,
  payload: data,
})

export const getEmergencyActiveAlertsFail = error => ({
  type: GET_EMERGENCY_ACTIVE_ALERTS_FAIL,
  payload: error,
})


export const getEmergencyArchivedAlerts = (params,types) => ({
  type: GET_EMERGENCY_ARCHIVED_ALERTS,
  params,
  types,
})

export const getEmergencyArchivedAlertsSuccess = data => ({
  type: GET_EMERGENCY_ARCHIVED_ALERTS_SUCCESS,
  payload: data,
})

export const getEmergencyArchivedAlertsFail = error => ({
  type: GET_EMERGENCY_ARCHIVED_ALERTS_FAIL,
  payload: error,
})