import {
    GET_INCIDENT_REPORT_ACTIVE,
    GET_INCIDENT_REPORT_ACTIVE_SUCCESS,
    GET_INCIDENT_REPORT_ACTIVE_FAIL,
    GET_INCIDENT_REPORT_COMPLETED,
    GET_INCIDENT_REPORT_COMPLETED_SUCCESS,
    GET_INCIDENT_REPORT_COMPLETED_FAIL,
    UPDATE_INCIDENT_REPORT,
    UPDATE_INCIDENT_REPORT_SUCCESS,
    UPDATE_INCIDENT_REPORT_FAIL,
    ADD_INCIDENT_REPORT,
    ADD_INCIDENT_REPORT_SUCCESS,
    ADD_INCIDENT_REPORT_FAIL,
    GET_INCIDENT_REPORT_STAFFS,
    GET_INCIDENT_REPORT_STAFFS_SUCCESS,
    GET_INCIDENT_REPORT_STAFFS_FAIL,
    GET_STAFFS_ROSTERS,
    GET_STAFFS_ROSTERS_SUCCESS,
    GET_STAFFS_ROSTERS_FAIL,

  } from "./actionTypes"
  
  export const getIncidentReportActive = (params,types) => ({
    type: GET_INCIDENT_REPORT_ACTIVE,
    params,
    types,
  })
  
  export const getIncidentReportActiveSuccess = data => ({
    type: GET_INCIDENT_REPORT_ACTIVE_SUCCESS,
    payload: data,
  })
  
  export const getIncidentReportActiveFail = error => ({
    type: GET_INCIDENT_REPORT_ACTIVE_FAIL,
    payload: error,
  })

  export const getIncidentReportCompleted = (params,types) => ({
    type: GET_INCIDENT_REPORT_COMPLETED,
    params,
    types,
  })
  
  export const getIncidentReportCompletedSuccess = data => ({
    type: GET_INCIDENT_REPORT_COMPLETED_SUCCESS,
    payload: data,
  })
  
  export const getIncidentReportCompletedFail = error => ({
    type: GET_INCIDENT_REPORT_COMPLETED_FAIL,
    payload: error,
  })

  export const getIncidentReportStaffs = (params,types) => ({
    type: GET_INCIDENT_REPORT_STAFFS,
    params,
    types,
  })
  
  export const getIncidentReportStaffsSuccess = data => ({
    type: GET_INCIDENT_REPORT_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const getIncidentReportStaffsFail = error => ({
    type: GET_INCIDENT_REPORT_STAFFS_FAIL,
    payload: error,
  })

  export const addIncidentReport = (params,types) => ({
    type: ADD_INCIDENT_REPORT,
    payload: params,
    types
  })

  export const addIncidentReportSuccess = data => ({
    type: ADD_INCIDENT_REPORT_SUCCESS,
    payload: data,
  })

  export const addIncidentReportFail = error => ({
    type: ADD_INCIDENT_REPORT_FAIL,
    payload: error,
  })

  export const updateIncidentReport = (params,types) => ({
    type: UPDATE_INCIDENT_REPORT,
    payload: params,
    types
  })

  export const updateIncidentReportSuccess = data => ({
    type: UPDATE_INCIDENT_REPORT_SUCCESS,
    payload: data,
  })

  export const updateIncidentReportFail = error => ({
    type: UPDATE_INCIDENT_REPORT_FAIL,
    payload: error,
  })

  export const getStaffsRosters = (params,types) => ({
    type: GET_STAFFS_ROSTERS,
    params,
    types
  })

  export const getStaffsRostersSuccess = data => ({
    type: GET_STAFFS_ROSTERS_SUCCESS,
    payload: data,
  })

  export const getStaffsRostersFail = error => ({
    type: GET_STAFFS_ROSTERS_FAIL,
    payload: error,
  })


