import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import withRouter from "components/Common/withRouter"
import { toast } from "react-toastify";
import { isEmpty, map, values } from "lodash"
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL } from "helpers/url_helper"
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";


//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


const libraries =['places'];


function AddClient(){
  //meta title
    document.title="Add Client | Tacveiha Panel";
    const inputRef = useRef();
  
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    });

    const dispatch = useDispatch();
  
//   const selectGroupRostersState = (state) => state.NewsFeedReducer;
//   const groupRostersProperties = createSelector(
//     selectGroupRostersState,
//       (rosterReducer) => ({
//         newsFeedsViewed: rosterReducer.newsFeedsViewed,
//         loading: rosterReducer.loading
//       })
//   );
//   const {
//     newsFeedsViewed, loading
//   } = useSelector(groupRostersProperties);

//   const [isLoading, setLoading] = useState(loading);

    // const params = props.router.params;

    const [deleteModal, setDeleteModal] = useState(false);

    const [roster, setRoster] = useState([]);
    const [TopLoading, setTopLoading] = useState(false);
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    
    const [ServiceTypes, setServiceTypes] = useState([]);
    const [VaccinationFlag, setVaccinationFlag] = useState(false);
    const [AnnualFluFlag, setAnnualFluFlag] = useState(false);
    const [ClientDob, setClientDob] = useState('');
    const [CareInstructions, setCareInstructions] = useState('');
    const [ClientAddress, setClientAddress] = useState('');
    
    const [ClientLatLng, setClientLatLng] = useState('');
    const [ClientTimezone, setClientTimezone] = useState([]);
    const [ClientType, setClientType] = useState([]);
    const [ClientDepartment, setClientDepartment] = useState([]);
    const [ClientCasemanager, setClientCasemanager] = useState([]);
    const [StaffsExecluded, setStaffsExecluded] = useState([]);

    const [staffs_dropdown, setStaffs_dropdown] = useState([]);
    const [client_timezone_dropdown, setClient_timezone_dropdown] = useState([]);
    const [department_dropdown, setDepartment_dropdown] = useState([]);
    const [casemanager_dropdown, setCasemanager_dropdown] = useState([]);
    
    
    const [FirstDoseDate, setFirstDoseDate] = useState('');
    const [SecondDoseDate, setSecondDoseDate] = useState('');
    const [AnnualFluDate, setAnnualFluDate] = useState('');

    // const [package_types_dropdown, setPackage_types_dropdown] = useState('');

    const [ServiceTypesArray, setServiceTypesArray] = useState([]);
    const [ClientsServiceTypes, setClientsServiceTypes ] = useState([]);
    const [PackageTypeDropdown, setPackageTypeDropdown] = useState([]);
    const [PackageType, setPackageType] = useState([]);
    const [ClientSupportType, setClientSupportType ] = useState([]);
    const [ClientPackageStartDate, setClientPackageStartDate] = useState('');
    const [ClientPackageEndDate, setClientPackageEndDate] = useState('');
    const [PackageLevelDropdown, setPackageLevelDropdown] = useState([]);
    const [PackageLevel, setPackageLevel] = useState([]);

    const [DailySubsidy, setDailySubsidy] = useState('0.00');
    const [WeeklySubsidy, setWeeklySubsidy] = useState('0.00');
    const [MonthlySubsidy, setMonthlySubsidy] = useState('0.00');
    const [YearlySubsidy, setYearlySubsidy] = useState('0.00');
    const [submitDisabled, setSubmitDisabled] = useState(false);


    const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        // service_type: ( roster && roster.service_type ) || "",
        vaccination_flag: ( roster && roster.vaccination_flag ) || 0,
        annual_flu_flag : ( roster && roster.annual_flu_flag ) || 0,
        user_title : ( roster && roster.user_title ) || "",
        client_fname: ( roster && roster.client_fname) || "",
        client_lname: ( roster && roster.client_lname) || "",
        client_email: ( roster && roster.client_email) || "",
        client_dob: ( roster && roster.client_dob) || "",
        client_gender: ( roster && roster.client_gender) || "",
        client_mobile_no: ( roster && roster.client_mobile_no) || "",
        client_home_no : ( roster && roster.client_home_no) || "",
        client_referral_source : ( roster && roster.client_referral_source) || "",
        client_address : ( roster && roster.client_address) || "",
        care_instruction : ( roster && roster.care_instruction) || "",
        client_latlng : ( roster && roster.client_latlng) || "",
        client_timezone: ( roster && roster.client_timezone) || "",
        client_type: ( roster && roster.client_type) || "",
        department_id: ( roster && roster.department_id) || "",
        case_manager_id: ( roster && roster.case_manager_id) || "",
        client_emergency_contact_name: ( roster && roster.client_emergency_contact_name) || "",
        client_emergency_contact_relation: ( roster && roster.client_emergency_contact_relation) || "",
        client_emergency_contact_mobile: ( roster && roster.client_emergency_contact_mobile) || "",
        client_emergency_contact_email: ( roster && roster.client_emergency_contact_email) || "",
        client_postcode: ( roster && roster.client_postcode) || "",
        first_dose: ( roster && roster.first_dose) || 0,
        first_dose_date: ( roster && roster.first_dose_date) || "",
        second_dose: ( roster && roster.second_dose) || 0,
        second_dose_date: ( roster && roster.second_dose_date) || "",
        annual_flu_dose: ( roster && roster.annual_flu_dose) || 0,
        annual_flu_dose_date: ( roster && roster.annual_flu_dose_date) || "",
        ctid: ( roster && roster.ctid) || "",
        cplid: ( roster && roster.cplid) || "",
        support_type: ( roster && roster.support_type) || "",
        amount: ( roster && roster.amount) || "0.00",

        hourly_rate: ( roster && roster.hourly_rate) || "0.00",
        daily_subsidy: ( roster && roster.daily_subsidy) || "0.00",
        weekly_subsidy: ( roster && roster.weekly_subsidy) || "0.00",
        monthly_subsidy: ( roster && roster.monthly_subsidy) || "0.00",
        yearly_subsidy: ( roster && roster.yearly_subsidy) || "0.00",

        start_date: ( roster && roster.start_date) || "",
        end_date: ( roster && roster.end_date) || "",
    },
    validationSchema: Yup.object({
        vaccination_flag: Yup.string().required("Required"),
        annual_flu_flag: Yup.string().required("Required"),
        user_title: Yup.string().required("Required"),
        client_fname: Yup.string().required("Required"),
        client_lname: Yup.string().required("Required"),
        client_email: Yup.string().required("Required"),
        client_gender: Yup.string().required("Required"),
        client_mobile_no: Yup.string().required("Required"),
        client_home_no: Yup.string().required("Required"),
        client_referral_source: Yup.string().required("Required"),
        client_address: Yup.string().required("Required"),
        client_postcode: Yup.string().required("Required"),
        client_emergency_contact_name : Yup.string().required("Required"),
        client_emergency_contact_relation: Yup.string().required("Required"),
        client_emergency_contact_mobile : Yup.string().required("Required"),
        client_emergency_contact_email : Yup.string().required("Required"),
    }),
    onSubmit: values => {

    //   console.log( values );
    

        if( ClientDob == "" ) {
          toast.error("Please provide client date of birth", { autoClose: 5000 });
          return false;
        }

        if( CareInstructions == "" ) {
          toast.error("Please provide care instructions", { autoClose: 5000 });
          return false;
        }

        if( ClientTimezone == '' ) {
            toast.error("Please select client timezone", { autoClose: 5000 });
          return false;
        }

        if( ClientType == '' ) {
            toast.error("Please select for option", { autoClose: 5000 });
          return false;
        }

        if( ClientDepartment == '' ) {
            toast.error("Please select client department", { autoClose: 5000 });
          return false;
        }

        if( ClientCasemanager == '' ) {
            toast.error("Please select client casemanager", { autoClose: 5000 });
          return false;
        }

        if( validation.values.first_dose == 1 &&  FirstDoseDate == '' ) {
            toast.error("Please provide first dose date", { autoClose: 5000 });
            return false;
        }

        if( validation.values.second_dose == 1 &&  SecondDoseDate == '' ) {
            toast.error("Please provide second dose date", { autoClose: 5000 });
            return false;
        }

        if( validation.values.annual_flu_dose == 1 &&  AnnualFluDate == '' ) {
            toast.error("Please provide annual flu dose date", { autoClose: 5000 });
            return false;
        }

        if( PackageType == '' ) {
            toast.error("Please select client package type", { autoClose: 5000 });
          return false;
        }


        if( PackageType.value == 1 ) {

            if( ClientSupportType == '' ) {
                toast.error("Please add support types", { autoClose: 5000 });
                return false;
            }

            if( validation.values.amount == '' ) {
                toast.error("Please select package amount", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageEndDate == '' ) {
                toast.error("Please select end date", { autoClose: 5000 });
                return false;
            }

        }

        if( PackageType.value == 2 ) {

            if( PackageLevel == '' ) {
                toast.error("Please select package level", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( WeeklySubsidy == '' && MonthlySubsidy == '' && YearlySubsidy == '') {
                toast.error("Please provide subsidy amount", { autoClose: 5000 });
                return false;
            }

        }

        if( PackageType.value == 3 ) {

            if( ClientSupportType == '' ) {
                toast.error("Please add support types", { autoClose: 5000 });
                return false;
            }

            if( validation.values.hourly_rate == '' ) {
                toast.error("Please provide hourly rate", { autoClose: 5000 });
                return false;
            }

            if( validation.values.amount == '' ) {
                toast.error("Please add total amount", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageStartDate == '' ) {
                toast.error("Please select start date", { autoClose: 5000 });
                return false;
            }

            if( ClientPackageEndDate == '' ) {
                toast.error("Please select end date", { autoClose: 5000 });
                return false;
            }

        }
        

        

        const formData = {
            service_type: ClientsServiceTypes,
            vaccination_flag: values.vaccination_flag,
            annual_flu_flag : values.annual_flu_flag,
            user_title : values.user_title,
            client_fname: values.client_fname,
            client_lname: values.client_lname,
            client_email: values.client_email,
            client_dob: ClientDob,
            client_gender: values.client_gender,
            client_mobile_no: values.client_mobile_no,
            client_home_no: values.client_home_no,

            client_referral_source: values.client_referral_source,
            client_address: values.client_address,
            care_instruction: CareInstructions,
            client_latlng: ClientLatLng,
            
            client_timezone: ClientTimezone,
            client_type: ClientType,//UserType,
            department_id: ClientDepartment,
            case_manager_id: ClientCasemanager,
            client_emergency_contact_name: values.client_emergency_contact_name,
            client_emergency_contact_relation: values.client_emergency_contact_relation,
            client_emergency_contact_mobile: values.client_emergency_contact_mobile,
            client_emergency_contact_email: values.client_emergency_contact_email,
            client_postcode: values.client_postcode,
            staffs_excluded : StaffsExecluded,
            first_dose: values.first_dose,
            first_dose_date: values.first_dose_date,
            second_dose: values.second_dose,
            second_dose_date: values.second_dose_date,
            annual_flu_dose: values.annual_flu_dose,
            annual_flu_dose_date: values.annual_flu_dose_date,

            ctid: PackageType,
            cplid: PackageLevel,
            support_type: ClientSupportType,
            amount: values.amount,
            hourly_rate: values.hourly_rate,
            daily_subsidy: DailySubsidy,
            weekly_subsidy: WeeklySubsidy,
            monthly_subsidy: MonthlySubsidy,
            yearly_subsidy: YearlySubsidy,

            start_date : ClientPackageStartDate,
            end_date : ClientPackageEndDate,

          
        };

        console.log( formData);
        // add data
      setTopLoading(true);
      axios.post(APP_URL+ACTION_URL,{'data_for':'add_client','formData': formData,'user': loggedUser})
      .then(res => {
        setTopLoading(false);
        
        const fetchedData = res.data;
        if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
          setClientsServiceTypes([]);
          setClientDob('');
          setCareInstructions('');
          setClientType('');
          setClientTimezone([]);
          setClientDepartment([]);
          setClientCasemanager([]);
          setStaffsExecluded([]);
          setPackageType([]);
          setPackageLevel([]);
          

          validation.resetForm();
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.error, { autoClose: 5000 });

        }

      });

    },
  });


  useEffect(() => {
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_clients_data_load'})
    .then(res => {
        setTopLoading(false);
        const fetchedData = res.data;
        setServiceTypesArray(fetchedData.ServiceTypesDropdown);
        setStaffs_dropdown(fetchedData.StaffsDropdown);
        setPackageTypeDropdown(fetchedData.ClientTypes);
        setClient_timezone_dropdown(fetchedData.TimeZones);
        setPackageLevelDropdown(fetchedData.PackageLevels);
    })
  }, []);

const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.client_address = place.formatted_address;
        setClientAddress(place.formatted_address);
        setClientLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());

        var postcode = place.address_components;
        var searchPostalCode = '';
        postcode.map(code => {
                if(code.types[0]=="postal_code"){
                    searchPostalCode=code.short_name;
                }
        });
        validation.values.client_postcode = searchPostalCode;
    }
}


  const handleClientType = (type) => {

    setClientDepartment([]);
    setClientCasemanager([]);

    const formData = {
        type: type
    }
    setClientType(type);
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const handleDepartmentChange = (e) => {
    setClientDepartment(e);
    setClientCasemanager([]);
    const formData = {
        dep_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManager','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setCasemanager_dropdown(res.data.casemanagers);
    })
  }

  const handleCaseMangerChange = (e) => {
    
    if( e.value  == 0 ) {
        setClientCasemanager([]);
        return false;
    }

    setClientCasemanager(e);

    const formData = {
        cm_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManagerDetials','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res.data;
        validation.values.client_emergency_contact_email = fetchedData.client_emergency_contact_email;
        validation.values.client_emergency_contact_mobile = fetchedData.client_emergency_contact_mobile;
        validation.values.client_emergency_contact_name = fetchedData.client_emergency_contact_name;
        validation.values.client_emergency_contact_relation = fetchedData.client_emergency_contact_relation;
    })
  }

  const handlePackageTypes = (e) => {

    validation.values.ctid = e;
    setPackageType(e);

    const formData = {
        client_type: e.value
    }
    // if( e.value == 1 ) {

    //     setClientSupportType([]);
    //     setClientPackageStartDate('');
    //     setClientPackageEndDate('');
    //     setDailySubsidy(fetchedData.daily_subsidy);
    //     setWeeklySubsidy(fetchedData.weekly_subsidy);
    //     setMonthlySubsidy(fetchedData.monthly_subsidy);
    //     setYearlySubsidy(fetchedData.yearly_subsidy);
    // }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_types','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        // setDepartment_dropdown(res.data);
    })

  }

  const handlePackageLevel = (e) => {
    setPackageLevel(e.value);

    // validation.values.cplid = e;

    const formData = {
        client_type: PackageType.value,
        client_package_level: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_package_leves_rate','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res.data;
      setDailySubsidy(fetchedData.daily_subsidy);
      setWeeklySubsidy(fetchedData.weekly_subsidy);
      setMonthlySubsidy(fetchedData.monthly_subsidy);
      setYearlySubsidy(fetchedData.yearly_subsidy);

    })

  }


  const handleCheckboxChange = (event) => {
    const checkedId = parseInt(event.target.value);
    // const isChecked = event.target.checked;
    if( event.target.checked ) {
        setClientsServiceTypes([...ClientsServiceTypes, checkedId]);
    } else{
        setClientsServiceTypes( (ClientsServiceTypes) => {
            return ClientsServiceTypes.filter((id) =>{
                return id !== checkedId
            })
        });
    }
  }

  useEffect(() => {
    console.log(ClientsServiceTypes);
  },[ClientsServiceTypes])


//   const handleCheckboxChange = (id) => {
//     console.log(ServiceTypesArray);
//     setItems(ServiceTypesArray.map(item => 
//       item.value === id ? { ...item, checked: !item.checked } : item
//     ));
//   };

  return (
    <React.Fragment>
      {
        TopLoading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Clients Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                    <Form
                        onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                        }}
                    >

                        <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Service Types
                        </Label>
                        <Col sm={9}>
                            {ServiceTypesArray.map(item => (
                                <div key={item.id} className="d-inline-flex">
                                <label>
                                    <Input
                                    type="checkbox"
                                    value={item.id}
                                    // checked={ClientsServiceTypes.includes(item.value)}
                                    onChange={(event) => handleCheckboxChange(event)}
                                    // checked={item.checked}
                                    // onChange={() => handleCheckboxChange(item.value)}
                                    />
                                    &nbsp;&nbsp;
                                    {item.service_name}
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                
                            ))}

                        </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Require Covid-19 Vaccinated Staff
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="vaccination_flag"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='1'
                                    invalid={
                                        validation.touched.vaccination_flag &&
                                        validation.errors.vaccination_flag
                                        ? true
                                        : false
                                    }
                                /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;<Input
                                    name="vaccination_flag"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='0'
                                    invalid={
                                    validation.touched.vaccination_flag &&
                                        validation.errors.vaccination_flag
                                        ? true
                                        : false
                                    }
                                />&nbsp;&nbsp;No
                                {validation.touched.vaccination_flag &&
                                validation.errors.vaccination_flag ? (
                                <FormFeedback type="invalid">
                                {validation.errors.vaccination_flag}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Require Annual Flu Vaccinated Staff
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="annual_flu_flag"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='1'
                                    invalid={
                                        validation.touched.annual_flu_flag &&
                                        validation.errors.annual_flu_flag
                                        ? true
                                        : false
                                    }
                                /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;<Input
                                    name="annual_flu_flag"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='0'
                                    invalid={
                                        validation.touched.annual_flu_flag &&
                                        validation.errors.annual_flu_flag
                                        ? true
                                        : false
                                    }
                                />&nbsp;&nbsp;No
                                {validation.touched.annual_flu_flag &&
                                validation.errors.annual_flu_flag ? (
                                <FormFeedback type="invalid">
                                {validation.errors.annual_flu_flag}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mr'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mrs'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Miss'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Ms'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Ms
                                {validation.touched.user_title &&
                                validation.errors.user_title ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_title}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_fname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_fname}
                                invalid={
                                validation.touched.client_fname &&
                                    validation.errors.client_fname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_fname &&
                                validation.errors.client_fname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_fname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Last Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_lname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_lname}
                                invalid={
                                validation.touched.client_lname &&
                                    validation.errors.client_lname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_lname &&
                                validation.errors.client_lname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_lname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_email}
                                invalid={
                                    validation.touched.client_email &&
                                    validation.errors.client_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_email &&
                                validation.errors.client_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client Gender
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Male'
                                    invalid={
                                        validation.touched.client_gender &&
                                        validation.errors.client_gender
                                        ? true
                                        : false
                                    }
                                /> &nbsp;&nbsp;Male
                                &nbsp;&nbsp;<Input
                                    name="client_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Female'
                                    invalid={
                                        validation.touched.client_gender &&
                                        validation.errors.client_gender
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Female
                                &nbsp;&nbsp;<Input
                                    name="client_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Neutral'
                                    invalid={
                                        validation.touched.client_gender &&
                                        validation.errors.client_gender
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Neutral
                                {validation.touched.client_gender &&
                                validation.errors.client_gender ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_gender}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client DOB
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="client_dob"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientDob(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_dob || ClientDob}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Primary Contact no & Security Code
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_mobile_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_mobile_no}
                                invalid={
                                validation.touched.client_mobile_no &&
                                    validation.errors.client_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_mobile_no &&
                                validation.errors.client_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Emergency Contact No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_home_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_home_no}
                                invalid={
                                validation.touched.client_home_no &&
                                    validation.errors.client_home_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_home_no &&
                                validation.errors.client_home_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_home_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Referral Source
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_referral_source"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_referral_source}
                                invalid={
                                validation.touched.client_referral_source &&
                                    validation.errors.client_referral_source
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_referral_source &&
                                validation.errors.client_referral_source ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_referral_source}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Care Instruction

                            </Label>
                            <Col sm={9}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={CareInstructions}
                                    name="task"
                                    onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        validation.values.care_instruction = data;
                                        setCareInstructions(data);
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        validation.values.care_instruction = data;
                                        // setCareInstructions(data);
                                    }}
                                    
                                />
                            
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Address
                            </Label>
                            <Col sm={9}>

                            <StandaloneSearchBox
                                onLoad={ref => inputRef.current = ref}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <input
                                    name="client_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={ validation.values.client_address }
                                />
                            </StandaloneSearchBox>

                                {/* <Input
                                
                                    name="client_address"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_address}
                                    invalid={
                                        validation.touched.client_address &&
                                        validation.errors.client_address
                                        ? true
                                        : false
                                    }
                                /> */}

                            </Col>
                            {validation.touched.client_address &&
                                validation.errors.client_address ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_address}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Client Postcode
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="client_postcode"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.client_postcode}
                                invalid={
                                    validation.touched.client_postcode &&
                                    validation.errors.client_postcode
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.client_postcode &&
                                validation.errors.client_postcode ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_postcode}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Timezone
                            </Label>
                            <Col sm={9}>
                                <Select
                                    value={client_timezone_dropdown.value}
                                    name="clinet_timezone"
                                    options={client_timezone_dropdown}
                                    onChange={(e) => setClientTimezone(e)}
                                    className="select2-selection"
                                    defaultValue={client_timezone_dropdown}
                                />
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                For
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_type"
                                    type="radio"
                                    onChange={(e) => {
                                        handleClientType('provider');
                                    }}
                                    value='provider'
                                    
                                /> &nbsp;&nbsp;Provider
                                &nbsp;&nbsp;<Input
                                    name="client_type"
                                    type="radio"
                                    onChange={(e) => {
                                        handleClientType('contractors');
                                    }}
                                    value='contractors'
                                    
                                />&nbsp;&nbsp;3rd Party Contractors
                                
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Client Department
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={ClientDepartment}
                                name="client_department"
                                options={department_dropdown}
                                onChange={(e) => handleDepartmentChange(e)}
                                className="select2-selection"
                                defaultValue={department_dropdown}
                            />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Case Manager
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={ClientCasemanager}
                                name="case_namager"
                                options={casemanager_dropdown}
                                onChange={handleCaseMangerChange}
                                className="select2-selection"
                                defaultValue={ClientCasemanager}
                            />
                            </Col>
                        </Row>

                        <CardTitle className="mb-4">Provider Contact</CardTitle>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_emergency_contact_name}
                                    invalid={
                                        validation.touched.client_emergency_contact_name &&
                                        validation.errors.client_emergency_contact_name
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.client_emergency_contact_name &&
                                validation.errors.client_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_name}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_relation"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_emergency_contact_relation}
                                    invalid={
                                        validation.touched.client_emergency_contact_relation &&
                                        validation.errors.client_emergency_contact_relation
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.client_emergency_contact_relation &&
                                validation.errors.client_emergency_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_relation}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_mobile"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_emergency_contact_mobile}
                                    invalid={
                                        validation.touched.client_emergency_contact_mobile &&
                                        validation.errors.client_emergency_contact_mobile
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.client_emergency_contact_mobile &&
                                validation.errors.client_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="client_emergency_contact_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.client_emergency_contact_email}
                                    invalid={
                                        validation.touched.client_emergency_contact_email &&
                                        validation.errors.client_emergency_contact_email
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.client_emergency_contact_email &&
                                validation.errors.client_emergency_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.client_emergency_contact_email}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staffs Excluded from rosters
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    // value={staffs_dropdown}
                                    value={staffs_dropdown.value}
                                    name="client_department"
                                    options={staffs_dropdown}
                                    onChange={(e) => setStaffsExecluded(e)}
                                    className="select2-selection"
                                    // defaultValue={staffs_dropdown}
                                />
                            </Col>
                            
                        </Row>

                        <CardTitle className="mb-4">Covid-19 Vaccination</CardTitle>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Dose
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="first_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.first_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Dose Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="first_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setFirstDoseDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_dose_date || FirstDoseDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Second Dose
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="second_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.second_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Second Dose Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="second_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setSecondDoseDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.second_dose_date || SecondDoseDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Annual Flu Vaccination
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="annual_flu_dose"
                                    type="checkbox"
                                    onChange={validation.handleChange}
                                    value='1'
                                />
                            </Col>
                            
                        </Row>

                        {  validation.values.annual_flu_dose == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Annual Flu Vaccination Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="annual_flu_dose_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setAnnualFluDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.annual_flu_dose_date || AnnualFluDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        </Row>
                        )}
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Package Type
                            </Label>
                            <Col sm={9}>
                            
                                <Select
                                   value={PackageType}
                                   name="ctid"
                                   options={PackageTypeDropdown}
                                   onChange={(e) => handlePackageTypes(e)}
                                   className="select2-selection"
                                   defaultValue={PackageType}
                                />
                            </Col>
                        
                        </Row>

                    { PackageType.value == '1' && (

                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Support Type
                            </Label>
                            <Col sm={9}>

                            <CreatableSelect 
                            isMulti
                            name="support_type"
                            value={ClientSupportType}
                            onChange={(e) => {
                                setClientSupportType('');
                                setClientSupportType(e);
                            }}
                             />

                                {/* <Input
                                    name="support_type"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.support_type} /> */}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Amount
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="amount"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.amount} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Start Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="start_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageStartDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.start_date || ClientPackageStartDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    // maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                End Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="end_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageEndDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.end_date || ClientPackageEndDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: ClientPackageStartDate,
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>
                        
                        </>
                        )}
                        
                        { PackageType.value == 2 && (

                            <>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Package Level
                                </Label>
                                <Col sm={9}>

                                    <Select
                                    value={validation.values.cplid.value}
                                    name="cplid"
                                    options={PackageLevelDropdown}
                                    onChange={(e) => handlePackageLevel(e)}
                                    className="select2-selection"
                                    // defaultValue={PackageLevelDropdown}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Start Date
                                </Label>
                                <Col sm={9}>
                                    <InputGroup>
                                        <Flatpickr
                                            name="start_date"
                                            type="text"
                                            className="form-control d-block"
                                            placeholder="dd-mm-YYYY"
                                            onChange={(selectedDates, dateStr, instance) => {
                                                setClientPackageStartDate(dateStr);
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.start_date || ClientPackageStartDate}
                                            options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            // maxDate: 'today',
                                            //   defaultDate: 'today',
                                            "locale": {
                                                    "firstDayOfWeek": 1 // start week on Monday
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Daily Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="daily_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={DailySubsidy}
                                        readOnly={true} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Weekly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="weekly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={WeeklySubsidy} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Monthly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="monthly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={MonthlySubsidy} />
                                        
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Yearly Subsidy
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        name="yearly_subsidy"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={YearlySubsidy} />
                                        
                                </Col>
                            </Row>

                            </>
                        )}
                    

                        { PackageType.value == '3' && (

                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Support Type
                            </Label>
                            <Col sm={9}>

                            <CreatableSelect 
                            isMulti
                            name="support_type"
                            value={ClientSupportType}
                            placeholder="Add Support Types"
                            onChange={(e) => {
                                setClientSupportType('');
                                setClientSupportType(e);
                            }}
                            />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Hourly Rate
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="hourly_rate"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.hourly_rate} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Total Amount
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="amount"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.amount} />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Start Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="start_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageStartDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.start_date || ClientPackageStartDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    // maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                End Date
                            </Label>
                            <Col sm={9}>
                            <InputGroup>
                                <Flatpickr
                                    name="end_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setClientPackageEndDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.end_date || ClientPackageEndDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: ClientPackageStartDate,
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                            </Col>
                        </Row>

                        </>
                        )}

                        <Row className="mb-4">
                            <Col>
                            <div className="text-end">
                                <button
                                type="submit"
                                className="btn btn-success save-user"
                                // disabled={TopLoading}
                                >
                                Add
                                </button>
                            </div>
                            </Col>
                        </Row>

                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

// AddClient.propTypes = {
//   match: PropTypes.any,
// };

export default withRouter(AddClient);