import {
  GET_DEPARTMENTS,
  GET_PROVIDERS_FAIL,
  GET_PROVIDERS_SUCCESS,
  // GET_PROVIDER_DETAIL,
  // GET_PROVIDER_DETAIL_FAIL,
  // GET_PROVIDER_DETAIL_SUCCESS,
  GET_THIRD_PARTY_CONTRACTORS,
  GET_THIRD_PARTY_CONTRACTORS_SUCCESS,
  GET_THIRD_PARTY_CONTRACTORS_FAIL,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS,
  // GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL,
  // ADD_DEPARTMENT,
  // ADD_DEPARTMENT_SUCCESS,
  // ADD_DEPARTMENT_FAIL,
  ADD_DEPARTMENTS,
  ADD_THIRD_PARTY_CONTRACTORS,
  UPDATE_DEPARTMENT_DETAIL,
  UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL
  // UPDATE_DEPARTMENT_DETAIL_SUCCESS,
  // UPDATE_DEPARTMENT_DETAIL_FAIL
} from "./actionTypes"

export const getDepartments = (params,types) => ({
  type: GET_DEPARTMENTS,
  params,
  types,
})

export const getDepartmentsSuccess = data => ({
  type: GET_PROVIDERS_SUCCESS,
  payload: data,
})

export const getDepartmentsFail = error => ({
  type: GET_PROVIDERS_FAIL,
  payload: error,
})

// export const getProviderDetail = id => ({
//   type: GET_PROVIDER_DETAIL,
//   id,
// })

// export const getProviderDetailSuccess = data => ({
//   type: GET_PROVIDER_DETAIL_SUCCESS,
//   payload: data,
// })

// export const getProviderDetailFail = error => ({
//   type: GET_PROVIDER_DETAIL_FAIL,
//   payload: error,
// })

export const getThirdPartyContractors = (params,types) => ({
  type: GET_THIRD_PARTY_CONTRACTORS,
  params,
  types
})

export const getThirdPartyContractorsSuccess = data => ({
  type: GET_THIRD_PARTY_CONTRACTORS_SUCCESS,
  payload: data,
})

export const getThirdPartyContractorsFail = error => ({
  type: GET_THIRD_PARTY_CONTRACTORS_FAIL,
  payload: error,
})

// export const getThirdPartyContractorsDetail = (params,types) => ({
//   type: GET_THIRD_PARTY_CONTRACTOR_DETAIL,
//   params,
//   types
// })

// export const getThirdPartyContractorsDetailSuccess = data => ({
//   type: GET_THIRD_PARTY_CONTRACTOR_DETAIL_SUCCESS,
//   payload: data,
// })

// export const getThirdPartyContractorsDetailFail = error => ({
//   type: GET_THIRD_PARTY_CONTRACTOR_DETAIL_FAIL,
//   payload: error,
// })

// export const addDepartment = (params,types) => ({
//   type: ADD_DEPARTMENT,
//   payload: params,
//   types
// })

// export const addDepartmentSuccess = data => ({
//   type: ADD_DEPARTMENT_SUCCESS,
//   payload: data,
// })

// export const addDepartmentFail = error => ({
//   type: ADD_DEPARTMENT_FAIL,
//   payload: error,
// })

export const addDepartments = (params) => ({
  type: ADD_DEPARTMENTS,
  payload: params,
})

export const addThirdPartyContractors = (params) => ({
  type: ADD_THIRD_PARTY_CONTRACTORS,
  payload: params,
})

// export const addDepartmentsSuccess = data => ({
//   type: ADD_DEPARTMENTS_SUCCESS,
//   payload: data,
// })

// export const addDepartmentsFail = error => ({
//   type: ADD_DEPARTMENTS_FAIL,
//   error,
// })

export const updateDepartment = (params) => ({
  type: UPDATE_DEPARTMENT_DETAIL,
  payload: params,
  // types
})

export const updateThirdPartyContractor = (params) => ({
  type: UPDATE_THIRD_PARTY_CONTRACTOR_DETAIL,
  payload: params,
  // types
})

// export const updateDepartmentSuccess = data => ({
//   type: UPDATE_DEPARTMENT_DETAIL_SUCCESS,
//   payload: data,
// })

// export const updateDepartmentFail = error => ({
//   type: UPDATE_DEPARTMENT_DETAIL_FAIL,
//   payload: error,
// })