import { call, put, takeEvery } from "redux-saga/effects"

// Redux States
import { GET_BROADCAST_ROSTERS } from "./actionTypes"
import {
  // getCasemanagers,
  getBroadcastRostersSuccess,
  getBroadcastRostersFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getBroadcastRosters,actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchBroadcastRosters({params,types}) {
  try {
    const response = yield call(getBroadcastRosters, params, types )
    console.log(response);
    yield put(getBroadcastRostersSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getBroadcastRostersFail(error))
  }
}

function* broadcastRosterSaga() {
  yield takeEvery(GET_BROADCAST_ROSTERS, fetchBroadcastRosters)
}

export default broadcastRosterSaga
