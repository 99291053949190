import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, isWeakMap, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, ADMIN_URL, GET_STAFFS } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import CheckboxComponent from "components/Common/CheckboxComponenet";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


const libraries =['places'];

//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getCsmStaffs as onGetCsmStaff,
    addCsmStaffs as onAddCsmStaffs,
    updateCsmStaff as onUpdateCsmStaff
} from "store/actions";

function AddStaffs(){
  //meta title
  document.title="Add Staffs| Tacveiha Panel";
  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });

  // console.log(getLoggedInUser );

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [ModalLoading, setModalLoading] = useState(false);
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [ServiceTypesArray, setServiceTypesArray] = useState([]);
  const [StaffServiceTypes, setStaffServiceTypes] = useState([]);
  const [UserType, setUserType] = useState('');
  const [StaffBuddies, setStaffBuddies] = useState([]);
  const [StaffDob, setStaffDob] = useState('');
  const [LanguageSpoken, setLanguageSpoken] = useState([]);
  const [LanguageSpokenDropdown, setLanguageSpokenDropdown] = useState([]);
  
  const [StaffAddress, setStaffAddress] = useState('');
  const [StaffLatLng, setStaffLatLng] = useState('');
  
  const [roster, setRoster] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [TopLoading, setTopLoading] = useState(false);
  
  const [selectedFiles, setselectedFiles] = useState([]);
  const [profileCover, setProfileCover] = useState('');

  const [staffTypes, setStaffTypes] = useState([]);
  const [BuddiesDropdown, setBuddiesDropdown ] = useState([]);

  const [StaffDepartment, setStaffDepartment] = useState([]);
  const [StaffCasemanager, setStaffCasemanager] = useState([]);
  const [department_dropdown, setDepartment_dropdown] = useState([]);
  const [casemanager_dropdown, setCasemanager_dropdown] = useState([]);

  const [staffTypesDropdown, setStaffTypesDropdown ] = useState([])


  const staffsRole = [
    {'label':'Select Staff Type','value':'0'},
    {'label':'Subadmin','value':'subadmin'},
    {'label':'Staff','value':'staff'},
  ]

  const staffsRole1 = [
    {'label':'Select Staff Type','value':'0'},
    {'label':'Admin','value':'subadmin'},
    {'label':'Staff','value':'staff'},
    {'label':'Human Resource Manager','value':'human_resource'}
]

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        loading: rosterReducer.loading
      })
  );
  const {
    loading
  } = useSelector(groupRostersProperties);

  useEffect(() => {
  
    setTopLoading(true);
      axios.post(APP_URL+GET_STAFFS,{'data_for':'get_add_staffs_data','user': loggedUser})
        .then(res => {
            setTopLoading(false);
          const fetchedData = res.data;
        //   console.log(fetchedData);
  
          if( fetchedData.status == 'success' ) {
            setServiceTypesArray(fetchedData.serviceTypesDropdown);
            setBuddiesDropdown(fetchedData.buddiesDropdown);
            setLanguageSpokenDropdown(fetchedData.languageDropdown);
            if( loggedUser.role !='human_resource') {
                setStaffTypesDropdown(staffsRole1);
            } else {
                setStaffTypesDropdown(staffsRole);
            }
            
          }
  
          if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
          }
      })

  }, []);
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        staff_type : ( roster && roster.staff_type ) || "",
        for_broadcast_roster : ( roster && roster.for_broadcast_roster ) || 0,
        buddy_id : ( roster && roster.buddy_id ) || "",
        user_type: ( roster && roster.user_type ) || "",
        user_title:  ( roster && roster.user_title ) || "",
        employee_id : ( roster && roster.employee_id ) || "",
        staff_fname : ( roster && roster.staff_fname ) || "",
        staff_lname : ( roster && roster.staff_lname ) || "",
        staff_email : ( roster && roster.staff_email ) || "",
        staff_password : "",
        staff_confirm_password : "",
        staff_gender : ( roster && roster.staff_gender ) || "",
        staff_dob : ( roster && roster.staff_dob ) || "",
        staff_mobile_no : ( roster && roster.staff_mobile_no ) || "",
        staff_home_no : ( roster && roster.staff_home_no ) || "",
        language_spoken : ( roster && roster.language_spoken ) || "",
        staff_address : ( roster && roster.staff_address ) || "",
        staff_latlng : ( roster && roster.staff_latlng ) || "",
        profile_cover : ( roster && roster.profile_cover ) || "",
        staff_emergency_contact_name : ( roster && roster.staff_emergency_contact_name ) || "",
        staff_emergency_contact_relation : ( roster && roster.staff_emergency_contact_relation ) || "",
        staff_emergency_contact_mobile : ( roster && roster.staff_emergency_contact_mobile ) || "",
        staff_emergency_contact_email : ( roster && roster.staff_emergency_contact_email ) || "",
        exclude_sms : ( roster && roster.exclude_sms ) || 0,
        can_delete_rosters : ( roster && roster.can_delete_rosters ) || 0,
        sms_auth : ( roster && roster.sms_auth ) || 0,
        email_auth : ( roster && roster.email_auth ) || 0,
        auth_email_address: ( roster && roster.auth_email_address ) || "",
    },
    validationSchema: Yup.object({
        // staff_type: Yup.string().required("Required"),
        //for_broadcast_roster: Yup.string().required("Required"),
        // user_type: Yup.string().required("Required"),
        employee_id : Yup.string().required("Required"),
        user_title: Yup.string().required("Required"),
        staff_fname: Yup.string().required("Required"),
        staff_lname: Yup.string().required("Required"),
        staff_email: Yup.string().required("Required"),
        staff_gender: Yup.string().required("Required"),
        staff_mobile_no: Yup.string().required("Required"),
        staff_home_no: Yup.string().required("Required"),
        staff_emergency_contact_name : Yup.string().required("Required"),
        staff_emergency_contact_relation: Yup.string().required("Required"),
        staff_emergency_contact_mobile : Yup.string().required("Required"),
        staff_emergency_contact_email : Yup.string().required("Required"),
    }),
    onSubmit: values => {

    //   console.log(values);
      
    //   if (isEdit) {

    

        if( staffTypes == "" ) {
            toast.error("Please select staff type", { autoClose: 5000 });
            return false;
        }

        if( staffTypes.value == "staff" ) {

            
            
            if( StaffServiceTypes.length == 0 ) {
                toast.error("Please select service types", { autoClose: 5000 });
                return false;
            }

            // if( StaffBuddies == "" ) {
            //     toast.error("Please select staff buddy", { autoClose: 5000 });
            //     return false;
            // }
            console.log(UserType);

            if( UserType == "" ) {
                toast.error("Please choose For option", { autoClose: 5000 });
                return false;
            }

            if( UserType  == 'contractors') {
    
                if( StaffDepartment == "" ) {
                    toast.error("Please select staff department/ 3rd party contractors", { autoClose: 5000 });
                    return false;
                }
        
                if( StaffCasemanager == "" ) {
                    toast.error("Please select staff case manager / contractor", { autoClose: 5000 });
                    return false;
                }

            }
    
        }

        

        if( StaffDob == "" ) {
          toast.error("Please provide staff date of birth", { autoClose: 5000 });
          return false;
        }

        if( LanguageSpoken == "" ) {
            toast.error("Please select language spoken", { autoClose: 5000 });
            return false;
        }
        
        if( StaffAddress == "" ) {
            toast.error("Please provide staff address", { autoClose: 5000 });
            return false;
        }

        if( values.email_auth == 1 &&  values.auth_email_address == '' ) {
          toast.error("Please provide authentication email address", { autoClose: 5000 });
          return false;
        }

        const newUser = {
          user_status : 1,
          for_broadcast_roster: values.for_broadcast_roster,
          staff_type: staffTypes,
          service_type: StaffServiceTypes,
          buddy_id: StaffBuddies,
          user_type: UserType,
          department_id: StaffDepartment,
          case_manager_id: StaffCasemanager,
          user_title: values.user_title,
          employee_id: values.employee_id,
          staff_fname: values.staff_fname,
          staff_lname: values.staff_lname,
          staff_email: values.staff_email,
          staff_password: values.staff_password,
          staff_dob: StaffDob,
          profile_pic: profileCover,
          staff_gender: values.staff_gender,
          staff_mobile_no: values.staff_mobile_no,
          staff_home_no: values.staff_home_no,
          staff_language: LanguageSpoken,
          staff_address: values.staff_address,
          staff_latlng: StaffLatLng,
          staff_emergency_contact_name: values.staff_emergency_contact_name,
          staff_emergency_contact_relation: values.staff_emergency_contact_relation,
          staff_emergency_contact_mobile: values.staff_emergency_contact_mobile,
          staff_emergency_contact_email: values.staff_emergency_contact_email,
          exclude_sms : values.exclude_sms,
          can_delete_rosters : values.can_delete_rosters,
          sms_auth: values.sms_auth,
          email_auth: values.email_auth,
          auth_email_address: values.auth_email_address,
        };

        // console.log(newUser);
        // return false;

        setTopLoading(true);
        axios.post(APP_URL+GET_STAFFS,{'data_for':'add_staff','formData': newUser, 'user': loggedUser})
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;
            // console.log(fetchedData);
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });

                validation.resetForm();
                setStaffAddress('');
                setStaffTypes('');
                setStaffServiceTypes([]);
                setStaffLatLng('');
                setStaffDob('');
                setStaffBuddies([]);
                setUserType('');
                setStaffDepartment([]);
                setStaffCasemanager([]);
                setLanguageSpoken([]);

            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

        // update user
        // dispatch(onUpdateCsmStaff('update_csm_staff',updateUser,loggedUser));
        

        // setIsEdit(false);
    //   } else {

    //     if( staffsProviders.length == 0 ) {
    //       toast.error("Please provide providers", { autoClose: 5000 });
    //       return false;
    //     }

    //     // const formData = new FormData();
    //     // formData.append('file', profileCover);
    //     // formData.append('fileName', profileCover.name);
        
    //     const newData = {
    //       user_status : values.user_status,
    //       department_id: staffsProviders,
    //       user_title: values.user_title,
    //       employee_id: values.employee_id,
    //       staff_fname: values.staff_fname,
    //       staff_lname: values.staff_lname,
    //       staff_email: values.staff_email,
    //       staff_password: values.staff_password,
    //       staff_dob: StaffDob,
    //       staff_gender: values.staff_gender,
    //       staff_mobile_no: values.staff_mobile_no,
    //       staff_home_no: values.staff_home_no,
    //       staff_language: values.staff_language,
    //       staff_address: StaffAddress,
    //       staff_latlng: StaffLatLng,
    //       profile_pic: profileCover,
    //       staff_emergency_contact_name: values.staff_emergency_contact_name,
    //       staff_emergency_contact_relation: values.staff_emergency_contact_relation,
    //       staff_emergency_contact_mobile: values.staff_emergency_contact_mobile,
    //       staff_emergency_contact_email: values.staff_emergency_contact_email,
    //       sms_auth: values.sms_auth,
    //       email_auth :values.email_auth,
    //       auth_email_address :values.auth_email_address,
    //     };
    //     // save new user
    //     dispatch(onAddCsmStaffs('add_csm_staff',newData,loggedUser));
    //     validation.resetForm();
    //   }
    //   toggle();
    },
  });

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };


  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.staff_address = place.formatted_address;
        setStaffAddress(place.formatted_address);
        setStaffLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
    }
  }

  const handleCheckboxChange = (event) => {
    const checkedId = parseInt(event.target.value);
    const isChecked = event.target.checked;
    // setStaffServiceTypes((StaffServiceTypes) => {
    //     if (isChecked) {
    //       return [...StaffServiceTypes, checkedId];
    //     } else {
    //       return StaffServiceTypes.filter((item) => item !== checkedId);
    //     }
    //   });

    //   console.log(StaffServiceTypes);

    
    if( event.target.checked ) {
        setStaffServiceTypes([...StaffServiceTypes, checkedId]);
    } else{
        setStaffServiceTypes( (StaffServiceTypes) => {
            return StaffServiceTypes.filter((id) =>{
                return id !== checkedId
            })
        });
    }
  }

  useEffect(() => {
  },[StaffServiceTypes]);

  const handleUserType = (type) => {

    const formData = {
        type: type
    }
    setUserType(type);
    setTopLoading(true);
    
    setStaffDepartment([]);
    setStaffCasemanager([]);
    setDepartment_dropdown([]);
    setCasemanager_dropdown([]);

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const handleDepartmentChange = (e) => {
    setStaffDepartment(e);
    const formData = {
        dep_id: e.value
    }
    setTopLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManager','formData':formData})
    .then(res => {
      setTopLoading(false);
      const fetchedData = res;
        setCasemanager_dropdown(res.data.casemanagers);
    })
  }

  //upload profile pic
    const handleCoverChange = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setProfileCover(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
        })
    }


  return (
    <React.Fragment>
      {
        TopLoading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Csm Staffs" breadcrumbItem=" Add Staff" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <> */}
                  <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                        Staff Type
                        </Label>
                        <Col sm={9}>
                            <Select
                                value={staffTypes}
                                name="staff_type"
                                options={staffTypesDropdown}
                                onChange={(e) => setStaffTypes(e)}
                                className="select2-selection"
                                defaultValue={staffTypesDropdown}
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                        </Col>
                    </Row>

                    { staffTypes.value == 'staff' ? 
                    <>

                    <Row className="mb-4">

                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        For Broadcast Rosters
                    </Label>
                    <Col sm={9}>
                        <Input
                            name="for_broadcast_roster"
                            type="radio"
                            onChange={validation.handleChange}
                            value='1'
                            invalid={
                                validation.touched.for_broadcast_roster &&
                                validation.errors.for_broadcast_roster
                                ? true
                                : false
                            }
                            checked={validation.values.for_broadcast_roster == 1 ? true: false}
                        /> &nbsp;&nbsp;Yes
                        &nbsp;&nbsp;<Input
                            name="for_broadcast_roster"
                            type="radio"
                            onChange={validation.handleChange}
                            value='0'
                            invalid={
                            validation.touched.for_broadcast_roster &&
                                validation.errors.for_broadcast_roster
                                ? true
                                : false
                            }
                            checked={validation.values.for_broadcast_roster == 0 ? true: false}
                        />&nbsp;&nbsp;No
                        {validation.touched.for_broadcast_roster &&
                        validation.errors.for_broadcast_roster ? (
                        <FormFeedback type="invalid">
                        {validation.errors.for_broadcast_roster}
                        </FormFeedback>
                        ) : null}
                    </Col>

                    </Row>

                    <Row className="mb-4">

                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Service Types
                        </Label>
                        <Col sm={9}>

                        {/* <CheckboxComponent options={ServiceTypesArray}></CheckboxComponent> */}
                        {/* {console.log(checkedItems)} */}
                            {ServiceTypesArray.map(item => (
                                <div key={item.value} className="d-inline-flex">
                                <label>
                                    <Input
                                    type="checkbox"
                                    value={item.value}
                                    checked={StaffServiceTypes.includes(item.value)}
                                    onChange={(event) => handleCheckboxChange(event)}
                                    
                                    />
                                    &nbsp;&nbsp;
                                    {item.label}
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                
                            ))}

                        </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Select Buddy
                            </Label>
                            <Col sm={9}>
                                <Select
                                    
                                    value={StaffBuddies}
                                    name="buddy_id"
                                    options={BuddiesDropdown}
                                    onChange={(e) => setStaffBuddies(e)}
                                    className="select2-selection"
                                    defaultValue={StaffBuddies}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                For
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_type"
                                    type="radio"
                                    onChange={(e) => {
                                        handleUserType('provider');
                                    }}
                                    value='provider'
                                    checked={ UserType == 'provider' ? true : false }
                                    invalid={
                                        validation.touched.user_type &&
                                        validation.errors.user_type
                                        ? true
                                        : false
                                    }
                                /> &nbsp;&nbsp;Provider
                                &nbsp;&nbsp;<Input
                                    name="user_type"
                                    type="radio"
                                    onChange={(e) => {
                                        handleUserType('contractors');
                                    }}
                                    value='contractors'
                                    checked={ UserType == 'contractors' ? true : false }
                                    invalid={
                                        validation.touched.user_type &&
                                        validation.errors.user_type
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;3rd Party Contractors
                                {validation.touched.user_type &&
                                validation.errors.user_type ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_type}
                                </FormFeedback>
                                ) : null}
                                
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staff Department/ 3rd Party Contractor
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={StaffDepartment}
                                name="staff_department"
                                options={department_dropdown}
                                onChange={(e) => handleDepartmentChange(e)}
                                className="select2-selection"
                                defaultValue={department_dropdown}
                            />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Staff Case Manager/ Contractor
                            </Label>
                            <Col sm={9}>
                            <Select
                                value={StaffCasemanager}
                                name="staff_department"
                                options={casemanager_dropdown}
                                onChange={(e) => setStaffCasemanager(e)}
                                className="select2-selection"
                                defaultValue={casemanager_dropdown}
                            />
                            </Col>
                        </Row>

                        </>
                        : null }

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Employeed ID
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="employee_id"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.employee_id}
                                invalid={
                                validation.touched.employee_id &&
                                    validation.errors.employee_id
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.employee_id &&
                                validation.errors.employee_id ? (
                                <FormFeedback type="invalid">
                                {validation.errors.employee_id}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        
                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mr'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Mr' ? true: false}
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mrs'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Mrs' ? true: false}
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Miss'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Miss' ? true: false}
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Ms'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title == 'Ms' ? true: false}
                                />&nbsp;&nbsp;Ms
                                {validation.touched.user_title &&
                                validation.errors.user_title ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_title}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                First Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_fname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_fname}
                                invalid={
                                validation.touched.staff_fname &&
                                    validation.errors.staff_fname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_fname &&
                                validation.errors.staff_fname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_fname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Last Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_lname"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_lname}
                                invalid={
                                validation.touched.staff_lname &&
                                    validation.errors.staff_lname
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_lname &&
                                validation.errors.staff_lname ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_lname}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_email}
                                invalid={
                                    validation.touched.staff_email &&
                                    validation.errors.staff_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_email &&
                                validation.errors.staff_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        { !!isEdit ? 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_password}
                                invalid={
                                    validation.touched.staff_password &&
                                    validation.errors.staff_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_password &&
                                validation.errors.staff_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                               Re-type Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_confirm_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_confirm_password}
                                invalid={
                                    validation.touched.staff_confirm_password &&
                                    validation.errors.staff_confirm_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_confirm_password &&
                                validation.errors.staff_confirm_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_confirm_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        
                        </> : null }

                        <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff Gender
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Male'
                                    invalid={
                                        validation.touched.staff_gender &&
                                        validation.errors.staff_gender
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.staff_gender == 'Male' ? true : false }
                                /> &nbsp;&nbsp;Male
                                &nbsp;&nbsp;<Input
                                    name="staff_gender"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Female'
                                    invalid={
                                        validation.touched.staff_gender &&
                                        validation.errors.staff_gender
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.staff_gender == 'Female' ? true : false }
                                />&nbsp;&nbsp;Female
                                
                                {validation.touched.staff_gender &&
                                validation.errors.staff_gender ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_gender}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff DOB
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="staff_dob"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setStaffDob(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_dob || StaffDob}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: 'today',
                                    //   defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Mobile No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_mobile_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_mobile_no}
                                invalid={
                                validation.touched.staff_mobile_no &&
                                    validation.errors.staff_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_mobile_no &&
                                validation.errors.staff_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Home Contact No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_home_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_home_no}
                                invalid={
                                validation.touched.staff_home_no &&
                                    validation.errors.staff_home_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_home_no &&
                                validation.errors.staff_home_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_home_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Language Spoken
                            </Label>
                            <Col sm={9}>
                                <Select
                                    isMulti={true}
                                    value={LanguageSpoken}
                                    name="language_spoken"
                                    options={LanguageSpokenDropdown}
                                    onChange={(e) => setLanguageSpoken(e)}
                                    className="select2-selection"
                                    defaultValue={LanguageSpoken}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Address
                            </Label>
                            <Col sm={9}>

                            <StandaloneSearchBox
                                onLoad={ref => inputRef.current = ref}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <input
                                    name="staff_address"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={ validation.values.staff_address }
                                />
                            </StandaloneSearchBox>
                            </Col>
                            {validation.touched.staff_address &&
                                validation.errors.staff_address ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_address}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Profile Cover
                            </Label>
                            <Col sm={9}>
                            <Input
                                type="file"
                                onChange={handleCoverChange}
                                accept='.jpg, .png, .jpeg|image/*'
                            />
                            <img src={profileCover}  height={110}/>
                            
                            </Col>
                        
                        </Row>

                        <CardTitle>Emergency Contact</CardTitle>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_emergency_contact_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_emergency_contact_name}
                                invalid={
                                validation.touched.staff_emergency_contact_name &&
                                    validation.errors.staff_emergency_contact_name
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_emergency_contact_name &&
                                validation.errors.staff_emergency_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_name}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_relation"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_relation}
                                    invalid={
                                        validation.touched.staff_emergency_contact_relation &&
                                        validation.errors.staff_emergency_contact_relation
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_relation &&
                                validation.errors.staff_emergency_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_relation}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_mobile"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_mobile}
                                    invalid={
                                        validation.touched.staff_emergency_contact_mobile &&
                                        validation.errors.staff_emergency_contact_mobile
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_mobile &&
                                validation.errors.staff_emergency_contact_mobile ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_mobile}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="staff_emergency_contact_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.staff_emergency_contact_email}
                                    invalid={
                                        validation.touched.staff_emergency_contact_email &&
                                        validation.errors.staff_emergency_contact_email
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.staff_emergency_contact_email &&
                                validation.errors.staff_emergency_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_emergency_contact_email}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        { staffTypes.value == 'staff' ||  staffTypes.value == 'human_resource' ? 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Sms Notification
                            </Label>
                            <Col sm={9}>
                              <Input
                                name="exclude_sms"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.exclude_sms &&
                                    validation.errors.exclude_sms
                                    ? true
                                    : false
                                }
                                checked={validation.values.exclude_sms == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="exclude_sms"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                //   invalid={
                                //   validation.touched.exclude_sms &&
                                //       validation.errors.exclude_sms
                                //       ? true
                                //       : false
                                //   }
                                  checked={validation.values.exclude_sms == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {/* {validation.touched.exclude_sms &&
                              validation.errors.exclude_sms ? (
                              <FormFeedback type="invalid">
                              {validation.errors.exclude_sms}
                              </FormFeedback>
                              ) : null} */}
                          </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Enable Delete Roster
                            </Label>
                            <Col sm={9}>
                              <Input
                                name="can_delete_rosters"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.can_delete_rosters &&
                                    validation.errors.can_delete_rosters
                                    ? true
                                    : false
                                }
                                checked={validation.values.can_delete_rosters == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="can_delete_rosters"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                //   invalid={
                                //   validation.touched.exclude_sms &&
                                //       validation.errors.exclude_sms
                                //       ? true
                                //       : false
                                //   }
                                  checked={validation.values.can_delete_rosters == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {/* {validation.touched.exclude_sms &&
                              validation.errors.exclude_sms ? (
                              <FormFeedback type="invalid">
                              {validation.errors.exclude_sms}
                              </FormFeedback>
                              ) : null} */}
                          </Col>
                            
                        </Row>

                        </>
                        : null }

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Sms Authentication
                            </Label>
                            <Col sm={9}>
                              <Input
                                name="sms_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.sms_auth &&
                                    validation.errors.sms_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.sms_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="sms_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.sms_auth &&
                                      validation.errors.sms_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.sms_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.sms_auth &&
                              validation.errors.sms_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.sms_auth}
                              </FormFeedback>
                              ) : null}
                          </Col>
                            
                        </Row>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Email Authentication
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="email_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.email_auth &&
                                    validation.errors.email_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.email_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="email_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.email_auth &&
                                      validation.errors.email_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.email_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.email_auth &&
                              validation.errors.email_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.email_auth}
                              </FormFeedback>
                              ) : null}
                            </Col>
                            
                        </Row>

                        {  validation.values.email_auth == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Provide Authentication Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                    name="auth_email_address"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.auth_email_address}
                                />
                                
                            </Col>

                        </Row>
                        )}

                        {/* documents and files uplaod */}
                        { !!isEdit ? (
                          <></>      
                        ) : null }

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={TopLoading}
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    
                  {/* </>
                } */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default AddStaffs;