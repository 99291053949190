import {
    GET_FEEDBACKS,
    GET_FEEDBACKS_FAIL,
    GET_FEEDBACKS_SUCCESS,
    ADD_FEEDBACK,
    UPDATE_FEEDBACK,
  
  } from "./actionTypes"
  
  export const getFeedbacks = (params,types) => ({
    type: GET_FEEDBACKS,
    params,
    types,
  })
  
  export const getFeedbacksSuccess = data => ({
    type: GET_FEEDBACKS_SUCCESS,
    payload: data,
  })
  
  export const getFeedbacksFail = error => ({
    type: GET_FEEDBACKS_FAIL,
    payload: error,
  })

  export const addFeedback = params => ({
    type: ADD_FEEDBACK,
    payload: params,
  })

  
  export const updateFeedback = (params) => ({
    type: UPDATE_FEEDBACK,
    payload: params,
  })
  