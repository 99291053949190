import {
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  GET_ARCHIVED_CLIENTS,
  GET_ARCHIVED_CLIENTS_SUCCESS,
  GET_ARCHIVED_CLIENTS_FAILED,
  GET_CLIENTS_ROSTERS,
  GET_CLIENTS_ROSTERS_SUCCESS,
  GET_CLIENTS_ROSTERS_FAIL,
  GET_CLIENTS_ROSTERS_DETAIL,
  GET_CLIENTS_ROSTERS_DETAIL_FAIL,
  GET_CLIENTS_ROSTERS_DETAIL_SUCCESS,
  GET_CLIENTS_SERVICES_TYPES,
  GET_CLIENTS_SERVICES_TYPES_SUCCESS,
  GET_CLIENTS_SERVICES_TYPES_FAIL,
  GET_CLIENTS_SERVICES_LIST,
  GET_CLIENTS_SERVICES_LIST_SUCCESS,
  GET_CLIENTS_SERVICES_LIST_FAIL,
  GET_CLIENTS_ISOLATION_LIST,
  GET_CLIENTS_ISOLATION_LIST_SUCCESS,
  GET_CLIENTS_ISOLATION_LIST_FAIL,
  GET_CLIENTS_DELETED_ROSTERS,
  GET_CLIENTS_DELETED_ROSTERS_SUCCESS,
  GET_CLIENTS_DELETED_ROSTERS_FAIL,
  GET_CLIENTS_NOTES,
  GET_CLIENTS_NOTES_SUCCESS,
  GET_CLIENTS_NOTES_FAIL,
  ADD_CLIENT_NOTES,
  ADD_CLIENT_NOTES_SUCCESS,
  ADD_CLIENT_NOTES_FAIL,

  UPDATE_CLIENT_NOTES,
  UPDATE_CLIENT_NOTES_SUCCESS,
  UPDATE_CLIENT_NOTES_FAIL,

  ADD_CLIENTS_SERVICES_LIST,
  ADD_CLIENTS_SERVICES_LIST_SUCCESS,
  ADD_CLIENTS_SERVICES_LIST_FAIL,

  UPDATE_CLIENTS_SERVICES_LIST,
  UPDATE_CLIENTS_SERVICES_LIST_SUCCESS,
  UPDATE_CLIENTS_SERVICES_LIST_FAIL,
  
  ADD_CLIENTS_SERVICES_TYPES,
  ADD_CLIENTS_SERVICES_TYPES_SUCCESS,
  ADD_CLIENTS_SERVICES_TYPES_FAIL,

  UPDATE_CLIENTS_SERVICES_TYPES,
  UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS,
  UPDATE_CLIENTS_SERVICES_TYPES_FAIL,
  
  ADD_CLIENTS_ISOLATION_LIST,
  ADD_CLIENTS_ISOLATION_LIST_SUCCESS,
  ADD_CLIENTS_ISOLATION_LIST_FAIL,

  UPDATE_CLIENTS_ISOLATION_LIST,
  UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS,
  UPDATE_CLIENTS_ISOLATION_LIST_FAIL,

  } from "./actionTypes"
  
  const INIT_STATE = {
    clients: [],
    archivedClients: [],
    clientDetails: {},
    clientRosters: [],
    clientRosterDetails: [],
    clientsNotes: [],
    deletedRosters: [],
    clientsServicesTypes: [],
    clientsServicesList: [],
    clientsIsolationList: [],
    error: {},
    loading: false,
    payCategory:[],
    supportTypes:[],
    groupRosterDropdown: [],
    broadcastStaffs: [],
    hiddenColumns:[]
  }
  
  const ClientsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CLIENTS:
        return {
          ...state,
          clients: state.clients,
          hiddenColumns: state.hiddenColumns,
          allStaffsDropdown: state.allStaffsDropdown,
          clientsDropdown: state.clientsDropdown,
          staffsDropdown: state.staffsDropdown,
          payCategory: state.payCategory,
          supportTypes: state.supportTypes,
          groupRosterDropdown: state.groupRosterDropdown,
          broadcastStaffs: state.broadcastStaffs,
          loading: true
        }
      case GET_CLIENTS_SUCCESS:
        return {
          ...state,
          clients: action.payload.data,
          hiddenColumns: action.payload.hiddenColumns,
          allStaffsDropdown: action.payload.allStaffsDropdown,
          clientsDropdown:action.payload.clientsDropdown,
          staffsDropdown: action.payload.staffsDropdown,
          payCategory: action.payload.payCategoryDropdown,
          supportTypes: action.payload.supportTypeDropdown,
          groupRosterDropdown: action.payload.groupRosterDropdown,
          broadcastStaffs: action.payload.broadcastStaffs,
          loading: false
        }
  
      case GET_CLIENTS_FAILED:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case GET_ARCHIVED_CLIENTS:
          return {
            ...state,
            archivedClients: [],
            loading: true
          }
      case GET_ARCHIVED_CLIENTS_SUCCESS:
          return {
            ...state,
            archivedClients: action.payload.data,
            loading: false
          }
    
      case GET_ARCHIVED_CLIENTS_FAILED:
          return {
            ...state,
            error: action.payload.error,
            loading: false
          }
      case GET_CLIENTS_DELETED_ROSTERS:
        return {
          ...state,
          deletedRosters: [],
          loading: true
        }
      case GET_CLIENTS_DELETED_ROSTERS_SUCCESS:
        return {
          ...state,
          deletedRosters: action.payload.data,
          loading: false
        }

      case GET_CLIENTS_DELETED_ROSTERS_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }

      case GET_CLIENTS_ROSTERS:
        return {
          ...state,
          clientRosters: [],
          loading: true
        }
    
      case GET_CLIENTS_ROSTERS_SUCCESS:
        return {
          ...state,
          clientRosters: action.payload.data,
          loading: false
        }
    
      case GET_CLIENTS_ROSTERS_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }
          
      case GET_CLIENTS_ROSTERS_DETAIL:
        return {
          ...state,
          clientRosterDetails: [],
          loading: true
        }
  
      case GET_CLIENTS_ROSTERS_DETAIL_SUCCESS:
        return {
          ...state,
          clientRosterDetails: action.payload.data,
          loading: false
        }
  
      case GET_CLIENTS_ROSTERS_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

      case GET_CLIENTS_SERVICES_LIST:
        return {
          ...state,
          clientsServicesList: [],
          loading: true
        }
  
      case GET_CLIENTS_SERVICES_LIST_SUCCESS:
        return {
          ...state,
          clientsServicesList: action.payload.data,
          loading: false
        }
  
      case GET_CLIENTS_SERVICES_LIST_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case GET_CLIENTS_SERVICES_TYPES:
        return {
          ...state,
          clientsServicesTypes: [],
          loading: true
        }
  
      case GET_CLIENTS_SERVICES_TYPES_SUCCESS:
        return {
          ...state,
          clientsServicesTypes: action.payload.data,
          loading: false
        }
  
      case GET_CLIENTS_SERVICES_TYPES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      
      case GET_CLIENTS_ISOLATION_LIST:
        return {
          ...state,
          clientsIsolationList: [],
          loading: true
        }
    
      case GET_CLIENTS_ISOLATION_LIST_SUCCESS:
        return {
          ...state,
          clientsIsolationList: action.payload.data,
          loading: false
        }
    
      case GET_CLIENTS_ISOLATION_LIST_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case GET_CLIENTS_NOTES:
        return {
          ...state,
          clientsNotes: [],
          loading: true
        }
      
      case GET_CLIENTS_NOTES_SUCCESS:
        return {
          ...state,
          clientsNotes: action.payload.data,
          loading: false
        }
  
      case GET_CLIENTS_NOTES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case ADD_CLIENT_NOTES:
        return {
          ...state,
          clientsNotes: [],
          loading: true
        }
        
      case ADD_CLIENT_NOTES_SUCCESS:
        return {
          ...state,
          clientsNotes: [...state.clientsNotes, action.payload.data],
          loading: false
        }
  
      case ADD_CLIENT_NOTES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case UPDATE_CLIENT_NOTES:
        return {
          ...state,
          // clientsNotes: [],
          loading: true
        }
        
      case UPDATE_CLIENT_NOTES_SUCCESS:
        return {
          ...state,
          clientsNotes: state.clientsNotes.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                  ? { rosters, ...action.payload.data }
                  : rosters
          ),
          // loading: false
          // clientsNotes: action.payload.data,
          loading: false
        }
  
      case UPDATE_CLIENT_NOTES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

      case ADD_CLIENTS_SERVICES_LIST:
        return {
          ...state,
          // clientsServicesList: [],
          loading: true
        }
        
      case ADD_CLIENTS_SERVICES_LIST_SUCCESS:
        return {
          ...state,
          clientsServicesList: [...state.clientsServicesList, action.payload.data],
          loading: false
        }
  
      case ADD_CLIENTS_SERVICES_LIST_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case ADD_CLIENTS_SERVICES_TYPES:
        return {
          ...state,
          //clientsServicesTypes: [],
          loading: true
        }
        
      case ADD_CLIENTS_SERVICES_TYPES_SUCCESS:
        return {
          ...state,
          clientsServicesTypes: [...state.clientsServicesTypes, action.payload.data],
          loading: false
        }
  
      case ADD_CLIENTS_SERVICES_TYPES_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case ADD_CLIENTS_ISOLATION_LIST:
        return {
          ...state,
          //clientsIsolationList: [],
          loading: true
        }
        
      case ADD_CLIENTS_ISOLATION_LIST_SUCCESS:
        return {
          ...state,
          clientsIsolationList: [...state.clientsIsolationList, action.payload.data],
          loading: false
        }
  
      case ADD_CLIENTS_ISOLATION_LIST_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
        }
      case UPDATE_CLIENTS_ISOLATION_LIST:
        return {
          ...state,
          // clientsIsolationList: [],
          loading: true
        }
        
      case UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS:
        return {
          ...state,
          clientsIsolationList: state.clientsIsolationList.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                  ? { rosters, ...action.payload.data }
                  : rosters
          ),
          // clientsIsolationList: action.payload.data,
          loading: false
        }
  
      case UPDATE_CLIENTS_ISOLATION_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

      case UPDATE_CLIENTS_SERVICES_LIST:
        return {
          ...state,
          // clientsIsolationList: [],
          loading: true
        }
        
      case UPDATE_CLIENTS_SERVICES_LIST_SUCCESS:
        return {
          ...state,
          clientsServicesList: state.clientsServicesList.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                  ? { rosters, ...action.payload.data }
                  : rosters
          ),
          // clientsServicesList: action.payload.data,
          loading: false
        }
  
      case UPDATE_CLIENTS_SERVICES_LIST_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

      case UPDATE_CLIENTS_SERVICES_TYPES:
        return {
          ...state,
          // clientsIsolationList: [],
          loading: true
        }
        
      case UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS:
        return {
          ...state,
          clientsServicesTypes: state.clientsServicesTypes.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                  ? { rosters, ...action.payload.data }
                  : rosters
          ),
          loading: false
        }
  
      case UPDATE_CLIENTS_SERVICES_TYPES_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }

      default:
        return state
    }
  }
  
  export default ClientsReducer
  