export const GET_SUPPORT_WORKERS = "GET_SUPPORT_WORKERS"
export const GET_SUPPORT_WORKERS_SUCCESS = "GET_SUPPORT_WORKERS_SUCCESS"
export const GET_SUPPORT_WORKERS_FAIL = "GET_SUPPORT_WORKERS_FAIL"

export const GET_ARCHIVED_SUPPORT_WORKERS = "GET_ARCHIVED_SUPPORT_WORKERS"
export const GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS = "GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS"
export const GET_ARCHIVED_SUPPORT_WORKERS_FAIL = "GET_ARCHIVED_SUPPORT_WORKERS_FAIL"

export const GET_STAFFS_SERVICE_TYPES = "GET_STAFFS_SERVICE_TYPES"
export const GET_STAFFS_SERVICE_TYPES_SUCCESS = "GET_STAFFS_SERVICE_TYPES_SUCCESS"
export const GET_STAFFS_SERVICE_TYPES_FAIL = "GET_STAFFS_SERVICE_TYPES_FAIL"

export const ADD_STAFF_SERVICE_TYPES = "ADD_STAFF_SERVICE_TYPES"
export const ADD_STAFF_SERVICE_TYPES_SUCCESS = "ADD_STAFF_SERVICE_TYPES_SUCCESS"
export const ADD_STAFF_SERVICE_TYPES_FAIL = "ADD_STAFF_SERVICE_TYPES_FAIL"

export const UPDATE_STAFF_SERVICE_TYPE = "UPDATE_STAFF_SERVICE_TYPE"
export const UPDATE_STAFF_SERVICE_TYPE_SUCCESS = "UPDATE_STAFF_SERVICE_TYPE_SUCCESS"
export const UPDATE_STAFF_SERVICE_TYPE_FAIL = "UPDATE_STAFF_SERVICE_TYPE_FAIL"

export const ADD_STAFF = "ADD_STAFF"
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS"
export const ADD_STAFF_FAIL = "ADD_STAFF_FAIL"

export const UPDATE_STAFF = "UPDATE_STAFF"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const UPDATE_STAFF_FAIL = "UPDATE_STAFF_FAIL"

export const GET_STAFF_LANGUAGES = "GET_STAFF_LANGUAGES"
export const GET_STAFF_LANGUAGES_SUCCESS = "GET_STAFF_LANGUAGES_SUCCESS"
export const GET_STAFF_LANGUAGES_FAIL = "GET_STAFF_LANGUAGES_FAIL"

export const ADD_STAFF_LANGUAGES = "ADD_STAFF_LANGUAGES"
export const ADD_STAFF_LANGUAGES_SUCCESS = "ADD_STAFF_LANGUAGES_SUCCESS"
export const ADD_STAFF_LANGUAGES_FAIL = "ADD_STAFF_LANGUAGES_FAIL"

export const UPDATE_STAFF_LANGUAGE = "UPDATE_STAFF_LANGUAGE"
export const UPDATE_STAFF_LANGUAGE_SUCCESS = "UPDATE_STAFF_LANGUAGE_SUCCESS"
export const UPDATE_STAFF_LANGUAGE_FAIL = "UPDATE_STAFF_LANGUAGE_FAIL"

//CSM Staffs
export const GET_CSM_STAFFS = "GET_CSM_STAFFS"
export const GET_CSM_STAFFS_SUCCESS = "GET_CSM_STAFFS_SUCCESS"
export const GET_CSM_STAFFS_FAIL = "GET_CSM_STAFFS_FAIL"

export const ADD_CSM_STAFFS = "ADD_CSM_STAFFS"
export const ADD_CSM_STAFFS_SUCCESS = "ADD_CSM_STAFFS_SUCCESS"
export const ADD_CSM_STAFFS_FAIL = "ADD_CSM_STAFFS_FAIL"

export const UPDATE_CSM_STAFF = "UPDATE_CSM_STAFF"
export const UPDATE_CSM_STAFF_SUCCESS = "UPDATE_CSM_STAFF_SUCCESS"
export const UPDATE_CSM_STAFF_FAIL = "UPDATE_CSM_STAFF_FAIL"



//Support Staffs
export const GET_SUPPORT_STAFFS = "GET_SUPPORT_STAFFS"
export const GET_SUPPORT_STAFFS_SUCCESS = "GET_SUPPORT_STAFFS_SUCCESS"
export const GET_SUPPORT_STAFFS_FAIL = "GET_SUPPORT_STAFFS_FAIL"

export const ADD_SUPPORT_STAFF = "ADD_SUPPORT_STAFF"
export const ADD_SUPPORT_STAFF_SUCCESS = "ADD_SUPPORT_STAFF_SUCCESS"
export const ADD_SUPPORT_STAFF_FAIL = "ADD_SUPPORT_STAFF_FAIL"

export const UPDATE_SUPPORT_STAFF = "UPDATE_SUPPORT_STAFF"
export const UPDATE_SUPPORT_STAFF_SUCCESS = "UPDATE_SUPPORT_STAFF_SUCCESS"
export const UPDATE_SUPPORT_STAFF_FAIL = "UPDATE_SUPPORT_STAFF_FAIL"


//Admin Staffs
export const GET_ADMIN_STAFFS = "GET_ADMIN_STAFFS"
export const GET_ADMIN_STAFFS_SUCCESS = "GET_ADMIN_STAFFS_SUCCESS"
export const GET_ADMIN_STAFFS_FAIL = "GET_ADMIN_STAFFS_FAIL"

export const ADD_ADMIN_STAFF = "ADD_ADMIN_STAFF"
export const ADD_ADMIN_STAFF_SUCCESS = "ADD_ADMIN_STAFF_SUCCESS"
export const ADD_ADMIN_STAFF_FAIL = "ADD_ADMIN_STAFF_FAIL"

export const UPDATE_ADMIN_STAFF = "UPDATE_ADMIN_STAFF"
export const UPDATE_ADMIN_STAFF_SUCCESS = "UPDATE_ADMIN_STAFF_SUCCESS"
export const UPDATE_ADMIN_STAFF_FAIL = "UPDATE_ADMIN_STAFF_FAIL"

//HR Managers
export const GET_HR_MANAGERS = "GET_HR_MANAGERS"
export const GET_HR_MANAGERS_SUCCESS = "GET_HR_MANAGERS_SUCCESS"
export const GET_HR_MANAGERS_FAIL = "GET_HR_MANAGERS_FAIL"

export const ADD_HR_MANAGER = "ADD_HR_MANAGER"
export const ADD_HR_MANAGER_SUCCESS = "ADD_HR_MANAGER_SUCCESS"
export const ADD_HR_MANAGER_FAIL = "ADD_HR_MANAGER_FAIL"

export const UPDATE_HR_MANAGER = "UPDATE_HR_MANAGER"
export const UPDATE_HR_MANAGER_SUCCESS = "UPDATE_HR_MANAGER_SUCCESS"
export const UPDATE_HR_MANAGER_FAIL = "UPDATE_HR_MANAGER_FAIL"

// Load Staffs
export const GET_LOAD_STAFFS = "GET_LOAD_STAFFS"
export const GET_LOAD_STAFFS_SUCCESS = "GET_LOAD_STAFFS_SUCCESS"
export const GET_LOAD_STAFFS_FAIL = "GET_LOAD_STAFFS_FAIL"
