import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"


import {
    getPackageReportSettings as onGetPackageReportSettings,
    updatePackageReportSettings as onUpdatePackageReportSettings
} from "store/actions";

function PackageReport(){
  //meta title
  document.title="Package Report Settings | Tacveiha Panel";

  // console.log(getLoggedInUser );
  

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [repeatType, setRepeatType] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedRepeatTypes, SetSelectedRepeatTypes] = useState([]);
  

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.PackageReportReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        packageReport : rosterReducer.packageReport,
        departmentsDropdown : rosterReducer.departmentsDropdown,
        repeatTypesDropdown : rosterReducer.repeatTypesDropdown,
        loading : rosterReducer.loading
      })
  );
  const {
    packageReport,loading, departmentsDropdown, repeatTypesDropdown
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (roster && roster.id) || "",
      status: (roster && roster.status) || "",
      repeat_type:(roster && roster.repeat_type) || "",
      // departments: (roster && roster.departments) || "",

    },
    validationSchema: Yup.object({
        status: Yup.string().required("Please select status"),
        repeat_type: Yup.string().required("Please select repeat type"),
        //departments: Yup.string().required("Please select departments"),
    }),
    onSubmit: values => {

        const updateUser = {
            status: values.status,
            repeat_type: values.repeat_type,
            departments: selectedDepartment,
        };

        // update user
        dispatch(onUpdatePackageReportSettings({'data_for':'update_package_report_settings','data': updateUser,'user': loggedUser}));

    },
  });

  useEffect(() => {
    dispatch(onGetPackageReportSettings({'data_for':'get_package_report_settings','user': loggedUser}));
    handleUserClick(packageReport);
    
    setSelectedDepartment(packageReport.data_dep);
    SetSelectedRepeatTypes([{label:packageReport.repeat_type,value:packageReport.repeat_type}]);
    // console.log(selectedDepartment);
  }, [dispatch]);


  const toggle = () => { 
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleDepartmentsDropdown = (e) => {
    // console.log(e);
  }

  const handleUserClick = arg => {
    // console.log(arg);
    const user = arg;
    if( user.data_details ) {
      setRoster({
          status: user.data_details.status,
          repeat_type: user.data_details.repeat_type,
          //departments: user.data_details.departments,
      });

      // setIsEdit(true);
    }
    // toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  const handleSelectedValue = (e) => {
    // console.log(e);
    setCtid(e);
  }

  // const clsoeModal = () => {
  //   toggle();
  // }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };
  const keyField = "id";

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Package Reports Settings" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                    <div className="alert alert-success">The script will run on every Sunday at 11.50PM. Depending on Repeat type the data will be exported in excel and email sent to admin.
</div>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                    <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row className="mb-6">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-6">
                      <Input
                          name="status"
                          type="radio"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value="1"
                          invalid={
                            validation.touched.status &&
                              validation.errors.status
                              ? true
                              : false
                          }
                          defaultChecked={validation.values.status == 1 ? true : false}
                        />  Yes
                        &nbsp;&nbsp;&nbsp;<Input
                          name="status"
                          type="radio"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value='0'
                          invalid={
                            validation.touched.status &&
                              validation.errors.status
                              ? true
                              : false
                          }
                          defaultChecked={validation.values.status == 0 ? true : false }
                        />  No
                        {validation.touched.status &&
                          validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                  <Col className="mb-6">

                    <Row className="mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                      Repeat TYpe
                      </label>
                      <div className="col-md-10">

                      <Select
                        value={repeatTypesDropdown.value}
                        onChange={(e) => {
                          setRepeatType(e);
                        }}
                        name="ctid"
                        options={repeatTypesDropdown}
                        className="select2-selection"
                        defaultValue={selectedRepeatTypes}
                      />
                      </div>
                    </Row>
                  </Col>

                  <Col className="mb-6">

                    <Row className="mb-3">
                      <label
                        htmlFor="example-tel-input"
                        className="col-md-2 col-form-label"
                      >
                      Department
                      </label>
                      <div className="col-md-10">

                      <Select
                        value={departmentsDropdown.value}
                        onChange={(e) => {
                          handleDepartmentsDropdown(e);
                        }}
                        name="department"
                        options={departmentsDropdown}
                        className="select2-selection"
                        defaultValue={selectedDepartment} //{{{abel : "Allied - Physiotherapist", value : 704 }}
                        isMulti={true}
                        // defaultInputValue={validation.values.client_type_name}
                      />
                      </div>
                    </Row>
                  </Col>
                
                    <Row>
                      <Col>
                        <div className="text-start">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                }
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(PackageReport);