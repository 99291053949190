import {
    GET_CLIENT_TYPES,
    GET_CLIENT_TYPES_SUCCESS,
    GET_CLIENT_TYPES_FAIL,
    GET_CLIENT_PACKAGE_TYPES,
    GET_CLIENT_PACKAGE_TYPES_SUCCESS,
    GET_CLIENT_PACKAGE_TYPES_FAIL,
    ADD_CLIENT_PACKAGE_TYPES,
    ADD_CLIENT_PACKAGE_TYPES_SUCCESS,
    ADD_CLIENT_PACKAGE_TYPES_FAIL,
    UPDATE_CLIENT_PACKAGE_TYPES,
    UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS,
    UPDATE_CLIENT_PACKAGE_TYPES_FAIL,
    ADD_CLIENT_TYPES,
    ADD_CLIENT_TYPES_SUCCESS,
    ADD_CLIENT_TYPES_FAIL,
    UPDATE_CLIENT_TYPES,
    UPDATE_CLIENT_TYPES_SUCCESS,
    UPDATE_CLIENT_TYPES_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    clientTypes: [],
    clientPackageTypes: [],
    clientTypesDropDown: [],
    error: {},
    loading: false,
  }
  
  const ClientTypesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_TYPES:
        return {
            ...state,
            clientTypes: [],
            
            loading: true
        }

        case GET_CLIENT_TYPES_SUCCESS:
        return {
            ...state,
            clientTypes: action.payload.data,
            
            loading: false
        }
  
        case GET_CLIENT_TYPES_FAIL:
        return {
            ...state,
            error: action.payload,
        }
  
        case GET_CLIENT_PACKAGE_TYPES:
        return {
            ...state,
            clientPackageTypes: [],
            clientTypesDropDown:[],
            loading: true
        }
        case GET_CLIENT_PACKAGE_TYPES_SUCCESS:
        return {
            ...state,
            clientPackageTypes: action.payload.data,
            clientTypesDropDown:action.payload.clientTypes,
            loading: false
        }
  
        case GET_CLIENT_PACKAGE_TYPES_FAIL:
        return {
            ...state,
            clientPackageTypes: [],
            error: action.payload.error,
        }

        case ADD_CLIENT_TYPES:
        return {
            ...state,
            //clientTypes: [],
            loading: true
        }

        case ADD_CLIENT_TYPES_SUCCESS:
        return {
            ...state,
            clientTypes: [...state.clientTypes, action.payload.data],
            loading: false
        }
  
        case ADD_CLIENT_TYPES_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_CLIENT_TYPES:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_CLIENT_TYPES_SUCCESS:
        return {
            ...state,
            clientTypes: state.clientTypes.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        };
  
        case UPDATE_CLIENT_TYPES_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_CLIENT_PACKAGE_TYPES:
        return {
            ...state,
            loading: true
        };

        case ADD_CLIENT_PACKAGE_TYPES_SUCCESS:
        return {
            ...state,
            clientPackageTypes: [...state.clientPackageTypes, action.payload.data],
            loading: false
        }

        case ADD_CLIENT_PACKAGE_TYPES_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        }
        case UPDATE_CLIENT_PACKAGE_TYPES:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS:
        return {
            ...state,
            clientPackageTypes: state.clientPackageTypes.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        };
  
        case UPDATE_CLIENT_PACKAGE_TYPES_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
        

        default:
            return state
        }
  }
  
  export default ClientTypesReducer
  