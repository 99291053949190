import {
    GET_CLIENT_TYPES,
    GET_CLIENT_TYPES_SUCCESS,
    GET_CLIENT_TYPES_FAIL,
    GET_CLIENT_PACKAGE_TYPES,
    GET_CLIENT_PACKAGE_TYPES_SUCCESS,
    GET_CLIENT_PACKAGE_TYPES_FAIL,
    ADD_CLIENT_PACKAGE_TYPES,
    ADD_CLIENT_PACKAGE_TYPES_SUCCESS,
    ADD_CLIENT_PACKAGE_TYPES_FAIL,
    UPDATE_CLIENT_PACKAGE_TYPES,
    UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS,
    UPDATE_CLIENT_PACKAGE_TYPES_FAIL,
    ADD_CLIENT_TYPES,
    ADD_CLIENT_TYPES_SUCCESS,
    ADD_CLIENT_TYPES_FAIL,
    UPDATE_CLIENT_TYPES,
    UPDATE_CLIENT_TYPES_SUCCESS,
    UPDATE_CLIENT_TYPES_FAIL
  } from "./actionTypes"
  
  export const getClientTypes = (params,types) => ({
    type: GET_CLIENT_TYPES,
    params,
    types,
  })
  
  export const getClientTypesSuccess = data => ({
    type: GET_CLIENT_TYPES_SUCCESS,
    payload: data,
  })
  
  export const getClientTypesFail = error => ({
    type: GET_CLIENT_TYPES_FAIL,
    payload: error,
  })

  export const getClientPackageTypes = (params,types) => ({
    type: GET_CLIENT_PACKAGE_TYPES,
    params,
    types,
  })
  
  export const getClientPackageTypesSuccess = data => ({
    type: GET_CLIENT_PACKAGE_TYPES_SUCCESS,
    payload: data,
  })
  
  export const getClientPackageTypesFail = error => ({
    type: GET_CLIENT_PACKAGE_TYPES_FAIL,
    payload: error,
  })

  export const addClientTypes = (params,types) => ({
    type: ADD_CLIENT_TYPES,
    payload: params,
    types
  })

  export const addClientTypesSuccess = data => ({
    type: ADD_CLIENT_TYPES_SUCCESS,
    payload: data,
  })

  export const addClientTypesFail = error => ({
    type: ADD_CLIENT_TYPES_FAIL,
    payload: error,
  })

  export const addClientPackageTypes = (params,types) => ({
    type: ADD_CLIENT_PACKAGE_TYPES,
    payload: params,
    types
  })

  export const addClientPackageTypesSuccess = data => ({
    type: ADD_CLIENT_PACKAGE_TYPES_SUCCESS,
    payload: data,
  })

  export const addClientPackageTypesFail = error => ({
    type: ADD_CLIENT_PACKAGE_TYPES_FAIL,
    payload: error,
  })

  export const updateClientTypes = (params,types) => ({
    type: UPDATE_CLIENT_TYPES,
    payload: params,
    types
  })

  export const updateClientTypesSuccess = data => ({
    type: UPDATE_CLIENT_TYPES_SUCCESS,
    payload: data,
  })

  export const updateClientTypesFail = error => ({
    type: UPDATE_CLIENT_TYPES_FAIL,
    payload: error,
  })

  export const updateClientPackageTypes = (params,types) => ({
    type: UPDATE_CLIENT_PACKAGE_TYPES,
    payload: params,
    types
  })

  export const updateClientPackageTypesSuccess = data => ({
    type: UPDATE_CLIENT_PACKAGE_TYPES_SUCCESS,
    payload: data,
  })

  export const updateClientPackageTypesFail = error => ({
    type: UPDATE_CLIENT_PACKAGE_TYPES_FAIL,
    payload: error,
  })
  

//   export const getNewsFeedsViewed = (params,types) => ({
//     type: GET_NEWS_FEEDS_VIEWED,
//     payload: params,
//     types
//   })
  
//   export const getNewsFeedsViewedSuccess = data => ({
//     type: GET_NEWS_FEEDS_VIEWED_SUCCESS,
//     payload: data,
//   })
  
//   export const getNewsFeedsViewedFail = error => ({
//     type: GET_NEWS_FEEDS_VIEWED_FAIL,
//     payload: error,
//   })

//   export const getStaffsRosters = (params,types) => ({
//     type: GET_STAFFS_ROSTERS,
//     params,
//     types
//   })

//   export const getStaffsRostersSuccess = data => ({
//     type: GET_STAFFS_ROSTERS_SUCCESS,
//     payload: data,
//   })

//   export const getStaffsRostersFail = error => ({
//     type: GET_STAFFS_ROSTERS_FAIL,
//     payload: error,
//   })


