import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, NO_CLOCKIN_ROSTERS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

import { Name, Status } from "components/Common/TableColumns"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
// import RosterDetailsModal from "components/Forms/RostersDetailsModal"

function NoClockInAllRostersArchived() {
  //meta title
  document.title="No Clockin Rosters Archived | Tacveiha Panel";
  
  
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const dispatch = useDispatch();
    const [roster, setRoster] = useState([]);
    const [ModalLoading, setModalLoading] = useState(false);
    const [TableData, setTableData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [RosterNotesList, setRosterNotesList] = useState([]);

    //roster notes section
    const [RosterNotes, setRosterNotes] = useState('');
    const [addNotesModal, setAddNotesModal] = useState(false);

    useEffect(() => {

        setModalLoading(true);

        axios.post(APP_URL+ NO_CLOCKIN_ROSTERS_PAGE,{'data_for': 'load_no_clock_in_rosters_archived','user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
              setTableData(fetchedData.data)
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

    },[dispatch])

    const columns = useMemo(
      () => [
        {
          Header: "#",
          accessor: "id",
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
            Header: "Roster ID",
            accessor: "roster_id",
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
        {
            Header: "Date & Time",
            accessor: "date_time",
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
        {
          Header: "Client Name",
          accessor: "client_name",
          //disableFilters: true, 
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Staff Name",
          accessor: "staff_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Roster Time",
          accessor: "roster_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Client Contact No",
          accessor: "client_number",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
            Header: "Staff Contact No",
            accessor: "staff_number",
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
        {
          Header: "Roster Status",
          accessor: "status",
          filterable: true,
          Cell: cellProps => {
            return <Status {...cellProps} />;
          },
        },
      ],
      []
  )

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  return (
    <React.Fragment>
      { ModalLoading ? <TopProgressBar loading/> : '' }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="No Clockin Rostes Archived" />

          { ModalLoading ? <Spinners setLoading={setLoading} />
            :
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">

                                <TableContainer
                                    columns={columns}
                                    data={TableData}
                                    isGlobalFilter={true}
                                    isDateSelecteOptions={true}
                                    showSearchBtn={true}
                                    customPageSize={25}
                                    className="custom-header-css"
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );


}

export default NoClockInAllRostersArchived;