import {
    GET_SMS_STAFFS_DROPDOWN,
    GET_SMS_STAFFS_DROPDOWN_SUCCESS,
    GET_SMS_STAFFS_DROPDOWN_FAIL,
    SEND_SMS,
    SEND_SMS_SUCCESS,
    SEND_SMS_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    staffsDropdown: [],
    smsDetails:[],
    error: {},
    loading: false,
  }
  
  const SendSmsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SMS_STAFFS_DROPDOWN:
        return {
            ...state,
            staffsDropdown : [],
            loading: true
        }

        case GET_SMS_STAFFS_DROPDOWN_SUCCESS:
        return {
            ...state,
            staffsDropdown: action.payload.data,
            loading: false
        }
  
        case GET_SMS_STAFFS_DROPDOWN_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case SEND_SMS:
        return {
            ...state,
            loading: true
        };
  
        case SEND_SMS_SUCCESS:
        return {
            ...state,
            smsDetails: action.payload.data,
            loading: false
        };
  
        case SEND_SMS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
        default:
            return state
        }
  }
  
  export default SendSmsReducer
  