import {
    GET_CHECKLIST_QUESTIONS,
    GET_CHECKLIST_QUESTIONS_SUCCESS,
    GET_CHECKLIST_QUESTIONS_FAIL,
    UPDATE_CHECKLIST_QUESTIONS,
    UPDATE_CHECKLIST_QUESTIONS_SUCCESS,
    UPDATE_CHECKLIST_QUESTIONS_FAIL,
  } from "./actionTypes"
  
  export const getChecklistQuestions = (params,types) => ({
    type: GET_CHECKLIST_QUESTIONS,
    params,
    types,
  })
  
  export const getChecklistQuestionsSuccess = data => ({
    type: GET_CHECKLIST_QUESTIONS_SUCCESS,
    payload: data,
  })
  
  export const getChecklistQuestionsFail = error => ({
    type: GET_CHECKLIST_QUESTIONS_FAIL,
    payload: error,
  })

  export const updateChecklistQuestions = (params,types) => ({
    type: UPDATE_CHECKLIST_QUESTIONS,
    payload: params,
    types
  })

  export const updateChecklistQuestionsSuccess = data => ({
    type: UPDATE_CHECKLIST_QUESTIONS_SUCCESS,
    payload: data,
  })

  export const updateChecklistQuestionsFail = error => ({
    type: UPDATE_CHECKLIST_QUESTIONS_FAIL,
    payload: error,
  })