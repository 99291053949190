import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONTRACTORS, GET_CONTRACTORS_DOCUMENTS, ADD_CONTRACTOR_DOCUMENTS, UPDATE_CONTRACTOR_DOCUMENTS } from "./actionTypes"
import {
  getContractorsFail,
  getContractorsSuccess,
  getContractorsDocumentsSuccess,
  getContractorsDocumentsFail,
  addContractorDocumentsSuccess,
  addContractorDocumentsFail,
  updateContractorDocumentsSuccess,
  updateContractorDocumentsFail

  
} from "./actions"

//Include Both Helper File with needed methods
import { contractorsPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchContractors({params,types}) {
  try {
    const response = yield call(contractorsPage, params, types )
    console.log(response);
    yield put(getContractorsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getContractorsFail(error))
  }
}

function* fetchContractorsDocuments({params,types}) {
  try {
    const response = yield call(contractorsPage, params, types )
    console.log(response);
    yield put(getContractorsDocumentsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getContractorsDocumentsFail(error))
  }
}

// function* fetchCasemanagerClients({ payload: data }) {
//   try {
//     const response = yield call(actionUrl,data)
//     console.log(response);
//     yield put(getCasemanagersClientsSuccess(response))
//   } catch (error) {
//     console.log( 'error is'+ error );
//     yield put(getCasemanagersClientsFail(error))
//   }
// }

function* addContractorsDocuments({ payload: data }) {
  try {
      const response = yield call(contractorsPage, data)
      yield put(addContractorDocumentsSuccess(response))
      toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(addContractorDocumentsFail(error))
      toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

function* updateContractorsDocuments({ payload: data }) {
  try {
      const response = yield call(contractorsPage, data)
      yield put(updateContractorDocumentsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateContractorDocumentsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* contractorsSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_CONTRACTORS, fetchContractors)
  yield takeEvery(GET_CONTRACTORS_DOCUMENTS, fetchContractorsDocuments)
  // yield takeEvery(GET_CASEMANAGERS_CLIENTS, fetchCasemanagerClients)
  yield takeEvery(ADD_CONTRACTOR_DOCUMENTS, addContractorsDocuments)
  yield takeEvery(UPDATE_CONTRACTOR_DOCUMENTS, updateContractorsDocuments)
}

export default contractorsSaga
