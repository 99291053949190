/* Rosters */
export const GET_ROSTER_REPORT = "GET_ROSTER_REPORT"
export const GET_ROSTER_REPORT_SUCCESS = "GET_ROSTER_REPORT_SUCCESS"
export const GET_ROSTER_REPORT_FAIL = "GET_ROSTER_REPORT_FAIL"

export const GET_ROSTER_REPORT_WITH_NOTES = "GET_ROSTER_REPORT_WITH_NOTES"
export const GET_ROSTER_REPORT_WITH_NOTES_SUCCESS = "GET_ROSTER_REPORT_WITH_NOTES_SUCCESS"
export const GET_ROSTER_REPORT_WITH_NOTES_FAIL = "GET_ROSTER_REPORT_WITH_NOTES_FAIL"

export const GET_CHECKLIST_REPORT = "GET_CHECKLIST_REPORT"
export const GET_CHECKLIST_REPORT_SUCCESS = "GET_CHECKLIST_REPORT_SUCCESS"
export const GET_CHECKLIST_REPORT_FAIL = "GET_CHECKLIST_REPORT_FAIL"

export const GET_TWO_HR_ROSTER_REPORT = "GET_TWO_HR_ROSTER_REPORT"
export const GET_TWO_HR_ROSTER_REPORT_SUCCESS = "GET_TWO_HR_ROSTER_REPORT_SUCCESS"
export const GET_TWO_HR_ROSTER_REPORT_FAIL = "GET_TWO_HR_ROSTER_REPORT_FAIL"

export const GET_GROUP_ROSTER_REPORT = "GET_GROUP_ROSTER_REPORT"
export const GET_GROUP_ROSTER_REPORT_SUCCESS = "GET_GROUP_ROSTER_REPORT_SUCCESS"
export const GET_GROUP_ROSTER_REPORT_FAIL = "GET_GROUP_ROSTER_REPORT_FAIL"

export const GET_BUDDY_REPORT = "GET_BUDDY_REPORT"
export const GET_BUDDY_REPORT_SUCCESS = "GET_BUDDY_REPORT_SUCCESS"
export const GET_BUDDY_REPORT_FAIL = "GET_BUDDY_REPORT_FAIL"

export const GET_SUPPORT_WORKER_DOC_HR_REPORT = "GET_SUPPORT_WORKER_DOC_HR_REPORT"
export const GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS = "GET_SUPPORT_WORKER_DOC_HR_REPORT_SUCCESS"
export const GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL = "GET_SUPPORT_WORKER_DOC_HR_REPORT_FAIL"

export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

export const GET_CLIENT_DEPARTMENTS = "GET_CLIENT_DEPARTMENTS"
export const GET_CLIENT_DEPARTMENTS_SUCCESS = "GET_CLIENT_DEPARTMENTS_SUCCESS"
export const GET_CLIENT_DEPARTMENTS_FAIL = "GET_CLIENT_DEPARTMENTS_FAIL"

export const GET_DEPARTMENTS_CLIENTS = "GET_DEPARTMENTS_CLIENTS"
export const GET_DEPARTMENTS_CLIENTS_SUCCESS = "GET_DEPARTMENTS_CLIENTS_SUCCESS"
export const GET_DEPARTMENTS_CLIENTS_FAIL = "GET_DEPARTMENTS_CLIENTS_FAIL"

