import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

import { getRosters as onGetRosters } from "store/actions";

import {Name, Email, Tags, Projects, Status } from "./AllRostersCol";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"

function AllRosters () {
  //meta title
  document.title="All Rosters | Tacveiha Panel";


  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const dispatch = useDispatch();
  const [roster, setRoster] = useState();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  
  const selectAllRostersState = (state) => state.RostersReducer;
    const RostersRostersProperties = createSelector(
        selectAllRostersState,
        (rosterReducer) => ({
            RostersTable: rosterReducer.rostersTable,
            hiddenColumns: rosterReducer.hiddenColumns,
            loading: rosterReducer.loading
        })
    );
    const {
      RostersTable, hiddenColumns, loading
    } = useSelector(RostersRostersProperties);
    
    const [isLoading, setLoading] = useState(loading);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const columns = useMemo(
      () => [
        {
          Header: "#",
          accessor: "ros_id",
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Clietn Name",
          accessor: "client_name",
          //disableFilters: true, 
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Staff Name",
          accessor: "staff_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Roster Date",
          accessor: "ros_date",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Pay Category External ID",
          accessor: "pay_cat",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Start Time",
          accessor: "start_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Finish Time",
          accessor: "finish_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Recurring Type",
          accessor: "recurring_type",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Suburb",
          accessor: "suburb",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Client Department",
          accessor: "client_department",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          filterable: true,
          Cell: cellProps => {
            return <Status {...cellProps} />;
          },
        },
        {
          Header: "Roster Type",
          accessor: "roster_type",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Group Roster",
          accessor: "group_roster_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        // {
        //   Header: "Action",
        //   Cell: cellProps => {
        //     return (
        //       <div className="d-flex gap-3">
        //         <Link
        //           to="#"
        //           className="text-success"
        //           onClick={() => {
        //             const userData = cellProps.row.original;
        //             handleUserClick(userData);
        //           }}
        //         >
        //           <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
        //           <UncontrolledTooltip placement="top" target="edittooltip">
        //             Edit
        //           </UncontrolledTooltip>
        //         </Link>
        //         <Link
        //           to="#"
        //           className="text-danger"
        //           onClick={() => {
        //             const userData = cellProps.row.original;
        //             onClickDelete(userData);
        //           }}
        //         >
        //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
        //           <UncontrolledTooltip placement="top" target="deletetooltip">
        //             Delete
        //           </UncontrolledTooltip>
        //         </Link>
        //       </div>
        //     );
        //   },
        // },
      ],
      []
    );


  // useEffect(() => {
  //   // dispatch(onSetLoading(true));
  // }, [dispatch]);

  // console.log(allRosters.allRosters);

  // useEffect(() => {
  //   setRoster(roster);
  //   setIsEdit(false);
  // }, [roster]);

  // useEffect(() => {
  //   if (!isEmpty(roster) && !!isEdit) {
  //     setRoster(roster);
  //     setIsEdit(false);
  //   }
  // }, [roster]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setRoster({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  const handleSearchBtn = () => {
    dispatch(onGetRosters({'fromdate': fromDate, 'todate': toDate,'ros_status' : 'all','user': loggedUser}));
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleUserClicks = () => {
    setRostersList("");
    setIsEdit(false);
    toggle();
  };

  const handleFromDate = value => {
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  return (
    <React.Fragment>
      { loading ? <TopProgressBar loading/> : '' }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Rosters" breadcrumbItem="All Rosters" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          { loading ? <Spinners setLoading={setLoading} />
            :
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">

                                <TableContainer
                                    columns={columns}
                                    data={RostersTable? RostersTable : []}
                                    isGlobalFilter={true}
                                    isDateSelecteOptions={true}
                                    showSearchBtn={true}
                                    handleUserClick={handleUserClicks}
                                    customPageSize={25}
                                    excelExport={true}
                                    fileName={'AllRosters'}
                                    className="custom-header-css"
                                    columnsHide={hiddenColumns}
                                    bgcolor={'broadcast_roster'}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );


}

export default AllRosters;