import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ALERTS, GET_ALERT_ROSTER_DETAILS, UPDATE_ALERT_ROSTER_DETAILS } from "./actionTypes"
import {
  getAlertsSuccess,
  getAlertsFail,
  getAlertRosterDetailsSuccess,
  getAlertRosterDetailsFail,
  updateAlertRosterDetailsSuccess,
  updateAlertRosterDetailsFail
} from "./actions"

//Include Both Helper File with needed methods
import { alertsPage, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchAlertRosters({params,types}) {
  try {
    const response = yield call(alertsPage, params, types )
    yield put(getAlertsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getAlertsFail(error))
  }
}

function* fetchAlertRosterDetails({params,types}) {
  try {
    const response = yield call(alertsPage, params, types )
    console.log(response);
    yield put(getAlertRosterDetailsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getAlertRosterDetailsFail(error))
  }
}

function* updateRosterDetails({params,types}) {
  try {
      const response = yield call(alertsPage, params, types)
      yield put(updateAlertRosterDetailsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateAlertRosterDetailsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* AlertRosterSaga() {
  yield takeEvery(GET_ALERTS, fetchAlertRosters)
  yield takeEvery(GET_ALERT_ROSTER_DETAILS, fetchAlertRosterDetails)
  yield takeEvery(UPDATE_ALERT_ROSTER_DETAILS, updateRosterDetails)
}

export default AlertRosterSaga
