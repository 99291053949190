/* Rosters */
export const GET_HR_DOCUMENT_NAME_LIST = "GET_HR_DOCUMENT_NAME_LIST"
export const GET_HR_DOCUMENT_NAME_LIST_SUCCESS = "GET_HR_DOCUMENT_NAME_LIST_SUCCESS"
export const GET_HR_DOCUMENT_NAME_LIST_FAIL = "GET_HR_DOCUMENT_NAME_LIST_FAIL"

export const UPDATE_HR_DOCUMENT_NAME_LIST = "UPDATE_HR_DOCUMENT_NAME_LIST"
export const UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS = "UPDATE_HR_DOCUMENT_NAME_LIST_SUCCESS"
export const UPDATE_HR_DOCUMENT_NAME_LIST_FAIL = "UPDATE_HR_DOCUMENT_NAME_LIST_FAIL"

export const ADD_HR_DOCUMENT_NAME_LIST = "ADD_HR_DOCUMENT_NAME_LIST"
export const ADD_HR_DOCUMENT_NAME_LIST_SUCCESS = "ADD_HR_DOCUMENT_NAME_LIST_SUCCESS"
export const ADD_HR_DOCUMENT_NAME_LIST_FAIL = "Add_HR_DOCUMENT_NAME_LIST_FAIL"

