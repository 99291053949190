/* Rosters */
export const GET_ALL_ROSTERS = "GET_ALL_ROSTERS"
export const GET_ALL_ROSTERS_SUCCESS = "GET_ALL_ROSTERS_SUCCESS"
export const GET_ALL_ROSTERS_FAIL = "GET_ALL_ROSTERS_FAIL"


export const GET_TODAYS_ROSTERS = "GET_TODAYS_ROSTERS"
export const GET_TODAYS_ROSTERS_SUCCESS = "GET_TODAYS_ROSTERS_SUCCESS"
export const GET_TODAYS_ROSTERS_FAIL = "GET_TODAYS_ROSTERS_FAIL"

export const GET_ROSTER_DETAIL = "GET_ROSTERS_DETAIL"
export const GET_ROSTER_DETAIL_SUCCESS = "GET_ROSTERS_DETAIL_SUCCESS"
export const GET_ROSTER_DETAIL_FAIL = "GET_ROSTERS_DETAIL_FAIL"

export const GET_GROUP_ROSTERS = "GET_GROUP_ROSTERS"
export const GET_GROUP_ROSTERS_SUCCESS = "GET_GROUP_ROSTERS_SUCCESS"
export const GET_GROUP_ROSTERS_FAIL = "GET_GROUP_ROSTERS_FAIL"


export const ADD_GROUP_ROSTER = "ADD_GROUP_ROSTER"
export const ADD_GROUP_ROSTER_SUCCESS = "ADD_GROUP_ROSTER_SUCCESS"
export const ADD_GROUP_ROSTER_FAIL = "ADD_GROUP_ROSTER_FAIL"

export const UPDATE_GROUP_ROSTER = "UPDATE_GROUP_ROSTER"
export const UPDATE_GROUP_ROSTER_SUCCESS = "UPDATE_GROUP_ROSTER_SUCCESS"
export const UPDATE_GROUP_ROSTER_FAIL = "UPDATE_GROUP_ROSTER_FAIL"


export const ADD_ROSTERS_DATA = "ADD_ROSTERS_DATA"
export const ADD_ROSTERS_DATA_SUCCESS = "ADD_ROSTERS_DATA_SUCCESS"
export const ADD_ROSTERS_DATA_FAIL = "ADD_ROSTERS_DATA_FAIL"
