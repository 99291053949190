import {
    GET_PAYCATEGORY_LIST,
    GET_PAYCATEGORY_LIST_SUCCESS,
    GET_PAYCATEGORY_LIST_FAIL,
    UPDATE_PAYCATEGORY_LIST,
    UPDATE_PAYCATEGORY_LIST_SUCCESS,
    UPDATE_PAYCATEGORY_LIST_FAIL,
    ADD_PAYCATEGORY,
    ADD_PAYCATEGORY_SUCCESS,
    ADD_PAYCATEGORY_FAIL
  } from "./actionTypes"
  
  export const getPayCategoryList = (params,types) => ({
    type: GET_PAYCATEGORY_LIST,
    params,
    types,
  })
  
  export const getPayCategoryListSuccess = data => ({
    type: GET_PAYCATEGORY_LIST_SUCCESS,
    payload: data,
  })
  
  export const getPayCategoryListFail = error => ({
    type: GET_PAYCATEGORY_LIST_FAIL,
    payload: error,
  })

  export const updatePayCategoryList = (params,types) => ({
    type: UPDATE_PAYCATEGORY_LIST,
    payload: params,
    types
  })

  export const updatePayCategoryListSuccess = data => ({
    type: UPDATE_PAYCATEGORY_LIST_SUCCESS,
    payload: data,
  })

  export const updatePayCategoryListFail = error => ({
    type: UPDATE_PAYCATEGORY_LIST_FAIL,
    payload: error,
  })

  export const addPayCategory = (params,types) => ({
    type: ADD_PAYCATEGORY,
    payload: params,
    types
  })

  export const addPayCategorySuccess = data => ({
    type: ADD_PAYCATEGORY_SUCCESS,
    payload: data,
  })

  export const addPayCategoryFail = error => ({
    type: ADD_PAYCATEGORY_FAIL,
    payload: error,
  })