import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { ACTION_URL, ADMIN_URL, APP_URL, SITE_URL, POST_LOGIN } from "helpers/url_helper"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import TopProgressBar from "components/Common/TopProgressBar"

const Login = props => {

  //meta title
  document.title = "Login | Tacveiha Panel";

  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [TopLoading, setTopLoading] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(true);
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [OTPCode, setOTPCode] = useState('');
  const [TmpUser, setTmpUser] = useState('');
  const [TmpOtpCode, setTmpOtpCode] = useState('');

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {

      const formData = {
        admin_email: values.email,
        admin_pass: values.password
      }

      setTopLoading(true);
        axios.post(APP_URL+POST_LOGIN,{'data_for':'web_login','formData': formData})
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;
            // console.log(fetchedData);
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });

                if( fetchedData.otp_show == 1 ) {
                  setShowLoginPage(false);
                  setShowOtpPage(true);
                  setTmpUser(fetchedData.temp_user);
                  setTmpOtpCode(fetchedData.otp_code);
                } else {
                  localStorage.setItem("authUser", JSON.stringify(fetchedData.authUser));
                  localStorage.setItem('alerts_count',fetchedData.alerts_count.alert_count)
                  localStorage.setItem('admin_alerts_count',fetchedData.alerts_count.admin_alerts)
                  localStorage.setItem('caremanager_alerts_count',fetchedData.alerts_count.care_management_alerts)
                  localStorage.setItem('service_manager_alerts_count',fetchedData.alerts_count.service_manager_alerts)
                  localStorage.setItem('emergency_alerts_count',fetchedData.alerts_count.emergency_alerts)
                  localStorage.setItem('broadcast_alerts_count',fetchedData.alerts_count.broadcast_alerts)
                  localStorage.setItem('leave_approved_count',fetchedData.alerts_count.leave_alert_approved_count)
                  localStorage.setItem('leave_unapproved_count',fetchedData.alerts_count.leave_alert_count)
                  localStorage.setItem('client_away_alerts_count',fetchedData.alerts_count.client_leave_alert_count)
                  localStorage.setItem('checklist_alerts_count',fetchedData.alerts_count.checklist_alerts)
                  localStorage.setItem('incidents_count',fetchedData.alerts_count.incident_alert_count)
                  localStorage.setItem('recruitment_count',fetchedData.alerts_count.recruitment_count)
                  localStorage.setItem('recruitment_employed_count',fetchedData.alerts_count.recruitment_employed_count)
                  localStorage.setItem('feedback_count',fetchedData.alerts_count.feedback_count)
                  localStorage.setItem('no_clockin_alerts_count',fetchedData.alerts_count.no_clockin_rosters_count)
                  localStorage.setItem('completed_rosters_alerts_count',fetchedData.alerts_count.completed_rosters_alerts)
                  props.router.navigate('/dashboard')
                  // if( fetchedData.user_role == 'admin' ) {
                  //   props.router.navigate('/dashboard')
                  // }
                  
                }

                
                
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

      // dispatch(loginUser(values, props.router.navigate));

    }
  });
  

  const verifyOTP = () => {

    if( TmpOtpCode != OTPCode ) {
      toast.error('Please enter valid OTP.', { autoClose: 5000 });
      return;
    }

    const formData = {
      tmp_user: TmpUser,
      otp_code: OTPCode,
      otp_required: TmpOtpCode
    }

    setTopLoading(true);
      axios.post(APP_URL+POST_LOGIN,{'data_for':'otp_verify','formData': formData})
      .then(res => {
          setTopLoading(false);
          const fetchedData = res.data;
          // console.log(fetchedData);
  
          if( fetchedData.status == 'success' ) {
              setOTPCode('')
              setTmpUser('')
              setTmpOtpCode('')
              toast.success(fetchedData.html, { autoClose: 5000 });

              localStorage.setItem("authUser", JSON.stringify(fetchedData.authUser));
              props.router.navigate('/dashboard')
              // if( fetchedData.user_role == 'admin' ) {
              //   props.router.navigate('/dashboard')
              // }
                
          }
  
          if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
          }
      })


  }


  const selectLoginState = (state) => state.Login;
    const LoginProperties = createSelector(
      selectLoginState,
        (login) => ({
          error: login.error          
        })
    );

    const {
      error
  } = useSelector(LoginProperties);

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      {
        TopLoading ? <TopProgressBar loading/>
        : ''
      }
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                  <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Tacveiha Panel.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    { showLoginPage && 
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={TopLoading}
                          >
                            Log In
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div> */}
                      </Form>

                    }

                    { showOtpPage && 
                    <>
                      <div className="mb-3">
                        <Label className="form-label">Enter OTP</Label>
                        <Input
                          name="otp"
                          value={OTPCode}
                          type="text"
                          placeholder="Enter OTP"
                          onChange={(e) => setOTPCode(e.target.value)}
                          onBlur={validation.handleBlur}
                          
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="btn"
                          onClick={verifyOTP}
                          disabled={TopLoading}
                        >
                          Verify OTP
                        </button>
                      </div>
                    </>
                    }

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                
                <p>
                  © {new Date().getFullYear()} Tacveiha Panel.
                  <i className="mdi mdi-heart text-danger" /> Created By DigitSoft
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
