import {
    GET_CHECKLIST_QUESTIONS,
    GET_CHECKLIST_QUESTIONS_SUCCESS,
    GET_CHECKLIST_QUESTIONS_FAIL,
    UPDATE_CHECKLIST_QUESTIONS,
    UPDATE_CHECKLIST_QUESTIONS_SUCCESS,
    UPDATE_CHECKLIST_QUESTIONS_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    checklistQuestions: [],
    error: {},
    loading: false,
  }
  
  const ChecklistQuestionsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHECKLIST_QUESTIONS:
        return {
            ...state,
            //settings: state.settings,
            loading: true
        }

        case GET_CHECKLIST_QUESTIONS_SUCCESS:
        return {
            ...state,
            checklistQuestions: action.payload.data,
            loading: false
        }
  
        case GET_CHECKLIST_QUESTIONS_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_CHECKLIST_QUESTIONS:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_CHECKLIST_QUESTIONS_SUCCESS:
        return {
            ...state,
            checklistQuestions: state.checklistQuestions.map(rosters =>
                rosters.id.toString() === action.payload.data.id.toString()
                        ? { rosters, ...action.payload.data }
                        : rosters
            ),
            loading: false
        };
  
        case UPDATE_CHECKLIST_QUESTIONS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        // case ADD_VACCINATION_DETAILS:
        // return {
        //     ...state,
        //     loading: true
        // };

        // case ADD_VACCINATION_DETAILS_SUCCESS:
        // return {
        //     ...state,
        //     vaccinationDetails: [...state.vaccinationDetails, action.payload.data],
        //     loading: false
        // }

        // case ADD_VACCINATION_DETAILS_FAIL:
        // return {
        //     ...state,
        //     error: action.payload.error,
        //     loading: false,
        // }
        default:
            return state
        }
  }
  
  export default ChecklistQuestionsReducer
  