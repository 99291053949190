import {
  GET_QUESTIONS,
  GET_QUESTIONS_FAIL,
  GET_QUESTIONS_SUCCESS,

  ADD_QUESTION,
  UPDATE_QUESTION,
  
  GET_EMPLOYEDS,
  GET_EMPLOYEDS_FAIL,
  GET_EMPLOYEDS_SUCCESS,
  GET_CANDIDATES,
  GET_CANDIDATES_FAIL,
  GET_CANDIDATES_SUCCESS,

  ADD_CANDIDATE,
  UPDATE_CANDIDATE,
  FILTER_CANDIDATES,

  } from "./actionTypes"
  
  const INIT_STATE = {
    candidates: [],
    employeds: [],
    questions: [],
    error: {},
    loading: false,
  }
  
  const RecruitmentsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_QUESTIONS:

        return {
          ...state,
          questions: state.questions,
          loading: true
       }
      case GET_QUESTIONS_SUCCESS:

        return {
          ...state,
          questions: action.payload.data,
          loading: false
       }
  
      case GET_QUESTIONS_FAIL:

      return {
        ...state,
        error: action.payload,
      }

      case GET_EMPLOYEDS:

        return {
          ...state,
          employeds: state.employeds,
          loading: true
       }
      case GET_EMPLOYEDS_SUCCESS:

        return {
          ...state,
          employeds: action.payload.data,
          loading: false
       }
  
      case GET_EMPLOYEDS_FAIL:

      return {
        ...state,
        error: action.payload,
      }

      case GET_CANDIDATES:

        return {
          ...state,
          candidates: state.candidates,
          questions: state.questions,
          loading: true
       }
      case GET_CANDIDATES_SUCCESS:

        return {
          ...state,
          candidates: action.payload.data,
          questions: action.payload.questions,
          loading: false
       }
  
      case GET_CANDIDATES_FAIL:

      return {
        ...state,
        error: action.payload,
      }

      case ADD_QUESTION:
        return {
          ...state,
          questions: [...state.questions, action.payload],
          loading: false
      }
  
      case UPDATE_QUESTION:
        return {
          ...state,
          questions: state.questions.map(rosters =>
            rosters.rtid.toString() === action.payload.rtid.toString()
                    ? { rosters, ...action.payload }
                    : rosters
            ),
          loading: false
      }

      case ADD_CANDIDATE:
        return {
          ...state,
          candidates: [...state.candidates, action.payload],
          loading: false
      }
  
      case UPDATE_CANDIDATE:
        return {
          ...state,
          candidates: state.candidates.map(rosters =>
            rosters.rtid.toString() === action.payload.rtid.toString()
                    ? { rosters, ...action.payload }
                    : rosters
            ),
          loading: false
      }
      
      case FILTER_CANDIDATES:
        return {
          ...state,
          candidates: state.candidates.filter(items =>
            items.status == action.payload
                    ? { items, ...action.payload }
                    : items
            ),
          loading: false
      }
  
        default:
          return state
    }
  }
  
  export default RecruitmentsReducer
  