import {
    GET_CLIENTS,
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_FAILED,
    GET_ARCHIVED_CLIENTS,
    GET_ARCHIVED_CLIENTS_SUCCESS,
    GET_ARCHIVED_CLIENTS_FAILED,
    GET_CLIENTS_ROSTERS,
    GET_CLIENTS_ROSTERS_SUCCESS,
    GET_CLIENTS_ROSTERS_FAIL,
    GET_CLIENTS_ROSTERS_DETAIL,
    GET_CLIENTS_ROSTERS_DETAIL_FAIL,
    GET_CLIENTS_ROSTERS_DETAIL_SUCCESS,
    GET_CLIENTS_SERVICES_TYPES,
    GET_CLIENTS_SERVICES_TYPES_SUCCESS,
    GET_CLIENTS_SERVICES_TYPES_FAIL,
    GET_CLIENTS_SERVICES_LIST,
    GET_CLIENTS_SERVICES_LIST_SUCCESS,
    GET_CLIENTS_SERVICES_LIST_FAIL,
    GET_CLIENTS_ISOLATION_LIST,
    GET_CLIENTS_ISOLATION_LIST_SUCCESS,
    GET_CLIENTS_ISOLATION_LIST_FAIL,
    GET_CLIENTS_DELETED_ROSTERS,
    GET_CLIENTS_DELETED_ROSTERS_SUCCESS,
    GET_CLIENTS_DELETED_ROSTERS_FAIL,
    GET_CLIENTS_NOTES,
    GET_CLIENTS_NOTES_SUCCESS,
    GET_CLIENTS_NOTES_FAIL,
    ADD_CLIENT_NOTES,
    ADD_CLIENT_NOTES_SUCCESS,
    ADD_CLIENT_NOTES_FAIL,

    UPDATE_CLIENT_NOTES,
    UPDATE_CLIENT_NOTES_SUCCESS,
    UPDATE_CLIENT_NOTES_FAIL,

    ADD_CLIENTS_ISOLATION_LIST,
    ADD_CLIENTS_ISOLATION_LIST_SUCCESS,
    ADD_CLIENTS_ISOLATION_LIST_FAIL,
    ADD_CLIENTS_SERVICES_LIST,
    ADD_CLIENTS_SERVICES_LIST_SUCCESS,
    ADD_CLIENTS_SERVICES_LIST_FAIL,
    ADD_CLIENTS_SERVICES_TYPES,
    ADD_CLIENTS_SERVICES_TYPES_SUCCESS,
    ADD_CLIENTS_SERVICES_TYPES_FAIL,

    UPDATE_CLIENTS_ISOLATION_LIST,
    UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS,
    UPDATE_CLIENTS_ISOLATION_LIST_FAIL,
    UPDATE_CLIENTS_SERVICES_LIST,
    UPDATE_CLIENTS_SERVICES_LIST_SUCCESS,
    UPDATE_CLIENTS_SERVICES_LIST_FAIL,
    UPDATE_CLIENTS_SERVICES_TYPES,
    UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS,
    UPDATE_CLIENTS_SERVICES_TYPES_FAIL
    
  } from "./actionTypes"
  
  export const getClients = (params,types) => ({
    type: GET_CLIENTS,
    params,
    types,
  })
  
  export const getClientsSuccess = clients => ({
    type: GET_CLIENTS_SUCCESS,
    payload: clients,
  })
  
  export const getClientsFail = error => ({
    type: GET_CLIENTS_FAILED,
    payload: error,
  })

  export const getArchivedClients = (params,types) => ({
    type: GET_ARCHIVED_CLIENTS,
    params,
    types,
  })
  
  export const getArchivedClientsSuccess = clients => ({
    type: GET_ARCHIVED_CLIENTS_SUCCESS,
    payload: clients,
  })
  
  export const getArchivedClientsFail = error => ({
    type: GET_ARCHIVED_CLIENTS_FAILED,
    payload: error,
  })

  export const getClientsRosters = (params,types) => ({
    type: GET_CLIENTS_ROSTERS,
    params,
    types
  })
  
  export const getClientsRostersSuccess = data => ({
    type: GET_CLIENTS_ROSTERS_SUCCESS,
    payload: data,
  })
  
  export const getClientsRostersFail = error => ({
    type: GET_CLIENTS_ROSTERS_FAIL,
    payload: error,
  })
  
  export const getClientsRostersDetail = (params, type) => ({
    type: GET_CLIENTS_ROSTERS_DETAIL,
    params,
    type
  })
  
  export const getClientsRostersDetailSuccess = data => ({
    type: GET_CLIENTS_ROSTERS_DETAIL_SUCCESS,
    payload: data,
  })
  
  export const getClientsRostersDetailFail = error => ({
    type: GET_CLIENTS_ROSTERS_DETAIL_FAIL,
    payload: error,
  })

  export const getClientsServicesList = (params,types) => ({
    type: GET_CLIENTS_SERVICES_LIST,
    params,
    types,
  })
  
  export const getClientsServicesListSuccess = data => ({
    type: GET_CLIENTS_SERVICES_LIST_SUCCESS,
    payload: data,
  })
  
  export const getClientsServicesListFail = error => ({
    type: GET_CLIENTS_SERVICES_LIST_FAIL,
    payload: error,
  })

  export const getClientsServicesTypes = (params,types) => ({
    type: GET_CLIENTS_SERVICES_TYPES,
    params,
    types,
  })
  
  export const getClientsServicesTypesSuccess = data => ({
    type: GET_CLIENTS_SERVICES_TYPES_SUCCESS,
    payload: data,
  })
  
  export const getClientsServicesTypesFail = error => ({
    type: GET_CLIENTS_SERVICES_TYPES_FAIL,
    payload: error,
  })
  
  export const getClientsIsolationList = (params,types) => ({
    type: GET_CLIENTS_ISOLATION_LIST,
    params,
    types,
  })
  
  export const getClientsIsolationListSuccess = data => ({
    type: GET_CLIENTS_ISOLATION_LIST_SUCCESS,
    payload: data,
  })
  
  export const getClientsIsolationListFail = error => ({
    type: GET_CLIENTS_ISOLATION_LIST_FAIL,
    payload: error,
  })

  export const getClientsDeletedRosters = (params,types) => ({
    type: GET_CLIENTS_DELETED_ROSTERS,
    params,
    types,
  })
  
  export const getClientsDeletedRostersSuccess = data => ({
    type: GET_CLIENTS_DELETED_ROSTERS_SUCCESS,
    payload: data,
  })
  
  export const getClientsDeletedRostersFail = error => ({
    type: GET_CLIENTS_DELETED_ROSTERS_FAIL,
    payload: error,
  })

  export const getClientsNotes = (params,types) => ({
    type: GET_CLIENTS_NOTES,
    params,
    types,
  })
  
  export const getClientsNotesSuccess = data => ({
    type: GET_CLIENTS_NOTES_SUCCESS,
    payload: data,
  })
  
  export const getClientsNotesFail = error => ({
    type: GET_CLIENTS_NOTES_FAIL,
    payload: error,
  })

  export const addClientNotes = (data_for,params,types) => ({
    type: ADD_CLIENT_NOTES,
    data_for: data_for,
    params,
    types,
  })
  
  export const addClientNotesSuccess = data => ({
    type: ADD_CLIENT_NOTES_SUCCESS,
    payload: data,
  })
  
  export const addClientNotesFail = error => ({
    type: ADD_CLIENT_NOTES_FAIL,
    payload: error,
  })


  export const updateClientNotes = (data_for,params,types) => ({
    type: UPDATE_CLIENT_NOTES,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateClientNotesSuccess = data => ({
    type: UPDATE_CLIENT_NOTES_SUCCESS,
    payload: data,
  })
  
  export const updateClientNotesFail = error => ({
    type: UPDATE_CLIENT_NOTES_FAIL,
    payload: error,
  })

  export const addClientsServicesTypes = (data_for,params,types) => ({
    type: ADD_CLIENTS_SERVICES_TYPES,
    data_for: data_for,
    params,
    types,
  })
  
  export const addClientsServicesTypesSuccess = data => ({
    type: ADD_CLIENTS_SERVICES_TYPES_SUCCESS,
    payload: data,
  })
  
  export const addClientsServicesTypesFail = error => ({
    type: ADD_CLIENTS_SERVICES_TYPES_FAIL,
    payload: error,
  })


  export const addClientsServicesList = (data_for,params,types) => ({
    type: ADD_CLIENTS_SERVICES_LIST,
    data_for: data_for,
    params,
    types,
  })
  
  export const addClientsServicesListSuccess = data => ({
    type: ADD_CLIENTS_SERVICES_LIST_SUCCESS,
    payload: data,
  })
  
  export const addClientsServicesListFail = error => ({
    type: ADD_CLIENTS_SERVICES_LIST_FAIL,
    payload: error,
  })

  export const addClientsIsolationList = (data_for,params,types) => ({
    type: ADD_CLIENTS_ISOLATION_LIST,
    data_for: data_for,
    params,
    types,
  })
  
  export const addClientsIsolationListSuccess = data => ({
    type: ADD_CLIENTS_ISOLATION_LIST_SUCCESS,
    payload: data,
  })
  
  export const addClientsIsolationListFail = error => ({
    type: ADD_CLIENTS_ISOLATION_LIST_FAIL,
    payload: error,
  })

  export const updateClientsServicesList = (data_for,params,types) => ({
    type: UPDATE_CLIENTS_SERVICES_LIST,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateClientsServicesListSuccess = data => ({
    type: UPDATE_CLIENTS_SERVICES_LIST_SUCCESS,
    payload: data,
  })
  
  export const updateClientsServicesListFail = error => ({
    type: UPDATE_CLIENTS_SERVICES_LIST_FAIL,
    payload: error,
  })

  export const updateClientsServicesTypes = (data_for,params,types) => ({
    type: UPDATE_CLIENTS_SERVICES_TYPES,
    data_for: data_for,
    params,
    types
  })
  
  export const updateClientsServicesTypesSuccess = data => ({
    type: UPDATE_CLIENTS_SERVICES_TYPES_SUCCESS,
    payload: data,
  })
  
  export const updateClientsServicesTypesFail = error => ({
    type: UPDATE_CLIENTS_SERVICES_TYPES_FAIL,
    payload: error,
  })

  export const updateClientsIsolationList = (data_for,params,types) => ({
    type: UPDATE_CLIENTS_ISOLATION_LIST,
    data_for:data_for,
    params,
    types,
  })
  
  export const updateClientsIsolationListSuccess = data => ({
    type: UPDATE_CLIENTS_ISOLATION_LIST_SUCCESS,
    payload: data,
  })
  
  export const updateClientsIsolationListFail = error => ({
    type: UPDATE_CLIENTS_ISOLATION_LIST_FAIL,
    payload: error,
  })