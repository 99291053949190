import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map, values } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, FEEDBACKS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";
import axios from 'axios';

import {Name, FeedStatus } from "../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getFeedbacks as onGetFeedbacks,
    updateFeedback as onUpdateFeedback,
    addFeedback as onAddFeedback
} from "store/actions";

function Feedbacks(){
  //meta title
  document.title="Feedbacks | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [status, setStatus] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [FeedFrom, setFeedFrom] = useState([]);
  const [FeedAboutStaff, setFeedAboutStaff] = useState([]);
  const [FeedAboutClient, setFeedAboutClient] = useState([]);

  const[ModalLoading, setModalLoading] = useState(false);

  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        feed_from: (roster && roster.feed_from) || "",
        feed_from_id : (roster && roster.feed_from_id) || "",
        feed_from_name : (roster && roster.feed_from_name) || "",
        feed_about : (roster && roster.feed_about) || "",
        feed_about_staff : (roster && roster.feed_about_staff) || "",
        feed_about_client : (roster && roster.feed_about_client) || "",
        feed_about_process : (roster && roster.feed_about_process) || "",
        feed_type : (roster && roster.feed_type) || "",
        feed_description : (roster && roster.feed_description) || null,
        feed_followup: (roster && roster.feed_followup) || null,
        feed_outcome: (roster && roster.feed_outcome) || null,
    },
    validationSchema: Yup.object({
        // service_name: Yup.string().required("Please Enter Service Name"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
            id: roster.id,
            feed_description : values.feed_description,
            feed_followup: values.feed_followup,
            feed_outcome: values.feed_outcome,
        };

        // console.log(updateUser);

        // update user
         setModalLoading(true);

         axios.post(APP_URL+FEEDBACKS_PAGE,{'data_for': 'feedback_form_edit','formData': updateUser,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                localStorage.setItem('feedback_count', fetchedData.feedback_count)
                dispatch(onUpdateFeedback(fetchedData.data));
               toast.success(fetchedData.html, { autoClose: 5000 });
               validation.resetForm();
               toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      } else {
        const newData = {
            feed_from: values.feed_from,
            feed_from_id : FeedFrom,
            feed_from_name : values.feed_from_name,
            feed_about : values.feed_about,
            feed_about_staff : FeedAboutStaff,
            // feed_about_client : FeedAboutClient,
            feed_about_process : values.feed_about_process,
            feed_type : values.feed_type,
            feed_description : values.feed_description,
        };

         //add staff leave
         setModalLoading(true);

         axios.post(APP_URL+FEEDBACKS_PAGE,{'data_for': 'feedback_add_form','formData': newData,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                localStorage.setItem('feedback_count', fetchedData.feedback_count);
                dispatch(onAddFeedback(fetchedData.data));
                toast.success(fetchedData.html, { autoClose: 5000 });
                validation.resetForm();
                setFeedFrom({label:'Select staff', value:'0'});
                setFeedAboutStaff({label:'Select staff', value:'0'});
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.FeedbacksReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        feedbacks: rosterReducer.feedbacks,
        clientsDropdown: rosterReducer.clientsDropdown,
        staffsDropdown: rosterReducer.staffsDropdown,
        loading: rosterReducer.loading
      })
  );
  const {
    feedbacks, staffsDropdown, clientsDropdown, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {

    const formData = {
      status : 'all'
    }
    if (feedbacks && !feedbacks.length) {
      dispatch(onGetFeedbacks({'data_for':'get_feedbacks','formData': formData,'user': loggedUser}));
    }
  }, [dispatch]);



  const handleStatus = (e) => {

    const checkedId = parseInt(e.target.value);
    // console.log( checkedId);
    if( e.target.checked ) {
      setStatus([...status, checkedId]);
    } else{
      setStatus( status.filter(id =>id != checkedId));
    }

  }

  useEffect(() => {
    // console.log(status);
  },[status])

  const handleSearchBtn= () => {

    const formData = {
      fromDate: fromDate,
      toDate: toDate,
      status: status
    }

    dispatch(onGetFeedbacks({'data_for':'get_feedbacks','formData': formData,'user': loggedUser}));

  }

  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    // console.log(user);
    setRoster({
        id: user.id,
        feed_from: user.feed_from,
        feed_from_id : user.feed_from_id,
        feed_from_name : user.feed_from_name,
        feed_about : user.feed_about,
        // feed_about_staff : user.feed_about_staff,
        // feed_about_client : user.feed_about_client,
        feed_about_process : user.feed_about_name,
        feed_type : user.feed_type,
        feed_description : user.feed_description,
        feed_description_datetime: user.feed_description_datetime,
        feed_description_by: user.feed_description_by,

        feed_followup : user.feed_followup,
        feed_followup_datetime: user.feed_followup_datetime,
        feed_followup_by: user.feed_followup_by,

        feed_outcome : user.feed_outcome,
        feed_outcome_datetime: user.feed_outcome_datetime,
        feed_outcome_by: user.feed_outcome_by,

        feed_close_date : user.feed_close_date,
        feed_close_date_datetime: user.feed_close_date_datetime,
        feed_close_date_by: user.feed_close_date_by,

        feed_closed_by : user.feed_closed_by,
        feed_closed_by_datetime: user.feed_closed_by_datetime,
        feed_closed_by_by: user.feed_closed_by_by,
        feed_status: user.feed_status
    });
    setIsEdit(true);
    if( user.feed_from == 'Internal') {
        setFeedFrom({label: user.feed_from_name,value:user.feed_from_id});
    }

    if( user.feed_about == 'staff'  || user.feed_about == 'client' ) {
        setFeedAboutStaff({label: user.feed_about_name,value:user.feed_about_id});
    }

    toggle();
  };

  const handleUserClicks = () => {
    setRoster([]);
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Feedback No",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback Date",
        accessor: "feed_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback Source",
        accessor: "feed_from",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback From",
        accessor: "feed_from_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback About",
        accessor: "feed_about",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback About Details",
        accessor: "feed_about_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Feedback Type",
        accessor: "feed_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "feed_description",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Followup",
        accessor: "feed_followup",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Outcome/Closing",
        accessor: "feed_outcome",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Close Date",
        accessor: "feed_close_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Closed By",
        accessor: "feed_close_by",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "feed_status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <FeedStatus {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Feedbacks" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>

                        <InputGroup>
                            <Flatpickr
                                name="from_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setFromDate(dateStr);
                                }}
                              
                                value={fromDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                        
                        </Col>

                        <Col sm={2}>

                        <InputGroup>
                            <Flatpickr
                                name="from_date"
                                type="text"
                                className="form-control d-block"
                                placeholder="dd-mm-YYYY"
                                onChange={(selectedDates, dateStr, instance) => {
                                    setToDate(dateStr);
                                }}
                                value={fromDate}
                                options={{
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                minDate: fromDate,
                                "locale": {
                                        "firstDayOfWeek": 1 // start week on Monday
                                    }
                                }}
                            />
                            </InputGroup>
                        </Col>

                        <Col sm={4}>
                        <Input
                        type="checkbox"
                        value="0"
                        checked={status.includes(0)}
                        onChange={e => {
                            handleStatus(e);
                        }}
                        />&nbsp;&nbsp; Open
                        &nbsp;&nbsp; &nbsp;&nbsp; 
                        <Input
                        type="checkbox"
                        value="1"
                        checked={status.includes(1)}
                        onChange={e => {
                            handleStatus(e);
                        }}
                        />&nbsp;&nbsp; &nbsp;&nbsp; Closed
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={feedbacks}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    isAddBtn={true}
                    btnText='Add Feedback'
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={true}
                    fileName='Feedbacks'
                  />
                }
                  <Modal isOpen={modal} toggle={toggle}  size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}

                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <CardTitle>Feedback Source</CardTitle>
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          ></Label>
                          <Col sm={9}>
                              <Input
                                  name="feed_from"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Internal'
                                  
                                  checked={validation.values.feed_from == 'Internal' ? true : false }
                                  disabled={isEdit ? true : false}
                                  
                              /> &nbsp;&nbsp;Internal
                            
                              
                              &nbsp;&nbsp;<Input
                                  name="feed_from"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='External'
                                  checked={validation.values.feed_from == 'External' ? true : false }
                                  disabled={isEdit ? true : false}
                              />&nbsp;&nbsp;External
                              

                            </Col>
                          </Row>
                          { validation.values.feed_from == 'Internal' && 
                          <>
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Support worker
                            </Label>
                            <Col sm={9}>
                            <Select
                              value={FeedFrom}
                              name="staff_name"
                              options={staffsDropdown}
                              onChange={(e) => setFeedFrom(e)}
                              className="select2-selection"
                              defaultValue={staffsDropdown}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                            />
                            </Col>
                          </Row>
                          </>
                          }
                          { validation.values.feed_from == 'External' && 
                          <>
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Person Reporting
                            </Label>
                            <Col sm={9}>
                            <Input
                              value={validation.values.feed_from_name}
                              name="feed_from_name"
                              type="text"
                              onChange={validation.handleChange}
                            />
                            </Col>
                          </Row>
                          
                          </>
                          }
                          <CardTitle>Feedback About</CardTitle>

                          <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          ></Label>
                          <Col sm={9}>
                              <Input
                                  name="feed_about"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='staff'
                                  
                                  checked={validation.values.feed_about == 'staff' ? true : false }
                                  disabled={isEdit ? true : false}
                                  
                              /> &nbsp;&nbsp;Staff
                            
                              
                              &nbsp;&nbsp;<Input
                                  name="feed_about"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='client'
                                  checked={validation.values.feed_about == 'client' ? true : false }
                                  disabled={isEdit ? true : false}
                              />&nbsp;&nbsp;Client
                              
                              &nbsp;&nbsp;<Input
                                  name="feed_about"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='process'
                                  checked={validation.values.feed_about == 'process' ? true : false }
                                  disabled={isEdit ? true : false}
                              />&nbsp;&nbsp;Process
                              

                            </Col>
                          </Row>

                          {validation.values.feed_about == 'staff' && 
                          <>
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Staff
                            </Label>
                            <Col sm={9}>
                            <Select
                              value={FeedAboutStaff}
                              name="staff_name"
                              options={staffsDropdown}
                              onChange={(e) => setFeedAboutStaff(e)}
                              className="select2-selection"
                              defaultValue={staffsDropdown}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                            />
                            </Col>
                          </Row>

                          </>
                          }
                          
                          {validation.values.feed_about == 'client' && 
                          <>
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Client
                            </Label>
                            <Col sm={9}>
                            <Select
                              value={FeedAboutStaff}
                              name="staff_name"
                              options={clientsDropdown}
                              onChange={(e) => FeedAboutStaff(e)}
                              className="select2-selection"
                              defaultValue={clientsDropdown}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                            />
                            </Col>
                          </Row>

                          </>
                          }

                          {validation.values.feed_about == 'process' && 
                          <>
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Process
                            </Label>
                            <Col sm={9}>
                            <Input
                              value={validation.values.feed_about_process}
                              name="feed_about_process"
                              type="textarea"
                              onChange={validation.handleChange}
                            />
                            </Col>
                          </Row>
                          </>
                          }
                          
                          <CardTitle>Feedback</CardTitle>

                          <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          ></Label>
                          <Col sm={9}>
                              <Input
                                  name="feed_type"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Compliment'
                                  checked={validation.values.feed_type == 'Compliment' ? true : false }
                                  disabled={isEdit ? true : false}
                              /> &nbsp;&nbsp;Compliment
                            
                              
                              &nbsp;&nbsp;<Input
                                  name="feed_type"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Complaint'
                                  checked={validation.values.feed_type == 'Complaint' ? true : false }
                                  disabled={isEdit ? true : false}
                              />&nbsp;&nbsp;Complaint
                              
                              &nbsp;&nbsp;<Input
                                  name="feed_type"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Suggesting'
                                  checked={validation.values.feed_type == 'Suggesting' ? true : false }
                                  disabled={isEdit ? true : false}
                              />&nbsp;&nbsp;Suggesting
                              

                            </Col>
                          </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Description
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="feed_description"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.feed_description}
                                    invalid={
                                    validation.touched.feed_description &&
                                    validation.errors.feed_description
                                        ? true
                                        : false
                                    }
                                />
                                {validation.touched.feed_description &&
                                    validation.errors.feed_description ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.feed_description}
                                    </FormFeedback>
                                ) : null}
                                 { roster.feed_description_datetime != null && 
                                <em>{roster.feed_description_datetime}, by { roster.feed_description_by}</em>
                                 }
                            </Col>
                        </Row>

                        { isEdit && 
                        <>
                        
                        { roster.feed_description != null  && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Follow Up
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="feed_followup"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.feed_followup}
                                />
                               { roster.feed_followup_datetime != null && 
                                <em>{roster.feed_followup_datetime}, by { roster.feed_followup_by}</em>
                               }
                            </Col>
                        </Row>
                        </>
                        }
                        
                        { roster.feed_followup != null  && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Outcome/Closing
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="feed_outcome"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.feed_outcome}
                                />
                                { roster.feed_outcome_datetime != null && 
                                <em>{roster.feed_outcome_datetime}, by { roster.feed_outcome_datetime_by}</em>
                                }
                            </Col>
                        </Row>
                        </>
                        }
                        
                        { roster.feed_close_date != null  && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Closed By
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="feed_closed_date"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={roster.feed_close_date}
                                />
                                { roster.feed_close_datetime != null && 
                                <em>{roster.feed_close_datetime}, by { roster.feed_close_by}</em>
                                }
                            </Col>
                        </Row>
                        </>
                        }
                        
                        { roster.feed_closed_by != null  && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Closed Date
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="feed_closed_by"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={roster.feed_closed_by}
                                />
                                { roster.feed_closed_by_datetime != null && 
                                <em>{roster.feed_closed_by_datetime}, by { roster.feed_closed_by}</em>
                                }
                            </Col>
                        </Row>
                        </>
                        }

                        </>
                        }

                        { roster.feed_status != 1  && 
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={ModalLoading}
                              >
                                { !!isEdit ? 'Update' : 'Add' }
                              </button>
                            </div>
                          </Col>
                        </Row>
                        }
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Feedbacks;