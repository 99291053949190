import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_PUBLIC_HOLIDAYS,
    ADD_PUBLIC_HOLIDAY,
    UPDATE_PUBLIC_HOLIDAY
} from "./actionTypes"
import {
  getPublicHolidaysSuccess,
  getPublicHolidaysFail,
  addPublicHolidaySuccess,
  addPublicHolidayFail,
  updatePublicHolidaySuccess,
  updatePublicHolidayFail
} from "./actions"

//Include Both Helper File with needed methods
import { publicHolidays, } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchPublicHolidays({params,types}) {
  try {
    const response = yield call(publicHolidays, params, types )
    console.log(response);
    yield put(getPublicHolidaysSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getPublicHolidaysFail(error))
  }
}


function* addPublicHoliday({ payload: data }) {
    try {
        const response = yield call(publicHolidays, data)
        yield put(addPublicHolidaySuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addPublicHolidayFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}

function* updatePublicHoliday({ payload: data }) {
  try {
      const response = yield call(publicHolidays, data)
      yield put(updatePublicHolidaySuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updatePublicHolidayFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* PublicHolidaysSaga() {
  yield takeEvery(GET_PUBLIC_HOLIDAYS, fetchPublicHolidays)
  yield takeEvery(ADD_PUBLIC_HOLIDAY, addPublicHoliday)
  yield takeEvery(UPDATE_PUBLIC_HOLIDAY, updatePublicHoliday)
}

export default PublicHolidaysSaga
