import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_PACKAGE_REPORT_SETTINGS,
    UPDATE_PACKAGE_REPORT_SETTINGS,
} from "./actionTypes"
import {
  getPackageReportSettingsSuccess,
  getPackageReportSettingsFail,
  updatePackageReportSettingsSuccess,
  updatePackageReportSettingsFail
} from "./actions"

//Include Both Helper File with needed methods
import { actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchPackageReportSettings({params,types}) {
  try {
    const response = yield call(actionUrl, params, types )
    console.log(response);
    yield put(getPackageReportSettingsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getPackageReportSettingsFail(error))
  }
}


function* updatePackageReportSettings({ payload: data }) {
  try {
      const response = yield call(actionUrl, data)
      yield put(updatePackageReportSettingsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updatePackageReportSettingsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}


function* PackageReportSaga() {
  yield takeEvery(GET_PACKAGE_REPORT_SETTINGS, fetchPackageReportSettings)
  yield takeEvery(UPDATE_PACKAGE_REPORT_SETTINGS, updatePackageReportSettings)
}

export default PackageReportSaga
