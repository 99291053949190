import {
    GET_PUBLIC_HOLIDAYS,
    GET_PUBLIC_HOLIDAYS_SUCCESS,
    GET_PUBLIC_HOLIDAYS_FAIL,
    ADD_PUBLIC_HOLIDAY,
    ADD_PUBLIC_HOLIDAY_SUCCESS,
    ADD_PUBLIC_HOLIDAY_FAIL,
    UPDATE_PUBLIC_HOLIDAY,
    UPDATE_PUBLIC_HOLIDAY_SUCCESS,
    UPDATE_PUBLIC_HOLIDAY_FAIL,
  } from "./actionTypes"
  
  export const getPublicHolidays = (params,types) => ({
    type: GET_PUBLIC_HOLIDAYS,
    params,
    types,
  })
  
  export const getPublicHolidaysSuccess = data => ({
    type: GET_PUBLIC_HOLIDAYS_SUCCESS,
    payload: data,
  })
  
  export const getPublicHolidaysFail = error => ({
    type: GET_PUBLIC_HOLIDAYS_FAIL,
    payload: error,
  })

  export const addPublicHoliday = (params,types) => ({
    type: ADD_PUBLIC_HOLIDAY,
    payload: params,
    types
  })

  export const addPublicHolidaySuccess = data => ({
    type: ADD_PUBLIC_HOLIDAY_SUCCESS,
    payload: data,
  })

  export const addPublicHolidayFail = error => ({
    type: ADD_PUBLIC_HOLIDAY_FAIL,
    payload: error,
  })

  export const updatePublicHoliday = (params,types) => ({
    type: UPDATE_PUBLIC_HOLIDAY,
    payload: params,
    types
  })

  export const updatePublicHolidaySuccess = data => ({
    type: UPDATE_PUBLIC_HOLIDAY_SUCCESS,
    payload: data,
  })

  export const updatePublicHolidayFail = error => ({
    type: UPDATE_PUBLIC_HOLIDAY_FAIL,
    payload: error,
  })