import {
    GET_SUPPORT_WORKERS,
    GET_SUPPORT_WORKERS_SUCCESS,
    GET_SUPPORT_WORKERS_FAIL,
    GET_ARCHIVED_SUPPORT_WORKERS,
    GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS,
    GET_ARCHIVED_SUPPORT_WORKERS_FAIL,

    GET_STAFFS_SERVICE_TYPES,
    GET_STAFFS_SERVICE_TYPES_SUCCESS,
    GET_STAFFS_SERVICE_TYPES_FAIL,
    ADD_STAFF_SERVICE_TYPES,
    ADD_STAFF_SERVICE_TYPES_SUCCESS,
    ADD_STAFF_SERVICE_TYPES_FAIL,
    UPDATE_STAFF_SERVICE_TYPE,
    UPDATE_STAFF_SERVICE_TYPE_SUCCESS,
    UPDATE_STAFF_SERVICE_TYPE_FAIL,

    ADD_STAFF,
    ADD_STAFF_SUCCESS,
    ADD_STAFF_FAIL,
    UPDATE_STAFF,
    UPDATE_STAFF_SUCCESS,
    UPDATE_STAFF_FAIL,

    GET_STAFF_LANGUAGES,
    GET_STAFF_LANGUAGES_SUCCESS,
    GET_STAFF_LANGUAGES_FAIL,
    ADD_STAFF_LANGUAGES,
    ADD_STAFF_LANGUAGES_SUCCESS,
    ADD_STAFF_LANGUAGES_FAIL,
    UPDATE_STAFF_LANGUAGE,
    UPDATE_STAFF_LANGUAGE_SUCCESS,
    UPDATE_STAFF_LANGUAGE_FAIL,

    GET_CSM_STAFFS,
    GET_CSM_STAFFS_SUCCESS,
    GET_CSM_STAFFS_FAIL,
    ADD_CSM_STAFFS,
    ADD_CSM_STAFFS_SUCCESS,
    ADD_CSM_STAFFS_FAIL,
    UPDATE_CSM_STAFF,
    UPDATE_CSM_STAFF_SUCCESS,
    UPDATE_CSM_STAFF_FAIL,

    GET_SUPPORT_STAFFS,
    GET_SUPPORT_STAFFS_SUCCESS,
    GET_SUPPORT_STAFFS_FAIL,
    ADD_SUPPORT_STAFF,
    ADD_SUPPORT_STAFF_SUCCESS,
    ADD_SUPPORT_STAFF_FAIL,
    UPDATE_SUPPORT_STAFF,
    UPDATE_SUPPORT_STAFF_SUCCESS,
    UPDATE_SUPPORT_STAFF_FAIL,


    GET_ADMIN_STAFFS,
    GET_ADMIN_STAFFS_SUCCESS,
    GET_ADMIN_STAFFS_FAIL,
    ADD_ADMIN_STAFF,
    ADD_ADMIN_STAFF_SUCCESS,
    ADD_ADMIN_STAFF_FAIL,
    UPDATE_ADMIN_STAFF,
    UPDATE_ADMIN_STAFF_SUCCESS,
    UPDATE_ADMIN_STAFF_FAIL,


    GET_HR_MANAGERS,
    GET_HR_MANAGERS_SUCCESS,
    GET_HR_MANAGERS_FAIL,
    ADD_HR_MANAGER,
    ADD_HR_MANAGER_SUCCESS,
    ADD_HR_MANAGER_FAIL,
    UPDATE_HR_MANAGER,
    UPDATE_HR_MANAGER_SUCCESS,
    UPDATE_HR_MANAGER_FAIL,
    

    GET_LOAD_STAFFS,
    GET_LOAD_STAFFS_SUCCESS,
    GET_LOAD_STAFFS_FAIL

  } from "./actionTypes"
  
  export const getSupportWorkers = (params,types) => ({
    type: GET_SUPPORT_WORKERS,
    params,
    types,
  })
  
  export const getSupportWorkersSuccess = data => ({
    type: GET_SUPPORT_WORKERS_SUCCESS,
    payload: data,
  })
  
  export const getSupportWorkersFail = error => ({
    type: GET_SUPPORT_WORKERS_FAIL,
    payload: error,
  })

  export const getArchivedSupportWorkers = (params,types) => ({
    type: GET_ARCHIVED_SUPPORT_WORKERS,
    params,
    types,
  })
  
  export const getArchivedSupportWorkersSuccess = data => ({
    type: GET_ARCHIVED_SUPPORT_WORKERS_SUCCESS,
    payload: data,
  })
  
  export const getArchivedSupportWorkersFail = error => ({
    type: GET_ARCHIVED_SUPPORT_WORKERS_FAIL,
    payload: error,
  })

  export const addStaff = (data_for,params,types) => ({
    type: ADD_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const addStaffSuccess = data => ({
    type: ADD_STAFF_SUCCESS,
    payload: data,
  })
  
  export const addStaffFail = error => ({
    type: ADD_STAFF_FAIL,
    payload: error,
  })


  export const updateStaff = (data_for,params,types) => ({
    type: UPDATE_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateStaffSuccess = data => ({
    type: UPDATE_STAFF_SUCCESS,
    payload: data,
  })
  
  export const updateStaffFail = error => ({
    type: UPDATE_STAFF_FAIL,
    payload: error,
  })

  export const getStaffsServiceTypes = (params,types) => ({
    type: GET_STAFFS_SERVICE_TYPES,
    params,
    types,
  })
  
  export const getStaffsServiceTypesSuccess = data => ({
    type: GET_STAFFS_SERVICE_TYPES_SUCCESS,
    payload: data,
  })
  
  export const getStaffsServiceTypesFail = error => ({
    type: GET_STAFFS_SERVICE_TYPES_FAIL,
    payload: error,
  })

  export const addStaffsServiceTypes = (data_for,params,types) => ({
    type: ADD_STAFF_SERVICE_TYPES,
    data_for: data_for,
    params,
    types,
  })
  
  export const addStaffsServiceTypesSuccess = data => ({
    type: ADD_STAFF_SERVICE_TYPES_SUCCESS,
    payload: data,
  })
  
  export const addStaffsServiceTypesFail = error => ({
    type: ADD_STAFF_SERVICE_TYPES_FAIL,
    payload: error,
  })

  

  export const updateStaffsServiceType = (data_for,params,types) => ({
    type: UPDATE_STAFF_SERVICE_TYPE,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateStaffsServiceTypeSuccess = data => ({
    type: UPDATE_STAFF_SERVICE_TYPE_SUCCESS,
    payload: data,
  })
  
  export const updateStaffsServiceTypeFail = error => ({
    type: UPDATE_STAFF_SERVICE_TYPE_FAIL,
    payload: error,
  })

  export const getStaffLanguages = (params,types) => ({
    type: GET_STAFF_LANGUAGES,
    params,
    types,
  })
  
  export const getStaffLanguagesSuccess = data => ({
    type: GET_STAFF_LANGUAGES_SUCCESS,
    payload: data,
  })
  
  export const getStaffLanguagesFail = error => ({
    type: GET_STAFF_LANGUAGES_FAIL,
    payload: error,
  })

  export const addStaffLanguage = (data_for,params,types) => ({
    type: ADD_STAFF_LANGUAGES,
    data_for: data_for,
    params,
    types,
  })
  
  export const addStaffLanguageSuccess = data => ({
    type: ADD_STAFF_LANGUAGES_SUCCESS,
    payload: data,
  })
  
  export const addStaffLanguageFail = error => ({
    type: ADD_STAFF_LANGUAGES_FAIL,
    payload: error,
  })

  export const updateStaffLanguage = (data_for,params,types) => ({
    type: UPDATE_STAFF_LANGUAGE,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateStaffLanguageSuccess = data => ({
    type: UPDATE_STAFF_LANGUAGE_SUCCESS,
    payload: data,
  })
  
  export const updateStaffLanguageFail = error => ({
    type: UPDATE_STAFF_LANGUAGE_FAIL,
    payload: error,
  })

  //csm staffs
  export const getCsmStaffs = (params,types) => ({
    type: GET_CSM_STAFFS,
    params,
    types,
  })
  
  export const getCsmStaffsSuccess = data => ({
    type: GET_CSM_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const getCsmStaffsFail = error => ({
    type: GET_CSM_STAFFS_FAIL,
    payload: error,
  })

  export const addCsmStaffs = (data_for,params,types) => ({
    type: ADD_CSM_STAFFS,
    data_for: data_for,
    params,
    types,
  })
  
  export const addCsmStaffsSuccess = data => ({
    type: ADD_CSM_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const addCsmStaffsFail = error => ({
    type: ADD_CSM_STAFFS_FAIL,
    payload: error,
  })

  export const updateCsmStaff = (data_for,params,types) => ({
    type: UPDATE_CSM_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateCsmStaffSuccess = data => ({
    type: UPDATE_CSM_STAFF_SUCCESS,
    payload: data,
  })
  
  export const updateCsmStaffFail = error => ({
    type: UPDATE_CSM_STAFF_FAIL,
    payload: error,
  })


  //support staffs
  export const getSupportStaffs = (params,types) => ({
    type: GET_SUPPORT_STAFFS,
    params,
    types,
  })
  
  export const getSupportStaffsSuccess = data => ({
    type: GET_SUPPORT_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const getSupportStaffsFail = error => ({
    type: GET_SUPPORT_STAFFS_FAIL,
    payload: error,
  })

  export const addSupportStaff = (data_for,params,types) => ({
    type: ADD_SUPPORT_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const addSupportStaffSuccess = data => ({
    type: ADD_SUPPORT_STAFF_SUCCESS,
    payload: data,
  })
  
  export const addSupportStaffFail = error => ({
    type: ADD_SUPPORT_STAFF_FAIL,
    payload: error,
  })

  export const updateSupportStaff = (data_for,params,types) => ({
    type: UPDATE_SUPPORT_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateSupportStaffSuccess = data => ({
    type: UPDATE_SUPPORT_STAFF_SUCCESS,
    payload: data,
  })
  
  export const updateSupportStaffFail = error => ({
    type: UPDATE_SUPPORT_STAFF_FAIL,
    payload: error,
  })

  //Admin Staffs
  export const getAdminStaffs = (params,types) => ({
    type: GET_ADMIN_STAFFS,
    params,
    types,
  })
  
  export const getAdminStaffsSuccess = data => ({
    type: GET_ADMIN_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const getAdminStaffsFail = error => ({
    type: GET_ADMIN_STAFFS_FAIL,
    payload: error,
  })

  export const addAdminStaff = (data_for,params,types) => ({
    type: ADD_ADMIN_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const addAdminStaffSuccess = data => ({
    type: ADD_ADMIN_STAFF_SUCCESS,
    payload: data,
  })
  
  export const addAdminStaffFail = error => ({
    type: ADD_ADMIN_STAFF_FAIL,
    payload: error,
  })

  export const updateAdminStaff = (data_for,params,types) => ({
    type: UPDATE_ADMIN_STAFF,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateAdminStaffSuccess = data => ({
    type: UPDATE_ADMIN_STAFF_SUCCESS,
    payload: data,
  })
  
  export const updateAdminStaffFail = error => ({
    type: UPDATE_ADMIN_STAFF_FAIL,
    payload: error,
  })


  //Hr Managers 
  export const getHrManagers = (params,types) => ({
    type: GET_HR_MANAGERS,
    params,
    types,
  })
  
  export const getHrManagersSuccess = data => ({
    type: GET_HR_MANAGERS_SUCCESS,
    payload: data,
  })
  
  export const getHrManagersFail = error => ({
    type: GET_HR_MANAGERS_FAIL,
    payload: error,
  })

  export const addHrManager = (data_for,params,types) => ({
    type: ADD_HR_MANAGER,
    data_for: data_for,
    params,
    types,
  })
  
  export const addHrManagerSuccess = data => ({
    type: ADD_HR_MANAGER_SUCCESS,
    payload: data,
  })
  
  export const addHrManagerFail = error => ({
    type: ADD_HR_MANAGER_FAIL,
    payload: error,
  })

  export const updateHrManager = (data_for,params,types) => ({
    type: UPDATE_HR_MANAGER,
    data_for: data_for,
    params,
    types,
  })
  
  export const updateHrManagerSuccess = data => ({
    type: UPDATE_HR_MANAGER_SUCCESS,
    payload: data,
  })
  
  export const updateHrManagerFail = error => ({
    type: UPDATE_HR_MANAGER_FAIL,
    payload: error,
  })

  export const getLoadStaffs = (params,types) => ({
    type: GET_LOAD_STAFFS,
    params,
    types,
  })
  
  export const getLoadStaffsSuccess = data => ({
    type: GET_LOAD_STAFFS_SUCCESS,
    payload: data,
  })
  
  export const getLoadStaffsFail = error => ({
    type: GET_LOAD_STAFFS_FAIL,
    payload: error,
  })