import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_ROSTERS_DATA, GET_ALL_ROSTERS, GET_ROSTER_DETAIL, GET_TODAYS_ROSTERS,GET_GROUP_ROSTERS, ADD_GROUP_ROSTER, UPDATE_GROUP_ROSTER } from "./actionTypes"
import {
  getRostersSuccess,
  getRostersFail,
  getRostersDetailSuccess,
  getRostersDetailFail,
  getTodaysRostesSuccess,
  getTodaysRostesFail,
  getGroupRostesSuccess,
  getGroupRostesFail,
  addNewGroupRosterSuccess,
  addNewGroupRosterFail,
  updateGroupRoster,
  updateGroupRosterSuccess,
  updateGroupRosterFail,
  addRostersDataSuccess,
  addRostersDataFail

} from "./actions"

// import { setLoading } from "../../components/Common/loadingAction";

//Include Both Helper File with needed methods
import { getAllRosters, getTodaysRosters, getGroupRosters, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchAllRosters({params,types}) {
  try {
    // yield put(setLoading(true));
    //yield put(resetAllRostersState());
    const response = yield call(getAllRosters, params, types )
    console.log(response);
    yield put(getRostersSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getRostersFail(error))
    // yield put(setLoading(false));
  }
}

function* fetchTodaysRosters({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getTodaysRosters,params,types)
    console.log(response);
    yield put(getTodaysRostesSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getTodaysRostesFail(error))
    // yield put(setLoading(false));
  }
}

function* fetchGroupRosters({params,types}) {
  try {
    // yield put(setLoading(true));
    const response = yield call(getGroupRosters,params,types)
    console.log(response);
    yield put(getGroupRostesSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getGroupRostesFail(error))
    // yield put(setLoading(false));
  }
}

function* onAddNewGroupRoster({ payload: data }) {
  try {
      const response = yield call(actionUrl, data)
      yield put(addNewGroupRosterSuccess(response))
      toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(addGroupRosterFail(error))
      toast.error("Details Add Failed", { autoClose: 2000 });
  }
}

function* onUpdateGroupRoster({ payload: data }) {
  try {
      const response = yield call(actionUrl, data)
      yield put(updateGroupRosterSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateGroupRosterFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* onAddRosterData({ payload: data }) {
  try {
      const response = yield call(actionUrl, data)
      yield put(addRostersDataSuccess(response))
      // toast.success("Details Added Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(addRostersDataFail(error))
      // toast.error("Details Add Failed", { autoClose: 2000 });
  }
}


// function* fetchInvoiceDetail({ invoiceId }) {
//   try {
//     const response = yield call(getInvoiceDetail, invoiceId)
//     yield put(getRostersDetailSuccess(response))
//   } catch (error) {
//     yield put(getRostersDetailFail(error))
//   }
// }

function* rostersSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_ALL_ROSTERS, fetchAllRosters)
  yield takeEvery(GET_TODAYS_ROSTERS, fetchTodaysRosters)
  yield takeEvery(GET_GROUP_ROSTERS, fetchGroupRosters)
  yield takeEvery(ADD_GROUP_ROSTER, onAddNewGroupRoster)
  yield takeEvery(UPDATE_GROUP_ROSTER, onUpdateGroupRoster)
  yield takeEvery(ADD_ROSTERS_DATA, onAddRosterData)
}

export default rostersSaga
