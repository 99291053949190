/* Rosters */
export const GET_ALERTS = "GET_ALERTS"
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS"
export const GET_ALERTS_FAIL = "GET_ALERTS_FAIL"

export const GET_ALERT_ROSTER_DETAILS = "GET_ALERT_ROSTER_DETAILS"
export const GET_ALERT_ROSTER_DETAILS_SUCCESS = "GET_ALERT_ROSTER_DETAILS_SUCCESS"
export const GET_ALERT_ROSTER_DETAILS_FAIL = "GET_ALERT_ROSTER_DETAILS_FAIL"

export const UPDATE_ALERT_ROSTER_DETAILS = "UPDATE_ALERT_ROSTER_DETAILS"
export const UPDATE_ALERT_ROSTER_DETAILS_SUCCESS = "UPDATE_vROSTER_DETAILS_SUCCESS"
export const UPDATE_ALERT_ROSTER_DETAILS_FAIL = "UPDATE_ALERT_ROSTER_DETAILS_FAIL"

