export const GET_QUESTIONS  = "GET_QUESTIONS"
export const GET_QUESTIONS_FAIL  = "GET_QUESTIONS_FAIL"
export const GET_QUESTIONS_SUCCESS  = "GET_QUESTIONS_SUCCESS"

export const ADD_QUESTION  = "ADD_QUESTION"
export const UPDATE_QUESTION  = "UPDATE_QUESTION"
    
export const GET_EMPLOYEDS  = "GET_EMPLOYEDS"
export const GET_EMPLOYEDS_FAIL  = "GET_EMPLOYEDS_FAIL"
export const GET_EMPLOYEDS_SUCCESS  = "GET_EMPLOYEDS_SUCCESS"
export const GET_CANDIDATES  = "GET_CANDIDATES"
export const GET_CANDIDATES_FAIL  = "GET_CANDIDATES_FAIL"
export const GET_CANDIDATES_SUCCESS  = "GET_CANDIDATES_SUCCESS"

export const ADD_CANDIDATE  = "ADD_CANDIDATE"
export const UPDATE_CANDIDATE  = "UPDATE_CANDIDATE"
export const FILTER_CANDIDATES  = "FILTER_CANDIDATES"

