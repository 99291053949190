import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getRosterReport as onGetRosterReport,
} from "store/actions";

function RosterReport(){
  //meta title
  document.title="Roster Report| Tacveiha Panel";

  // console.log(getLoggedInUser );
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fromDate: (roster && roster.fromDate) || "",
      toDate: (roster && roster.toDate) || "",
    },
    validationSchema: Yup.object({
        fromDate: Yup.string().required("Please Enter From Date"),
        toDate: Yup.string().required("Please Enter To Date"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: roster.id,
          service_name: values.service_name,
        };

        // update user
        dispatch(onUpdateClientsServiceTypes('update_service_types',updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newData = {
            fromDate: values["fromDate"],
            toDate: values["toDate"]
        };
        // save new user
        dispatch(onAddNewClientsServiceTypes('add_service_types',newData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.ReportsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        rosterReport: rosterReducer.rosterReport,
        loading: rosterReducer.loading
      })
  );
  const {
    rosterReport, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    //if (isEmpty(todaysRosters)) {
    //   if (clientAwayAlertApproved && !clientAwayAlertApproved.length) {
    //   dispatch(onGetClientAwayAlertApproved({'data_for':'client_away_alert_approved', 'user': user}));
    //   }
    //}
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      id: user.id,
      service_name: user.service_name,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const handleSearchBtn = () => {
    if( fromDate == '' && toDate == '' ) {
        toast.error("Please provide from to till dates", { autoClose: 5000 });
        return false;
    }
    dispatch(onGetRosterReport({'data_for':'roster_reports', 'fromdate': fromDate, 'todate': toDate,'user': loggedUser}));
  };

  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "client_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Staff Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date and day",
        accessor: "date_day",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "start_date",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Start Time2",
        accessor: "start_time2",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "End Time",
        accessor: "finish_time",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Units",
        accessor: "units",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Hrs",
        accessor: "hrs",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Rostered Units(In Minutes)",
        accessor: "rostered_units",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Travel Time Between Clients",
        accessor: "travel_time",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Group Roster Name",
        accessor: "group_roster_name",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date1",
        accessor: "date1",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Kms",
        accessor: "kms",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Kms Unit",
        accessor: "kms_unit",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Parking Fee",
        accessor: "parking_fee",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Pay Category External ID",
        accessor: "pay_category",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Billable",
        accessor: "billable",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Payable",
        accessor: "payable",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Units3",
        accessor: "unit3",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Units2",
        accessor: "unit2",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Allowance",
        accessor: "allowance",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Actual Units",
        accessor: "actual_units",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Diff",
        accessor: "diff",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Actual Start Time",
        accessor: "actual_start_time",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Actual Finish Time",
        accessor: "actual_end_time",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Actual Units(In Minutes)",
        accessor: "actual_units_in_minutes",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Employee External ID",
        accessor: "employee_external_id",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Client Department",
        accessor: "client_department",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Staff Mobile No",
        accessor: "staff_mobile_no",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "ros_status",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Roster Reports" breadcrumbItem="" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={rosterReport}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={true}
                    fileName='Roster Report'
                  />
                }
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row form>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label"></Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(RosterReport);