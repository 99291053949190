import {
  GET_STAFF_LEAVES_APPROVED,
  GET_STAFF_LEAVES_APPROVED_FAIL,
  GET_STAFF_LEAVES_APPROVED_SUCCESS,
  GET_STAFF_LEAVES_PROCESSED,
  GET_STAFF_LEAVES_PROCESSED_SUCCESS,
  GET_STAFF_LEAVES_PROCESSED_FAIL,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL,
  GET_STAFF_LEAVES_UNAPPROVED,
  GET_STAFF_LEAVES_UNAPPROVED_SUCCESS,
  GET_STAFF_LEAVES_UNAPPROVED_FAIL,
  GET_STAFF_LEAVES_DELETED,
  GET_STAFF_LEAVES_DELETED_SUCCESS,
  GET_STAFF_LEAVES_DELETED_FAIL,
  UPDATE_STAFF_LEAVE_PROCESSED_LIST,
  UPDATE_STAFF_LEAVE_APPROVED_LIST,
  UPDATE_STAFF_LEAVE_UNAPPROVED_LIST,
  UPDATE_STAFF_LEAVE_DELETED_LIST,
  UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST

} from "./actionTypes"

export const getStaffLeavesApproved = (params,types) => ({
  type: GET_STAFF_LEAVES_APPROVED,
  params,
  types,
})

export const getStaffLeavesApprovedSuccess = data => ({
  type: GET_STAFF_LEAVES_APPROVED_SUCCESS,
  payload: data,
})

export const getStaffLeavesApprovedFail = error => ({
  type: GET_STAFF_LEAVES_APPROVED_FAIL,
  payload: error,
})

export const getStaffLeavesProcessed = (params,types) => ({
  type: GET_STAFF_LEAVES_PROCESSED,
  params,
  types,
})

export const getStaffLeavesProcessedSuccess = data => ({
  type: GET_STAFF_LEAVES_PROCESSED_SUCCESS,
  payload: data,
})

export const getStaffLeavesProcessedFail = error => ({
  type: GET_STAFF_LEAVES_PROCESSED_FAIL,
  payload: error,
})

export const getStaffLeavesApprovedArchived = (params,types) => ({
  type: GET_STAFF_LEAVES_APPROVED_ARCHIVED,
  params,
  types
})

export const getStaffLeavesApprovedArchivedSuccess = data => ({
  type: GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS,
  payload: data,
})

export const getStaffLeavesApprovedArchivedFail = error => ({
  type: GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL,
  payload: error,
})

export const getStaffLeavesUnApproved = (params,types) => ({
  type: GET_STAFF_LEAVES_UNAPPROVED,
  params,
  types,
})

export const getStaffLeavesUnApprovedSuccess = data => ({
  type: GET_STAFF_LEAVES_UNAPPROVED_SUCCESS,
  payload: data,
})

export const getStaffLeavesUnApprovedFail = error => ({
  type: GET_STAFF_LEAVES_UNAPPROVED_FAIL,
  payload: error,
})

export const getStaffLeavesDeleted = (params,types) => ({
  type: GET_STAFF_LEAVES_DELETED,
  params,
  types,
})

export const getStaffLeavesDeletedSuccess = data => ({
  type: GET_STAFF_LEAVES_DELETED_SUCCESS,
  payload: data,
})

export const getStaffLeavesDeletedFail = error => ({
  type: GET_STAFF_LEAVES_DELETED_FAIL,
  payload: error,
})

export const updateStaffLeaveProcessedList = (params) => ({
  type: UPDATE_STAFF_LEAVE_PROCESSED_LIST,
  payload: params,
})

export const updateStaffLeaveApprovedList = (params) => ({
  type: UPDATE_STAFF_LEAVE_APPROVED_LIST,
  payload: params,
})

export const updateStaffLeaveUnApprovedList = (params) => ({
  type: UPDATE_STAFF_LEAVE_UNAPPROVED_LIST,
  payload: params,
})

export const updateStaffLeaveDeletedList = (params) => ({
  type: UPDATE_STAFF_LEAVE_DELETED_LIST,
  payload: params,
})

export const updateStaffLeaveApprovedArchivedList = (params) => ({
  type: UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST,
  payload: params,
})

// export const updateCasemanagerSuccess = data => ({
//   type: UPDATE_CASEMANAGER_SUCCESS,
//   payload: data,
// })

// export const updateCasemanagerFail = error => ({
//   type: UPDATE_CASEMANAGER_FAIL,
//   payload: error,
// })