/* Rosters */
export const GET_STAFF_LEAVES_APPROVED = "GET_STAFF_LEAVES_APPROVED"
export const GET_STAFF_LEAVES_APPROVED_SUCCESS = "GET_STAFF_LEAVES_APPROVED_SUCCESS"
export const GET_STAFF_LEAVES_APPROVED_FAIL = "GET_STAFF_LEAVES_APPROVED_FAIL"

export const GET_STAFF_LEAVES_PROCESSED = "GET_STAFF_LEAVES_PROCESSED"
export const GET_STAFF_LEAVES_PROCESSED_SUCCESS = "GET_STAFF_LEAVES_PROCESSED_SUCCESS"
export const GET_STAFF_LEAVES_PROCESSED_FAIL = "GET_STAFF_LEAVES_PROCESSED_FAIL"

export const GET_STAFF_LEAVES_APPROVED_ARCHIVED = "GET_STAFF_LEAVES_APPROVED_ARCHIVED"
export const GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS = "GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS"
export const GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL = "GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL"

export const GET_STAFF_LEAVES_UNAPPROVED = "GET_STAFF_LEAVES_UNAPPROVED"
export const GET_STAFF_LEAVES_UNAPPROVED_SUCCESS = "GET_STAFF_LEAVES_UNAPPROVED_SUCCESS"
export const GET_STAFF_LEAVES_UNAPPROVED_FAIL = "GET_STAFF_LEAVES_UNAPPROVED_FAIL"

export const GET_STAFF_LEAVES_DELETED = "GET_STAFF_LEAVES_DELETED"
export const GET_STAFF_LEAVES_DELETED_SUCCESS = "GET_STAFF_LEAVES_DELETED_SUCCESS"
export const GET_STAFF_LEAVES_DELETED_FAIL = "GET_STAFF_LEAVES_DELETED_FAIL"

export const UPDATE_STAFF_LEAVE_PROCESSED_LIST = "UPDATE_STAFF_LEAVE_PROCESSED_LIST"
export const UPDATE_STAFF_LEAVE_APPROVED_LIST = "UPDATE_STAFF_LEAVE_APPROVED_LIST"
export const UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST = "UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST"
export const UPDATE_STAFF_LEAVE_UNAPPROVED_LIST = "UPDATE_STAFF_LEAVE_UNAPPROVED_LIST"
export const UPDATE_STAFF_LEAVE_DELETED_LIST = "UPDATE_STAFF_LEAVE_DELETED_LIST"

