import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TopProgressBar from "components/Common/TopProgressBar"


function CaseManagerProfile(){
  //meta title
  document.title="Case Manager Profile | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const dispatch = useDispatch();

  const [ModalLoading, setModalLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
     
        case_manager_name: ( roster && roster.case_manager_name ) || "",
        department_name: ( roster && roster.department_name ) || "",
        user_email: ( roster && roster.user_email ) || "",
        user_gender: ( roster && roster.user_gender ),
        user_pass: "",
        user_pass_confirm: "",
        user_mobile_no: ( roster && roster.user_mobile_no ) || "",
        user_address: ( roster && roster.user_address ) || "",
    },
    validationSchema: Yup.object({
      case_manager_name: Yup.string().required("This field is required"),
      department_lname: Yup.string().required("This field is required"),
      user_email: Yup.string().required("This field is required"),
      user_mobile_no:Yup.string().required("This field is required"),
      user_pass: Yup.string().oneOf([Yup.ref('user_pass_confirm'),null], 'Passwords much match'),
      user_pass_confirm: Yup.string().oneOf([Yup.ref('user_pass'),null], 'Passwords much match'),
      user_address:Yup.string().required("This field is required"),
      
    }),
    onSubmit: values => {

        const updateUser = {
          user_id: roster.user_id,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_gender: values.user_gender,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
        }

        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'edit_cm_profile','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              toast.success(fetchedData.html, { autoClose: 5000 });
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
    },
  });

  useEffect(() => {
    setModalLoading(true);

    axios.post(APP_URL+ACTION_URL,{'data_for': 'get_cm_profile','user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            toast.success(fetchedData.html, { autoClose: 5000 });
            setRoster({
                user_id: loggedUser.uid,
                department_name: fetchedData.data.department_name,
                case_manager_name: fetchedData.data.case_manager_name,
                user_email: fetchedData.data.user_email,
                user_pass: fetchedData.user_pass,
                user_pass_confirm: fetchedData.user_pass_confirm,
                user_mobile_no: fetchedData.data.user_mobile_no,
                user_address: fetchedData.data.user_address,
                user_gender: fetchedData.data.user_gender
            })
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })

  }, [dispatch])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="CaseManagers" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

                <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >


                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Department Name
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="department_name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.department_name}
                            disabled={true}
                        />
                       
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            Case Manager Name
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="case_manager_name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.case_manager_name}
                            invalid={
                            validation.touched.case_manager_name &&
                                validation.errors.case_manager_name
                                ? true
                                : false
                            }
                        />
                        {validation.touched.case_manager_name &&
                            validation.errors.case_manager_name ? (
                            <FormFeedback type="invalid">
                            {validation.errors.case_manager_name}
                            </FormFeedback>
                        ) : null}
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                            User Email
                        </Label>
                        <Col sm={9}>
                        <Input
                            name="user_email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user_email}
                            invalid={
                            validation.touched.user_email &&
                                validation.errors.user_email
                                ? true
                                : false
                            }
                            disabled={true}
                        />
                        {validation.touched.user_email &&
                            validation.errors.user_email ? (
                            <FormFeedback type="invalid">
                            {validation.errors.user_email}
                            </FormFeedback>
                        ) : null}
                        </Col>
                    </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass}
                                invalid={
                                validation.touched.user_pass &&
                                    validation.errors.user_pass
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass &&
                                validation.errors.user_pass ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Confirm Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass_confirm"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass_confirm}
                                invalid={
                                validation.touched.user_pass_confirm &&
                                    validation.errors.user_pass_confirm
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass_confirm &&
                                validation.errors.user_pass_confirm ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass_confirm}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              User Gender
                          </Label>
                          <Col sm={9}>
                      
                          <Input
                              name="user_gender"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Male"
                              checked={validation.values.user_gender == 'Male' ? true : false }
                              
                          /> Male &nbsp;&nbsp;
                          <Input
                              name="user_gender"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="Female"
                              checked={validation.values.user_gender == 'Female' ? true : false }
                              
                          /> Female
                          </Col>
                        </Row>

                        
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Mobile No
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_mobile_no"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_mobile_no}
                              invalid={
                              validation.touched.user_mobile_no &&
                                  validation.errors.user_mobile_no
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_mobile_no &&
                              validation.errors.user_mobile_no ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_mobile_no}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Address
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_address}
                              invalid={
                              validation.touched.user_address &&
                                  validation.errors.user_address
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_address &&
                              validation.errors.user_address ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_address}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={ModalLoading}
                                >
                                    Save
                                </button>
                                </div>
                            </Col>
                        </Row>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}
export default CaseManagerProfile;