import {
  GET_STAFF_LEAVES_APPROVED,
  GET_STAFF_LEAVES_APPROVED_FAIL,
  GET_STAFF_LEAVES_APPROVED_SUCCESS,
  GET_STAFF_LEAVES_PROCESSED,
  GET_STAFF_LEAVES_PROCESSED_SUCCESS,
  GET_STAFF_LEAVES_PROCESSED_FAIL,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS,
  GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL,
  GET_STAFF_LEAVES_UNAPPROVED,
  GET_STAFF_LEAVES_UNAPPROVED_SUCCESS,
  GET_STAFF_LEAVES_UNAPPROVED_FAIL,
  GET_STAFF_LEAVES_DELETED,
  GET_STAFF_LEAVES_DELETED_SUCCESS,
  GET_STAFF_LEAVES_DELETED_FAIL,
  UPDATE_STAFF_LEAVE_PROCESSED_LIST,
  UPDATE_STAFF_LEAVE_APPROVED_LIST,
  UPDATE_STAFF_LEAVE_UNAPPROVED_LIST,
  UPDATE_STAFF_LEAVE_DELETED_LIST,
  UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST
} from "./actionTypes"

const INIT_STATE = {
  staffLeavesProcessed: [],
  staffLeavesApproved: [],
  staffLeavesApprovedArchived: [],
  staffLeavesUnApproved: [],
  staffLeavesDeleted: [],
  error: {},
  loading: false,
}

const StaffLeavesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAFF_LEAVES_APPROVED:
      console.log('loading');
      return {
        ...state,
        staffLeavesApproved: state.staffLeavesApproved,
        loading: true
     }
    case GET_STAFF_LEAVES_APPROVED_SUCCESS:
      console.log('success');
      return {
        ...state,
        staffLeavesApproved: action.payload.data,
        loading: false
     }

    case GET_STAFF_LEAVES_APPROVED_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    case GET_STAFF_LEAVES_PROCESSED:
    console.log('loading');
    return {
      ...state,
      staffLeavesProcessed: state.staffLeavesProcessed,
      loading: true
    }

    case GET_STAFF_LEAVES_PROCESSED_SUCCESS:
      return {
        ...state,
        staffLeavesProcessed: action.payload.data,
        loading: false
      }

    case GET_STAFF_LEAVES_PROCESSED_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }

    case GET_STAFF_LEAVES_APPROVED_ARCHIVED:
        return {
          ...state,
          staffLeavesApprovedArchived: state.staffLeavesApprovedArchived,
            loading: true
      };
    
    case GET_STAFF_LEAVES_APPROVED_ARCHIVED_SUCCESS:
        return {
          ...state,
          staffLeavesApprovedArchived: action.payload.data,
          loading: false
      };
    
    case GET_STAFF_LEAVES_APPROVED_ARCHIVED_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false
      };

    case GET_STAFF_LEAVES_DELETED:
      console.log('loading');
      return {
        ...state,
        staffLeavesDeleted: state.GET_STAFF_LEAVES_DELETED, //[...state.staffLeavesDeleted],
        loading: true
     }

    case GET_STAFF_LEAVES_DELETED_SUCCESS:
      console.log('success');
      return {
        ...state,
        staffLeavesDeleted: action.payload.data,
        loading: false
      }

    case GET_STAFF_LEAVES_DELETED_FAIL:
    console.log('failed');
      return {
        ...state,
        error: action.payload,
      }

    case GET_STAFF_LEAVES_UNAPPROVED:
        console.log('loading');
        return {
          ...state,
          staffLeavesUnApproved: state.staffLeavesUnApproved,
          loading: true
       }
  
    case GET_STAFF_LEAVES_UNAPPROVED_SUCCESS:
        console.log('success');
        return {
          ...state,
          staffLeavesUnApproved: action.payload.data,
          loading: false
        }
  
    case GET_STAFF_LEAVES_UNAPPROVED_FAIL:
      console.log('failed');
        return {
          ...state,
          error: action.payload,
      }


      case UPDATE_STAFF_LEAVE_PROCESSED_LIST:
        return {
          ...state,
          staffLeavesProcessed: action.payload,
          staffLeavesProcessed: state.staffLeavesProcessed.filter(rosters =>
            rosters.id.toString() !== action.payload.id.toString()
          ),
          loading: false
      }

      case UPDATE_STAFF_LEAVE_UNAPPROVED_LIST:
        return {
          ...state,
          staffLeavesUnApproved: state.staffLeavesUnApproved.filter(rosters =>
            rosters.id.toString() !== action.payload.id.toString()
          ),
          loading: false
      }

      case UPDATE_STAFF_LEAVE_APPROVED_LIST:
        return {
          ...state,
          // staffLeavesApproved: action.payload,
          staffLeavesApproved: state.staffLeavesApproved.filter(rosters =>
            rosters.id.toString() !== action.payload.id.toString()
          ),
          loading: false
      }

      case UPDATE_STAFF_LEAVE_APPROVED_ARCHIVED_LIST:
        return {
          ...state,
          staffLeavesApprovedArchived: action.payload,
          staffLeavesApprovedArchived: state.staffLeavesApprovedArchived.filter(rosters =>
            rosters.id.toString() !== action.payload.id.toString()
          ),
          loading: false
      }

      case UPDATE_STAFF_LEAVE_DELETED_LIST:
        return {
          ...state,
          // staffLeavesDeleted: action.payload,
          staffLeavesDeleted: state.staffLeavesDeleted.filter(rosters =>
            rosters.id.toString() !== action.payload.id.toString()
          ),
          loading: false
      }

      // case UPDATE_CASEMANAGER_SUCCESS:
      //   return {
      //       ...state,
      //       casemanager: state.providers.map(rosters =>
      //         rosters.id.toString() === action.payload.data.id.toString()
      //               ? { rosters, ...action.payload.data }
      //               : rosters
      //       ),
      //       loading: false
      // };

      // case UPDATE_CASEMANAGER_FAIL:
      //   return {
      //       ...state,
      //       error: action.payload.error,
      //       loading: false
      //   };

      default:
        return state
  }
}

export default StaffLeavesReducer
