import {
  GET_BROADCAST_ROSTERS,
  GET_BROADCAST_ROSTERS_FAIL,
  GET_BROADCAST_ROSTERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  broadcastRosters: [],
  error: {},
  loading: false,
}

const BroadcastRosterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BROADCAST_ROSTERS:
      console.log('loading');
      return {
        casemanagers: [],
        loading: true
     }
    case GET_BROADCAST_ROSTERS_SUCCESS:
      console.log('success');
      return {
        ...state,
        broadcastRosters: action.payload.data,
        loading: false
     }

    case GET_BROADCAST_ROSTERS_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    default:
        return state
  }
}

export default BroadcastRosterReducer
