import React from "react";
import {connect} from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
    barColors: {
        "0": " #6571FF",
        "1.0": "#6571FF",
    },
    shadowBlur: 0,
    barThickness: 2
});

const TopProgressBar = (props) => {
    const { loading } = props;

    // console.log(loading);

    return loading ? <TopBarProgress/> : null;
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.loading || ownProps.loading
    };
};

export default connect(mapStateToProps, null)(TopProgressBar);
