import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status,ServiceTypes, Languages } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


import {
    getArchivedSupportWorkers as onGetArchivedStaffs,
//   addNewClients as onAddNewClients,
//   updateClients as onUpdateClients
} from "store/actions";

function ArchivedSupportWorkers () {
  //meta title
  document.title="Archived Staffs | Tacveiha Panel";

  // console.log(getLoggedInUser );
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [staffID, setstaffID] = useState('');
  const [columnsHide, setColumnsHide]= useState([]);

  const [ModalLoading, setModalLoading] = useState(false);
  const [ClientsNotesLoading, setClientsNotesLoading] = useState(false);
  //modals
  const [NotesActivity, setNotesActivity] = useState([]);
  const [ClientNotes, setClientNotes] = useState('');
  const [ClientNotesModal, setClientNotesModal] = useState(false);
  const [roster, setRoster] = useState(null);


  //sms modal
  const[SendSmsModal, setSendSmsModal] = useState(false);
  const [SmsModalLoading, setSmsModalLoading] = useState(false);
  const[ SmsSubject, setSmsSubject ] = useState('');
  const[ SmsMobile, setSmsMobile ] = useState('');
  const[ SmsMessage, setSmsMessage ] = useState('');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      service_name: (roster && roster.service_name) || "",
    },
    validationSchema: Yup.object({
        service_name: Yup.string().required("Please Enter Service Name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: roster.id,
          service_name: values.service_name,
          data_for: 'update_service_types'
        };

        // update user
        dispatch(onUpdateClients(updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newData = {
            service_name: values["service_name"],
            data_for: 'add_staffs_service_types'
        };
        // save new user
        dispatch(onAddNewClients(newData,user));
        validation.resetForm();
      }
      toggle();
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        archivedstaffs: rosterReducer.archivedStaffs,
        loading: rosterReducer.loading
      })
  );
  const {
    archivedstaffs, loading
  } = useSelector(groupRostersProperties);

  const [Loading, setLoading] = useState(loading);
  const user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
      if (archivedstaffs && !archivedstaffs.length) {
        dispatch(onGetArchivedStaffs({'data_for': 'get_staffs','type': 'inactive', 'user': user}));
      }
  }, [dispatch],[archivedstaffs]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      id: user.id,
      service_name: user.service_name,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const handleDeletedRosters = clients => {
    const user = arg;
  }

  const handleViewProfile = clients => {
    const user = arg;
  }

  const handleViewNotes = (staffs) => {

    toggleClientNotesModal();

    const formData = {
      staff_id: staffs.user_id
    }

    setModalLoading(true);

    axios.post(APP_URL+ACTION_URL,{'data_for':'staffs_view_notes','formData': formData,'user': loggedUser})
      .then(res => {
        setModalLoading(false);
        const fetchedData = res.data;
        // console.log(fetchedData.data);

        if( fetchedData.status == 'success' ) {
          setstaffID(staffs.user_id);
          setNotesActivity(fetchedData.data);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })

  }

  const handleAddNotes = clients => {
    const user = arg;
  }

  const toggleClientNotesModal = () => {

    if (ClientNotesModal) {
      setClientNotesModal(false);
    } else {
      setClientNotesModal(true);
    }
  }

  const toggleSendSmsModal = () => {

    if (SendSmsModal) {
      setSendSmsModal(false);
    } else {
        setSendSmsModal(true);
    }
  }

  const addClientNotes = () => {

    if( ClientNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
      }
  
    const formData = {
        ros_id: staffID,
        notes: ClientNotes,
        activity_for: 'staff'
    };

    setClientsNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': formData,'user': loggedUser})
      .then(res => {
        setClientsNotesLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setClientNotes('');
          setNotesActivity(NotesActivity => [fetchedData.data,...NotesActivity]);
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }

  const handleSendSms = (staffs) => {
    toggleSendSmsModal();
    setstaffID(staffs.user_id);
  }

  const sendClientSms = () => {

    if( SmsSubject == '' ) {
        toast.error("Please add sms subject", { autoClose: 5000 });
        return false;
    }

    if( SmsMobile == '' ) {
      toast.error("Please add mobile no", { autoClose: 5000 });
      return false;
    }

    if( SmsMessage == '' ) {
      toast.error("Please add sms message", { autoClose: 5000 });
      return false;
    }
  
    const formData = {
        sms_staff: staffID,
        sms_subject: SmsSubject,
        sms_mobile_no: SmsMobile,
        sms_message: SmsMessage,
        smsType: 'client'
    };

    setSmsModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'send_sms_client_staff','formData': formData,'user': loggedUser})
      .then(res => {
        setSmsModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
          setstaffID('');
          setSmsSubject('');
          setSmsMobile('');
          setSmsMessage('');
          toast.success("Sms sent successfully", { autoClose: 5000 });
          toggleSendSmsModal();
        }

        if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })
  }


  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Staff Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      
      {
        Header: "Email",
        accessor: "user_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Mobile No",
        accessor: "staff_mobile_no",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "staff_address",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                         to={"/staffs/edit-staff/" +cellProps.row.original.user_id}
                        className="btn btn-sm btn-soft-info"
                        id={`deletetooltip-${cellProps.row.original.id}`}
                        target="_blank"
                    >
                        <i className="mdi mdi-pencil" />
                        <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                            Edit Profile
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  if( loggedUser.role == 'admin' ) {
    // setColumnsHide(['Email','Notes','Action','Employee ID']);
  }
  

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Staffs" breadcrumbItem="Archived List" />

          <Modal isOpen={ClientNotesModal} toggle={toggleClientNotesModal} size="xl" backdrop="static" scrollable>
            <ModalHeader toggle={toggleClientNotesModal} tag="h4">
                Notes details
            </ModalHeader>
            <ModalBody>
                { ModalLoading ? <Spinners setLoading={setLoading} />
                :
                <Row>
                  <Col xs={8}>
                    <Row>
                        <div className="order_notes_div">
                            <CardTitle>Activity Log</CardTitle>
                            {
                              ClientsNotesLoading ? <Spinners setLoading={setLoading} />
                              :
                            <ul className="order_notes">
                                { NotesActivity.map(activity => (
                                <li key={activity.id} rel={activity.id} className="note">
                                    <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                    <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                    <strong>
                                    {activity.activity_by}
                                    </strong>
                                    </abbr></p>
                                </li>
                                ))}
                            </ul>
                          }
                        </div>
                    </Row>
                  </Col>
                  <Col xs={4}>

                  {  loggedUser.role == 'admin' || loggedUser.role == 'sub-admin' || loggedUser.role == 'subadmin' ? 
                  <>
                    <Row>
                      <Col xs={12}>

                      

                          <div className="mb-3">
                              <Label className="form-label">Add Notes</Label>
                              <Input
                              name="roster_notes"
                              type="textarea"
                              onChange={e => {
                                  setClientNotes(e.target.value);
                              }}
                              value={ClientNotes}
                              />
                              
                          </div>

                        
                      
                      </Col>
                    </Row>

                    <Row>
                        <Col>
                        
                        <div className="text-end">
                            <button onClick={addClientNotes}
                            type="submit"
                            className="btn btn-success save-user"
                            >
                            Add
                            </button>
                        </div>
                        </Col>
                    </Row>

                  </>
                  : null }
                        
                  </Col>

                  
                </Row>
                }
                <br/>
                <br/>
                <br/>
            </ModalBody>
          </Modal>

          <Modal isOpen={SendSmsModal} toggle={toggleSendSmsModal} size="xl" backdrop="static" scrollable>
            <ModalHeader toggle={toggleSendSmsModal} tag="h4">
                Send SMS
            </ModalHeader>
            <ModalBody>
                { ModalLoading ? <Spinners setLoading={setLoading} />
                :
                <>
                <Row>
                    <Col xs={12}>

                        <div className="mb-3">
                            <Label className="form-label">Subject</Label>
                            <Input
                            name="sms_subject"
                            type="text"
                            onChange={e => {
                                setSmsSubject(e.target.value);
                            }}
                            value={SmsSubject}
                            />
                            
                        </div>
                    
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>

                        <div className="mb-3">
                            <Label className="form-label">Mobile No</Label>
                            <em>( Provide 9 digit mobile no without +61)</em>
                            <Input
                            name="mobile_no"
                            type="text"
                            onChange={e => {
                                setSmsMobile(e.target.value);
                            }}
                            value={SmsMobile}
                            />
                            
                        </div>
                    
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>

                        <div className="mb-3">
                            <Label className="form-label">Message</Label>
                            <Input
                            name="roster_notes"
                            type="textarea"
                            onChange={e => {
                                setSmsMessage(e.target.value);
                            }}
                            value={SmsMessage}
                            />
                            
                        </div>
                    
                    </Col>
                </Row>

                <Row>
                    <Col>
                    { SmsModalLoading ? <Spinners setLoading={setLoading} />
                    :
                      <div className="text-end">
                          <button onClick={sendClientSms}
                          type="submit"
                          className="btn btn-success save-user"
                          >
                          Add
                          </button>
                      </div>
                    }
                    </Col>
                </Row>
                </>
                }
                <br/>
                <br/>
                <br/>
            </ModalBody>
          </Modal>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={archivedstaffs}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    isAddBtn={true}
                    
                    btnText={'Add New Staff'}
                    columnsHide={columnsHide}
                  />
                }
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      Client Package Details
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row form>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label"></Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default ArchivedSupportWorkers;