import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { size, map } from "lodash";


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const Name = (cell) => {
    return cell.value ? cell.value : '';
};


const Doses = (cell) => {
    return (
        <>
        { cell.value == 'multi' &&
            <Link
                to="#"
                className="badge bg-info font-size-14 m-2"
                key={"_skill_" + cell.value}
            >
                2 Doses
            </Link>
        }

        { cell.value == 'single' &&
            <Link
                to="#"
                className="badge bg-info font-size-14 m-2"
                key={"_skill_" + cell.value}
            >
                1 Dose
            </Link>
        }

        </>
    )
};

const Email = (cell) => {
    return cell.value ? cell.value : '';
};

const Types = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    index < 2 && (
                        <Link
                            to="#"
                            className="badge badge-soft-primary font-size-11 m-1"
                            key={"_skill_" + cell.value + index}
                        >
                            {tag}
                        </Link>

                    )
            )}
            {size(cell.value) > 2 && (
                <Link
                    to="#"
                    className="badge badge-soft-primary font-size-11 m-1"
                    key={"_skill_" + cell.value}
                >
                    {size(cell.value) - 1} + more
                </Link>
            )}
        </>
    );
};

const RecruitmentStatus = (cell) => {
    return (
        <>
        { cell.value == '1' &&
                <Link
                    to="#"
                    className="badge bg-info font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Active
                </Link>
        }

        { cell.value == '2' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Employed
                </Link>
        }

        { cell.value == '3' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Rejected
                </Link>
        }

        </>
    )
}

const FeedStatus = (cell) => {
    return (
        <>
        { cell.value == '1' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Closed
                </Link>
        }

        { cell.value == '0' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Open
                </Link>
        }

        </>
    )
}
const Status = (cell) => {
    return (
        <>

            { cell.value == 'Processed' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Notes Added' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }
            { cell.value == 'Available' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Not Available' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Read' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Unread' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Approved' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Not Approved' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }

            { cell.value == 'Approval Pending' &&
                <Link
                    to="#"
                    className="badge bg-info font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }
            { cell.value == 'Not Applied' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }
            { cell.value == 'Deleted' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == 'Not Active' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   {cell.value}
                </Link>
            }
            { cell.value == 'Accepted' &&
                <Link
                    to="#"
                    className="badge bg-info font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == 'Active' &&
                <Link
                    to="#"
                    className="badge bg-info font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == 'Verified' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == 'Completed' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == 'In Progress' &&
                <Link
                    to="#"
                    className="badge bg-warning font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    {cell.value}
                </Link>
            }
            { cell.value == '0' &&
                <Link
                    to="#"
                    className="badge bg-danger font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                   Inactive
                </Link>
            }
            { cell.value == '1' &&
                <Link
                    to="#"
                    className="badge bg-success font-size-14 m-2"
                    key={"_skill_" + cell.value}
                >
                    Active
                </Link>
            }
        </>
    );
};

const Tags = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    index < 2 && (
                        <Link
                            to="#"
                            className="badge badge-soft-primary font-size-11 m-1"
                            key={"_skill_" + cell.value + index}
                        >
                            {tag}
                        </Link>

                    )
            )}
            {size(cell.value) > 2 && (
                <Link
                    to="#"
                    className="badge badge-soft-primary font-size-11 m-1"
                    key={"_skill_" + cell.value}
                >
                    {size(cell.value) - 1} + more
                </Link>
            )}
        </>
    );
};

const Projects = (cell) => {
    return cell.value ? cell.value : '';
};

const Desc = (cell) => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: cell.value }}></div>
        </>
    )

};

const Img = (cell) => {
    return (
        <>
            {!cell.value ? (
                <div className="avatar-xs">
                    <span className="avatar-title rounded-circle">
                        {/* {console.log("cell", cell.data[0].name)} */}
                        {cell.data[0].name.charAt(0)}
                    </span>
                </div>
            ) : (
                <div>
                    <img
                        className="rounded-circle avatar-xs"
                        src={cell.value}
                        alt=""
                    />
                </div>
            )}
        </>
    );
};

const ServiceTypes = (cell) => {
    return (
        <>
            {map( 
                cell.value,
                (tag, index) =>
                        <Link
                            to="#"
                            className="badge badge-soft-primary font-size-11 m-1"
                            key={"_skill_" + index}
                        >
                            {tag.service_name}
                        </Link>

            )}
        </>
    );
};

const Languages = (cell) => {
    return (
        <>
            {map(
                cell.value,
                (tag, index) =>
                    <Link
                        to="#"
                        className="badge badge-soft-primary font-size-11 m-1"
                        key={"_skill_"+ index}
                    >
                        {tag.value}
                    </Link>
            )}
        </>
    );
};

const NursingUnits = cell => {
    return (
    <>
     { cell.data ? 
     <span className='badge badge-soft-success font-size-14 m-2'>
        {cell.data }
     </span>
     : '' }

     </>

    )
};



export {
    Name,
    Email,
    Tags,
    Projects,
    Img,
    Status,
    Types,
    Desc,
    Doses,
    ServiceTypes,
    Languages,
    NursingUnits,
    FeedStatus,
    RecruitmentStatus
};