import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_HR_DOCUMENT_NAME_LIST, GET_HR_DOCUMENT_NAME_LIST, UPDATE_HR_DOCUMENT_NAME_LIST } from "./actionTypes"
import { addHrDocumentsNameListFail, addHrDocumentsNameListSuccess, getHrDocumentsNameListFail, getHrDocumentsNameListSuccess, updateHrDocumentsNameListFail, updateHrDocumentsNameListSuccess } from "./actions"

//Include Both Helper File with needed methods
import { hrDocumentsNameListPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";


function* fetchHrDocumentsName({params,types}) {
  try {
    const response = yield call(hrDocumentsNameListPage, params, types )
    console.log(response);
    yield put(getHrDocumentsNameListSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getHrDocumentsNameListFail(error))
  }
}


function* updateHrDocumentsName({ payload: data }) {
  try {
      const response = yield call(hrDocumentsNameListPage, data)
      yield put(updateHrDocumentsNameListSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateHrDocumentsNameListFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* addHrDocumentsName({ payload: data }) {
    try {
        const response = yield call(hrDocumentsNameListPage, data)
        yield put(addHrDocumentsNameListSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addHrDocumentsNameListFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}


function* HrDocumentsNameSaga() {
  yield takeEvery(GET_HR_DOCUMENT_NAME_LIST, fetchHrDocumentsName)
  yield takeEvery(UPDATE_HR_DOCUMENT_NAME_LIST, updateHrDocumentsName)
  yield takeEvery(ADD_HR_DOCUMENT_NAME_LIST, addHrDocumentsName)
}

export default HrDocumentsNameSaga
