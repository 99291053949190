import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, isWeakMap, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, ADMIN_URL, GET_STAFFS } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


const libraries =['places'];

//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getSupportStaffs as onGetSupportStaffs,
    addSupportStaff as onAddSupportStaffs,
} from "store/actions";

function SupportStaffs(){
  //meta title
  document.title="Support Staffs| Tacveiha Panel";

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries
  });

  // console.log(getLoggedInUser );

  const [ModalLoading, setModalLoading] = useState(false);
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [providersDropdown, setProvidersDropdown] = useState([]);
  const [staffsProviders, setStaffsProviders] = useState([]);
  const [StaffDob, setStaffDob] = useState('');
  
  const [StaffAddress, setStaffAddress] = useState('');
  const [StaffLatLng, setStaffLatLng] = useState('');
  
  const [roster, setRoster] = useState(null);
  const [Loading, setLoading] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [selectedFiles, setselectedFiles] = useState([]);
  const [profileCover, setProfileCover] = useState();


  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.StaffsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        supportStaffsList: rosterReducer.supportStaffs,
        staffsProvidersList: rosterReducer.staffsProviders,
        loading: rosterReducer.loading
      })
  );
  const {
    supportStaffsList, staffsProvidersList, loading
  } = useSelector(groupRostersProperties);



  useEffect(() => {
    if (supportStaffsList && !supportStaffsList.length) {
      dispatch(onGetSupportStaffs({'data_for': 'get_support_staffs','user': loggedUser}));
    }
  }, [dispatch]);
  

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      staff_email : ( roster && roster.staff_email ) || "",
      staff_password : "",
      staff_confirm_password : "",
      staff_mobile_no : ( roster && roster.staff_mobile_no ) || "",
      sms_auth : ( roster && roster.sms_auth ) || 0,
      email_auth : ( roster && roster.email_auth ) || 0,
      auth_email_address: ( roster && roster.auth_email_address ) || "",
    },
    validationSchema: Yup.object({
      staff_email: Yup.string().required("Required"),
      staff_mobile_no: Yup.string().required("Required"),
      staff_password: Yup.string(),
      staff_confirm_password: Yup.string().oneOf([Yup.ref('staff_password'),null],'Passwords must match')

    }),
    onSubmit: values => {

        if( values.email_auth == 1 &&  values.auth_email_address == '' ) {
          toast.error("Please provide authentication email address", { autoClose: 5000 });
          return false;
        }

        const newData = {
          staff_email: values.staff_email,
          staff_password: values.staff_password,
          staff_mobile_no: values.staff_mobile_no,
          sms_auth: values.sms_auth,
          email_auth :values.email_auth,
          auth_email_address :values.auth_email_address,
          added_by : loggedUser.uid
        };
        // save new user
        dispatch(onAddSupportStaffs('add_support_staff',newData,loggedUser));

        
        validation.resetForm();
        toggle();
    },
  });


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  // const handleUserClick = arg => {
  //   const user = arg;
  //   const formData = {
  //       user_id: user.user_id
  //   }

  //   setModalLoading(true);
  //   axios.post(APP_URL+GET_STAFFS,{'data_for':'get_support_staff_details','formData':formData,'user':loggedUser})
  //   .then(res => {
  //     setModalLoading(false);
  //     const fetchedData = res.data;

  //     setRoster({
  //       id: fetchedData.userRow.user_id,
  //       user_status : fetchedData.userRow.user_status,
  //       user_title: fetchedData.userRow.user_title,
  //       employee_id: fetchedData.userRow.employee_id,
  //       staff_fname: fetchedData.userRow.staff_fname,
  //       staff_lname: fetchedData.userRow.staff_lname,
  //       staff_email: fetchedData.userRow.user_email,
  //       staff_dob: fetchedData.userRow.staff_dob,
  //       staff_gender: fetchedData.userRow.staff_gender,
  //       staff_mobile_no: fetchedData.userRow.staff_mobile_no,
  //       staff_home_no: fetchedData.userRow.staff_home_no,
  //       staff_language: fetchedData.userRow.staff_language,
  //       staff_address: fetchedData.userRow.staff_address,
  //       staff_latlng: fetchedData.userRow.staff_latlng,
  //       staff_emergency_contact_name: fetchedData.userRow.staff_emergency_contact_name,
  //       staff_emergency_contact_relation: fetchedData.userRow.staff_emergency_contact_relation,
  //       staff_emergency_contact_mobile: fetchedData.userRow.staff_emergency_contact_mobile,
  //       staff_emergency_contact_email: fetchedData.userRow.staff_emergency_contact_email,
  //       sms_auth: fetchedData.userRow.sms_auth,

  //     });
  //   })

    
  //   setIsEdit(true);
  //   toggle();
  // };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }


  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) { 
        validation.values.staff_address = place.formatted_address;
        setStaffAddress(place.formatted_address);
        setStaffLatLng(place.geometry.location.lat()+','+place.geometry.location.lng());
    }
  }

  function handleCoverChange(e) {
    // console.log(e.target.files);
    setProfileCover(URL.createObjectURL(e.target.files[0]));
}


  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "user_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Account Status",
        accessor: "user_status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                  to={"/staffs/edit-support-staff/" +cellProps.row.original.user_id}
                  className="btn btn-sm btn-soft-info"
                  id={`edittooltip-${cellProps.row.original.id}`}
                  target="_blank"
              >
                  <i className="mdi mdi-pencil" />
                  <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`}>
                      Edit Profile
                  </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Staffs" breadcrumbItem="Support Staffs" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <>
                    <TableContainer
                      columns={columns}
                      data={supportStaffsList}
                      isGlobalFilter={true}
                      isDateSelecteOptions={true}
                      showSearchBtn={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={50}
                      className="custom-header-css"
                      isAddBtn={true}
                      btnText={'Add Suppport Staff'}
                    />
                  </>
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Support Staff" : "Add Support Staff "}
                    </ModalHeader>
                    <ModalBody>

                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                    {/* <Row className="mb-4">

                    <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                    >
                        Account Status
                    </Label>
                    <Col sm={9}>
                        <Input
                            name="user_status"
                            type="radio"
                            onChange={validation.handleChange}
                            value='1'
                            invalid={
                                validation.touched.user_status &&
                                validation.errors.user_status
                                ? true
                                : false
                            }
                            checked={validation.values.user_status == 1 ? true: false}
                        /> &nbsp;&nbsp;Active
                        &nbsp;&nbsp;<Input
                            name="user_status"
                            type="radio"
                            onChange={validation.handleChange}
                            value='0'
                            invalid={
                            validation.touched.user_status &&
                                validation.errors.user_status
                                ? true
                                : false
                            }
                            checked={validation.values.user_status == 0 ? true: false}
                        />&nbsp;&nbsp;InActive
                        {validation.touched.user_status &&
                        validation.errors.user_status ? (
                        <FormFeedback type="invalid">
                        {validation.errors.user_status}
                        </FormFeedback>
                        ) : null}
                    </Col>
                    </Row> */}
                    

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                              Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_email}
                                invalid={
                                    validation.touched.staff_email &&
                                    validation.errors.staff_email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_email &&
                                validation.errors.staff_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_email}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_password}
                                invalid={
                                    validation.touched.staff_password &&
                                    validation.errors.staff_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_password &&
                                validation.errors.staff_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                               Re-type Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_confirm_password"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_confirm_password}
                                invalid={
                                    validation.touched.staff_confirm_password &&
                                    validation.errors.staff_confirm_password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_confirm_password &&
                                validation.errors.staff_confirm_password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_confirm_password}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Mobile No
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="staff_mobile_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.staff_mobile_no}
                                invalid={
                                validation.touched.staff_mobile_no &&
                                    validation.errors.staff_mobile_no
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.staff_mobile_no &&
                                validation.errors.staff_mobile_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.staff_mobile_no}
                                </FormFeedback>
                            ) : null}
                            </Col>
                            
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Sms Authentication
                            </Label>
                            <Col sm={9}>
                              <Input
                                name="sms_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.sms_auth &&
                                    validation.errors.sms_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.sms_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="sms_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.sms_auth &&
                                      validation.errors.sms_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.sms_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.sms_auth &&
                              validation.errors.sms_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.sms_auth}
                              </FormFeedback>
                              ) : null}
                          </Col>
                            
                        </Row>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Email Authentication
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="email_auth"
                                type="radio"
                                onChange={validation.handleChange}
                                value='1'
                                invalid={
                                    validation.touched.email_auth &&
                                    validation.errors.email_auth
                                    ? true
                                    : false
                                }
                                checked={validation.values.email_auth == 1 ? true: false}
                              /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                  name="email_auth"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  invalid={
                                  validation.touched.email_auth &&
                                      validation.errors.email_auth
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.email_auth == 0 ? true: false}
                                />&nbsp;&nbsp;No
                              {validation.touched.email_auth &&
                              validation.errors.email_auth ? (
                              <FormFeedback type="invalid">
                              {validation.errors.email_auth}
                              </FormFeedback>
                              ) : null}
                            </Col>
                            
                        </Row>

                        {  validation.values.email_auth == 1  && (
                            <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Provide Authentication Email
                            </Label>
                            <Col sm={9}>
                            <Input
                                    name="auth_email_address"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.auth_email_address}
                                    // invalid={
                                    //     validation.touched.client_emergency_contact_email &&
                                    //     validation.errors.client_emergency_contact_email
                                    //     ? true
                                    //     : false
                                    // }
                                />
                                
                            </Col>

                        </Row>
                        )}

                        {/* documents and files uplaod */}
                        { !!isEdit ? (
                          <></>      
                        ) : null }

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    }
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default SupportStaffs;