import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"

import withRouter from "components/Common/withRouter"
import {Name,Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getNewsFeedsViewed as onGetNewsFeedsViewed,
} from "store/actions";

const NewsFeedsViewed = props =>  {
  //meta title
  document.title="News Feeds Viewed| Tacveiha Panel";

  // console.log(getLoggedInUser );
  
  const [deleteModal, setDeleteModal] = useState(false);
  const params = props.router.params;

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.NewsFeedReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        newsFeedsViewed: rosterReducer.newsFeedsViewed,
        loading: rosterReducer.loading
      })
  );
  const {
    newsFeedsViewed, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));


  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetNewsFeedsViewed({'data_for':'get_news_feed_viewed','news_id':params.id, 'user': loggedUser}));
    }
  }, [dispatch]);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };
  
  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Employeed ID",
        accessor: "employee_id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "User Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "User Email",
        accessor: "user_email",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "read_status",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="News Feeds Viewed" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={newsFeedsViewed}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={true}
                    fileName='News Feeds Viewed'
                  />
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

NewsFeedsViewed.propTypes = {
  match: PropTypes.any,
};

export default withRouter(NewsFeedsViewed);