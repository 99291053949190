import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { toast } from "react-toastify";
import { ACTION_URL, APP_URL, GET_CONTRACTORS, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";
import Dropzone from "react-dropzone";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const UploadDocuments = ({clientID,dataFor,userType}) => {

    //dropzone
    const [ModalLoading, setModalLoading] = useState(false);
    const [Loading, setLoading] = useState(true);


    const [selectedDocFiles, setselectedDocFiles] = useState([]);

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));


    const handleUploadDoc = (files) => {

        files.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })
        )
        
        setselectedDocFiles(files);
    
        const formData = new FormData();
    
        files.forEach((file) => {
            formData.append('file', file);
        });
    
        // files.forEach((file) => {
        //     console.log(file);
        //     formData.append('files[]', file);
        // });
    
        formData.append('data_for',  dataFor);
        formData.append('user_id', clientID);
        formData.append('user_type', userType);
        formData.append('is_expiry', '');
        formData.append('expiry_date', '');
        formData.append('doc_list_id', '');
        formData.append('doc_list_name', '');
        formData.append('added_by', loggedUser.uid);
        formData.append('role', loggedUser.role);
    
        setModalLoading(true);
    
        axios.post(APP_URL+'/upload_files.php',formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setselectedDocFiles([]);
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
    
        })
    }
    
    
    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

return (
    <>

    { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

    <Card>
            <CardBody>
                <CardTitle>Upload Documents</CardTitle>
                <Dropzone 
                    onDrop={acceptedFiles => {
                        handleUploadDoc(acceptedFiles)
                    }}
                >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                    <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here</h4>
                    </div>
                    </div>
                )}
                </Dropzone>

                <div className="dropzone-previews mt-3" id="file-previews">
                {selectedDocFiles.map((f, i) => {
                    // console.log(f);
                    return (
                    <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                    >
                        <div className="p-2">
                        <Row className="align-items-center">
                            <Col className="col-auto">
                            {f.type != 'application/pdf' ?
                            <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                            />
                            : null }
                            </Col>
                            <Col>
                            <Link
                                to="#"
                                className="text-muted font-weight-bold"
                            >
                                {f.name}
                            </Link>
                            <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                            </p>
                            </Col>
                        </Row>
                        </div>
                    </Card>
                    )
                })}
                </div>

            </CardBody>
        </Card>
    </>
    )

}

UploadDocuments.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default UploadDocuments;