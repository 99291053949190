import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_NEWS_FEEDS,
  GET_NEWS_FEEDS_ARCHIVED,
  ADD_NEWS_FEED,
  UPDATE_NEWS_FEED,
  UPDATE_NEWS_FEED_ARCHIVED,
  GET_NEWS_FEEDS_VIEWED
} from "./actionTypes"
import {
  getNewsFeedsSuccess,
  getNewsFeedsFail,
  getNewsFeedsArchivedSuccess,
  getNewsFeedsArchivedFail,
  addNewsFeedSuccess,
  addNewsFeedFail,
  updateNewsFeedSuccess,
  updateNewsFeedFail,
  updateNewsFeedArchivedSuccess,
  updateNewsFeedArchivedFail,
  getNewsFeedsViewedSuccess,
  getNewsFeedsViewedFail
} from "./actions"

//Include Both Helper File with needed methods
import { newsFeedsPage, actionUrl, getNewsFeedsViewed } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchNewsFeeds({params,types}) {
  try {
    const response = yield call(newsFeedsPage, params, types )
    console.log(response);
    yield put(getNewsFeedsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getNewsFeedsFail(error))
  }
}

function* fetchNewsFeedsArchived({ params,type }) {
  try {
      const response = yield call(newsFeedsPage, params,type)
      yield put(getNewsFeedsArchivedSuccess(response))
  } catch (error) {
      yield put(getNewsFeedsArchivedFail(error))
  }
}

// function* fetchStaffs({ params,type }) {
//   try {
//       const response = yield call(actionUrl, params,type)
//       yield put(getIncidentReportStaffsSuccess(response))
//   } catch (error) {
//       yield put(getIncidentReportStaffsFail(error))
//   }
// }

// function* fetchStaffsRosters({ params,type }) {
//   try {
//       const response = yield call(incidentReportPage, params,type)
//       yield put(getStaffsRostersSuccess(response))
//   } catch (error) {
//       yield put(getStaffsRostersFail(error))
//   }
// }

function* updateNewsFeed({ payload: data }) {
  try {
      const response = yield call(newsFeedsPage, data)
      yield put(updateNewsFeedSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateNewsFeedFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* addNewsFeed({ payload: data }) {
    try {
        const response = yield call(newsFeedsPage, data)
        yield put(addNewsFeedSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addNewsFeedFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}

function* updateNewsFeedArchived({ payload: data }) {
  try {
      const response = yield call(newsFeedsPage, data)
      yield put(updateNewsFeedArchivedSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateNewsFeedArchivedFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* fetchNewsFeedsViewed({ payload: data }) {
  try {
      const response = yield call(newsFeedsPage, data)
      yield put(getNewsFeedsViewedSuccess(response))
      // toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(getNewsFeedsViewedFail(error))
      toast.error("Something went wrong, please retry", { autoClose: 2000 });
  }
}

function* NewsFeedSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_NEWS_FEEDS, fetchNewsFeeds)
  yield takeEvery(GET_NEWS_FEEDS_ARCHIVED, fetchNewsFeedsArchived)
  yield takeEvery(ADD_NEWS_FEED, addNewsFeed)
  yield takeEvery(UPDATE_NEWS_FEED, updateNewsFeed)
  yield takeEvery(UPDATE_NEWS_FEED_ARCHIVED, updateNewsFeedArchived)
  yield takeEvery(GET_NEWS_FEEDS_VIEWED, fetchNewsFeedsViewed)
}

export default NewsFeedSaga
