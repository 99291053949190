import {
  GET_ALERTS,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAIL,
  GET_ALERT_ROSTER_DETAILS,
  GET_ALERT_ROSTER_DETAILS_SUCCESS,
  GET_ALERT_ROSTER_DETAILS_FAIL,
  UPDATE_ALERT_ROSTER_DETAILS,
  UPDATE_ALERT_ROSTER_DETAILS_SUCCESS,
  UPDATE_ALERT_ROSTER_DETAILS_FAIL
} from "./actionTypes"

export const getAlerts = (params,types) => ({
  type: GET_ALERTS,
  params,
  types,
})
export const getAlertsSuccess = data=> ({
  type: GET_ALERTS_SUCCESS,
  payload: data
})
export const getAlertsFail = error => ({
  type: GET_ALERTS_FAIL,
  payload: error,
})

export const getAlertRosterDetails = (params,types) => ({
  type: GET_ALERT_ROSTER_DETAILS,
  params,
  types,
})

export const getAlertRosterDetailsSuccess = data => ({
  type: GET_ALERT_ROSTER_DETAILS_SUCCESS,
  payload: data,
})

export const getAlertRosterDetailsFail = error => ({
  type: GET_ALERT_ROSTER_DETAILS_FAIL,
  payload: error,
})

export const updateAlertRosterDetails = (params,types) => ({
  type: UPDATE_ALERT_ROSTER_DETAILS,
  params,
  types
})

export const updateAlertRosterDetailsSuccess = data => ({
  type: UPDATE_ALERT_ROSTER_DETAILS_SUCCESS,
  payload: data,
})

export const updateAlertRosterDetailsFail = error => ({
  type: UPDATE_ALERT_ROSTER_DETAILS_FAIL,
  payload: error,
})