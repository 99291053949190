import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_CLIENT_TYPES,
    GET_CLIENT_PACKAGE_TYPES,
    ADD_CLIENT_TYPES,
    ADD_CLIENT_PACKAGE_TYPES,
    UPDATE_CLIENT_PACKAGE_TYPES,
    UPDATE_CLIENT_TYPES
} from "./actionTypes"
import {
  getClientTypesSuccess,
  getClientTypesFail,
  getClientPackageTypesSuccess,
  getClientPackageTypesFail,
  addClientTypesSuccess,
  addClientTypesFail,
  addClientPackageTypesSuccess,
  addClientPackageTypesFail,
  updateClientTypesSuccess,
  updateClientTypesFail,
  updateClientPackageTypesSuccess,
  updateClientPackageTypesFail
} from "./actions"

//Include Both Helper File with needed methods
import { clientTypesPage, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchClientTypes({params,types}) {
  try {
    const response = yield call(clientTypesPage, params, types )
    console.log(response);
    yield put(getClientTypesSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getClientTypesFail(error))
  }
}

function* fetchClientPackageTypes({ params,type }) {
  try {
      const response = yield call(clientTypesPage, params,type)
      yield put(getClientPackageTypesSuccess(response))
  } catch (error) {
      yield put(getClientPackageTypesFail(error))
  }
}


// function* fetchStaffs({ params,type }) {
//   try {
//       const response = yield call(actionUrl, params,type)
//       yield put(getIncidentReportStaffsSuccess(response))
//   } catch (error) {
//       yield put(getIncidentReportStaffsFail(error))
//   }
// }

// function* fetchStaffsRosters({ params,type }) {
//   try {
//       const response = yield call(incidentReportPage, params,type)
//       yield put(getStaffsRostersSuccess(response))
//   } catch (error) {
//       yield put(getStaffsRostersFail(error))
//   }
// }

function* addClientTypes({ payload: data }) {
    try {
        const response = yield call(clientTypesPage, data)
        yield put(addClientTypesSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addClientTypesFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}

function* addClientPackageTypes({ payload: data }) {
    try {
        const response = yield call(clientTypesPage, data)
        yield put(addClientPackageTypesSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addClientPackageTypesFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}

function* updateClientTypes({ payload: data }) {
  try {
      const response = yield call(clientTypesPage, data)
      yield put(updateClientTypesSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateClientTypesFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* updateClientPackageTypes({ payload: data }) {
  try {
      const response = yield call(clientTypesPage, data)
      yield put(updateClientPackageTypesSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateClientPackageTypesFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

// function* fetchNewsFeedsViewed({ payload: data }) {
//   try {
//       const response = yield call(newsFeedsPage, data)
//       yield put(getNewsFeedsViewedSuccess(response))
//       // toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(getNewsFeedsViewedFail(error))
//       toast.error("Something went wrong, please retry", { autoClose: 2000 });
//   }
// }

function* ClientTypesSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_CLIENT_TYPES, fetchClientTypes)
  yield takeEvery(GET_CLIENT_PACKAGE_TYPES, fetchClientPackageTypes)
  yield takeEvery(ADD_CLIENT_TYPES, addClientTypes)
  yield takeEvery(ADD_CLIENT_PACKAGE_TYPES, addClientPackageTypes)
  yield takeEvery(UPDATE_CLIENT_TYPES, updateClientTypes)
  yield takeEvery(UPDATE_CLIENT_PACKAGE_TYPES, updateClientPackageTypes)
//   yield takeEvery(GET_NEWS_FEEDS_VIEWED, fetchNewsFeedsViewed)
}

export default ClientTypesSaga
