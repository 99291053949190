import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import axios from 'axios';

import { ACTION_URL, APP_URL, ADMIN_URL, ROSTER_REPORT_PAGE } from "helpers/url_helper"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
function RosterDepartmentReport(){
  //meta title
  document.title="Roster Report | Tacveiha Panel";

  // console.log(getLoggedInUser );
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [ModalLoading, setModalLoading] = useState(false);
  const [rosterReport, setRosterReport] = useState([]);

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();

//   const selectGroupRostersState = (state) => state.ReportsReducer;
//   const groupRostersProperties = createSelector(
//     selectGroupRostersState,
//       (rosterReducer) => ({
//         rosterReport: rosterReducer.rosterReport,
//         loading: rosterReducer.loading
//       })
//   );
//   const {
//     rosterReport, loading
//   } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      id: user.id,
      service_name: user.service_name,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleFromDate = value => {
    // console.log(value);
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const handleSearchBtn = () => {

    if( fromDate == '' && toDate == '' ) {
        toast.error("Please provide from to till dates", { autoClose: 5000 });
        return false;
    }

    const formData = {
        fromDate: fromDate,
        toDate: toDate
    }

    setModalLoading(true);

    axios.post(APP_URL+'/reports',{'data_for': 'get_department_roster_reports','formData':formData ,'user': loggedUser})
        .then(res => {

        setModalLoading(false);
        const fetchedData = res.data;

        if( fetchedData.status == 'success' ) {
            setRosterReport(fetchedData.roster_reports);
        }

        if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
        }
    })


    // dispatch(onGetRosterReport({'data_for':'roster_reports', 'fromdate': fromDate, 'todate': toDate,'user': loggedUser}));
  };

  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "client_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Staff Name",
        accessor: "staff_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      
      {
        Header: "Roster Date",
        accessor: "start_date",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Case Manager",
        accessor: "case_manager",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Care Notes",
        accessor: "care_notes",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        ModalLoading ? <TopProgressBar ModalLoading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports with notes ( Only completed rosters )" breadcrumbItem="" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                { ModalLoading ? <Spinners setLoading={setLoading} /> :
                    <TableContainer
                        columns={columns}
                        data={rosterReport}
                        isGlobalFilter={true}
                        isDateSelecteOptions={true}
                        showSearchBtn={true}
                        handleUserClick={handleUserClicks}
                        customPageSize={50}
                        className="custom-header-css"
                        excelExport={true}
                        fileName='Roster Report'
                    />
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default RosterDepartmentReport;