import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_CHECKLIST_QUESTIONS,
    UPDATE_CHECKLIST_QUESTIONS,
} from "./actionTypes"
import { getChecklistQuestionsFail, getChecklistQuestionsSuccess, updateChecklistQuestionsFail, updateChecklistQuestionsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { checklistQuestionsPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";


function* fetchChecklistQuestions({params,types}) {
  try {
    const response = yield call(checklistQuestionsPage, params, types )
    console.log(response);
    yield put(getChecklistQuestionsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getChecklistQuestionsFail(error))
  }
}


function* updateChecklistQuestions({ payload: data }) {
  try {
      const response = yield call(checklistQuestionsPage, data)
      yield put(updateChecklistQuestionsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateChecklistQuestionsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

// function* addVaccinationDetails({ payload: data }) {
//     try {
//         const response = yield call(vaccinationDetailsPage, data)
//         yield put(addVaccinationDetailsSuccess(response))
//         toast.success("Details added successfully", { autoClose: 2000 });
//     } catch (error) {
//         yield put(addVaccinationDetailsFail(error))
//         toast.error("Details add Failed", { autoClose: 2000 });
//     }
// }


function* ChecklistQuestionSaga() {
  yield takeEvery(GET_CHECKLIST_QUESTIONS, fetchChecklistQuestions)
  yield takeEvery(UPDATE_CHECKLIST_QUESTIONS, updateChecklistQuestions)
//   yield takeEvery(ADD_VACCINATION_DETAILS, addVaccinationDetails)
}

export default ChecklistQuestionSaga
