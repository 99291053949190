import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import axios from 'axios';
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, ADMIN_URL } from "helpers/url_helper"
import { toast } from "react-toastify";

// import {Name, Email, Tags, Projects, Status } from "../Providers/columns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
  getDepartments as onGetDepartments,
  addNewGroupRoster as onAddNewGroupRoster,
  updateGroupRoster as onUpdateGroupRoster
} from "store/actions";

function AddCasemanager(){
  //meta title
  document.title="Departments| Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [ModalLoading, setModalLoading] = useState(false);
  const [StaffDepartment, setStaffDepartment] = useState([]);
  const [department_dropdown, setDepartment_dropdown] = useState([]);
  const [UserType, setUserType] = useState('');

  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user_type: ( roster && roster.user_type ) || "",
      user_title: ( roster && roster.user_title ) || "",
      case_manager_name: ( roster && roster.case_manager_name ) || "",
      user_email: ( roster && roster.user_email ) || "",
      user_gender: ( roster && roster.user_gender ) || "",
      user_mobile_no: ( roster && roster.user_mobile_no ) || "",
      user_address: ( roster && roster.user_address ) || "",
      provider_contact_name: ( roster && roster.provider_contact_name ) || "",
      provider_contact_relation: ( roster && roster.provider_contact_relation ) || "",
      provider_contact_no: ( roster && roster.provider_contact_no ) || "",
      provider_contact_email: ( roster && roster.provider_contact_email ) || "",
      report_weekly: (roster && roster.report_weekly) ? true : false ,
      report_weekly_email: (roster && roster.report_weekly_email) || "",
      report_monthly: (roster && roster.report_monthly) ? true : false,
      report_monthly_email: (roster && roster.report_monthly_email) || "",

    },
    validationSchema: Yup.object({
      user_type: Yup.string().required("This field is required"),
      user_title: Yup.string().required("This field is required"),
      case_manager_name: Yup.string().required("This field is required"),
      user_email: Yup.string().required("This field is required"),
      user_gender: Yup.string().required("This field is required"),
      user_mobile_no:Yup.string().required("This field is required"),
      user_address:Yup.string().required("This field is required"),
      provider_contact_name: Yup.string().required("This field is required"),
      provider_contact_relation: Yup.string().required("This field is required"),
      provider_contact_no: Yup.string().required("This field is required"),
      provider_contact_email: Yup.string().required("This field is required"),
      report_weekly: Yup.boolean().default(false),
      report_weekly_email: Yup.string().when('report_weekly', {
        is: true,
        then: schema => schema.required("Please provide weekly report email"),
        otherwise: schema => schema.optional(),
      }),
      report_monthly: Yup.boolean().default(false),
      report_monthly_email: Yup.string().when('report_monthly', {
        is: true,
        then: schema => schema.required("Please provide monthly report email"),
        otherwise: schema => schema.optional(),
      }),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          user_id: roster.user_id,
          user_type: values.user_type,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.case_manuser_addressager_name,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
          report_weekly: (values.report_weekly === true) ? 1 : 0,
          report_weekly_email: values.report_weekly_email,
          report_monthly: (values.report_monthly === true) ? 1 : 0,
          report_monthly_email: values.report_monthly_email,
        };


        // console.log(updateUser);        
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'edit_casemanager','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              dispatch(onUpdateCasemanager(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              validation.resetForm();
              setIsEdit(false);
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      } else {
        const newData = {
          user_type: values.user_type,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.case_manuser_addressager_name,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
          report_weekly: (values.report_weekly === true) ? 1 : 0,
          report_weekly_email: values.report_weekly_email,
          report_monthly: (values.report_monthly === true) ? 1 : 0,
          report_monthly_email: values.report_monthly_email,
        };
        // save new user
        setModalLoading(true);

        axios.post(APP_URL+ACTION_URL,{'data_for': 'add_casemanager','formData': newData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            
            if( fetchedData.status == 'success' ) {
              dispatch(onAddDepartments(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.DepartmentReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        providers: rosterReducer.providers,
          loading: rosterReducer.loading
      })
  );
  const {
    providers, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const user = JSON.parse(localStorage.getItem("authUser"));

  // useEffect(() => {
  //   // //if (isEmpty(todaysRosters)) {
  //   //   //if (providers && !providers.length) {
  //   //   const todaysDate = moment().format("YYYY-MM-DD");
  //   //   dispatch(onGetDepartments({'data_for': 'providers','user': user}));
  //   //   //}
  //   // //}
  // }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
      user_id: user.user_id,
      user_type: user.user_type,
      department_id: user.department_id,
      user_title: user.user_title,
      case_manager_name: user.case_manager_name,
      user_email: user.user_email,
      user_gender: user.user_gender,
      user_mobile_no: user.user_mobile_no,
      user_address: user.case_manuser_addressager_name,
      provider_contact_name: user.provider_contact_name,
      provider_contact_relation: user.provider_contact_relation,
      provider_contact_no: user.provider_contact_no,
      provider_contact_email: user.provider_contact_email,
      report_weekly: user.report_weekly,
      report_weekly_email: user.report_weekly_email,
      report_monthly: user.report_monthly,
      report_monthly_email: user.report_monthly_email,
    });
    setStaffDepartment({label:user.department_name,value:department_id});
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleUserType = (type) => {

    validation.values.user_type = type;

    const formData = {
        type: type
    }
    setUserType(type);
    setModalLoading(true);
    
    setStaffDepartment([]);
    setStaffCasemanager([]);
    setDepartment_dropdown([]);
    setCasemanager_dropdown([]);

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const handleDepartmentChange = (e) => {
    setStaffDepartment(e);
    const formData = {
        dep_id: e.value
    }
    setModalLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'loadCaseManager','formData':formData})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res;
        setCasemanager_dropdown(res.data.casemanagers);
    })
  }

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Casemanagers" breadcrumbItem="Add Casemanager" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  { loading ? <Spinners setLoading={setLoading} /> : '' }
              
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    
                    <Row className="mb-4">
                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      >
                          For
                      </Label>
                      <Col sm={9}>
                      { loggedUser.role != 'human_resource' && 
                          <>
                          <Input
                              name="user_type"
                              type="radio"
                              onChange={(e) => {
                                  handleUserType('provider');
                              }}
                              value='provider'
                              checked={ validation.values.user_type == 'provider' ? true : false }
                              invalid={
                                  validation.touched.user_type &&
                                  validation.errors.user_type
                                  ? true
                                  : false
                              }
                          /> &nbsp;&nbsp;Provider
                          </>
                          }
                          &nbsp;&nbsp;<Input
                              name="user_type"
                              type="radio"
                              onChange={(e) => {
                                  handleUserType('contractors');
                              }}
                              value='contractors'
                              checked={ validation.values.user_type == 'contractors' ? true : false }
                              invalid={
                                  validation.touched.user_type &&
                                  validation.errors.user_type
                                  ? true
                                  : false
                              }
                          
                          />&nbsp;&nbsp;3rd Party Contractors
                          {validation.touched.user_type &&
                          validation.errors.user_type ? (
                          <FormFeedback type="invalid">
                          {validation.errors.user_type}
                          </FormFeedback>
                          ) : null}
                          
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      >
                      Department
                      </Label>
                      <Col sm={9}>
                        <Select
                            value={StaffDepartment}
                            name="staff_department"
                            options={department_dropdown}
                            onChange={(e) => setStaffDepartment(e)}
                            className="select2-selection"
                            defaultValue={department_dropdown}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mr'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mrs'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Miss'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Ms'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                
                                />&nbsp;&nbsp;Ms
                                {validation.touched.user_title &&
                                validation.errors.user_title ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_title}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Case Manager Name
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="case_manager_name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.case_manager_name}
                              invalid={
                              validation.touched.case_manager_name &&
                                  validation.errors.case_manager_name
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.case_manager_name &&
                              validation.errors.case_manager_name ? (
                              <FormFeedback type="invalid">
                              {validation.errors.case_manager_name}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Case Manager Email
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_email}
                              invalid={
                              validation.touched.user_email &&
                                  validation.errors.user_email
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_email &&
                              validation.errors.user_email ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_email}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Client Gender
                          </Label>
                          <Col sm={9}>
                              <Input
                                  name="user_gender"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Male'
                                  invalid={
                                      validation.touched.user_gender &&
                                      validation.errors.user_gender
                                      ? true
                                      : false
                                  }
                              /> &nbsp;&nbsp;Male
                              &nbsp;&nbsp;<Input
                                  name="user_gender"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Female'
                                  invalid={
                                      validation.touched.user_gender &&
                                      validation.errors.user_gender
                                      ? true
                                      : false
                                  }
                              
                              />&nbsp;&nbsp;Female
                              
                              {validation.touched.user_gender &&
                              validation.errors.user_gender ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_gender}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Mobile No
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_mobile_no"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_mobile_no}
                              invalid={
                              validation.touched.user_mobile_no &&
                                  validation.errors.user_mobile_no
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_mobile_no &&
                              validation.errors.user_mobile_no ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_mobile_no}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Address
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_address}
                              invalid={
                              validation.touched.user_address &&
                                  validation.errors.user_address
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_address &&
                              validation.errors.user_address ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_address}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <CardTitle className="mb-4">Provider Contact</CardTitle>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_name}
                                    invalid={
                                        validation.touched.provider_contact_name &&
                                        validation.errors.provider_contact_name
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_name &&
                                validation.errors.provider_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_name}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_relation"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_relation}
                                    invalid={
                                        validation.touched.provider_contact_relation &&
                                        validation.errors.provider_contact_relation
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_relation &&
                                validation.errors.provider_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_relation}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_no"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_no}
                                    invalid={
                                        validation.touched.provider_contact_no &&
                                        validation.errors.provider_contact_no
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_no &&
                                validation.errors.provider_contact_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_no}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_email}
                                    invalid={
                                        validation.touched.provider_contact_email &&
                                        validation.errors.provider_contact_email
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_email &&
                                validation.errors.provider_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_email}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                          <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                          >
                            Auto Report Send
                          </Label>
                          <Col sm={9}>
                                <Input
                                    type="checkbox"
                                    value="1"
                                    name="report_weekly"
                                    onChange={validation.handleChange}
                                    checked={validation.values.report_weekly == 1 ? true : false}
                                /> Weekly

                                { validation.values.report_weekly == 1 && 
                                <>
                                <Col sm={12}>
                                  <Input
                                      name="report_weekly_email"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.report_weekly_email}
                                      invalid={
                                        validation.touched.report_weekly_email &&
                                        validation.errors.report_weekly_email
                                        ? true
                                        : false
                                      }
                                  />
                                  <em>Add emails with (,) to send report to multiple emails</em>
                                </Col>

                                {validation.touched.report_weekly_email &&
                                validation.errors.report_weekly_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.report_weekly_email}
                                </FormFeedback>
                                ) : null}
                              </>
                              }
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                          >
                            
                          </Label>
                          <Col sm={9}>
                                <Input
                                    type="checkbox"
                                    value="1"
                                    name="report_monthly"
                                    onChange={validation.handleChange}
                                    checked={validation.values.report_monthly == 1 ? true : false}
                                /> Monthly

                                { validation.values.report_monthly  == 1 && 
                                <>

                                <Col sm={12}>
                                  <Input
                                      name="report_monthly_email"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.report_monthly_email}
                                      invalid={
                                        validation.touched.report_monthly_email &&
                                        validation.errors.report_monthly_email
                                        ? true
                                        : false
                                      }
                                  />
                                  <em>Add emails with (,) to send report to multiple emails</em>
                                </Col>

                                {validation.touched.report_monthly_email &&
                                  validation.errors.report_monthly_email ? (
                                  <FormFeedback type="invalid">
                                  {validation.errors.report_monthly_email}
                                  </FormFeedback>
                                  ) : null}

                                </>
                              }
                          </Col>
                        </Row>
                        
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={ModalLoading}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default AddCasemanager;