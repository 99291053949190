import {
    GET_NEWS_FEEDS,
    GET_NEWS_FEEDS_SUCCESS,
    GET_NEWS_FEEDS_FAIL,
    GET_NEWS_FEEDS_ARCHIVED,
    GET_NEWS_FEEDS_ARCHIVED_SUCCESS,
    GET_NEWS_FEEDS_ARCHIVED_FAIL,
    UPDATE_NEWS_FEED,
    UPDATE_NEWS_FEED_SUCCESS,
    UPDATE_NEWS_FEED_FAIL,
    ADD_NEWS_FEED,
    ADD_NEWS_FEED_SUCCESS,
    ADD_NEWS_FEED_FAIL,
    UPDATE_NEWS_FEED_ARCHIVED,
    UPDATE_NEWS_FEED_ARCHIVED_SUCCESS,
    UPDATE_NEWS_FEED_ARCHIVED_FAIL,
    GET_NEWS_FEEDS_VIEWED,
    GET_NEWS_FEEDS_VIEWED_SUCCESS,
    GET_NEWS_FEEDS_VIEWED_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    newsFeeds: [],
    newsFeedsArchived: [],
    newsFeedsViewed: [],
    error: {},
    loading: false,
  }
  
  const NewsFeedReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS_FEEDS:
        return {
            ...state,
            newsFeeds: state.newsFeeds,
            loading: true
        }

        case GET_NEWS_FEEDS_SUCCESS:
        return {
            ...state,
            newsFeeds: action.payload.data,
            loading: false
        }
  
        case GET_NEWS_FEEDS_FAIL:
        return {
            ...state,
            error: action.payload,
        }
  
        case GET_NEWS_FEEDS_ARCHIVED:
        return {
            ...state,
            newsFeedsArchived: state.newsFeedsArchived,
            loading: true
        }
        case GET_NEWS_FEEDS_ARCHIVED_SUCCESS:
        return {
            ...state,
            newsFeedsArchived: action.payload.data,
            loading: false
        }
  
        case GET_NEWS_FEEDS_ARCHIVED_FAIL:
        return {
            ...state,
            newsFeedsArchived: state.newsFeedsArchived,
            error: action.payload.error,
        }
  
        case UPDATE_NEWS_FEED:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_NEWS_FEED_SUCCESS:
        return {
            ...state,
            newsFeeds: state.newsFeeds.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        };
  
        case UPDATE_NEWS_FEED_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_NEWS_FEED:
        return {
            ...state,
            loading: true
        };

        case ADD_NEWS_FEED_SUCCESS:
        return {
            ...state,
            newsFeeds: [...state.newsFeeds, action.payload.data],
            loading: false
        }

        case ADD_NEWS_FEED_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        }
        case UPDATE_NEWS_FEED_ARCHIVED:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_NEWS_FEED_ARCHIVED_SUCCESS:
        return {
            ...state,
            newsFeedsArchived: state.newsFeedsArchived.map(rosters =>
            rosters.id.toString() === action.payload.data.id.toString()
                    ? { rosters, ...action.payload.data }
                    : rosters
            ),
            loading: false
        };
  
        case UPDATE_NEWS_FEED_ARCHIVED_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
        case GET_NEWS_FEEDS_VIEWED:
        return {
            ...state,
            newsFeedsViwed: [],
            loading: true
        }

        case GET_NEWS_FEEDS_VIEWED_SUCCESS:
        return {
            ...state,
            newsFeedsViewed: action.payload.data,
            loading: false
        }
  
        case GET_NEWS_FEEDS_VIEWED_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        default:
            return state
        }
  }
  
  export default NewsFeedReducer
  