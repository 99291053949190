import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import axios from 'axios';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getIncidentReportStaffs as onGetStaffs,
    getStaffsRosters as onGetStaffsRosters,
    addIncidentReport as onAddNewIncidentReport
} from "store/actions";
import { ADMIN_URL, APP_URL, INCIDENT_REPORT_PAGE } from "helpers/url_helper"

function IncidentReportAdd(){
  //meta title
  document.title="Add Incident Report | Tacveiha Panel";

  // console.log(getLoggedInUser );
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');
  const [staff, setStaff] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.IncidentReportReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        incidentReportStaffRosters: rosterReducer.incidentReportStaffRosters,
        loading: rosterReducer.loading,
        staffs: rosterReducer.staffs,
        isAdded: rosterReducer.isAdded,
        post_msg: rosterReducer.post_msg
      })
  );
  const {
    incidentReportStaffRosters, loading, staffs,isAdded, post_msg
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  // const [isAdded, setIsAdded] = useState(isAdded);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ros_id: (roster && roster.ros_id) || "",
      person_completing_form: (roster && roster.person_completing_form) || "",
      added_by: (roster && roster.added_by) || "",
      person_reporting_incident: (roster && roster.person_reporting_incident) || "",
      client_name: (roster && roster.client_name) || "",
      tasks: ( roster && roster.tasks ) || "",
      staff_name: (roster && roster.staff_name) || "",
      client_id: (roster && roster.client_id) || "",
      staff_id: (roster && roster.staff_id) || "",
      case_manager: (roster && roster.case_manager) || "",
      case_manager_id: (roster && roster.case_manager_id) || "",
      urgent_actions: (roster && roster.urgent_actions) || "",
      incident_hazard: (roster && roster.incident_hazard) || "",
      other_incident_hazard: (roster && roster.other_incident_hazard) || "",
      injuries_desc: (roster && roster.injuries_desc) || "",
      any_witness: (roster && roster.any_witness) || "",
      incident_desc: (roster && roster.incident_desc) || "",
      location: (roster && roster.client_address) || "",
      first_aid: (roster && roster.first_aid) || "",
      ambulance_req: (roster && roster.ambulance_req) || "",
      ambulance_req_notes: (roster && roster.ambulance_req_notes) || "",
      hospitalisation_req: (roster && roster.hospitalisation_req) || "",
      hospitalisation_req_notes: (roster && roster.hospitalisation_req_notes) || "",
    },
    validationSchema: Yup.object({
        case_manager:  Yup.string().required("Please enter case manager name"),
        urgent_actions: Yup.string().required("Please select Urget action requires"),
        incident_hazard: Yup.string().required("Please select incident type"),
        injuries_desc: Yup.string().required("Please enter injury description"),
        any_witness: Yup.string().required("Please select any witness"),
        incident_desc: Yup.string().required("Please enter description of incident"),
        first_aid: Yup.string().required("Please select first aid"),
        ambulance_req: Yup.string().required("Please select ambulance required?"),
        hospitalisation_req: Yup.string().required("Please select hospitalisation required?"),    }),
    onSubmit: values => {
      // if (isEdit) {
      //   const updateUser = {
      //   };
      // } else {
        const newData = {
            ros_id: values.ros_id,
            person_completing_form: values.person_completing_form,
            added_by: values.added_by,
            person_reporting_incident: values.person_reporting_incident,
            tasks: values.tasks,
            client_name: values.client_name,
            staff_name: values.staff_name,
            client_id: values.client_id,
            staff_id: values.staff_id,
            case_manager: values.case_manager,
            case_manager_id: values.case_manager_id,
            urgent_actions: values.urgent_actions,
            incident_hazard: values.incident_hazard,
            other_incident_hazard: values.other_incident_hazard,
            injuries_desc: values.injuries_desc,
            any_witness: values.any_witness,
            incident_desc: values.incident_desc,
            location: values.location,
            first_aid: values.first_aid,
            ambulance_req: values.ambulance_req,
            ambulance_req_notes: values.ambulance_req_notes,
            hospitalisation_req: values.hospitalisation_req,
            hospitalisation_req_notes: values.hospitalisation_req_notes,
        };
        // save new user
        if( values.ambulance_req == 'yes' ) {
          if( values.ambulance_req_notes == '' ) {
            toast.error("Please provide ambulance required notes", { autoClose: 4000 });
            return false;
          }
        }

        if( values.hospitalisation_req == 'yes' ) {
          if( values.hospitalisation_req_notes == '' ) {
            toast.error("Please provide hospitalisation required notes", { autoClose: 4000 });
            return false;
          }
        }


        setModalLoading(true);
        axios.post(APP_URL+INCIDENT_REPORT_PAGE,{'data_for':'add_new_incident','formData': newData, 'user': loggedUser})
        .then(res => {
          setModalLoading(false);
            const fetchedData = res.data;
    
            if( fetchedData.status == 'success' ) {
              localStorage.setItem('incidents_count', fetchedData.incidents_count);
              // let count = parseInt(localStorage.getItem('incidents_count'));
              // count = count + 1;
              // localStorage.setItem('incidents_count', count);

              // localStorage.setItem('incidents_count', parseInt(localStorage.getItem('incidents_count') + 1));
                toast.success(fetchedData.html, { autoClose: 5000 });
                // setIsEdit(false);
                // location.reload();
                
                validation.resetForm();
                window.open('#/incident-reports/active', '_self');
  
            }
    
            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })


        // dispatch(onAddNewIncidentReport({'form_data':newData,'data_for': 'add_new_incident','user': loggedUser}));
        

         
            // setIsEdit(false);
            // toggle();
            
          // if( isAdded ) {
          //   console.log('added');
            
  
          // } else {
          //   // console.log('error');
          //   // toast.error('someting went wrong, pls try again.', { autoClose: 4000 });
          // }


        
        
        //validation.resetForm();
      //}
    },
  });


  useEffect(() => {
    dispatch(onGetStaffs({'data_for':'get_staffs_dropdown','user': loggedUser}));
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
          ros_id: user.ros_id,
          staff_id: user.staff_id,
          client_id: user.client_id,
          person_completing_form: loggedUser.username,
          added_by: loggedUser.uid,
          person_reporting_incident: user.staff_name,
          care_instructions: user.care_instructions,
          client_name: user.client_name,
          staff_name: user.staff_name,
          case_manager: user.case_manager,
          case_manager_id: user.case_manager_id,
          tasks: user.tasks,
          client_address: user.client_address,
    });
    // setIsEdit(true);
    toggle();
  };

  const printUserClick = arg => {
    const user = arg;
    const printUrl = ADMIN_URL+'view_pdf.php?type=incident_report&uid='+arg.report_no;

    window.open(printUrl, "_blank");
    // window.location.href="view_pdf.php?type=incident_report&uid="+arg.report_no
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleStaffChange = (arg) => {
    setStaff(arg);
  }

  const handleSearchBtn = () => {

    // console.log(staff);
    if( staff == '' ) {
        toast.error("Please select staff", { autoClose: 5000 });
        return false;
    }
    dispatch(onGetStaffsRosters({'data_for':'incident_report_staff_rosters','staff': staff,'user': loggedUser}));
  };

  const handleExportExcelLink = () => {
    const url = 'incident_report_excel.php?type=excel_export&fromdate='+fromDate+'&todate='+toDate+'&status=completed';
    window.open(url, "_blank");
  }

  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Roster ID",
        accessor: "ros_id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Roster Date",
        accessor: "roster_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Staff Name",
        accessor: "staff_name",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                Add Incident
                {/* <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip> */}
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  printUserClick(userData);
                }}
              >
                <i className="mdi mdi-print font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Print
                </UncontrolledTooltip>
              </Link>
        
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Incident Reports" />
          
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">

                        <Col sm={6}>

                        <Select
                            value={staff}
                            onChange={(e) => {
                              handleStaffChange(e);
                            }}
                            options={staffs}
                            className="select2-selection"
                          />
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={incidentReportStaffRosters}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    handleExportExcelLink={handleExportExcelLink}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    excelExportLink={true}
                    fileName=''
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                      { modalLoading ? <Spinners setLoading={setLoading} /> :'' }
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Person Completing Form</Label>
                              <Input
                                name="person_completing_form"
                                type="text"
                                value={validation.values.person_completing_form}
                                readOnly
                              />
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Person Reporting Incident Form</Label>
                              <Input
                                name="person_reporting_incident"
                                type="text"
                                value={validation.values.person_reporting_incident}
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Client Name</Label>
                              <Input
                                name="client_name"
                                type="text"
                                value={validation.values.client_name}
                                readOnly
                              />
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Case Manager</Label>
                              <Input
                                name="case_manager"
                                type="text"
                                value={validation.values.case_manager}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.case_manager &&
                                    validation.errors.case_manager
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.case_manager &&
                                  validation.errors.incident_hazard ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.case_manager}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              {/* Caregiver(s) Invloved */}Care Plan
                            </label>
                            <div className="col-md-10">

                            <CKEditor
                              editor={ClassicEditor}
                              data={validation.values.tasks}
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log('Editor is ready to use!', editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                              }}
                            />

                            {/* <Input
                                name="tasks"
                                type="textarea"
                                placeholder="Caregiver(s) Involved"
                                value={validation.values.tasks}
                                rows="3"
                                onChange={validation.handleChange}
                              /> */}
                            </div>
                          </Row>
                        </Col>

                        {/* <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label"> new test</Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label"> new test</Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row> */}

                        <Row form>
                          {/* <Col xs={12}> */}

                            <Row className="mb-6">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                                Urgent Requires / Action
                              </label>
                              <div className="col-md-6">
                              <Input
                                  name="urgent_actions"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="yes"
                                  invalid={
                                    validation.touched.urgent_actions &&
                                      validation.errors.urgent_actions
                                      ? true
                                      : false
                                  }
                                  defaultChecked={validation.values.urgent_actions == 'yes' ? true : false}
                                />  Yes
                                &nbsp;&nbsp;&nbsp;<Input
                                  name="urgent_actions"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='no'
                                  invalid={
                                    validation.touched.urgent_actions &&
                                      validation.errors.urgent_actions
                                      ? true
                                      : false
                                  }
                                  defaultChecked={validation.values.urgent_actions == 'no' ? true : false }
                                />  No
                                {validation.touched.urgent_actions &&
                                  validation.errors.urgent_actions ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.urgent_actions}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Row>
                          {/* </Col> */}
                        </Row>
                        <p className="card-title">Description of Incident / Hazard</p>
                        <Row form>
                          {/* <Col xs={12}> */}

                            <Row className="mb-6">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                               Type
                              </label>
                              <div className="col-md-10">
                              <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="medicalemergency"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'medicalemergency' ? true : false}
                                />   Medical Emergency   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='aggresive_behaviour'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'aggresive_behaviour' ? true : false }
                                />  Aggresive Behaviour
                                &nbsp;&nbsp;&nbsp;
                                
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="slip_trip_fall"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'slip_trip_fall' ? true : false}
                                />   Slip/ Trip/ Fall   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='loss_breakage'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'loss_breakage' ? true : false }
                                />  Loss / Breakage
                                &nbsp;&nbsp;&nbsp;
                                
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="actual_hazard"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'actual_hazard' ? true : false}
                                />  Actual Hazard   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='potential_hazard'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'potential_hazard' ? true : false }
                                />  Potential Hazard
                                &nbsp;&nbsp;&nbsp;
                                
                                
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onClick={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="other"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'other' ? true : false}
                                />  Other   
                                &nbsp;&nbsp;&nbsp;
                                {validation.touched.incident_hazard &&
                                  validation.errors.incident_hazard ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incident_hazard}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Row>
                          {/* </Col> */}
                        </Row>

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="other_incident_hazard"
                                type="text"
                                placeholder="Other"
                                value={validation.values.other_incident_hazard}
                                onChange={validation.handleChange}
                              />
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              Description of Injury(if applicable)
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="injuries_desc"
                                type="textarea"
                                placeholder="Injury description"
                                value={validation.values.injuries_desc}
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.injuries_desc &&
                                    validation.errors.injuries_desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.injuries_desc &&
                                validation.errors.injuries_desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.injuries_desc}
                                </FormFeedback>
                                ) : null}
                            </div>
                            
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              Any Witness
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="any_witness"
                                type="text"
                                placeholder="Any witness"
                                value={validation.values.any_witness}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.any_witness &&
                                    validation.errors.any_witness
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.any_witness &&
                                validation.errors.any_witness ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.any_witness}
                                </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             Description of Incident
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="incident_desc"
                                type="textarea"
                                placeholder="Description of incident"
                                value={validation.values.incident_desc}
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.incident_desc &&
                                    validation.errors.incident_desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.incident_desc &&
                                validation.errors.incident_desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.incident_desc}
                                </FormFeedback>
                                ) : null}
                              <em>(Provide facts about the occurrence.)</em>
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             Location
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="location"
                                type="text"
                                placeholder="Location"
                                value={validation.values.location}
                                readOnly
                              />
                              
                            </div>
                          </Row>
                        </Col>

                        <p className="card-title">Treatment</p>
                        
                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             First Aid
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="first_aid"
                                type="textarea"
                                placeholder="First aid"
                                value={validation.values.first_aid}
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.first_aid &&
                                    validation.errors.first_aid
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.first_aid &&
                                validation.errors.first_aid ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.first_aid}
                                </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                       
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Ambulance Required
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="ambulance_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.ambulance_req &&
                                  validation.errors.ambulance_req
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.ambulance_req == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="ambulance_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.ambulance_req &&
                                  validation.errors.ambulance_req
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.ambulance_req == 'no' ? true : false }
                            />  No
                            {validation.touched.ambulance_req &&
                              validation.errors.ambulance_req ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ambulance_req}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>

                        { validation.values.ambulance_req == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="ambulance_req_notes"
                                  type="textarea"
                                  placeholder="Ambulance required notes"
                                  value={validation.values.ambulance_req_notes}
                                  rows="3"
                                  onChange={validation.handleChange}
                                />
                              </div>
                            </Row>
                          </Col>
                          )}

                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Hospitalisation Required
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="hospitalisation_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.hospitalisation_req &&
                                  validation.errors.hospitalisation_req
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.hospitalisation_req == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="hospitalisation_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.hospitalisation_req &&
                                  validation.errors.hospitalisation_req
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.hospitalisation_req == 'no' ? true : false }
                            />  No
                            {validation.touched.hospitalisation_req &&
                              validation.errors.hospitalisation_req ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hospitalisation_req}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.hospitalisation_req == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="hospitalisation_req_notes"
                                  type="textarea"
                                  placeholder="Hospitalization required notes"
                                  value={validation.values.hospitalisation_req_notes}
                                  rows="3"
                                  onChange={validation.handleChange}
                                  
                                />
                                
                              </div>
                            </Row>
                          </Col>
                          )}
                          {/* <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Office Notified?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="office_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.office_notified &&
                                  validation.errors.office_notified
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.office_notified == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="office_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.office_notified &&
                                  validation.errors.office_notified
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.office_notified == 'no' ? true : false }
                            />  No
                            {validation.touched.office_notified &&
                              validation.errors.office_notified ? (
                              <FormFeedback type="invalid">
                                {validation.errors.office_notified}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.office_notified == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Date & Time
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="office_notified_datetime"
                                  type="datetime-local"
                                  fromDate
                                  placeholder="Office notified date * time"
                                  value={validation.values.office_notified_datetime}
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        { validation.values.office_notified == 'yes' && (
                          <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Office Staff Name
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="office_staff_name"
                                type="text"
                                placeholder="Location"
                                value={validation.values.office_staff_name}
                                readOnly
                              />
                              
                            </div>
                          </Row>
                          </Col>
                        )}
                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Signarure
                            </label>
                            <div className="col-md-10">
                              <img src="validation.values.signature" height="60px;"/>
                            </div>
                          </Row>
                        </Col>

                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Medical Officer Contacted
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="medical_officer_contacted"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.medical_officer_contacted &&
                                  validation.errors.medical_officer_contacted
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.medical_officer_contacted == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="medical_officer_contacted"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.medical_officer_contacted &&
                                  validation.errors.medical_officer_contacted
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.medical_officer_contacted == 'no' ? true : false }
                            />  No
                            {validation.touched.medical_officer_contacted &&
                              validation.errors.medical_officer_contacted ? (
                              <FormFeedback type="invalid">
                                {validation.errors.medical_officer_contacted}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.medical_officer_contacted == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="medical_officer_contacted_notes"
                                  type="textarea"
                                  placeholder="Medical oficer contacted  notes"
                                  value={validation.values.medical_officer_contacted_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Reportable Incident?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="work_cover_claim_made"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.work_cover_claim_made &&
                                  validation.errors.work_cover_claim_made
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.work_cover_claim_made == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="work_cover_claim_made"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.work_cover_claim_made &&
                                  validation.errors.work_cover_claim_made
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.work_cover_claim_made == 'no' ? true : false }
                            />  No
                            {validation.touched.work_cover_claim_made &&
                              validation.errors.work_cover_claim_made ? (
                              <FormFeedback type="invalid">
                                {validation.errors.work_cover_claim_made}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.medical_officer_contacted == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="work_cover_claim_made_notes"
                                  type="textarea"
                                  placeholder="Medical oficer contacted  notes"
                                  value={validation.values.work_cover_claim_made_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Case Manager Nofitifed?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="case_manager_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.case_manager_notified &&
                                  validation.errors.case_manager_notified
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.case_manager_notified == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="case_manager_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.case_manager_notified &&
                                  validation.errors.case_manager_notified
                                  ? true
                                  : false
                              }
                              defaultChecked={validation.values.case_manager_notified == 'no' ? true : false }
                            />  No
                            {validation.touched.case_manager_notified &&
                              validation.errors.case_manager_notified ? (
                              <FormFeedback type="invalid">
                                {validation.errors.case_manager_notified}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Investigation of Incident / Hazard - Factors contribution to Incident
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="invt_fact_incident"
                                type="textarea"
                                placeholder=""
                                value={validation.values.invt_fact_incident}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.invt_fact_incident &&
                                    validation.errors.invt_fact_incident
                                    ? true
                                    : false
                                }
                              />
                              <em>{validation.values.invt_fact_incident_date} by {validation.values.invt_fact_incident_activity_by}</em>
                              {validation.touched.invt_fact_incident &&
                              validation.errors.invt_fact_incident ? (
                              <FormFeedback type="invalid">
                                {validation.errors.invt_fact_incident}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Action Taken / Preventative Measures
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="followup_desc"
                                type="textarea"
                                placeholder=""
                                value={validation.values.followup_desc}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.followup_desc &&
                                    validation.errors.followup_desc
                                    ? true
                                    : false
                                }
                              />
                              <em>{validation.values.followup_date} by {validation.values.followup_name}</em>
                              {validation.touched.invt_fact_incident &&
                              validation.errors.followup_desc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.followup_desc}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Date Discussed at OHS forum adn closed
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="date_diss_ohs_forum"
                                type="text"
                                placeholder=""
                                value={validation.values.date_diss_ohs_forum}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.date_diss_ohs_forum &&
                                    validation.errors.date_diss_ohs_forum
                                    ? true
                                    : false
                                }
                              />
                             
                              {validation.touched.date_diss_ohs_forum &&
                              validation.errors.date_diss_ohs_forum ? (
                              <FormFeedback type="invalid">
                                {validation.errors.date_diss_ohs_forum}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>
                         */}

                        
                        <Row>
                          <Col>

                          <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                            {/* <div className="text-end">
                              <button
                                type="button"
                                className="btn btn-success save-user"
                                onClick={handleUserClicks}
                              >
                                Close
                              </button>
                            </div> */}
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(IncidentReportAdd);