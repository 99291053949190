import {
    GET_PUBLIC_HOLIDAYS,
    GET_PUBLIC_HOLIDAYS_SUCCESS,
    GET_PUBLIC_HOLIDAYS_FAIL,
    ADD_PUBLIC_HOLIDAY,
    ADD_PUBLIC_HOLIDAY_SUCCESS,
    ADD_PUBLIC_HOLIDAY_FAIL,
    UPDATE_PUBLIC_HOLIDAY,
    UPDATE_PUBLIC_HOLIDAY_SUCCESS,
    UPDATE_PUBLIC_HOLIDAY_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    PublicHolidays: [],
    datesDropdown:[],
    monthDropdown:[],
    yearDropdown:[],
    timezonesDropdown:[],
    error: {},
    loading: false,
  }
  
  const PublicHolidaysReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PUBLIC_HOLIDAYS:
            return {
                ...state,
                PublicHolidays: state.PublicHolidays,
                datesDropdown: state.datesDropdown,
                monthDropdown: state.monthDropdown,
                yearDropdown: state.yearDropdown,
                timezonesDropdown: state.timezoneDropdown,
                loading: true
            }

        case GET_PUBLIC_HOLIDAYS_SUCCESS:
            return {
                ...state,
                PublicHolidays: action.payload.publicHolidays,
                datesDropdown: action.payload.datesDropdown,
                monthDropdown: action.payload.monthDropdown,
                yearDropdown: action.payload.yearDropdown,
                timezonesDropdown: action.payload.timezoneDropdown,
                loading: false
            }
  
        case GET_PUBLIC_HOLIDAYS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case ADD_PUBLIC_HOLIDAY:
            return {
                ...state,
                loading: true
            };

        case ADD_PUBLIC_HOLIDAY_SUCCESS:
            return {
                ...state,
                PublicHolidays: [...state.PublicHolidays, action.payload.data],
                loading: false
            }

        case ADD_PUBLIC_HOLIDAY_FAIL:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            }

        case UPDATE_PUBLIC_HOLIDAY:
            return {
                ...state,
                loading: true
            };
  
        case UPDATE_PUBLIC_HOLIDAY_SUCCESS:
            return {
                ...state,
                PublicHolidays: state.PublicHolidays.map(rosters =>
                rosters.phid.toString() === action.payload.data.phid.toString()
                        ? { rosters, ...action.payload.data }
                        : rosters
                ),
                loading: false
            };
  
        case UPDATE_PUBLIC_HOLIDAY_FAIL:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };

        default:
            return state
        }
  }
  
  export default PublicHolidaysReducer
  