// // firebaseConfig.js
// import firebase from 'firebase/compat/app'
// // import 'firebase/firestore';
// // import 'firebase/compat/database';

// import { getDatabase, ref, set } from "firebase/database";
// console.log(process.env.REACT_APP_APIKEY);
// // Firebase configuration
// const firebaseConfig = {
//     // apiKey: process.env.REACT_APP_APIKEY,
//     // authDomain: process.env.REACT_APP_AUTHDOMAIN,
//     // databaseURL: process.env.REACT_APP_DATABASEURL,
//     // projectId: process.env.REACT_APP_PROJECTID,
//     // storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//     // messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//     // appId: process.env.REACT_APP_APPID,
//     // measurementId: process.env.REACT_APP_MEASUREMENTID,

//     apiKey: "AIzaSyDaW4FJaSLrvGf8ELKxQWX11YxyMZlf3-s",
//     authDomain: "attendanceapp-538dc.firebaseio.com",
//     databaseURL: "https://attendanceapp-538dc.firebaseio.com/",
//     projectId: "attendanceapp-538dc",
//     storageBucket: "attendanceapp-538dc.appspot.com",
//     messagingSenderId: "767930403523"
//   };

// // Initialize Firebase
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// const db = getDatabase();

// export { db, ref, set };


// firebaseConfig.js
import firebase from 'firebase/compat/app';
import { database } from 'firebase/compat/database'; // Import Firebase Realtime Database

// Firebase configuration (get this from your Firebase project settings)
const firebaseConfig = {
  apiKey: 'AIzaSyDaW4FJaSLrvGf8ELKxQWX11YxyMZlf3-s',
  authDomain: 'attendanceapp-538dc.firebaseio.com',
  databaseURL: 'https://attendanceapp-538dc.firebaseio.com/', // Realtime Database URL
  // projectId: 'attendanceapp-538dc',
  // storageBucket: 'attendanceapp-538dc',
  // messagingSenderId: '767930403523',
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.database();
// const testingCount = ref(db, 'testingCount');


// Fetch the current count
// const fetchTestingCount = async () => {
//   const snapshot = await get(child(countRef, "count"));
//   if (snapshot.exists()) {
//     console.log('firebase data ' + snapshot.val())
//     return snapshot.val();
//   } else {
//     return 0;
//   }
// };

// // Increment the count
// const incrementCount = async () => {
//   const currentCount = await fetchCount();
//   const newCount = currentCount + 1;
//   await set(child(countRef, "count"), newCount);
// };




export { db };
