/* Rosters */
export const GET_CLIENT_AWAY_ALERT_APPROVED = "GET_CLIENT_AWAY_ALERT_APPROVED"
export const GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS = "GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS"
export const GET_CLIENT_AWAY_ALERT_APPROVED_FAIL = "GET_CLIENT_AWAY_ALERT_APPROVED_FAIL"

export const GET_CLIENT_AWAY_ALERT_UNAPPROVED = "GET_CLIENT_AWAY_ALERT_UNAPPROVED"
export const GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS = "GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS"
export const GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL = "GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL"

export const GET_CLIENT_AWAY_ALERT_DELETED = "GET_CLIENT_AWAY_ALERT_DELETED"
export const GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS = "GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS"
export const GET_CLIENT_AWAY_ALERT_DELETED_FAIL = "GET_CLIENT_AWAY_ALERT_DELETED_FAIL"

export const UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST = "UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST"
export const UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST = "UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST"
export const UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST = "UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST"

