import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import axios from 'axios';
import { toast } from "react-toastify";
import * as moment from "moment";
import { useFormik } from "formik";
import { ACTION_URL, APP_URL, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";

import { Name } from "components/Common/TableColumns";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";
import DynamicLabelToggle from "components/Common/DynamicLabelToggle";
import TableContainer from "components/Common/Bkp-TableContainer";


  const VaccinationFormModal = (params) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [checked, setChecked] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [StaffsVaccinationDetails, setStaffsVaccinationDetails] = useState([]);
    const [VaccinationQuestions, setVaccinationQuestions] = useState([]); 

    const [fields, setFields] = useState([
        { name: '', file: null }
    ]);

    // Handle text input change
    const handleInputChange = (index, event) => {
        const newFields = [...fields];
        newFields[index].name = event.target.value;
        setFields(newFields);
    };

    // Handle file input change
    const handleFileChange = (index, event) => {
        const newFields = [...fields];
        newFields[index].file = event.target.files[0];
        setFields(newFields);
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // You can now send `fields` data to your API, including the uploaded files
        console.log('Form submitted:', fields);
        fields.forEach((field, index) => {
        if (field.file) {
            console.log(`File for field ${index + 1}:`, field.file);
        }
        });
    };




    const handleFormSubmit = () => {

        const formData = new FormData();

         formData.append('file', file);

            console.log( formData);
            return;
            // add data
            setModalLoading(true);
            axios.post(APP_URL+ACTION_URL,{'data_for':'add_assesment_emergency_plan','formData': formData,'user': loggedUser})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                if( fetchedData.status == 'success' ) {
                    toast.success(fetchedData.html, { autoClose: 5000 });
                    validation.resetForm();
                }
    
                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.error, { autoClose: 5000 });
                }
    
            })

    }

    useEffect(() => {
        const formData = {
            user_id: params.staffID
        }
        setModalLoading(true)
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_vaccination_questions','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setVaccinationQuestions(fetchedData.data);
                setStaffsVaccinationDetails(fetchedData.vaccination_reecords);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.error, { autoClose: 5000 });
            }

        })
    },[])


    const handleChecked = (e) => {
        console.log(e.target.value);
        setChecked(e.target.value);
    }

    const columns = useMemo(
        () => [
          {
            Header: "#",
            accessor: "id",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Vaccination For",
            accessor: "vaccination_for",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Vaccination Date",
            accessor: "vaccination_date",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "View Certificate",
            // accessor: "expiry_date",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return (
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                        const userData = cellProps.row.original;
                        handleViewCertificate(userData);
                    }}
                    id={`downloadtooltip-${cellProps.row.original.id}`}
                >
                <i className="dripicons-preview" />
                </Link>

              )
            },
          },
        ],
        []
    )

    const handleViewCertificate = (rows) => {
        if( rows.vaccination_certi ) {
            window.open(SITE_URL+rows.vaccination_certi, '_blank', 'noopener,noreferrer');
        } else {
            toast.error('Certicifate file not available.', { autoClose: 5000 });
        }
    }

    return (
        <>
        { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }

        <Row>
            <Col md={8}>
            <TableContainer
                columns={columns}
                data={StaffsVaccinationDetails}
                isGlobalFilter={true}
                showSearchBtn={true}
                customPageSize={50}
                className="custom-header-css"
                // columnsHide={ColumnsHide}
            />
                
            </Col>
            <Col md={4}>
            {/* <Form> */}

            {/* <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label>Client First Name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    // onChange={validation.handleChange}
                                    // value={validation.values.client_fname}
                                />
                            </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3">
                                <Label>Client Last Name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    // onChange={validation.handleChange}
                                    // value={validation.values.client_lname}
                                />
                            </div>
                        </Col>
                    </Row> */}

            
                { VaccinationQuestions.map((quest,index) => {
                    // <div key={index}>
                        
                    { quest.type === "multi" && 
                    <>
                    { quest.quest1 == "First_Dose" &&

                        <>
                            <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                {quest.quest1}
                                {console.log(quest.quest1)}
                            </Label>
                            <Col md={9}>
                                <Input
                                    name={`first_dose_${quest.id}`}
                                    type="radio"
                                    onChange={(event) => handleClickEvent(index, event)}
                                    value='1'
                                    checked={`first_dose_${quest.id}` == 1 ? true: false}
                                /> &nbsp;&nbsp;Active
                                &nbsp;&nbsp;<Input
                                    name={`first_dose_${quest.id}`}
                                    type="radio"
                                    onChange={(event) => handleClickEvent(index, event)}
                                    value='0'
                                    checked={`first_dose_${quest.id}` == 0 ? true: false}
                                />&nbsp;&nbsp;InActive
                                
                            </Col>

                            </Row>

                            { `first_dose_${quest.id}` == 1  && 
                            <>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        First Dose Date
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                        <Flatpickr
                                            name={`first_dose_${quest.id}`}
                                            type="text"
                                            className="form-control d-block"
                                            placeholder="dd-mm-YYYY"
                                            onChange={(selectedDates, dateStr, instance) => {
                                                handleDate(dateStr, index);
                                            }}
                                            value={`first_dose_${quest_id}`}
                                            options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            minDate: 'today',
                                            defaultDate: 'today',
                                            "locale": {
                                                    "firstDayOfWeek": 1 // start week on Monday
                                                }
                                            }}
                                        />
                                        </InputGroup>
                                        
                                    </Col>
                                </Row>
                            </>
                            }
                        </>
                    }
                    </>
                    }
                    
                    // </div>
                    })
                }
                    {/* <div>
                        <input
                            type="checkbox"
                            id="square-switch1"
                            className="switch"
                            defaultChecked={sq1}
                            onChange={() =>
                            setsq1(!sq1)
                            }
                        />
                        <label
                            htmlFor="square-switch1"
                            data-on-label="On"
                            data-off-label="Off"
                        />
                    </div> */}

                    {/* <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label>Client First Name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_fname}
                                />
                            </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3">
                                <Label>Client Last Name</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    value={validation.values.client_lname}
                                />
                            </div>
                        </Col>
                    </Row> */}

                

                    <div>
                        <button type="submit" className="btn btn-primary w-md">
                        { !!isEdit ? 'Update' : 'Add' }
                        </button>
                    </div>
                {/* </Form> */}

            </Col>
        </Row>

        </> 
    )

    }

    VaccinationFormModal.propTypes = {
        handleChecked: PropTypes.func,
    }

    export default VaccinationFormModal;