import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_FEEDBACKS  } from "./actionTypes"
import {
getFeedbacksSuccess,
getFeedbacksFail
} from "./actions"

//Include Both Helper File with needed methods
import { feedbacksPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchFeedbacks({params,types}) {
  try {
    const response = yield call(feedbacksPage, params, types )
    yield put(getFeedbacksSuccess(response))
  } catch (error) {
    yield put(getFeedbacksFail(error))
  }
}

function* FeedbacksSaga() {
  yield takeEvery(GET_FEEDBACKS, fetchFeedbacks)

}

export default FeedbacksSaga
