import {
    GET_NEWS_FEEDS,
    GET_NEWS_FEEDS_SUCCESS,
    GET_NEWS_FEEDS_FAIL,
    GET_NEWS_FEEDS_ARCHIVED,
    GET_NEWS_FEEDS_ARCHIVED_SUCCESS,
    GET_NEWS_FEEDS_ARCHIVED_FAIL,
    UPDATE_NEWS_FEED,
    UPDATE_NEWS_FEED_SUCCESS,
    UPDATE_NEWS_FEED_FAIL,
    ADD_NEWS_FEED,
    ADD_NEWS_FEED_SUCCESS,
    ADD_NEWS_FEED_FAIL,
    GET_NEWS_FEEDS_VIEWED,
    GET_NEWS_FEEDS_VIEWED_SUCCESS,
    GET_NEWS_FEEDS_VIEWED_FAIL
  } from "./actionTypes"
  
  export const getNewsFeeds = (params,types) => ({
    type: GET_NEWS_FEEDS,
    params,
    types,
  })
  
  export const getNewsFeedsSuccess = data => ({
    type: GET_NEWS_FEEDS_SUCCESS,
    payload: data,
  })
  
  export const getNewsFeedsFail = error => ({
    type: GET_NEWS_FEEDS_FAIL,
    payload: error,
  })

  export const getNewsFeedsArchived = (params,types) => ({
    type: GET_NEWS_FEEDS_ARCHIVED,
    params,
    types,
  })
  
  export const getNewsFeedsArchivedSuccess = data => ({
    type: GET_NEWS_FEEDS_ARCHIVED_SUCCESS,
    payload: data,
  })
  
  export const getNewsFeedsArchivedFail = error => ({
    type: GET_NEWS_FEEDS_ARCHIVED_FAIL,
    payload: error,
  })

  export const addNewsFeed = (params,types) => ({
    type: ADD_NEWS_FEED,
    payload: params,
    types
  })

  export const addNewsFeedSuccess = data => ({
    type: ADD_NEWS_FEED_SUCCESS,
    payload: data,
  })

  export const addNewsFeedFail = error => ({
    type: ADD_NEWS_FEED_FAIL,
    payload: error,
  })

  export const updateNewsFeed = (params,types) => ({
    type: UPDATE_NEWS_FEED,
    payload: params,
    types
  })

  export const updateNewsFeedSuccess = data => ({
    type: UPDATE_NEWS_FEED_SUCCESS,
    payload: data,
  })

  export const updateNewsFeedFail = error => ({
    type: UPDATE_NEWS_FEED_FAIL,
    payload: error,
  })

  export const updateNewsFeedArchived = (params,types) => ({
    type: UPDATE_NEWS_FEED,
    payload: params,
    types
  })

  export const updateNewsFeedArchivedSuccess = data => ({
    type: UPDATE_NEWS_FEED_SUCCESS,
    payload: data,
  })

  export const updateNewsFeedArchivedFail = error => ({
    type: UPDATE_NEWS_FEED_FAIL,
    payload: error,
  })
  

  export const getNewsFeedsViewed = (params,types) => ({
    type: GET_NEWS_FEEDS_VIEWED,
    payload: params,
    types
  })
  
  export const getNewsFeedsViewedSuccess = data => ({
    type: GET_NEWS_FEEDS_VIEWED_SUCCESS,
    payload: data,
  })
  
  export const getNewsFeedsViewedFail = error => ({
    type: GET_NEWS_FEEDS_VIEWED_FAIL,
    payload: error,
  })

//   export const getStaffsRosters = (params,types) => ({
//     type: GET_STAFFS_ROSTERS,
//     params,
//     types
//   })

//   export const getStaffsRostersSuccess = data => ({
//     type: GET_STAFFS_ROSTERS_SUCCESS,
//     payload: data,
//   })

//   export const getStaffsRostersFail = error => ({
//     type: GET_STAFFS_ROSTERS_FAIL,
//     payload: error,
//   })


