import {
    GET_PACKAGE_REPORT_SETTINGS,
    GET_PACKAGE_REPORT_SETTINGS_SUCCESS,
    GET_PACKAGE_REPORT_SETTINGS_FAIL,
    UPDATE_PACKAGE_REPORT_SETTINGS,
    UPDATE_PACKAGE_REPORT_SETTINGS_SUCCESS,
    UPDATE_PACKAGE_REPORT_SETTINGS_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    packageReport: [],
    departmentsDropdown:[],
    repeatTypesDropdown:[],
    error: {},
    loading: false,
  }
  
  const PackageReportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PACKAGE_REPORT_SETTINGS:
        return {
            ...state,
            packageReport : [],
            //settings: state.settings,
            loading: true
        }

        case GET_PACKAGE_REPORT_SETTINGS_SUCCESS:
        return {
            ...state,
            packageReport: action.payload.data,
            departmentsDropdown: action.payload.data.departmentsDropdown,
            repeatTypesDropdown: action.payload.data.repeatTypesDropdown,
            loading: false
        }
  
        case GET_PACKAGE_REPORT_SETTINGS_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_PACKAGE_REPORT_SETTINGS:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_PACKAGE_REPORT_SETTINGS_SUCCESS:
        return {
            ...state,
            packageReport: action.payload.data,
            loading: false
        };
  
        case UPDATE_PACKAGE_REPORT_SETTINGS_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };
        default:
            return state
        }
  }
  
  export default PackageReportReducer
  