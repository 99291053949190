import React from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import ClientAssementCarePlanModal from "components/Forms/ClientAssesmentCarePlan";


const ClientsPlan = () => {

    document.title = "Form Layouts | Skote - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Form Layouts" />
          <ClientAssementCarePlanModal />
          </Container>
        </div>
    </React.Fragment>
  )
}

export default ClientsPlan;