import {
    GET_PAYCATEGORY_LIST,
    GET_PAYCATEGORY_LIST_SUCCESS,
    GET_PAYCATEGORY_LIST_FAIL,
    UPDATE_PAYCATEGORY_LIST,
    UPDATE_PAYCATEGORY_LIST_SUCCESS,
    UPDATE_PAYCATEGORY_LIST_FAIL,
    ADD_PAYCATEGORY,
    ADD_PAYCATEGORY_SUCCESS,
    ADD_PAYCATEGORY_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    payCategory: [],
    error: {},
    loading: false,
  }
  
  const PayCategoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PAYCATEGORY_LIST:
        return {
            ...state,
            //settings: state.settings,
            loading: true
        }

        case GET_PAYCATEGORY_LIST_SUCCESS:
        return {
            ...state,
            payCategory: action.payload.data,
            loading: false
        }
  
        case GET_PAYCATEGORY_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }

        case UPDATE_PAYCATEGORY_LIST:
        return {
            ...state,
            loading: true
        };
  
        case UPDATE_PAYCATEGORY_LIST_SUCCESS:
        return {
            ...state,
            payCategory: state.payCategory.map(rosters =>
                rosters.id.toString() === action.payload.data.id.toString()
                        ? { rosters, ...action.payload.data }
                        : rosters
            ),
            loading: false
        };
  
        case UPDATE_PAYCATEGORY_LIST_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false
        };

        case ADD_PAYCATEGORY:
        return {
            ...state,
            loading: true
        };

        case ADD_PAYCATEGORY_SUCCESS:
        return {
            ...state,
            payCategory: [...state.payCategory, action.payload.data],
            loading: false
        }

        case ADD_PAYCATEGORY_FAIL:
        return {
            ...state,
            error: action.payload.error,
            loading: false,
        }
        default:
            return state
        }
  }
  
  export default PayCategoryReducer
  