import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getIncidentReportCompleted as onGetIncidentReportCompleted,
} from "store/actions";
import { ADMIN_URL } from "helpers/url_helper"

function IncidentReportCompleted(){
  //meta title
  document.title="Incident Report Completed | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [roster, setRoster] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      person_completing_form: (roster && roster.person_completing_form) || "",
      person_reporting_incident: (roster && roster.person_reporting_incident) || "",
      client_name: (roster && roster.client_name) || "",
      case_manager: (roster && roster.case_manager) || "",
      urgent_actions: (roster && roster.urgent_actions) || "",
      incident_hazard: (roster && roster.incident_hazard) || "",
      person_completing_form: (roster && roster.person_completing_form) || "",
      other_incident_hazard: (roster && roster.other_incident_hazard) || "",
      injuries_desc: (roster && roster.injuries_desc) || "",
      any_witness: (roster && roster.any_witness) || "",
      incident_desc: (roster && roster.incident_desc) || "",
      location: (roster && roster.location) || "",
      first_aid: (roster && roster.first_aid) || "",
      ambulance_req: (roster && roster.ambulance_req) || "",
      ambulance_req_notes: (roster && roster.ambulance_req_notes) || "",
      hospitalisation_req: (roster && roster.hospitalisation_req) || "",
      hospitalisation_req_notes: (roster && roster.hospitalisation_req_notes) || "",
      office_notified: (roster && roster.office_notified) || "",
      office_notified_datetime: (roster && roster.office_notified_datetime) || "",

      office_staff_name: (roster && roster.office_staff_name) || "",
      report_no: ( roster && roster.report_no ) || "",
      signature: (roster && roster.signature) || "",
      medical_officer_contacted: (roster && roster.medical_officer_contacted) || "",
      medical_officer_contacted_notes: (roster && roster.medical_officer_contacted_notes) || "",
      work_cover_claim_made: (roster && roster.work_cover_claim_made) || "",
      work_cover_claim_made_notes: (roster && roster.work_cover_claim_made_notes) || "",
      case_manager_notified: (roster && roster.case_manager_notified) || "",
      invt_fact_incident: (roster && roster.invt_fact_incident) || "",
      invt_fact_incident_date: (roster && roster.invt_fact_incident_date) || "",
      invt_fact_incident_activity_by: (roster && roster.invt_fact_incident_activity_by) || "",
      followup_desc: (roster && roster.followup_desc) || "",
      followup_date: (roster && roster.followup_date) || "",
      followup_name: (roster && roster.followup_name) || "",
      date_diss_ohs_forum: (roster && roster.date_diss_ohs_forum) || "",

    },
    // validationSchema: Yup.object({
    //     person_completing_form: Yup.string().required("Please Enter From Date"),
    //     toDate: Yup.string().required("Please Enter To Date"),
    // }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          report_no: roster.report_no,
          urgent_actions: roster.urgent_actions,
          incident_hazard: roster.incident_hazard,
          person_completing_form: roster.person_completing_form,
          other_incident_hazard: roster.other_incident_hazard,
          injuries_desc: roster.injuries_desc,
          any_witness: roster.any_witness,
          incident_desc: roster.incident_desc,
          location: (roster && roster.location) || "",
          first_aid: roster.first_aid,
          ambulance_req: roster.ambulance_req,
          ambulance_req_notes: roster.ambulance_req_notes,
          hospitalisation_req: roster.hospitalisation_req,
          hospitalisation_req_notes: roster.hospitalisation_req_notes,
          office_notified: roster.office_notified,
          office_notified_datetime: roster.office_notified_datetime,
          office_staff_name: roster.office_staff_name,
          report_no: roster.report_no,
          signature: (roster && roster.signature) || "",
          medical_officer_contacted: roster.medical_officer_contacted,
          medical_officer_contacted_notes: roster.medical_officer_contacted_notes,
          work_cover_claim_made: roster.work_cover_claim_made,
          work_cover_claim_made_notes: roster.work_cover_claim_made_notes,
          case_manager_notified: roster.case_manager_notified,
          invt_fact_incident: roster.invt_fact_incident,
          invt_fact_incident_date: roster.invt_fact_incident_date,
          invt_fact_incident_activity_by: roster.invt_fact_incident_activity_by,
          followup_desc: roster.followup_desc,
          followup_date: roster.followup_date,
          followup_name: roster.followup_name,
          date_diss_ohs_forum: roster.date_diss_ohs_forum,
        };

        // update user
        // dispatch(onUpdateClientsServiceTypes('update_service_types',updateUser));
        // validation.resetForm();
        // setIsEdit(false);
      } else {
        // const newData = {
        //     fromDate: values["fromDate"],
        //     toDate: values["toDate"]
        // };
        // // save new user
        // dispatch(onAddNewClientsServiceTypes('add_service_types',newData));
        // validation.resetForm();
      }
      toggle();
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.IncidentReportReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        incidentReportsCompleted: rosterReducer.incidentReportsCompleted,
        loading: rosterReducer.loading
      })
  );
  const {
    incidentReportsCompleted, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);


//   useEffect(() => {
//     dispatch(onGetIncidentReportCompleted({'data_for':'incident_report_completed','fromdate': fromdate ,'todate': toDate,'user': loggedUser}));
//   }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
          report_no: user.report_no,
          person_completing_form: user.person_completing_form,
          person_reporting_incident: user.person_reporting_incident,
          client_name: user.client_name,
          case_manager: user.case_manager,
          urgent_actions: user.urgent_actions,
          incident_hazard: user.incident_hazard,
          other_incident_hazard: user.other_incident_hazard,
          injuries_desc: user.injuries_desc,
          any_witness: user.any_witness,
          incident_desc: user.incident_desc,
          location: (user && user.location) || "",
          first_aid: user.first_aid,
          ambulance_req: user.ambulance_req,
          ambulance_req_notes: user.ambulance_req_notes,
          hospitalisation_req: user.hospitalisation_req,
          hospitalisation_req_notes: user.hospitalisation_req_notes,
          office_notified: user.office_notified,
          office_notified_datetime: user.office_notified_datetime,
          office_staff_name: user.office_staff_name,
          signature: (user && user.signature) || "",
          medical_officer_contacted: user.medical_officer_contacted,
          medical_officer_contacted_notes: user.medical_officer_contacted_notes,
          work_cover_claim_made: user.work_cover_claim_made,
          work_cover_claim_made_notes: user.work_cover_claim_made_notes,
          case_manager_notified: user.case_manager_notified,
          invt_fact_incident: user.invt_fact_incident,
          invt_fact_incident_date: user.invt_fact_incident_date,
          invt_fact_incident_activity_by: user.invt_fact_incident_activity_by,
          followup_desc: user.followup_desc,
          followup_date: user.followup_date,
          followup_name: user.followup_name,
          date_diss_ohs_forum: user.date_diss_ohs_forum,
    });
    setIsEdit(true);
    toggle();
  };

  const printUserClick = arg => {
    const user = arg;
    const printUrl = ADMIN_URL+'view_pdf.php?type=incident_report&uid='+arg.report_no;

    window.open(printUrl, "_blank");
    // window.location.href="view_pdf.php?type=incident_report&uid="+arg.report_no
  };

  const handleUserClicks = () => {
    window.open('#/incident-reports/add', '_blank');
    // setRoster("");
    // setIsEdit(false);
    // toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };


  const handleFromDate = value => {
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  const handleSearchBtn = () => {
    if( fromDate == '' || toDate == '' ) {
        toast.error("Please provide all details", { autoClose: 5000 });
        return false;
    }
    dispatch(onGetIncidentReportCompleted({'data_for':'incident_report_completed','fromdate': fromDate ,'todate': toDate,'user': loggedUser}));
  };

  const handleExportExcelLink = () => {
    const url = ADMIN_URL+'incident_report_excel.php?type=excel_export&fromdate='+fromDate+'&todate='+toDate+'&status=completed';
    window.open(url, "_blank");
  }

  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "Report No",
        accessor: "report_no",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Roster Date",
        accessor: "roster_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

      {
        Header: "Staff Name",
        accessor: "staff_name",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        disableFilters: false, 
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  printUserClick(userData);
                }}
              >
                <i className="mdi mdi-print font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Print
                </UncontrolledTooltip>
              </Link>
        
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Incident Reports" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        {/*<Col sm={2}>

                         <select
                          className="select2-selection"
                          value={clientDepartment}
                          onChange={(e) => {
                            setClientDeparment(e.target.value);
                          }}
                          
                        >
                          <option key={0} value={0}>
                              Select Client Department
                            </option>
                          {clientDepartments.map(staff => (
                            <option key={staff.user_id} value={staff.user_id}>
                              {staff.department_name}
                            </option>
                          ))}
                        </select> */}

                        {/* <Select
                            value={clientDepartment}
                            onChange={(e) => {
                              handleSelectGroup(e);
                            }}
                            options={clientDepartments}
                            className="select2-selection"
                          />

                        </Col> */}
                        {/* <Col sm={2}>

                        <Select
                            value={client}
                            onChange={(e) => {
                              handleSelectGroupClient(e);
                            }}
                            options={clients}
                            className="select2-selection"
                          />
                        </Col>

                        <Col sm={2}>

                        <Select
                            value={rosterStatus}
                            onChange={(e) => {
                              handleRosterStatus(e);
                            }}
                            options={rosterStatusOptions}
                            className="select2-selection"
                          />

                        </Col> */}

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={incidentReportsCompleted}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    handleExportExcelLink={handleExportExcelLink}
                    isAddBtn={loggedUser.role == 'admin' || loggedUser.role == 'sub_admin' ? true : false }
                    btnText={'Add Incident'}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    excelExportLink={true}
                    fileName=''
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        // onSubmit={e => {
                        //   e.preventDefault();
                        //   validation.handleSubmit();
                        //   return false;
                        // }}
                      >
                        <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Person Completing Form</Label>
                              <Input
                                name="person_completing_form"
                                type="text"
                                value={validation.values.person_completing_form}
                                readOnly
                              />
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Person Reporting Incident Form</Label>
                              <Input
                                name="person_reporting_incident"
                                type="text"
                                value={validation.values.person_reporting_incident}
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Client Name</Label>
                              <Input
                                name="client_name"
                                type="text"
                                value={validation.values.client_name}
                                readOnly
                              />
                            </div>
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Case Manager</Label>
                              <Input
                                name="case_manager"
                                type="text"
                                value={validation.values.case_manager}
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* <Row form>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label"> new test</Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>

                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label"> new test</Label>
                              <Input
                                name="service_name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_name || ""}
                                invalid={
                                  validation.touched.service_name &&
                                    validation.errors.service_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.service_name &&
                                validation.errors.service_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.service_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row> */}

                        <Row form>
                          {/* <Col xs={12}> */}

                            <Row className="mb-6">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                                Urgent Requires / Action
                              </label>
                              <div className="col-md-6">
                              <Input
                                  name="urgent_actions"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="yes"
                                  invalid={
                                    validation.touched.urgent_actions &&
                                      validation.errors.urgent_actions
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.urgent_actions == 'yes' ? true : false}
                                />  Yes
                                &nbsp;&nbsp;&nbsp;<Input
                                  name="urgent_actions"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='no'
                                  invalid={
                                    validation.touched.urgent_actions &&
                                      validation.errors.urgent_actions
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.urgent_actions == 'no' ? true : false }
                                />  No
                                {validation.touched.urgent_actions &&
                                  validation.errors.urgent_actions ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.urgent_actions}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Row>
                          {/* </Col> */}
                        </Row>
                        <p className="card-title">Description of Incident / Hazard</p>
                        <Row form>
                          {/* <Col xs={12}> */}

                            <Row className="mb-6">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                               Type
                              </label>
                              <div className="col-md-10">
                              <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="yes"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'yes' ? true : false}
                                />   Medical Emergency   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='no'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'no' ? true : false }
                                />  Aggresive Behaviour
                                &nbsp;&nbsp;&nbsp;
                                {validation.touched.incident_hazard &&
                                  validation.errors.incident_hazard ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incident_hazard}
                                  </FormFeedback>
                                ) : null}
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="slip_trip_fall"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'slip_trip_fall' ? true : false}
                                />   Slip/ Trip/ Fall   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='loss_breakage'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'loss_breakage' ? true : false }
                                />  Loss / Breakage
                                &nbsp;&nbsp;&nbsp;
                                {validation.touched.incident_hazard &&
                                  validation.errors.incident_hazard ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incident_hazard}
                                  </FormFeedback>
                                ) : null}
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="actual_hazard"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'actual_hazard' ? true : false}
                                />  Actual Hazard   
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value='potential_hazard'
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'potential_hazard' ? true : false }
                                />  Potential Hazard
                                &nbsp;&nbsp;&nbsp;
                                {validation.touched.incident_hazard &&
                                  validation.errors.incident_hazard ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.incident_hazard}
                                  </FormFeedback>
                                ) : null}
                                
                                <Input
                                  name="incident_hazard"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value="other"
                                  invalid={
                                    validation.touched.incident_hazard &&
                                      validation.errors.incident_hazard
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.incident_hazard == 'other' ? true : false}
                                />  Other   
                                &nbsp;&nbsp;&nbsp;
                                
                              </div>
                            </Row>
                          {/* </Col> */}
                        </Row>

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="other_incident_hazard"
                                type="text"
                                placeholder="Other"
                                value={validation.values.other_incident_hazard}
                              />
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              Description of Injury(if applicable)
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="injuries_desc"
                                type="textarea"
                                placeholder=""
                                value={validation.values.injuries_desc}
                                rows="3"
                              />
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              Any Witness
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="any_witness"
                                type="text"
                                placeholder=""
                                value={validation.values.any_witness}
                              />
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             Description of Incident
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="incident_desc"
                                type="textarea"
                                placeholder=""
                                value={validation.values.incident_desc}
                                rows="3"
                              />
                              <em>(Provide facts about the occurrence.)</em>
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             Location
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="location"
                                type="text"
                                placeholder="Location"
                                value={validation.values.location}
                                readOnly
                              />
                              
                            </div>
                          </Row>
                        </Col>

                        <p className="card-title">Treatment</p>
                        
                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                             First Aid
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="first_aid"
                                type="textarea"
                                placeholder=""
                                value={validation.values.first_aid}
                                rows="3"
                              />
                            </div>
                          </Row>
                        </Col>

                       
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Ambulance Required
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="ambulance_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.ambulance_req &&
                                  validation.errors.ambulance_req
                                  ? true
                                  : false
                              }
                              checked={validation.values.ambulance_req == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="ambulance_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.ambulance_req &&
                                  validation.errors.ambulance_req
                                  ? true
                                  : false
                              }
                              checked={validation.values.ambulance_req == 'no' ? true : false }
                            />  No
                            {validation.touched.ambulance_req &&
                              validation.errors.ambulance_req ? (
                              <FormFeedback type="invalid">
                                {validation.errors.ambulance_req}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>

                        { validation.values.ambulance_req == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="ambulance_req_notes"
                                  type="textarea"
                                  placeholder="Hospitalization required notes"
                                  value={validation.values.ambulance_req_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                          )}

                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Hospitalisation Required
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="hospitalisation_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.hospitalisation_req &&
                                  validation.errors.hospitalisation_req
                                  ? true
                                  : false
                              }
                              checked={validation.values.hospitalisation_req == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="hospitalisation_req"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.hospitalisation_req &&
                                  validation.errors.hospitalisation_req
                                  ? true
                                  : false
                              }
                              checked={validation.values.hospitalisation_req == 'no' ? true : false }
                            />  No
                            {validation.touched.hospitalisation_req &&
                              validation.errors.hospitalisation_req ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hospitalisation_req}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.hospitalisation_req == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="hospitalisation_req_notes"
                                  type="textarea"
                                  placeholder="Hospitalization required notes"
                                  value={validation.values.hospitalisation_req_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                          )}
                          <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Office Notified?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="office_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.office_notified &&
                                  validation.errors.office_notified
                                  ? true
                                  : false
                              }
                              checked={validation.values.office_notified == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="office_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.office_notified &&
                                  validation.errors.office_notified
                                  ? true
                                  : false
                              }
                              checked={validation.values.office_notified == 'no' ? true : false }
                            />  No
                            {validation.touched.office_notified &&
                              validation.errors.office_notified ? (
                              <FormFeedback type="invalid">
                                {validation.errors.office_notified}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.office_notified == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Date & Time
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="office_notified_datetime"
                                  type="datetime-local"
                                  fromDate
                                  placeholder="Office notified date * time"
                                  value={validation.values.office_notified_datetime}
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        { validation.values.office_notified == 'yes' && (
                          <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Office Staff Name
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="office_staff_name"
                                type="text"
                                placeholder="Location"
                                value={validation.values.office_staff_name}
                                readOnly
                              />
                              
                            </div>
                          </Row>
                          </Col>
                        )}
                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Signarure
                            </label>
                            <div className="col-md-10">
                              <img src="validation.values.signature" height="60px;"/>
                            </div>
                          </Row>
                        </Col>

                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Medical Officer Contacted
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="medical_officer_contacted"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.medical_officer_contacted &&
                                  validation.errors.medical_officer_contacted
                                  ? true
                                  : false
                              }
                              checked={validation.values.medical_officer_contacted == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="medical_officer_contacted"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.medical_officer_contacted &&
                                  validation.errors.medical_officer_contacted
                                  ? true
                                  : false
                              }
                              checked={validation.values.medical_officer_contacted == 'no' ? true : false }
                            />  No
                            {validation.touched.medical_officer_contacted &&
                              validation.errors.medical_officer_contacted ? (
                              <FormFeedback type="invalid">
                                {validation.errors.medical_officer_contacted}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.medical_officer_contacted == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="medical_officer_contacted_notes"
                                  type="textarea"
                                  placeholder="Medical oficer contacted  notes"
                                  value={validation.values.medical_officer_contacted_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Reportable Incident?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="work_cover_claim_made"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.work_cover_claim_made &&
                                  validation.errors.work_cover_claim_made
                                  ? true
                                  : false
                              }
                              checked={validation.values.work_cover_claim_made == 'yes' ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="work_cover_claim_made"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.work_cover_claim_made &&
                                  validation.errors.work_cover_claim_made
                                  ? true
                                  : false
                              }
                              checked={validation.values.work_cover_claim_made == 'no' ? true : false }
                            />  No
                            {validation.touched.work_cover_claim_made &&
                              validation.errors.work_cover_claim_made ? (
                              <FormFeedback type="invalid">
                                {validation.errors.work_cover_claim_made}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        { validation.values.medical_officer_contacted == 'yes' && (
                          <Col className="mb-6">
                            <Row className="mb-3">
                              <label
                                htmlFor="example-tel-input"
                                className="col-md-2 col-form-label"
                              >
                              Notes
                              </label>
                              <div className="col-md-10">
                                <Input
                                  name="work_cover_claim_made_notes"
                                  type="textarea"
                                  placeholder="Medical oficer contacted  notes"
                                  value={validation.values.work_cover_claim_made_notes}
                                  rows="3"
                                />
                              </div>
                            </Row>
                          </Col>
                        )}
                        
                        <Row className="mb-6">
                          <label
                            htmlFor="example-tel-input"
                            className="col-md-2 col-form-label"
                          >
                            Case Manager Nofitifed?
                          </label>
                          <div className="col-md-6">
                          <Input
                              name="case_manager_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value="yes"
                              invalid={
                                validation.touched.case_manager_notified &&
                                  validation.errors.case_manager_notified
                                  ? true
                                  : false
                              }
                              checked={validation.values.case_manager_notified == 1 ? true : false}
                            />  Yes
                            &nbsp;&nbsp;&nbsp;<Input
                              name="case_manager_notified"
                              type="radio"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value='no'
                              invalid={
                                validation.touched.case_manager_notified &&
                                  validation.errors.case_manager_notified
                                  ? true
                                  : false
                              }
                              checked={validation.values.case_manager_notified == 0 ? true : false }
                            />  No
                            {validation.touched.case_manager_notified &&
                              validation.errors.case_manager_notified ? (
                              <FormFeedback type="invalid">
                                {validation.errors.case_manager_notified}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Row>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Report No
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="report_no"
                                type="text"
                                placeholder="Report No"
                                value={validation.values.report_no}
                                readOnly
                              />
                              
                            </div>
                          </Row>
                          </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Investigation of Incident / Hazard - Factors contribution to Incident
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="invt_fact_incident"
                                type="textarea"
                                placeholder=""
                                value={validation.values.invt_fact_incident}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.invt_fact_incident &&
                                    validation.errors.invt_fact_incident
                                    ? true
                                    : false
                                }
                              />
                              <em>{validation.values.invt_fact_incident_date} by {validation.values.office_staff_name}</em>
                              {validation.touched.invt_fact_incident &&
                              validation.errors.invt_fact_incident ? (
                              <FormFeedback type="invalid">
                                {validation.errors.invt_fact_incident}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Action Taken / Preventative Measures
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="followup_desc"
                                type="textarea"
                                placeholder=""
                                value={validation.values.followup_desc}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.followup_desc &&
                                    validation.errors.followup_desc
                                    ? true
                                    : false
                                }
                              />
                              <em>{validation.values.followup_date} by {validation.values.followup_name}</em>
                              {validation.touched.invt_fact_incident &&
                              validation.errors.followup_desc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.followup_desc}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Col className="mb-6">
                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                            Date Discussed at OHS forum adn closed
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="date_diss_ohs_forum"
                                type="text"
                                placeholder=""
                                value={validation.values.date_diss_ohs_forum}
                                rows="4"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.date_diss_ohs_forum &&
                                    validation.errors.date_diss_ohs_forum
                                    ? true
                                    : false
                                }
                              />
                             
                              {validation.touched.date_diss_ohs_forum &&
                              validation.errors.date_diss_ohs_forum ? (
                              <FormFeedback type="invalid">
                                {validation.errors.date_diss_ohs_forum}
                              </FormFeedback>
                            ) : null}
                            </div>
                          </Row>
                        </Col>
                        
                        
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="button"
                                className="btn btn-success save-user"
                                onClick={() => setModal(false)}
                              >
                                Close
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(IncidentReportCompleted);