/* Contractors */
export const GET_CONTRACTORS = "GET_CONTRACTORS"
export const GET_CONTRACTORS_SUCCESS = "GET_CONTRACTORS_SUCCESS"
export const GET_CONTRACTORS_FAIL = "GET_CONTRACTORS_FAIL"

export const GET_CONTRACTORS_DOCUMENTS = "GET_CONTRACTORS_DOCUMENTS"
export const GET_CONTRACTORS_DOCUMENTS_SUCCESS = "GET_CONTRACTORS_DOCUMENTS_SUCCESS"
export const GET_CONTRACTORS_DOCUMENTS_FAIL = "GET_CONTRACTORS_DOCUMENTS_FAIL"


export const ADD_CONTRACTOR_DOCUMENTS = "ADD_CONTRACTOR_DOCUMENTS"
export const ADD_CONTRACTOR_DOCUMENTS_SUCCESS = "ADD_CONTRACTOR_DOCUMENTS_SUCCESS"
export const ADD_CONTRACTOR_DOCUMENTS_FAIL = "ADD_CONTRACTOR_DOCUMENTS_FAIL"

export const UPDATE_CONTRACTOR_DOCUMENTS= "UPDATE_CONTRACTOR_DOCUMENTS"
export const UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS = "UPDATE_CONTRACTOR_DOCUMENTS_SUCCESS"
export const UPDATE_CONTRACTOR_DOCUMENTS_FAIL = "UPDATE_CONTRACTOR_DOCUMENTS_FAIL"

export const ADD_CONTRACTOR = "ADD_CONTRACTOR"
export const UPDATE_CONTRACTOR= "UPDATE_CONTRACTOR"