import {
    GET_VACCINATION_DETAILS,
    GET_VACCINATION_DETAILS_SUCCESS,
    GET_VACCINATION_DETAILS_FAIL,
    UPDATE_VACCINATION_DETAILS,
    UPDATE_VACCINATION_DETAILS_SUCCESS,
    UPDATE_VACCINATION_DETAILS_FAIL,
    ADD_VACCINATION_DETAILS,
    ADD_VACCINATION_DETAILS_SUCCESS,
    ADD_VACCINATION_DETAILS_FAIL
  } from "./actionTypes"
  
  export const getVaccinationDetails = (params,types) => ({
    type: GET_VACCINATION_DETAILS,
    params,
    types,
  })
  
  export const getVaccinationDetailsSuccess = data => ({
    type: GET_VACCINATION_DETAILS_SUCCESS,
    payload: data,
  })
  
  export const getVaccinationDetailsFail = error => ({
    type: GET_VACCINATION_DETAILS_FAIL,
    payload: error,
  })

  export const updateVaccinationDetails = (params,types) => ({
    type: UPDATE_VACCINATION_DETAILS,
    payload: params,
    types
  })

  export const updateVaccinationDetailsSuccess = data => ({
    type: UPDATE_VACCINATION_DETAILS_SUCCESS,
    payload: data,
  })

  export const updateVaccinationDetailsFail = error => ({
    type: UPDATE_VACCINATION_DETAILS_FAIL,
    payload: error,
  })

  export const addVaccinationDetails = (params,types) => ({
    type: ADD_VACCINATION_DETAILS,
    payload: params,
    types
  })

  export const addVaccinationDetailsSuccess = data => ({
    type: ADD_VACCINATION_DETAILS_SUCCESS,
    payload: data,
  })

  export const addVaccinationDetailsFail = error => ({
    type: ADD_VACCINATION_DETAILS_FAIL,
    payload: error,
  })