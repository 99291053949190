import {
    GET_PACKAGE_REPORT_SETTINGS,
    GET_PACKAGE_REPORT_SETTINGS_SUCCESS,
    GET_PACKAGE_REPORT_SETTINGS_FAIL,
    UPDATE_PACKAGE_REPORT_SETTINGS,
    UPDATE_PACKAGE_REPORT_SETTINGS_SUCCESS,
    UPDATE_PACKAGE_REPORT_SETTINGS_FAIL,
  } from "./actionTypes"
  
  export const getPackageReportSettings = (params,types) => ({
    type: GET_PACKAGE_REPORT_SETTINGS,
    params,
    types,
  })
  
  export const getPackageReportSettingsSuccess = data => ({
    type: GET_PACKAGE_REPORT_SETTINGS_SUCCESS,
    payload: data,
  })
  
  export const getPackageReportSettingsFail = error => ({
    type: GET_PACKAGE_REPORT_SETTINGS_FAIL,
    payload: error,
  })

  export const updatePackageReportSettings = (params,types) => ({
    type: UPDATE_PACKAGE_REPORT_SETTINGS,
    payload: params,
    types
  })

  export const updatePackageReportSettingsSuccess = data => ({
    type: UPDATE_PACKAGE_REPORT_SETTINGS_SUCCESS,
    payload: data,
  })

  export const updatePackageReportSettingsFail = error => ({
    type: UPDATE_PACKAGE_REPORT_SETTINGS_FAIL,
    payload: error,
  })