/* Rosters */
export const GET_NEWS_FEEDS = "GET_NEWS_FEEDS"
export const GET_NEWS_FEEDS_SUCCESS = "GET_NEWS_FEEDS_SUCCESS"
export const GET_NEWS_FEEDS_FAIL = "GET_NEWS_FEEDS_FAIL"

export const GET_NEWS_FEEDS_ARCHIVED = "GET_NEWS_FEEDS_ARCHIVED"
export const GET_NEWS_FEEDS_ARCHIVED_SUCCESS = "GET_NEWS_FEEDS_ARCHIVED_SUCCESS"
export const GET_NEWS_FEEDS_ARCHIVED_FAIL = "GET_NEWS_FEEDS_ARCHIVED_FAIL"

export const ADD_NEWS_FEED = "ADD_NEWS_FEED"
export const ADD_NEWS_FEED_SUCCESS = "ADD_NEWS_FEED_SUCCESS"
export const ADD_NEWS_FEED_FAIL = "ADD_NEWS_FEED_FAIL"

export const UPDATE_NEWS_FEED = "UPDATE_NEWS_FEED"
export const UPDATE_NEWS_FEED_SUCCESS = "UPDATE_NEWS_FEED_SUCCESS"
export const UPDATE_NEWS_FEED_FAIL = "UPDATE_NEWS_FEED_FAIL"

export const UPDATE_NEWS_FEED_ARCHIVED = "UPDATE_NEWS_FEED_ARCHIVED"
export const UPDATE_NEWS_FEED_ARCHIVED_SUCCESS = "UPDATE_NEWS_FEED_ARCHIVED_SUCCESS"
export const UPDATE_NEWS_FEED_ARCHIVED_FAIL = "UPDATE_NEWS_FEED_ARCHIVED_FAIL"


export const GET_NEWS_FEEDS_VIEWED = "GET_NEWS_FEEDS_VIEWED"
export const GET_NEWS_FEEDS_VIEWED_SUCCESS = "GET_NEWS_FEEDS_VIEWED_SUCCESS"
export const GET_NEWS_FEEDS_VIEWED_FAIL = "GET_NEWS_FEEDS_VIEWED_FAIL"

