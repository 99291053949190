import {
    GET_QUESTIONS,
    GET_QUESTIONS_FAIL,
    GET_QUESTIONS_SUCCESS,

    ADD_QUESTION,
    UPDATE_QUESTION,

    GET_EMPLOYEDS,
    GET_EMPLOYEDS_FAIL,
    GET_EMPLOYEDS_SUCCESS,
    GET_CANDIDATES,
    GET_CANDIDATES_FAIL,
    GET_CANDIDATES_SUCCESS,

    ADD_CANDIDATE,
    UPDATE_CANDIDATE,
    FILTER_CANDIDATES
  
  } from "./actionTypes"
  
  export const getQuestions = (params,types) => ({
    type: GET_QUESTIONS,
    params,
    types,
  })
  
  export const getQuestionsSuccess = data => ({
    type: GET_QUESTIONS_SUCCESS,
    payload: data,
  })
  
  export const getQuestionsFail = error => ({
    type: GET_QUESTIONS_FAIL,
    payload: error,
  })

  export const getEmployds = (params,types) => ({
    type: GET_EMPLOYEDS,
    params,
    types,
  })
  
  export const getEmploydsSuccess = data => ({
    type: GET_EMPLOYEDS_SUCCESS,
    payload: data,
  })
  
  export const getEmploydsFail = error => ({
    type: GET_EMPLOYEDS_FAIL,
    payload: error,
  })

  export const getCandidates = (params,types) => ({
    type: GET_CANDIDATES,
    params,
    types,
  })
  
  export const getCandidatesSuccess = data => ({
    type: GET_CANDIDATES_SUCCESS,
    payload: data,
  })
  
  export const getCandidatesFail = error => ({
    type: GET_CANDIDATES_FAIL,
    payload: error,
  })

  export const addQuestion = params => ({
    type: ADD_QUESTION,
    payload: params,
  })
  
  export const updateQuestion = (params) => ({
    type: UPDATE_QUESTION,
    payload: params,
  })

  export const addCandidate = params => ({
    type: ADD_CANDIDATE,
    payload: params,
  })
  
  export const updateCandidate= (params) => ({
    type: UPDATE_CANDIDATE,
    payload: params,
  })

  export const filterCandidates = (params) => ({
    type: FILTER_CANDIDATES,
    payload: params,
    // filter: filterval
  })
  