import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_VACCINATION_DETAILS,
    ADD_VACCINATION_DETAILS,
    UPDATE_VACCINATION_DETAILS,
} from "./actionTypes"
import { addVaccinationDetailsFail, addVaccinationDetailsSuccess, getVaccinationDetailsFail, getVaccinationDetailsSuccess, updateVaccinationDetailsFail, updateVaccinationDetailsSuccess } from "./actions"

//Include Both Helper File with needed methods
import { vaccinationDetailsPage } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchVaccinationDetails({params,types}) {
  try {
    const response = yield call(vaccinationDetailsPage, params, types )
    console.log(response);
    yield put(getVaccinationDetailsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getVaccinationDetailsFail(error))
  }
}


function* updateVaccinationDetails({ payload: data }) {
  try {
      const response = yield call(vaccinationDetailsPage, data)
      yield put(updateVaccinationDetailsSuccess(response))
      toast.success("Details Updated Successfully", { autoClose: 2000 });
  } catch (error) {
      yield put(updateVaccinationDetailsFail(error))
      toast.error("Details Updated Failed", { autoClose: 2000 });
  }
}

function* addVaccinationDetails({ payload: data }) {
    try {
        const response = yield call(vaccinationDetailsPage, data)
        yield put(addVaccinationDetailsSuccess(response))
        toast.success("Details added successfully", { autoClose: 2000 });
    } catch (error) {
        yield put(addVaccinationDetailsFail(error))
        toast.error("Details add Failed", { autoClose: 2000 });
    }
}


function* VaccinationDetailsSaga() {
  yield takeEvery(GET_VACCINATION_DETAILS, fetchVaccinationDetails)
  yield takeEvery(UPDATE_VACCINATION_DETAILS, updateVaccinationDetails)
  yield takeEvery(ADD_VACCINATION_DETAILS, addVaccinationDetails)
}

export default VaccinationDetailsSaga
