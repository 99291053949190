import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_EMERGENCY_ACTIVE_ALERTS, GET_EMERGENCY_ARCHIVED_ALERTS } from "./actionTypes"
import {
 
  // getEmergencyActiveAlerts,
  getEmergencyActiveAlertsSuccess,
  getEmergencyActiveAlertsFail,
  // getEmergencyArchivedAlerts,
  getEmergencyArchivedAlertsSuccess,
  getEmergencyArchivedAlertsFail
  
} from "./actions"

//Include Both Helper File with needed methods
import { emergencyActiveAlerts, emergencyArchivedAlerts, actionUrl } from "helpers/fakebackend_helper"
import { toast } from "react-toastify";

function* fetchEmergencyActiveAlerts({params,types}) {
  try {
    const response = yield call(emergencyActiveAlerts, params, types )
    console.log(response);
    yield put(getEmergencyActiveAlertsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getEmergencyActiveAlertsFail(error))
  }
}

function* fetchEmergencyArchivedAlerts({params,types}) {
  try {
    const response = yield call(emergencyArchivedAlerts, params, types )
    console.log(response);
    yield put(getEmergencyArchivedAlertsSuccess(response))
  } catch (error) {
    console.log( 'error is'+ error );
    yield put(getEmergencyArchivedAlertsFail(error))
  }
}

// function* fetchCasemanagerClients({ payload: data }) {
//   try {
//     const response = yield call(actionUrl,data)
//     console.log(response);
//     yield put(getCasemanagersClientsSuccess(response))
//   } catch (error) {
//     console.log( 'error is'+ error );
//     yield put(getCasemanagersClientsFail(error))
//   }
// }

// function* addCasemanager({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(addCasemanagerSuccess(response))
//       toast.success("Details Added Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(addCasemanagerFail(error))
//       toast.error("Details Add Failed", { autoClose: 2000 });
//   }
// }

// function* updateCasemanager({ payload: data }) {
//   try {
//       const response = yield call(actionUrl, data)
//       yield put(updateCasemanagerSuccess(response))
//       toast.success("Details Updated Successfully", { autoClose: 2000 });
//   } catch (error) {
//       yield put(updateCasemanagerFail(error))
//       toast.error("Details Updated Failed", { autoClose: 2000 });
//   }
// }

function* emergencyAlertsSaga() {
  // yield takeEvery(RESET_ALL_ROSTERS_STATE, resetAllRosters)
  yield takeEvery(GET_EMERGENCY_ACTIVE_ALERTS, fetchEmergencyActiveAlerts)
  yield takeEvery(GET_EMERGENCY_ARCHIVED_ALERTS, fetchEmergencyArchivedAlerts)
  // yield takeEvery(GET_CASEMANAGERS_CLIENTS, fetchCasemanagerClients)
  // yield takeEvery(ADD_CASEMANAGER, addCasemanager)
  // yield takeEvery(UPDATE_CASEMANAGER, updateCasemanager)
}

export default emergencyAlertsSaga
