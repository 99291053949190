import {
  GET_CLIENT_AWAY_ALERT_APPROVED,
  GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_APPROVED_FAIL,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL,
  GET_CLIENT_AWAY_ALERT_DELETED,
  GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS,
  GET_CLIENT_AWAY_ALERT_DELETED_FAIL,
  UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST,
  UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST,
  UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST
} from "./actionTypes"

const INIT_STATE = {
  clientAwayAlertApproved: [],
  clientAwayAlertUnApproved: [],
  clientAwayAlertDeleted: [],
  error: {},
  loading: false,
}

const ClientAwayAlertReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_AWAY_ALERT_APPROVED:
      console.log('loading');
      return {
        ...state,
        clientAwayAlertApproved: state.clientAwayAlertApproved,
        loading: true
     }
    case GET_CLIENT_AWAY_ALERT_APPROVED_SUCCESS:
      console.log('success');
      return {
        ...state,
        clientAwayAlertApproved: action.payload.data,
        loading: false
     }

    case GET_CLIENT_AWAY_ALERT_APPROVED_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    case GET_CLIENT_AWAY_ALERT_UNAPPROVED:
    console.log('loading');
    return {
      ...state,
      clientAwayAlertUnApproved: state.clientAwayAlertUnApproved,
      loading: true
    }

    case GET_CLIENT_AWAY_ALERT_UNAPPROVED_SUCCESS:
      return {
        ...state,
        clientAwayAlertUnApproved: action.payload.data,
        loading: false
      }

    case GET_CLIENT_AWAY_ALERT_UNAPPROVED_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }

    case GET_CLIENT_AWAY_ALERT_DELETED:
      console.log('loading');
      return {
        ...state,
        clientAwayAlertDeleted: state.clientAwayAlertDeleted, //[...state.staffLeavesDeleted],
        loading: true
     }

    case GET_CLIENT_AWAY_ALERT_DELETED_SUCCESS:
      console.log('success');
      return {
        ...state,
        clientAwayAlertDeleted: action.payload.data,
        loading: false
      }

    case GET_CLIENT_AWAY_ALERT_DELETED_FAIL:
    console.log('failed');
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CLIENT_AWAY_ALERT_APPROVED_LIST:
      return {
        ...state,
        clientAwayAlertApproved: state.clientAwayAlertApproved.filter(rosters =>
          rosters.id.toString() !== action.payload.id.toString()
        ),
        loading: false
    }

    case UPDATE_CLIENT_AWAY_ALERT_UNAPPROVED_LIST:
      return {
        ...state,
        clientAwayAlertUnApproved: state.clientAwayAlertUnApproved.filter(rosters =>
          rosters.id.toString() !== action.payload.id.toString()
        ),
        loading: false
    }

    case UPDATE_CLIENT_AWAY_ALERT_DELETED_LIST:
      return {
        ...state,
        clientAwayAlertDeleted: state.clientAwayAlertDeleted.filter(rosters =>
          rosters.id.toString() !== action.payload.id.toString()
        ),
        loading: false
    }

      default:
        return state
  }
}

export default ClientAwayAlertReducer
