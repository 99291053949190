import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map, values } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, RECRUITMENTS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";
import axios from 'axios';

import {Name, Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getQuestions as onGetQuestions,
    updateQuestion as onUpdateQuestion,
    addQuestion as onAddQuestion
} from "store/actions";

function Questions(){
  //meta title
  document.title="Questions List | Tacveiha Panel";

  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  
  
  const [modal, setModal] = useState(false);
  const [ModalLoading, setModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');


  const [roster, setRoster] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        question_text: (roster && roster.question_text) || "",
        status : (roster && roster.status) || 1,
    },
    validationSchema: Yup.object({
        status: Yup.string().required("This field is required"),
        question_text: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
            id: roster.id,
            question_text : values.question_text,
            status: values.status,
        };

        console.log(updateUser);

        // update user
         setModalLoading(true);

         axios.post(APP_URL+RECRUITMENTS_PAGE,{'data_for': 'edit_interview_questions','formData': updateUser,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                dispatch(onUpdateQuestion(fetchedData.data));
                toast.success(fetchedData.html, { autoClose: 5000 });
                validation.resetForm();
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      } else {

        const newData = {
            status: values.status,
            question_text : values.question_text,
        };

         //add staff leave
         setModalLoading(true);

         axios.post(APP_URL+RECRUITMENTS_PAGE,{'data_for': 'add_interview_questions','formData': newData,'user': loggedUser})
           .then(res => {
             setModalLoading(false);
             const fetchedData = res.data;
 
             if( fetchedData.status == 'success' ) {
                
                dispatch(onAddQuestion(fetchedData.data));
                toast.success(fetchedData.html, { autoClose: 5000 });
                validation.resetForm();
                toggle();
             }
 
             if( fetchedData.status == 'error' ) {
               toast.error(fetchedData.html, { autoClose: 5000 });
             }
           })
      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.RecruitmentsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        questions: rosterReducer.questions,
        loading: rosterReducer.loading
      })
  );
  const {
    questions, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {

    const formData = {
      status : 'all'
    }

    if (questions && !questions.length) {
      dispatch(onGetQuestions({'data_for':'get_questions','formData': formData,'user': loggedUser}));
    }

  }, [dispatch]);



//   const handleStatus = (e) => {

//     const checkedId = parseInt(e.target.value);
//     console.log( checkedId);
//     if( e.target.checked ) {
//       setStatus([...status, checkedId]);
//     } else{
//       setStatus( status.filter(id =>id != checkedId));
//     }

//   }

//   useEffect(() => {
//     console.log(status);
//   },[status])

  const handleSearchBtn= () => {

    const formData = {
      fromDate: fromDate,
      toDate: toDate,
      status: status
    }

    dispatch(onGetQuestions({'data_for':'get_feedbacks','formData': formData,'user': loggedUser}));

  }

  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
        id: user.id,
        status: user.status,
        question_text: user.question_text
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster([]);
    setIsEdit(false);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Question Name",
        accessor: "question_text",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Interview Questions" />
          
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={questions}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    isAddBtn={true}
                    btnText='Add Questions'
                    customPageSize={50}
                    className="custom-header-css"
                    // excelExport={true}
                    // fileName='Questions'
                  />
                }
                  <Modal isOpen={modal} toggle={toggle}  size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}

                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          ></Label>
                          <Col sm={9}>
                              <Input
                                  name="status"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='1'
                                  checked={validation.values.status == '1' ? true : false }
                                  invalid={
                                    validation.touched.status &&
                                    validation.errors.status
                                        ? true
                                        : false
                                    }
                              /> &nbsp;&nbsp;Active
                            
                              
                              &nbsp;&nbsp;<Input
                                  name="status"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='0'
                                  checked={validation.values.status == '0' ? true : false }
                                  invalid={
                                    validation.touched.status &&
                                    validation.errors.status
                                        ? true
                                        : false
                                    }
                              />&nbsp;&nbsp;InActive
                              {validation.touched.status &&
                                    validation.errors.status ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.status}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                          </Row>
                         
                          <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Question Name
                            </Label>
                            <Col sm={9}>
                            <Input
                              value={validation.values.question_text}
                              name="question_text"
                              type="textarea"
                              onChange={validation.handleChange}
                              invalid={
                                validation.touched.question_text &&
                                validation.errors.question_text
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.question_text &&
                                    validation.errors.question_text ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.question_text}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                          </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={ModalLoading}
                              >
                                { !!isEdit ? 'Update' : 'Add' }
                              </button>
                            </div>
                          </Col>
                        </Row>
   
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Questions;