import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {Name, Desc, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


//Import Card invoice
// import CardInvoice from "./card-invoice"
import {
    getClientTypes as onGetClietTypes,
    addClientTypes as onAddClientTypes,
    updateClientTypes as onUpdateClientTypes
} from "store/actions";

function ClientTypes(){
  //meta title
  document.title="Client Types | Tacveiha Panel";

  // console.log(getLoggedInUser );
  

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [roster, setRoster] = useState(null);

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.ClientTypesReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        clientTypes: rosterReducer.clientTypes,
        loading: rosterReducer.loading
      })
  );
  const {
    clientTypes, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (roster && roster.id) || "",
      name: (roster && roster.name) || "",

    },
    validationSchema: Yup.object({
        name: Yup.string().required("Please enter client type"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
            id: values.id,
            name: values.name,
        };

        // update user
        dispatch(onUpdateClientTypes({'data_for':'update_client_type','data': updateUser,'user': loggedUser}));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newData = {
            name: values.name,
        };
        // // save new user
        dispatch(onAddClientTypes({'data_for':'add_client_type','data': newData,'user': loggedUser}));
        validation.resetForm();
      }
      toggle();
    },
  });

  useEffect(() => {
    dispatch(onGetClietTypes({'data_for':'get_client_types','user': loggedUser}));
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster(null);
    } else {
        setModal(true);
    }
  };

  const addClientType = arg => {
    setIsEdit(false);
    toggle();
  }

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
          id: user.id,
          name: user.name,
    });
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster("");
    setIsEdit(false);
    toggle();
  };

  // const clsoeModal = () => {
  //   toggle();
  // }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = roster => {
    setRoster(roster);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    //dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };
  const keyField = "id";

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Client Type",
        accessor: "name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },

     
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
                <Link
                    to="#"
                    className="btn btn-sm btn-soft-success"
                    onClick={() => {
                        const userData = cellProps.row.original;
                        handleUserClick(userData);
                    }}
                    id={`edittooltip-${cellProps.row.original.id}`}
                >
                    <i className="mdi mdi-pencil-outline" />
                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                        Edit
                    </UncontrolledTooltip>
                </Link>
            </li>
        </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Client Types" />

          <Row className="mb-6">
            <Col sm="12">
                <div className="text-sm-end">
                    <Button
                    type="button"
                    color="primary"
                    className="mb-2 me-2"
                    onClick={addClientType}
                    >
                    
                    Add Client Types
                    </Button>
                </div>
            </Col>
        </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {
                    loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={clientTypes}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    excelExport={false}
                    fileName=''
                  />
                }
                  <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        
                        

                        <Col className="mb-6">

                          <Row className="mb-3">
                            <label
                              htmlFor="example-tel-input"
                              className="col-md-2 col-form-label"
                            >
                              Client Types
                            </label>
                            <div className="col-md-10">
                              <Input
                                name="name"
                                type="text"
                                placeholder="Client Type"
                                value={validation.values.name}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.name &&
                                      validation.errors.name
                                      ? true
                                      : false
                                  }
                              />
                              {validation.touched.name &&
                                  validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Row>
                        </Col>

                        <Row>
                          <Col>
                            <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default withRouter(ClientTypes);