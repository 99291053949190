import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, GET_ALERTS_PAGE } from "helpers/url_helper"
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";


import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";

import { Name, Status } from "components/Common/TableColumns"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"
import RosterDetailsModal from "components/Forms/RostersDetailsModal"

function CompletedRostersPage () {
  //meta title
  document.title="Completed Rosters | Tacveiha Panel";
  
  
    const loggedUser = JSON.parse(localStorage.getItem("authUser"));

    const dispatch = useDispatch();
    const [roster, setRoster] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [ModalLoading, setModalLoading] = useState(false);
    const [completedRosters, setCompletedRosters] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ShowRostersDetails, setShowRostersDetails] = useState(false);

    useEffect(() => {

        setModalLoading(true);

        axios.post(APP_URL+GET_ALERTS_PAGE,{'data_for': 'load_completed_rosters','user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            if( fetchedData.status == 'success' ) {
              localStorage.setItem('completed_rosters_alerts_count', fetchedData.completed_rosters_alerts_count);
              toast.success(fetchedData.html, { autoClose: 5000 });
              setCompletedRosters(fetchedData.data)
              
            }
            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })

    },[dispatch])

    const columns = useMemo(
      () => [
        {
          Header: "#",
          accessor: "ros_id",
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Client Name",
          accessor: "client_name",
          //disableFilters: true, 
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Staff Name",
          accessor: "staff_name",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Roster Date",
          accessor: "ros_date",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Start Time",
          accessor: "start_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Finish Time",
          accessor: "finish_time",
          filterable: true,
          Cell: cellProps => {
            return <Name {...cellProps} />;
          },
        },
        {
          Header: "Status",
          accessor: "ros_status",
          filterable: true,
          Cell: cellProps => {
            return <Status {...cellProps} />;
          },
        },
        
        {
          Header: "Action",
          Cell: cellProps => {
            return (
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              </div>
            );
          },
        },
      ],
      []
    );


  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setRoster({
        ros_id: user.ros_id,
      });
    setShowRostersDetails(true);
  };

//   const handleSearchBtn = () => {

   

//     const formData = {
//         fromdate: fromDate,
//         todate: toDate
//     }
    

//   };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

//   const handleUserClicks = () => {
//     setRostersList("");
//     setIsEdit(false);
//     toggle();
//   };

  const handleFromDate = value => {
    setFromDate(value);
  }

  const handleToDate = value => {
    setToDate(value);
  }

  return (
    <React.Fragment>
      { ModalLoading ? <TopProgressBar loading/> : '' }
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="#" breadcrumbItem="Completed Rostes" />
          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody className="flex align-items-center">
                    <Row className="mb-2">
                        <Col sm={2}>
                        <Input
                        type="date"
                        value={fromDate}
                        onChange={e => {
                            handleFromDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm={2}>
                        <Input
                        type="date"
                        value={toDate}
                        onChange={e => {
                            handleToDate(e.target.value);
                        }}
                        />
                        
                        </Col>

                        <Col sm="1">
                            <div className="text-sm-end">
                                <Button
                                type="button"
                                color="primary"
                                className=" mb-2 me-2"
                                onClick={handleSearchBtn}
                                >
                                <i className="mdi mdi-search me-1" />
                                Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <RosterDetailsModal
            ros_id={roster.ros_id}
            page="completed_rosters"
            show={ShowRostersDetails}
            onCloseModal={() => setShowRostersDetails(false)}
          />

          { ModalLoading ? <Spinners setLoading={setLoading} />
            :
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row className="mb-2">

                                <TableContainer
                                    columns={columns}
                                    data={completedRosters}
                                    isGlobalFilter={true}
                                    isDateSelecteOptions={true}
                                    showSearchBtn={true}
                                    // handleUserClick={handleUserClicks}
                                    customPageSize={25}
                                    className="custom-header-css"
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );


}

export default CompletedRostersPage;