export const APP_URL = "https://homecarevic.com/apiv2";
export const SITE_URL = "https://homecarevic.com/";
export const ADMIN_URL = "https://homecarevic.com/admin/";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_LOGIN = "/post-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_MAILS_LIST = "/mailslists";
export const SELECT_FOLDER = "/folders";
export const GET_SELECTED_MAILS = "/selectedmails";
export const SET_FOLDER_SELECTED_MAILS = "/setfolderonmail";
export const UPDATE_MAIL = "/update/mail";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";
export const DELETE_MESSAGE = "/delete/message";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";
export const GET_CRYPTO_PRODUCTS = "/crypto-products";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//Blog
export const GET_VISITOR_DATA = "/visitor-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard crypto
export const GET_WALLET_DATA = "/wallet-balance-data";

//dashboard jobs
export const GET_STATISTICS_DATA = "/Statistics-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";


// Tacveiha work

//Rosters
export const GET_ALL_ROSTERS = '/get_all_rosters';
export const GET_TODAYS_ROSTERS = '/get_todays_rosters';

export const GET_ROSTERS_DETAIL = '/get_rosters_details';
export const GET_GROUP_ROSTERS = '/get_group_rosters';

export const ACTION_URL = '/action';

//Departments
export const GET_DEPARTMENTS = '/departments';

export const GET_THIRD_PARTY_CONTRACTORS = '/departments';


//Casemanagers
export const GET_CASEMANAGERS = '/casemanagers';

//Casemanagers
export const GET_CONTRACTORS = '/contractors';

//Casemanagers
export const STAFF_LEAVES = '/staffleaves';

//Clients
export const GET_CLIENTS = '/clients';
export const GET_ARCHIVED_CLIENTS = '/clients';
// export const GET_CLIENTS_SERVICE_TYPES = '/clients';
export const GET_CLIENT_ROSTERS = '/get_clients_rosters';
export const GET_STAFF_ROSTERS = '/get_staffs_rosters';


//Broadcast Rosters
export const GET_BROADCAST_ROSTERS = '/get_broadcast_rosters';

//Alerts Rosters
export const GET_ALERTS_PAGE = '/alert_rosters';

//Emergency Alerts Rosters
export const GET_EMERGENCY_ACTIVE_ALERTS = '/emergency-rosters-alert';
export const GET_EMERGENCY_ARCHIVED_ALERTS = '/emergency-rosters-alert';

// //broadcast rosters
// export const GET_BROADCAST_ROSTERS = '/emergency-rosters-alert';

//Clinet Away
export const CLIENT_AWAY_ALERT = '/get-clients-away-alerts';

//Clinet Away
export const ROSTER_REPORT_PAGE = '/reports';

//Incident Reports
export const INCIDENT_REPORT_PAGE = '/incident-reports';

export const NEWS_FEEDS_PAGE = '/news-feeds';

export const CLIENT_TYPES_PAGE = '/client-types';

export const PAY_CATEGORY_PAGE = '/pay-category-list';

export const VACCINATION_DETAILS_PAGE = '/vaccination-details';

export const CHECKLIST_QUESTIONS_PAGE = '/checklist-questions';

export const HR_DOCUMENTS_NAME_LIST_PAGE = '/hr-documents-name-list';

//staffs
export const GET_STAFFS = '/get_staffs';

//public holidas 
export const PublicHolidayPage = '/public_holidays';

//feedbacks holidas 
export const FEEDBACKS_PAGE = '/feedbacks';

//Recruitments Page 
export const RECRUITMENTS_PAGE = '/recruitments';


//No clock in page
export const  NO_CLOCKIN_ROSTERS_PAGE = '/clockin_rosters';

//Alerts Rosters
export const GET_CHECKLIST_ALERTS_PAGE = '/checklist-alerts';