import {
    GET_INCIDENT_REPORT_ACTIVE,
    GET_INCIDENT_REPORT_ACTIVE_SUCCESS,
    GET_INCIDENT_REPORT_ACTIVE_FAIL,
    GET_INCIDENT_REPORT_COMPLETED,
    GET_INCIDENT_REPORT_COMPLETED_SUCCESS,
    GET_INCIDENT_REPORT_COMPLETED_FAIL,
    UPDATE_INCIDENT_REPORT,
    UPDATE_INCIDENT_REPORT_SUCCESS,
    UPDATE_INCIDENT_REPORT_FAIL,
    ADD_INCIDENT_REPORT,
    ADD_INCIDENT_REPORT_SUCCESS,
    ADD_INCIDENT_REPORT_FAIL,
    GET_INCIDENT_REPORT_STAFFS,
    GET_INCIDENT_REPORT_STAFFS_SUCCESS,
    GET_INCIDENT_REPORT_STAFFS_FAIL,
    GET_STAFFS_ROSTERS,
    GET_STAFFS_ROSTERS_SUCCESS,
    GET_STAFFS_ROSTERS_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    incidentReportsActive: [],
    incidentReportsCompleted: [],
    incidentReportStaffRosters: [],
    staffs: [],
    staffRosters: [],
    error: {},
    loading: false,
    isAdded:false,
    post_msg:{}
  }
  
  const IncidentReportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_INCIDENT_REPORT_ACTIVE:
        
        return {
          ...state,
          incidentReportsActive: [],
          loading: true
        }
      case GET_INCIDENT_REPORT_ACTIVE_SUCCESS:
        
        return {
          ...state,
          incidentReportsActive: action.payload.data,
          loading: false
        }
  
      case GET_INCIDENT_REPORT_ACTIVE_FAIL:
      
        return {
            ...state,
            error: action.payload,
    
        }
  
      case GET_INCIDENT_REPORT_COMPLETED:
      
        return {
            ...state,
            incidentReportsCompleted: [],
            loading: true
        }
  
      case GET_INCIDENT_REPORT_COMPLETED_SUCCESS:
        return {
          ...state,
          incidentReportsCompleted: action.payload.data,
          loading: false
        }
  
      case GET_INCIDENT_REPORT_COMPLETED_FAIL:
        return {
          ...state,
          incidentReportsCompleted: [],
          error: action.payload.error,
        }

    case GET_INCIDENT_REPORT_STAFFS:
          return {
            ...state,
            staffs: [], //[...state.staffs], //[...state.staffLeavesDeleted],
            loading: true
          }
        
    case GET_INCIDENT_REPORT_STAFFS_SUCCESS:
          
          return {
            ...state,
            staffs: action.payload.data,
            loading: false
          }
    
    case GET_INCIDENT_REPORT_STAFFS_FAIL:
  
          return {
            ...state,
            error: action.payload,
          }
    case GET_STAFFS_ROSTERS:
        return {
          ...state,
          incidentReportStaffRosters: [], //[...state.staffs], //[...state.staffLeavesDeleted],
          loading: true
        }
          
    case GET_STAFFS_ROSTERS_SUCCESS:
          
      return {
        ...state,
        incidentReportStaffRosters: action.payload.data,
        loading: false
      }
      
    case GET_STAFFS_ROSTERS_FAIL:
  
      return {
        ...state,
        error: action.payload,
      }
  
    case UPDATE_INCIDENT_REPORT:
          return {
            ...state,
            loading: true
        };
  
    case UPDATE_INCIDENT_REPORT_SUCCESS:
          return {
              ...state,
              incidentReportsActive: state.providers.map(rosters =>
                rosters.id.toString() === action.payload.data.id.toString()
                      ? { rosters, ...action.payload.data }
                      : rosters
              ),
              loading: false
        };
  
    case UPDATE_INCIDENT_REPORT_FAIL:
      return {
          ...state,
          error: action.payload.error,
          loading: false
      };

    case ADD_INCIDENT_REPORT:
        return {
          ...state,
          loading: true
        };
    case ADD_INCIDENT_REPORT_SUCCESS:
        return {
          ...state,
          //groupRosters: [...state.groupRosters, action.payload.groupRosters],
          loading: false,
          isAdded: true,
          post_msg: action.payload.post_msg
        }
    case ADD_INCIDENT_REPORT_FAIL:
        return {
          ...state,
          error: action.payload.error,
          loading: false,
          isAdded: false,
        };
    default:
          return state
    }
  }
  
  export default IncidentReportReducer
  