import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { isEmpty, isWeakMap, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { ACTION_URL, APP_URL, SITE_URL, GET_STAFFS } from "helpers/url_helper"
import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  InputGroup,
  CardTitle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"


const EditSupportStaff = props => {
  //meta title
  document.title="Edit Support Staff | Tacveiha Panel";


  const params = props.router.params;
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));

  //form radio option values
  const [UserStatus, setUserStatus] = useState('');
  const [SmsAuth, setSmsAuth] = useState('');
  const [EmailAuth, setEmailAuth] = useState('');

  const [ModalLoading, setModalLoading] = useState(false);
  const [ModalTitle, setModalTitle] = useState('');
  const [columns, setColumns] = useState('admin_columns');
  const [ColumnsHide, setColumnsHide] = useState([]);

  
  const [roster, setRoster] = useState({});
  const [Loading, setLoading] = useState(false);
  const [TopLoading, setTopLoading] = useState(false);
  const [PageLoading, setPageLoading] = useState(false);
  
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedDocFiles, setselectedDocFiles] = useState([]);


  //all notes
  const [StaffNotes, setStaffNotes] = useState('');
  const [StaffNotesList, setStaffNotesList ] = useState([]);

  //HR nores
  const [StaffHrNotes, setStaffHrNotes] = useState('');
  const [StaffHrNotesList, setStaffHrNotesList] = useState([]);
  const [HrNotesLoading, setHrNotesLoading] = useState(false);

  //Modals
  const [DocumentsModal, setDocumentsModal] = useState(false);
  const [HrDocumentsModal, setHrDocumentsModal] = useState(false);
  const [NotesLoading, setNotesLoading] = useState(false);

  //documents details
    const [DocumentsActivity, setDocumentsActivity] = useState([]);
    const [StaffsActiveDocuments, setStaffsActiveDocuments] = useState([]);
    const [StaffsDeletedDocuments, setStaffsDeletedDocuments] = useState([]);
    const [StaffsDocuments, setStaffsDocuments] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);

    //hr documents upload
    const [HrDocsDropdown, SetHrDocsDropdown ] = useState([]);
    const [HrDocs, setHrDocs ] = useState([]);
    const [HrDocsIsExpiry, setHrDocsIsExpiry ] = useState('');
    const [HrDocsExpiryDate, setHrDocsExpiryDate ] = useState('');

    const dispatch = useDispatch();

    const selectGroupRostersState = (state) => state.StaffsReducer;
    const groupRostersProperties = createSelector(
    selectGroupRostersState,
        (rosterReducer) => ({
        loading: rosterReducer.loading
        })
    );
    const {
    loading
    } = useSelector(groupRostersProperties);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            user_status : ( roster && roster.user_status ),
            staff_email : ( roster && roster.staff_email ) || "",
            staff_password : "",
            staff_confirm_password : "",
            staff_mobile_no : ( roster && roster.staff_mobile_no ) || "",
            sms_auth : ( roster && roster.sms_auth ) || "",
            email_auth : ( roster && roster.email_auth ) || "",
            auth_email_address: ( roster && roster.auth_email_address ) || "",
        },
        validationSchema: Yup.object({
            user_status: Yup.string().required("Please select user status"),
            staff_email: Yup.string().required("Required"),
            staff_mobile_no: Yup.string().required("Required"),
        }),
        onSubmit: values => {

            if( values.staff_password != "" ) {
                
                if( values.staff_password != values.staff_confirm_password ) {
                    toast.error("Password does not match", { autoClose: 5000 });
                    return false;
                }

            }

            if( values.email_auth == 1 &&  values.auth_email_address == '' ) {
            toast.error("Please provide authentication email address", { autoClose: 5000 });
            return false;
            }

            const newUser = {
                user_id: roster.user_id,
                user_status : values.user_status,
                staff_email: values.staff_email,
                staff_password: values.staff_password,
                staff_mobile_no: values.staff_mobile_no,
                sms_auth: values.sms_auth,
                email_auth: values.email_auth,
                auth_email_address: values.auth_email_address,
            };

            setTopLoading(true);
            axios.post(APP_URL+GET_STAFFS,{'data_for':'edit_support_staff','formData': newUser, 'user': loggedUser})
            .then(res => {
                setTopLoading(false);
                const fetchedData = res.data;
                // console.log(fetchedData);
        
                if( fetchedData.status == 'success' ) {
                    toast.success(fetchedData.html, { autoClose: 5000 });
                }
        
                if( fetchedData.status == 'error' ) {
                    toast.error(fetchedData.html, { autoClose: 5000 });
                }
            })
        }
    });

  useEffect(() => {

    const formData = {
        user_id: params.id
    }
    setTopLoading(true);
    // setPageLoading(true);
      axios.post(APP_URL+GET_STAFFS,{'data_for':'get_support_staff_details','formData': formData,'user': loggedUser})
        .then(res => {
            
          const fetchedData = res.data;
  
          if( fetchedData.status == 'success' ) {
            setStaffNotesList(fetchedData.staff_notes);
            setStaffHrNotesList(fetchedData.hr_notes);
            // updateRoster(fetchedData);
            setUserStatus(fetchedData.user_status);

            setRoster({
                user_id: fetchedData.user_id,
                user_status : fetchedData.user_status,
                staff_email : fetchedData.user_email ,
                staff_mobile_no : fetchedData.staff_mobile_no,
                sms_auth : fetchedData.sms_auth,
                email_auth : fetchedData.email_auth,
                auth_email_address: fetchedData.auth_email_address,
            });

            setTopLoading(false);
            // setPageLoading(false);

          }
  
          if( fetchedData.status == 'error' ) {

            setTopLoading(false);
            // setPageLoading(false);
            toast.error(fetchedData.html, { autoClose: 5000 });

            
          }
      })

  }, []);


  const updateRoster = (userRow) => {
   
  }

  const addStaffNotes = () => {

    if( StaffNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
    }
  
    const notesForm = {
        ros_id: params.id,
        notes: StaffNotes,
        activity_for: 'sub_admin',
    };

    setNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': notesForm,'user': loggedUser})
        .then(res => {
            setNotesLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                setStaffNotes('');
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffNotesList(StaffNotesList => [fetchedData.data,...StaffNotesList]);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
  }

  //Hr Notes

  const addStaffHrNotes = () => {

    if( StaffHrNotes == '' ) {
        toast.error("Please add notes", { autoClose: 5000 });
        return false;
      }
  
    const notesForm = {
        ros_id: params.id,
        notes: StaffHrNotes,
        activity_for: 'sub_admin',
        role: loggedUser.role,
    };

    setHrNotesLoading(true);
    axios.post(APP_URL+ACTION_URL,{'data_for':'add_notes_client','formData': notesForm,'user': loggedUser})
        .then(res => {
            setHrNotesLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                setStaffHrNotes('');
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffHrNotesList(StaffHrNotesList => [fetchedData.data,...StaffHrNotesList]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
  }


  const handleRadioChange = e => {
    // console.log(e.target.value);
    setUserStatus(e.target.value);
  }

  const toggleDocumentsModal = () => {
    if (DocumentsModal) {
      setDocumentsModal(false);
    } else {
        setDocumentsModal(true);
    }
  };

  const doc_columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        enableHiding: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        enableHiding: false,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        enableHiding: false,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            if( userData.doc_status != 5 ) {
                                handleArchiveDoc(userData);
                            }
                        }}
                        id={`viewprofiletooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-archive" />
                        <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                            Archive
                        </UncontrolledTooltip>
                    </Link>
                </li>

                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleRemoveDoc(userData);
                        }}
                        id={`removetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-trash"/>
                        <UncontrolledTooltip placement="top" target={`removetooltip-${cellProps.row.original.id}`} >
                            Remove
                        </UncontrolledTooltip>
                    </Link>
                </li>

            </ul>
          );
        },
      },
    ],
    []
  );

  const doc_columns_deleted = useMemo(
    () => [
      {
        Header: "#",
        accessor: "iid",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Document Name",
        accessor: "doc_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "doc_type",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        // enableHiding: loggedUser.role === 'admin' ? true : false,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    ],
    []
  );

    const StaffsAllRostersBtn = () => {
        
    }

    const StaffsDocumentsBtn = () => {

        setModalTitle('All Documents');

        setColumnsHide([]);
        if( loggedUser.role == 'human_resource') {
            //setColumnsHide(ColumnsHide => [...ColumnsHide, 'doc_added']);
        }

        setColumns(doc_columns);

        toggleDocumentsModal();
        setModalLoading(true);
        const formData = {
            user_id: params.id,
            type: 'staff_doc',
            doc_by: ''
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_clients_documents','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                
                setStaffsActiveDocuments(fetchedData.documents_active);
                setStaffsDeletedDocuments(fetchedData.documents_deleted);
                setStaffsDocuments(fetchedData.documents_active);
                setDocumentsActivity(fetchedData.docs_activity);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
    }

    const StaffsHRDocumentsBtn = () => {
        
        setModalTitle('HR Documents');
        
        setColumnsHide([]);
        if( loggedUser.role == 'human_resource') {
            //setColumnsHide(ColumnsHide => [...ColumnsHide, 'doc_added']);
        }
       
        setColumns(doc_columns);

        toggleDocumentsModal();
        setModalLoading(true);
        const formData = {
            user_id: params.id,
            type: 'staff_doc',
            doc_by: 'human_resource'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'get_clients_documents','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                
                setStaffsActiveDocuments(fetchedData.documents_active);
                setStaffsDeletedDocuments(fetchedData.documents_deleted);
                setStaffsDocuments(fetchedData.documents_active);
                setDocumentsActivity(fetchedData.docs_activity);
            }

            if( fetchedData.status == 'error' ) {
            toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
        
    }


    const handleShowDocuments = (type) => {
        // console.log(type);
        if( type == 'active' ) {
            setColumns(doc_columns);
            setStaffsDocuments(StaffsActiveDocuments);
            
            // if( loggedUser.role == 'admin' ) {
            //     setColumnsHide(['Expiry Date']);
            // }

            // if ( loggedUser.role == 'human_resource' ){
            //     setColumnsHide([]);
            // }
        }
        if( type == 'deleted') {
            // columns = hr_columns_deleted;
            setColumns(doc_columns_deleted);
            setStaffsDocuments(StaffsDeletedDocuments);
            
            // if( loggedUser.role == 'admin' ) {
            //     setColumnsHide(['Expiry Date','Action']);
            // }

            // if ( loggedUser.role == 'human_resource' ){
            //     setColumnsHide(['Action'])
            // }
            
        }
    }

    const handleArchiveDoc = (docs) => {
        setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'staff_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'archive_doc','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffsDocuments((StaffsDocuments) => StaffsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }

    const handleRemoveDoc = (docs) => {


        setModalLoading(true);
        const formData = {
            doc_id: docs.id,
            type: 'staff_doc'
        };
        axios.post(APP_URL+ACTION_URL,{'data_for':'remove_doc','formData': formData,'user': loggedUser})
        .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setStaffsDocuments((StaffsDocuments) => StaffsDocuments.filter(data => data.id.toString() !==  docs.id.toString()));
                
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }


    const handleDownloadDoc = (docs) => {
        window.open(ADMIN_URL+'download_file.php?name='+docs.doc_name+'&user_id='+params.id, '_blank', 'noopener,noreferrer');
    }


    //dropzone upload files
    const handleHrIsExpiry = e => {
        // console.log(e.target.value);
        // console.log(e.target.checked);
        if( e.target.checked ) {
            setHrDocsIsExpiry(e.target.value);
        }
    }

    const handleUploadDoc = (files) => {

        files.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })
        )
        
        setselectedDocFiles(files);

        const formData = new FormData();

        files.forEach((file) => {
            formData.append('file', file);
        });

        formData.append('data_for', 'staff_doc');
        formData.append('user_id', params.id);
        formData.append('user_type', 'staff_doc');
        formData.append('is_expiry', '');
        formData.append('expiry_date', '');
        formData.append('doc_list_id', '');
        formData.append('doc_list_name', '');
        formData.append('added_by', loggedUser.uid);
        formData.append('role', loggedUser.role);

        setTopLoading(true);

        axios.post(APP_URL+'/upload_files.php',formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setselectedDocFiles([]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })
    }

    function handleAcceptedFiles(files) {
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        )
        setselectedFiles(files);
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    const handleHrDocuments = () => {

        if( HrDocs.length == 0  ) {
            toast.error('Please select Hr document');
            return;
        }

        if( HrDocsIsExpiry == '') {
            toast.error('Please select expiry option');
            return;
        }

        if( HrDocsIsExpiry == 1 && HrDocsExpiryDate == '' ) {
            toast.error('Please provide document expiry date');
            return;
        }

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('file', file);
        });
        formData.append('data_for', 'staff_hr_doc');
        formData.append('user_id', params.id);
        formData.append('user_type', 'staff_doc');
        formData.append('is_expiry', HrDocsIsExpiry);
        formData.append('expiry_date', HrDocsExpiryDate);
        formData.append('doc_list_id', HrDocs.value);
        formData.append('doc_list_name', HrDocs.label);
        formData.append('added_by', loggedUser.uid);
        formData.append('role', loggedUser.role);

        setTopLoading(true);

        axios.post(APP_URL+'/upload_files.php',formData,{
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setTopLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
                toast.success(fetchedData.html, { autoClose: 5000 });
                setHrDocsIsExpiry(0);
                setHrDocsExpiryDate('');
                setHrDocs([]);
                setselectedFiles([]);
            }

            if( fetchedData.status == 'error' ) {
                toast.error(fetchedData.html, { autoClose: 5000 });
            }

        })

    }
    

  return (
    <React.Fragment>
      {
        TopLoading ? <TopProgressBar loading/>
        : ''
      }
      <div className="page-content">
        <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Staffs" breadcrumbItem=" Edit Staff" />

            <Row className="mb-6">
                <Col sm="12">
                    <div className="text-sm-end">
                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsDocumentsBtn}
                        disabled={TopLoading}
                        >
                        All Documents
                        </Button>

                        <Button
                        type="button"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={StaffsHRDocumentsBtn}
                        disabled={TopLoading}
                        >
                        HR Documents
                        </Button>
                    </div>
                </Col>
            </Row>

            {/* All Modals */}

            {/* Documents modals */}

            <Modal isOpen={DocumentsModal} toggle={toggleDocumentsModal} size="xl" backdrop="static" scrollable>
                <ModalHeader toggle={toggleDocumentsModal} tag="h4">
                    {ModalTitle}
                </ModalHeader>
                <ModalBody>
                    {
                    ModalLoading ? <Spinners setLoading={setLoading} />
                    :
                    <Row>
                        
                        {/* <Col xs={8}>
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() => handleShowDocuments('active')}
                                >
                                
                                Active
                                </Button>
                            </div>
                            <div className="d-inline-flex">
                                <Button
                                type="button"
                                color="primary"
                                className="mb-2 me-2"
                                onClick={() =>handleShowDocuments('deleted')}
                                >
                                
                                Deleted
                                </Button>
                            </div>
                        </Col> */}
                        
                        <Col xs={8}>

                            <TableContainer
                                columns={columns}
                                data={StaffsDocuments}
                                isGlobalFilter={true}
                                showSearchBtn={true}
                                customPageSize={50}
                                className="custom-header-css"
                                columnsHide={ColumnsHide}
                            />

                        </Col>

                        <Col xs={4}>

                            <Row>
                                <div className="order_notes_div">
                                    <CardTitle>Activity Log</CardTitle>
                                    <ul className="order_notes">
                                        { DocumentsActivity.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                            <strong>
                                            {activity.activity_by}
                                            </strong>
                                            </abbr></p>
                                        </li>
                                        ))}
                                    </ul>
                                
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    }
                    <br/>
                    <br/>
                    <br/>
                </ModalBody>
            </Modal>


            <Row>
                <Col lg="8">
                
                    <Card>
                        <CardBody>
                        
                            <Form
                                onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                                }}
                            >
                                <Row className="mb-4">

                                    <Label
                                        className="col-sm-3 col-form-label"
                                    >
                                        Account Status {validation.values.user_status}
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="user_status"
                                        type="radio"
                                        id="first"
                                        onChange={handleRadioChange}
                                        value='1'
                                        invalid={
                                            validation.touched.user_status &&
                                            validation.errors.user_status
                                            ? true
                                            : false
                                        }
                                        checked={UserStatus == 1 }
                                    /> &nbsp;&nbsp;Active
                                    &nbsp;&nbsp;<Input
                                        name="user_status"
                                        type="radio"
                                        id="second"
                                        onChange={handleRadioChange}
                                        value='1'
                                        invalid={
                                        validation.touched.user_status &&
                                            validation.errors.user_status
                                            ? true
                                            : false
                                        }
                                        checked={UserStatus == 0}
                                    />&nbsp;&nbsp;InActive
                                    
                                    {validation.touched.user_status &&
                                    validation.errors.user_status ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.user_status}
                                    </FormFeedback>
                                    ) : null}
                                </Col>

                                </Row>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Staff Email
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="staff_email"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_email}
                                        invalid={
                                            validation.touched.staff_email &&
                                            validation.errors.staff_email
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.staff_email &&
                                        validation.errors.staff_email ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.staff_email}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                
                                </Row>
                                
                                
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Password
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="staff_password"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_password}
                                        invalid={
                                            validation.touched.staff_password &&
                                            validation.errors.staff_password
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.staff_password &&
                                        validation.errors.staff_password ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.staff_password}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                
                                </Row>

                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                    Re-type Password
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="staff_confirm_password"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_confirm_password}
                                        invalid={
                                            validation.touched.staff_confirm_password &&
                                            validation.errors.staff_confirm_password
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.staff_confirm_password &&
                                        validation.errors.staff_confirm_password ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.staff_confirm_password}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                
                                </Row>
                                
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                    Mobile No
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="staff_mobile_no"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_mobile_no}
                                        invalid={
                                        validation.touched.staff_mobile_no &&
                                            validation.errors.staff_mobile_no
                                            ? true
                                            : false
                                        }
                                    />
                                    {validation.touched.staff_mobile_no &&
                                        validation.errors.staff_mobile_no ? (
                                        <FormFeedback type="invalid">
                                        {validation.errors.staff_mobile_no}
                                        </FormFeedback>
                                    ) : null}
                                    </Col>
                                    
                                </Row>
                                    
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Sms Authentication
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            name="sms_auth"
                                            type="radio"
                                            onChange={validation.handleChange}
                                            value='1'
                                            invalid={
                                                validation.touched.sms_auth &&
                                                validation.errors.sms_auth
                                                ? true
                                                : false
                                            }
                                            checked={validation.values.sms_auth == 1 ? true: false}
                                            /> &nbsp;&nbsp;Yes
                                            &nbsp;&nbsp;
                                            <Input
                                            name="sms_auth"
                                            type="radio"
                                            onChange={validation.handleChange}
                                            value='0'
                                            invalid={
                                            validation.touched.sms_auth &&
                                                validation.errors.sms_auth
                                                ? true
                                                : false
                                            }
                                            checked={validation.values.sms_auth == 0 ? true: false}
                                            />&nbsp;&nbsp;No
                                            {validation.touched.sms_auth &&
                                                validation.errors.sms_auth ? (
                                                <FormFeedback type="invalid">
                                                {validation.errors.sms_auth}
                                                </FormFeedback>
                                            ) : null}
                                    </Col>
                                    
                                </Row>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Email Authentication
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                        name="email_auth"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='1'
                                        invalid={
                                            validation.touched.email_auth &&
                                            validation.errors.email_auth
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.email_auth == 1 ? true: false}
                                    /> &nbsp;&nbsp;Yes
                                        &nbsp;&nbsp;
                                        <Input
                                        name="email_auth"
                                        type="radio"
                                        onChange={validation.handleChange}
                                        value='0'
                                        invalid={
                                        validation.touched.email_auth &&
                                            validation.errors.email_auth
                                            ? true
                                            : false
                                        }
                                        checked={validation.values.email_auth == 0 ? true: false}
                                        />&nbsp;&nbsp;No
                                    {validation.touched.email_auth &&
                                    validation.errors.email_auth ? (
                                    <FormFeedback type="invalid">
                                    {validation.errors.email_auth}
                                    </FormFeedback>
                                    ) : null}
                                    </Col>
                                    
                                </Row>

                                {  validation.values.email_auth == 1  && (
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Provide Authentication Email
                                    </Label>
                                    <Col sm={9}>
                                    <Input
                                            name="auth_email_address"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.auth_email_address}
                                        />
                                        
                                    </Col>

                                </Row>
                                )}

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                        <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={TopLoading}
                                        >
                                            Update
                                        </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                    
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle>Upload Documents</CardTitle>
                        <Dropzone 
                        onDrop={acceptedFiles => {
                            handleUploadDoc(acceptedFiles)
                        }}
                        >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here</h4>
                            </div>
                            </div>
                        )}
                        </Dropzone>

                        </CardBody>
                    </Card>

                    <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedDocFiles.map((f, i) => {
                            // console.log(f);
                            return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    {f.type != 'application/pdf' ?
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                    />
                                    : null }
                                    </Col>
                                    <Col>
                                    <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                    >
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                    </Col>
                                </Row>
                                </div>
                            </Card>
                            )
                        })}
                    </div>


                    <Card>
                        <CardBody>
                            <CardTitle>Upload HR Documents</CardTitle>
                        <Dropzone
                        onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                        }}
                        >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here to upload.</h4>
                            </div>
                            </div>
                        )}
                        </Dropzone>

                        <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFiles.map((f, i) => {
                            // console.log(f);
                            return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    {f.type != 'application/pdf"' ?
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                    />
                                    : null }
                                    </Col>
                                    <Col>
                                    <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                    >
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                    </Col>

                                </Row>
                                </div>
                            </Card>
                            )
                        })}
                        
                        </div>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Document Name
                            </Label>
                            <Col sm={9}>
                                <Select
                                    
                                    value={HrDocs}
                                    name="hr_doc_name"
                                    options={HrDocsDropdown}
                                    onChange={(e) => setHrDocs(e)}
                                    className="select2-selection"
                                    defaultValue={HrDocs}
                                    styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Is Expiry?
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="hr_docs_is_expiry"
                                    type="radio"
                                    onChange={(e) => handleHrIsExpiry(e) }
                                    value='1'
                                /> &nbsp;&nbsp;Yes
                                &nbsp;&nbsp;
                                <Input
                                    name="hr_docs_is_expiry"
                                    type="radio"
                                    onChange={(e) => handleHrIsExpiry(e) }
                                    value='0'
                                />&nbsp;&nbsp;No
                                
                            </Col>
                            
                        </Row>


                    { HrDocsIsExpiry == 1  &&  (
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Expiry Date
                            </Label>
                            <Col sm={9}>
                                <InputGroup>
                                <Flatpickr
                                    name="hr_docs_expiry_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setHrDocsExpiryDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={HrDocsExpiryDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    minDate: 'today',
                                    defaultDate: 'today',
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                                
                            </Col>

                        
                        </Row>

                        )}
                        
                        <Row>
                            <Col>
                                <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                    disabled={TopLoading}
                                    onClick={handleHrDocuments}
                                >
                                    Upload
                                </button>
                                </div>
                            </Col>
                        </Row>

                        </CardBody>
                    </Card>

                </Col>
                
                <Col lg="4">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12}>

                                    <div className="mb-3">
                                        <Label className="form-label">Add Notes</Label>
                                        <Input
                                        name="roster_notes"
                                        type="textarea"
                                        onChange={e => {
                                            setStaffNotes(e.target.value);
                                        }}
                                        value={StaffNotes}
                                        />
                                        
                                    </div>
                                
                                </Col>
                            </Row>
                            { loggedUser.role == 'admin' || loggedUser.role == 'sub_admin' || loggedUser.role == 'csm' || loggedUser.role == 'human_resource' ? 
                            <>
                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <button onClick={addStaffNotes}
                                        type="submit"
                                        className="btn btn-success save-user"
                                        disabled={TopLoading}
                                        >
                                        Add
                                        </button>
                                    </div>
                                </Col>
                            </Row>

                            </>

                            : null }

                            <Row>
                                <div className="order_notes_div">
                                {
                                    HrNotesLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <ul className="order_notes">
                                        { StaffNotesList.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                            <strong>
                                            {activity.activity_by}
                                            </strong>
                                            </abbr></p>
                                        </li>
                                        ))}
                                    </ul>
                                }
                                </div>
                            </Row>
                            <br/><br/>

                            <CardTitle>Hr Notes Acticity</CardTitle>

                            { loggedUser.role == 'admin' || loggedUser.role == 'sub_admin' || loggedUser.role == 'human_resource' ? 
                            <>

                                { loggedUser.role == 'human_resource' || loggedUser.role == 'admin' ? 
                                <>
                                    <Row>
                                        <Col xs={12}>

                                            <div className="mb-3">
                                                <Label className="form-label">Add Hr Notes</Label>
                                                <Input
                                                name="roster_notes"
                                                type="textarea"
                                                onChange={e => {
                                                    setStaffHrNotes(e.target.value);
                                                }}
                                                value={StaffHrNotes}
                                                />
                                                
                                            </div>
                                        
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                        <div className="text-end">
                                                <button onClick={addStaffHrNotes}
                                                type="submit"
                                                className="btn btn-success save-user"
                                                disabled={TopLoading}
                                                >
                                                Add
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    </> : null }

                                </> : null }
                            <Row>
                                <div className="order_notes_div">
                                {
                                    NotesLoading ? <Spinners setLoading={setLoading} />
                                    :
                                    <ul className="order_notes">
                                        { StaffHrNotesList.map(activity => (
                                        <li key={activity.id} rel={activity.id} className="note">
                                            <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                            <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                            <strong>
                                            {activity.activity_by}
                                            </strong>
                                            </abbr></p>
                                        </li>
                                        ))}
                                    </ul>
                                }
                                </div>
                            </Row>
                    
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}


EditSupportStaff.propTypes = {
    match: PropTypes.any,
};
export default withRouter(EditSupportStaff);