/* Publi Holidays */
export const GET_PUBLIC_HOLIDAYS = "GET_PUBLIC_HOLIDAYS"
export const GET_PUBLIC_HOLIDAYS_SUCCESS = "GET_PUBLIC_HOLIDAYS_SUCCESS"
export const GET_PUBLIC_HOLIDAYS_FAIL = "GET_PUBLIC_HOLIDAYS_FAIL"

export const ADD_PUBLIC_HOLIDAY = "ADD_PUBLIC_HOLIDAY"
export const ADD_PUBLIC_HOLIDAY_SUCCESS = "ADD_PUBLIC_HOLIDAY_SUCCESS"
export const ADD_PUBLIC_HOLIDAY_FAIL = "ADD_PUBLIC_HOLIDAY_FAIL"

export const UPDATE_PUBLIC_HOLIDAY = "UPDATE_PUBLIC_HOLIDAY"
export const UPDATE_PUBLIC_HOLIDAY_SUCCESS = "UPDATE_PUBLIC_HOLIDAY_SUCCESS"
export const UPDATE_PUBLIC_HOLIDAY_FAIL = "UPDATE_PUBLIC_HOLIDAY_FAIL"

