import {
  GET_EMERGENCY_ACTIVE_ALERTS,
  GET_EMERGENCY_ACTIVE_ALERTS_FAIL,
  GET_EMERGENCY_ACTIVE_ALERTS_SUCCESS,
  GET_EMERGENCY_ARCHIVED_ALERTS,
  GET_EMERGENCY_ARCHIVED_ALERTS_SUCCESS,
  GET_EMERGENCY_ARCHIVED_ALERTS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  emergencyActiveAlerts: [],
  emergencyArchivedAlerts: [],
  error: {},
  loading: false,
}

const EmergencyAlertsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMERGENCY_ACTIVE_ALERTS:
      console.log('loading');
      return {
        ...state,
        emergencyActiveAlerts: state.emergencyActiveAlerts,
        loading: true
     }
    case GET_EMERGENCY_ACTIVE_ALERTS_SUCCESS:
      console.log('success');
      return {
        ...state,
        emergencyActiveAlerts: action.payload.data,
        loading: false
     }

    case GET_EMERGENCY_ACTIVE_ALERTS_FAIL:
    console.log('failed');
    return {
      ...state,
      error: action.payload,

    }

    case GET_EMERGENCY_ARCHIVED_ALERTS:
    console.log('loading');
    return {
      ...state,
      emergencyArchivedAlerts: state.emergencyArchivedAlerts,
      loading: true
    }

    case GET_EMERGENCY_ARCHIVED_ALERTS_SUCCESS:
      return {
        ...state,
        emergencyArchivedAlerts: action.payload.data,
        loading: false
      }

    case GET_EMERGENCY_ARCHIVED_ALERTS_FAIL:
      return {
        ...state,
        error: action.payload.error,
      }

    //   case ADD_CASEMANAGER:
    //     return {
    //         ...state,
    //         loading: true
    //   };
    //   case ADD_CASEMANAGER_SUCCESS:
    //     return {
    //       ...state,
    //       casemanagers: [...state.casemanagers, action.payload.data],
    //       loading: false
    //   };
    //   case ADD_CASEMANAGER_FAIL:
    //     return {
    //       ...state,
    //       error: action.payload.error,
    //       loading: false
    //   };

    //   case GET_CASEMANAGER_DETAILS:
    //   console.log('loading');
    //   return {
    //     casemanager: [],
    //     loading: true
    //  }

    // case GET_CASEMANAGER_DETAILS_SUCCESS:
    //   console.log('success');
    //   return {
    //     ...state,
    //     casemanager: action.payload.data,
    //     loading: false
    //  }

    // case GET_CASEMANAGER_DETAILS_FAIL:
    // console.log('failed');
    // return {
    //   ...state,
    //   error: action.payload,
    // }

    //   case UPDATE_CASEMANAGER:
    //     return {
    //       ...state,
    //       loading: true
    //   };

    //   case UPDATE_CASEMANAGER_SUCCESS:
    //     return {
    //         ...state,
    //         casemanager: state.providers.map(rosters =>
    //           rosters.id.toString() === action.payload.data.id.toString()
    //                 ? { rosters, ...action.payload.data }
    //                 : rosters
    //         ),
    //         loading: false
    //   };

    //   case UPDATE_CASEMANAGER_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload.error,
    //         loading: false
    //     };

      default:
        return state
  }
}

export default EmergencyAlertsReducer
