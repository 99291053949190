import {
  GET_CASEMANAGERS,
  GET_CASEMANAGERS_FAIL,
  GET_CASEMANAGERS_SUCCESS,
  GET_CASEMANAGERS_CLIENTS,
  GET_CASEMANAGERS_CLIENTS_SUCCESS,
  GET_CASEMANAGERS_CLIENTS_FAIL,
  GET_CASEMANAGER_DETAILS,
  GET_CASEMANAGER_DETAILS_SUCCESS,
  GET_CASEMANAGER_DETAILS_FAIL,
  ADD_CASEMANAGER,
  ADD_CASEMANAGER_SUCCESS,
  ADD_CASEMANAGER_FAIL,
  UPDATE_CASEMANAGER,
  UPDATE_CASEMANAGER_SUCCESS,
  UPDATE_CASEMANAGER_FAIL
} from "./actionTypes"

export const getCasemanagers = (params,types) => ({
  type: GET_CASEMANAGERS,
  params,
  types,
})

export const getCasemanagersSuccess = data => ({
  type: GET_CASEMANAGERS_SUCCESS,
  payload: data,
})

export const getCasemanagersFail = error => ({
  type: GET_CASEMANAGERS_FAIL,
  payload: error,
})

export const getCasemanagerDetails = id => ({
  type: GET_CASEMANAGER_DETAILS,
  id,
})

export const getCasemanagerDetailsSuccess = data => ({
  type: GET_CASEMANAGER_DETAILS_SUCCESS,
  payload: data,
})

export const getCasemanagerDetailsFail = error => ({
  type: GET_CASEMANAGER_DETAILS_FAIL,
  payload: error,
})

export const getCasemanagersClients = id => ({
  type: GET_CASEMANAGERS_CLIENTS,
  id,
})

export const getCasemanagersClientsSuccess = data => ({
  type: GET_CASEMANAGERS_CLIENTS_SUCCESS,
  payload: data,
})

export const getCasemanagersClientsFail = error => ({
  type: GET_CASEMANAGERS_CLIENTS_FAIL,
  payload: error,
})

export const addCasemanager = (params) => ({
  type: ADD_CASEMANAGER,
  payload: params,
  // types
})

// export const addCasemanagerSuccess = data => ({
//   type: ADD_CASEMANAGER_SUCCESS,
//   payload: data,
// })

// export const addCasemanagerFail = error => ({
//   type: ADD_CASEMANAGER_FAIL,
//   payload: error,
// })

export const updateCasemanager = (params) => ({
  type: UPDATE_CASEMANAGER,
  payload: params,
  // types
})

// export const updateCasemanagerSuccess = data => ({
//   type: UPDATE_CASEMANAGER_SUCCESS,
//   payload: data,
// })

// export const updateCasemanagerFail = error => ({
//   type: UPDATE_CASEMANAGER_FAIL,
//   payload: error,
// })