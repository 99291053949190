import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import axios from 'axios';
import { toast } from "react-toastify";
import { ACTION_URL, APP_URL, SITE_URL } from "helpers/url_helper"
import Spinners from "components/Common/Spinner";


import {
    Card,
    CardBody,
    Col,
    Container,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Button,
    InputGroup,
    CardTitle
  } from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import { Name } from "components/Common/TableColumns";

  const ClientPackageArchivedModal = ({clientID, show, onCloseModal}) => {

    const loggedUser = JSON.parse(localStorage.getItem("authUser"));
    const [ModalLoading, setModalLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [ColumnsHide, setColumnsHide] = useState([]);
    const [ArchivedPackages, setArchivedPackages] = useState([]);

    useEffect(() => {
        if( show ) { 
            setModalLoading(true);
            const formData = {
                client_id: clientID
            }
            axios.post(APP_URL+ACTION_URL,{'data_for':'load_client_archived_package_levels','formData' : formData})
            .then(res => {
                setModalLoading(false);
                const fetchedData = res.data;
                setArchivedPackages(fetchedData.archivePackages);
            })
        }
    }, [show]);

    const columns = useMemo(
        () => [
          {
            Header: "Client Type",
            accessor: "client_type",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Support Type",
            accessor: "support_type",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Package Level",
            accessor: "package_level",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Daily Subsidy",
            accessor: "daily_subsidy",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Weekly Subsidy",
            accessor: "weekly_subsidy",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Monthly Subsidy",
            accessor: "monthly_subsidy",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Yearly Subsidy",
            accessor: "yearly_subsidy",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Hourly Rate",
            accessor: "hourly_rate",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Total Amount",
            accessor: "total_amount",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
          {
            Header: "End Date",
            accessor: "end_date",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
          },
        ],
        []
      );
    
    return (
        <>
        <Modal isOpen={show} toggle={onCloseModal} size="xl" backdrop="static" scrollable >
            <ModalHeader toggle={onCloseModal} tag="h4">
                Archived Packages { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
            </ModalHeader>
            <ModalBody style={{ minHeight: '400px' }}>

            <TableContainer
                columns={columns}
                data={ArchivedPackages}
                isGlobalFilter={true}
                showSearchBtn={true}
                customPageSize={50}
                className="custom-header-css"
                columnsHide={ColumnsHide}
            />

            </ModalBody>
        </Modal>
    </>
    )

}

ClientPackageArchivedModal.propTypes = {
    onCloseModal: PropTypes.func,
    show: PropTypes.any
}

export default ClientPackageArchivedModal;