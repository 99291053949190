import React, { useEffect, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import axios from 'axios';
import withRouter from "components/Common/withRouter"
import { isEmpty, map } from "lodash"
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { ACTION_URL, APP_URL, GET_CONTRACTORS } from "helpers/url_helper"
import { toast } from "react-toastify";

import {Name, Email, Tags, Projects, Status } from "../../../components/Common/TableColumns";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  CardTitle,
  InputGroup
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer"
import TopProgressBar from "components/Common/TopProgressBar"



import {
  getContractors as onGetContractors,
  addContractor as onAddContractor,
  updateContractor as onUpdateContractor
} from "store/actions";

import CaseMangerClientsModal from "components/Forms/CaseManagerClients";

function Contractors(){
  //meta title
  document.title="Contractors | Tacveiha Panel";
  
  const loggedUser = JSON.parse(localStorage.getItem("authUser"));
  const [modal, setModal] = useState(false);
 
  const [isEdit, setIsEdit] = useState(false);
  const [editID, setEditID] = useState('');

  const [ModalLoading, setModalLoading] = useState(false);
  const [StaffDepartment, setStaffDepartment] = useState([]);
  const [department_dropdown, setDepartment_dropdown] = useState([]);
  const [UserType, setUserType] = useState('');

  const [roster, setRoster] = useState([]);

  const[contractorActivityLog, setContractorActivityLog] = useState([]);

  //upload contractors document
  const [DocumentName, setDocumentName] = useState([]);
  const [Expiry, setExpiry] = useState(0);
  const [ExpiryDate, setExpiryDate] = useState('');
  const [DocumentExpiryDate, setDocumentExpiryDate] = useState('');


  //documents modal
  const [DocumentsModal, setDocumentsModal] = useState(false);
  const [DocumentsList, setDocumentsList]= useState([]);
  const [DocumentsActivity, setDocumentsActivity]= useState([]);

  const [CaseManagerName, setCaseManagerName] = useState('');
  const [CaseManagerID, setCaseManagerID] = useState('');
  const [CMClientsModal, setCMClientsModal] = useState(false);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user_status: ( roster && roster.user_status ) || 0,
      user_title: ( roster && roster.user_title ) || "",
      case_manager_name: ( roster && roster.case_manager_name ) || "",
      user_email: ( roster && roster.user_email ) || "",
      user_gender: ( roster && roster.user_gender ) || "",
      user_mobile_no: ( roster && roster.user_mobile_no ) || "",
      user_address: ( roster && roster.user_address ) || "",
      user_pass: "",
      user_pass_confirm: "",
      provider_contact_name: ( roster && roster.provider_contact_name ) || "",
      provider_contact_relation: ( roster && roster.provider_contact_relation ) || "",
      provider_contact_no: ( roster && roster.provider_contact_no ) || "",
      provider_contact_email: ( roster && roster.provider_contact_email ) || "",
    },
    validationSchema: Yup.object({
      user_title: Yup.string().required("This field is required"),
      case_manager_name: Yup.string().required("This field is required"),
      user_email: Yup.string().required("This field is required"),
      user_gender: Yup.string().required("This field is required"),
      user_mobile_no:Yup.string().required("This field is required"),
      user_pass: Yup.string().oneOf([Yup.ref('user_pass_confirm'),null], 'Passwords much match'),
      user_pass_confirm: Yup.string().oneOf([Yup.ref('user_pass'),null], 'Passwords much match'),
      user_address:Yup.string().required("This field is required"),
      provider_contact_name: Yup.string().required("This field is required"),
      provider_contact_relation: Yup.string().required("This field is required"),
      provider_contact_no: Yup.string().required("This field is required"),
      provider_contact_email: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {


      if( UserType == '' ) {
        toast.error('Please select user type.', { autoClose: 5000 });
        return false;
      }

      if( StaffDepartment.value == '' ) {
        toast.error('Please select department.', { autoClose: 5000 });
        return false;
      }

      if (isEdit) {
        const updateUser = {
          user_id: roster.user_id,
          user_status: values.user_status,
          user_type: UserType,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
          user_latlng: roster.user_latlng,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
        }

        setModalLoading(true);

        axios.post(APP_URL+GET_CONTRACTORS,{'data_for': 'edit_contractor','formData': updateUser,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;

            if( fetchedData.status == 'success' ) {
              dispatch(onUpdateContractor(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              setIsEdit(false);
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      } else {
        const newData = {
          user_status: values.user_status,
          user_type: UserType,
          department_id: StaffDepartment,
          user_title: values.user_title,
          case_manager_name: values.case_manager_name,
          user_email: values.user_email,
          user_pass: values.user_pass,
          user_pass_confirm: values.user_pass_confirm,
          user_gender: values.user_gender,
          user_mobile_no: values.user_mobile_no,
          user_address: values.user_address,
          provider_contact_name: values.provider_contact_name,
          provider_contact_relation: values.provider_contact_relation,
          provider_contact_no: values.provider_contact_no,
          provider_contact_email: values.provider_contact_email,
        };
        // save new user
        setModalLoading(true);

        axios.post(APP_URL+GET_CONTRACTORS,{'data_for': 'add_contractor','formData': newData,'user': loggedUser})
          .then(res => {
            setModalLoading(false);
            const fetchedData = res.data;
            
            if( fetchedData.status == 'success' ) {
              dispatch(onAddContractor(fetchedData.data));
              toast.success(fetchedData.html, { autoClose: 5000 });
              toggle();
            }

            if( fetchedData.status == 'error' ) {
              toast.error(fetchedData.html, { autoClose: 5000 });
            }
        })
      }
    },
  });

  const dispatch = useDispatch();
  
  const selectGroupRostersState = (state) => state.ContractorsReducer;
  const groupRostersProperties = createSelector(
    selectGroupRostersState,
      (rosterReducer) => ({
        contractors: rosterReducer.contractors,
        hiddenColumns: rosterReducer.hiddenColumns,
        docsDropdown: rosterReducer.docsDropdown,
        loading: rosterReducer.loading
      })
  );
  const {
    contractors, hiddenColumns,docsDropdown, loading
  } = useSelector(groupRostersProperties);

  const [isLoading, setLoading] = useState(loading);
  const user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    //if (isEmpty(todaysRosters)) {
      if (contractors && !contractors.length) {
      dispatch(onGetContractors({'data_for':'get_contractors', 'user': user}));
      }
    //}
  }, [dispatch]);


  const toggle = () => {
    // console.log(' modal atatus ' + modal)
    if (modal) {
      setModal(false);
      setRoster([]);
    } else {
        setModal(true);
    }
  }

  const toggleDocumentsModal = () => {
    if (DocumentsModal) {
      setDocumentsModal(false);
      setDocumentsList([]);
      setDocumentsActivity([]);
    } else {
      setDocumentsModal(true);
    }
  }

  const handleUserClick = arg => {
    const user = arg;
    loadCmDetailsActivity(user.user_id);
    setRoster({
      user_id: user.user_id,
      user_status: user.user_status,
      user_type: user.user_type,
      user_title: user.user_title,
      case_manager_name: user.case_manager_name,
      user_email: user.user_email,
      user_gender: user.user_gender,
      user_mobile_no: user.user_mobile_no,
      user_address: user.user_address,
      user_latlng: user.user_latlng,
      provider_contact_name: user.provider_contact_name,
      provider_contact_relation: user.provider_contact_relation,
      provider_contact_no: user.provider_contact_no,
      provider_contact_email: user.provider_contact_email,
    });
    setUserType(user.user_type)
    handleUserType(user.user_type);
    setStaffDepartment({label:user.department_name,value:user.department_id});
    setIsEdit(true);
    toggle();
  };

  const handleUserClicks = () => {
    setRoster([]);
    setUserType('');
    setContractorActivityLog([]);
    setIsEdit(false);
    toggle();
  };
  
  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  }


  const loadCmDetailsActivity = (user_id) => {

    const formData = {
        uid: user_id
    }

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_activity_log','formData':formData})
    .then(res => {
      const fetchedData = res.data;
        setContractorActivityLog(fetchedData.activityLog);
    })
  }

  const handleUserType = (type) => {

    validation.values.user_type = type;

    const formData = {
        type: type,
        // user_id: user_id,
        // for: 'profilePage'
    }
    setUserType(type);
    setModalLoading(true);
    
    setStaffDepartment([]);
    // setStaffCasemanager([]);
    setDepartment_dropdown([]);
    // setCasemanager_dropdown([]);

    axios.post(APP_URL+ACTION_URL,{'data_for':'load_department','formData':formData})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res;
        setDepartment_dropdown(res.data.departments);
    })
  }

  const docsColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
        {
            Header: "Document Name",
            accessor: "doc_name",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
      {
        Header: "Expiry",
        accessor: "expiry_date",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Added",
        accessor: "doc_added",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            if( userData.doc_status != 5 ) {
                                handleArchiveDoc(userData);
                            }
                        }}
                        id={`viewprofiletooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-archive" />
                        <UncontrolledTooltip placement="top" target={`viewprofiletooltip-${cellProps.row.original.id}`} >
                            Archive
                        </UncontrolledTooltip>
                    </Link>
                </li>
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                            const userData = cellProps.row.original;
                            handleRemoveDoc(userData);
                        }}
                        id={`removetooltip-${cellProps.row.original.id}`}
                    >
                        <i className="dripicons-trash"/>
                        <UncontrolledTooltip placement="top" target={`removetooltip-${cellProps.row.original.id}`} >
                            Remove
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
        {
            Header: "Department",
            accessor: "department_name",
            //disableFilters: true, 
            filterable: true,
            Cell: cellProps => {
              return <Name {...cellProps} />;
            },
        },
      {
        Header: "Name",
        accessor: "case_manager_name",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {

          return <Name {...cellProps} />
          // return (
          //   <Link
          //       to="/clients"
          //       className="text-success"
          //       // onClick={() => {
          //       //   const userData = cellProps.row.original;
          //       //   showCaseManagerClients(userData);
          //       // }}
          //     ></Link>
          // )
        },
      },
      {
        Header: "Email",
        accessor: "user_email",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Address",
        accessor: "user_address",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Phone No",
        accessor: "user_mobile_no",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "status",
        accessor: "user_status",
        //disableFilters: true, 
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              
            </div>
          );
        },
      },
    ],
    []
);

const handleExpiryRadio = (e) => {
  if( e.target.checked ) {
    setExpiry(e.target.value);
  } else {
    setExpiry(0);
  }
}

const uploadDocument = () => {

  if( DocumentName == '' ) {
    toast.error('Pleaes select document name.', { autoClose: 5000 });
    return;
  }

  if( Expiry == 1  && ExpiryDate == '') {
    toast.error('Pleaes add expiry date for document.', { autoClose: 5000 });
    return;
  }

  const formData = {
    is_expiry: Expiry,
    expiry_date: ExpiryDate,
    doc_list_name: DocumentName,
    user_id: roster.user_id
  }

  // console.log(formData);

  setModalLoading(true);

  axios.post(APP_URL+ACTION_URL,{'data_for': 'contractor_doc_upload','formData': formData,'user': loggedUser})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;
      
      if( fetchedData.status == 'success' ) {
        setExpiryDate('');
        setExpiry(0);
        setDocumentName({label:'Select Document Name', value:0});
        toast.success(fetchedData.html, { autoClose: 5000 });
        // toggle();
      }

      if( fetchedData.status == 'error' ) {
        toast.error(fetchedData.html, { autoClose: 5000 });
      }
  })

}

const handleArchiveDoc = (docs) => {
  setModalLoading(true);
  const formData = {
      doc_id: docs.id,
      type: 'cont_doc'
  };
  axios.post(APP_URL+ACTION_URL,{'data_for':'archive_doc','formData': formData,'user': loggedUser})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;

      if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
          setDocumentsList((DocumentsList) => DocumentsList.filter(data => data.id.toString() !==  docs.id.toString()));
      }

      if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
      }

    })
}

const handleRemoveDoc = (docs) => {


setModalLoading(true);
  const formData = {
      doc_id: docs.id,
      type: 'cont_doc'
  };
  axios.post(APP_URL+ACTION_URL,{'data_for':'remove_doc','formData': formData,'user': loggedUser})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;

      if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
          setDocumentsList((DocumentsList) => DocumentsList.filter(data => data.id.toString() !==  docs.id.toString()));
          
      }

      if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
      }

    })
}

const toggleContactorsDoc = () => {

  toggleDocumentsModal();

  const formData = {
    user_id : roster.user_id,
    type: 'cont_doc',
    doc_by : ''
  }
  
  setModalLoading(true);
  
  axios.post(APP_URL+GET_CONTRACTORS,{'data_for':'get_contractor_docs_list','formData': formData,'user': loggedUser})
    .then(res => {
      setModalLoading(false);
      const fetchedData = res.data;

      if( fetchedData.status == 'success' ) {
          toast.success(fetchedData.html, { autoClose: 5000 });
          setDocumentsList(fetchedData.contractor_docs);
          setDocumentsActivity(fetchedData.docs_activity);
      }

      if( fetchedData.status == 'error' ) {
          toast.error(fetchedData.html, { autoClose: 5000 });
      }

    })

}

const showCaseManagerClients = (userData) => {
  // console.log( userData);
  toggleCMClientsModal();
  setCaseManagerName(userData.case_manager_name);
  setCaseManagerID(userData.user_id);

}

const toggleCMClientsModal = () => {
  if( CMClientsModal) {
    setCMClientsModal(false)
  } else {
    setCMClientsModal(true)
  }
}

  return (
    <React.Fragment>
      {
        loading ? <TopProgressBar loading/>
        : ''
      }

      <CaseMangerClientsModal
        CaseManagerID={CaseManagerID}
        CaseMangerName={CaseManagerName}
        show={CMClientsModal}
        onCloseModal={() => setCMClientsModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contractors" breadcrumbItem="Contractors" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                {loading ? <Spinners setLoading={setLoading} />
                    :
                  <TableContainer
                    columns={columns}
                    data={contractors}
                    isGlobalFilter={true}
                    isDateSelecteOptions={true}
                    showSearchBtn={true}
                    handleUserClick={handleUserClicks}
                    // handleUserNotesClick={handleUserNotesClicks}
                    customPageSize={50}
                    className="custom-header-css"
                    isAddBtn={loggedUser.role != 'human_resource' ? true : false }
                    btnText={'Add Contractor'}
                    columnsHide={hiddenColumns}
                  />
                }

                {/* Contractors View Modal */}

                <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" scrollable>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Details" : "Add Details"}
                      { ModalLoading ? <Spinners setLoading={setLoading} /> : '' }
                      { !!isEdit && 
                      <div className="text-end">
                        <button
                         className="btn btn-info"
                        onClick={toggleContactorsDoc}
                        >
                          All Documents
                        </button>
                      </div>
                    }
                    </ModalHeader>
                    <ModalBody>

                  <Row>
                    <Col md={8}>
                      <Form
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                    
                    { isEdit && 
                      <Row className="mb-4">

                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      > User Status </Label>
                      <Col sm={9}>
                          <Input
                              name="user_status"
                              type="radio"
                              onChange={validation.handleChange}
                              value='1'
                              invalid={
                                  validation.touched.user_status &&
                                  validation.errors.user_status
                                  ? true
                                  : false
                              }
                              checked={validation.values.user_status == 1 ? true : false }
                              
                          /> &nbsp;&nbsp;Active
                          &nbsp;&nbsp;<Input
                              name="user_status"
                              type="radio"
                              onChange={validation.handleChange}
                              value='0'
                              invalid={
                                  validation.touched.user_status &&
                                  validation.errors.user_status
                                  ? true
                                  : false
                              }
                              checked={validation.values.user_status == 0 ? true : false }
                          
                          />&nbsp;&nbsp;Inactive
                          
                          {validation.touched.user_status &&
                          validation.errors.user_status ? (
                          <FormFeedback type="invalid">
                          {validation.errors.user_status}
                          </FormFeedback>
                          ) : null}
                      </Col>

                      </Row>
                    }
                    
                    <Row className="mb-4">
                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      >
                          For
                      </Label>
                      <Col sm={9}>
                      { loggedUser.role != 'human_resource' && 
                          <>
                          <Input
                              name="user_type"
                              type="radio"
                              onChange={(e) => {
                                  handleUserType('provider');
                              }}
                              value='provider'
                              checked={ UserType == 'provider' ? true : false }
                              // invalid={
                              //     validation.touched.user_type &&
                              //     validation.errors.user_type
                              //     ? true
                              //     : false
                              // }
                          /> &nbsp;&nbsp;Provider
                          </>
                          }
                          &nbsp;&nbsp;<Input
                              name="user_type"
                              type="radio"
                              onChange={(e) => {
                                  handleUserType('contractors');
                              }}
                              value='contractors'
                              checked={ UserType == 'contractors' ? true : false }
                              // invalid={
                              //     validation.touched.user_type &&
                              //     validation.errors.user_type
                              //     ? true
                              //     : false
                              // }
                          
                          />&nbsp;&nbsp;3rd Party Contractors
                          {/* {validation.touched.user_type &&
                          validation.errors.user_type ? (
                          <FormFeedback type="invalid">
                          {validation.errors.user_type}
                          </FormFeedback>
                          ) : null} */}
                          
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                      >
                      Department
                      </Label>
                      <Col sm={9}>
                        <Select
                            value={StaffDepartment}
                            name="staff_department"
                            options={department_dropdown}
                            onChange={(e) => setStaffDepartment(e)}
                            className="select2-selection"
                            defaultValue={department_dropdown}
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">

                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Title
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mr'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title === "Mr" ? true : false }
                                /> &nbsp;&nbsp;Mr
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Mrs'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title === "Mrs" ? true : false }
                                
                                />&nbsp;&nbsp;Mrs
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Miss'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title === "Miss" ? true : false }
                                />&nbsp;&nbsp;Miss
                                &nbsp;&nbsp;<Input
                                    name="user_title"
                                    type="radio"
                                    onChange={validation.handleChange}
                                    value='Ms'
                                    invalid={
                                        validation.touched.user_title &&
                                        validation.errors.user_title
                                        ? true
                                        : false
                                    }
                                    checked={validation.values.user_title === "Ms" ? true : false }
                                />&nbsp;&nbsp;Ms
                                {validation.touched.user_title &&
                                validation.errors.user_title ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_title}
                                </FormFeedback>
                                ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Case Manager Name
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="case_manager_name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.case_manager_name}
                              invalid={
                              validation.touched.case_manager_name &&
                                  validation.errors.case_manager_name
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.case_manager_name &&
                              validation.errors.case_manager_name ? (
                              <FormFeedback type="invalid">
                              {validation.errors.case_manager_name}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Case Manager Email
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_email}
                              invalid={
                              validation.touched.user_email &&
                                  validation.errors.user_email
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_email &&
                              validation.errors.user_email ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_email}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        { isEdit && 
                        <>
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass}
                                invalid={
                                validation.touched.user_pass &&
                                    validation.errors.user_pass
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass &&
                                validation.errors.user_pass ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                                Confirm Password
                            </Label>
                            <Col sm={9}>
                            <Input
                                name="user_pass_confirm"
                                type="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_pass_confirm}
                                invalid={
                                validation.touched.user_pass_confirm &&
                                    validation.errors.user_pass_confirm
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.user_pass_confirm &&
                                validation.errors.user_pass_confirm ? (
                                <FormFeedback type="invalid">
                                {validation.errors.user_pass_confirm}
                                </FormFeedback>
                            ) : null}
                            </Col>
                        
                        </Row>

                        </>
                        }

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Client Gender
                          </Label>
                          <Col sm={9}>
                              <Input
                                  name="user_gender"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Male'
                                  invalid={
                                      validation.touched.user_gender &&
                                      validation.errors.user_gender
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.user_gender ==="Male" ? true : false }
                              /> &nbsp;&nbsp;Male
                              &nbsp;&nbsp;<Input
                                  name="user_gender"
                                  type="radio"
                                  onChange={validation.handleChange}
                                  value='Female'
                                  invalid={
                                      validation.touched.user_gender &&
                                      validation.errors.user_gender
                                      ? true
                                      : false
                                  }
                                  checked={validation.values.user_gender === "Female" ? true : false }
                              
                              />&nbsp;&nbsp;Female
                              
                              {validation.touched.user_gender &&
                              validation.errors.user_gender ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_gender}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Mobile No
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_mobile_no"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_mobile_no}
                              invalid={
                              validation.touched.user_mobile_no &&
                                  validation.errors.user_mobile_no
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_mobile_no &&
                              validation.errors.user_mobile_no ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_mobile_no}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-3 col-form-label"
                          >
                              Address
                          </Label>
                          <Col sm={9}>
                          <Input
                              name="user_address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_address}
                              invalid={
                              validation.touched.user_address &&
                                  validation.errors.user_address
                                  ? true
                                  : false
                              }
                          />
                          {validation.touched.user_address &&
                              validation.errors.user_address ? (
                              <FormFeedback type="invalid">
                              {validation.errors.user_address}
                              </FormFeedback>
                          ) : null}
                          </Col>
                        </Row>

                        <CardTitle className="mb-4">Provider Contact</CardTitle>
                        
                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Name
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_name}
                                    invalid={
                                        validation.touched.provider_contact_name &&
                                        validation.errors.provider_contact_name
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_name &&
                                validation.errors.provider_contact_name ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_name}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Relation
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_relation"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_relation}
                                    invalid={
                                        validation.touched.provider_contact_relation &&
                                        validation.errors.provider_contact_relation
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_relation &&
                                validation.errors.provider_contact_relation ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_relation}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Mobile No
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_no"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_no}
                                    invalid={
                                        validation.touched.provider_contact_no &&
                                        validation.errors.provider_contact_no
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_no &&
                                validation.errors.provider_contact_no ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_no}
                                </FormFeedback>
                            ) : null}
                        </Row>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                            >
                            Contact Email
                            </Label>
                            <Col sm={9}>
                                <Input
                                    name="provider_contact_email"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.provider_contact_email}
                                    invalid={
                                        validation.touched.provider_contact_email &&
                                        validation.errors.provider_contact_email
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            {validation.touched.provider_contact_email &&
                                validation.errors.provider_contact_email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.provider_contact_email}
                                </FormFeedback>
                            ) : null}
                        </Row>                        
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                                disabled={ModalLoading}
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>


                      <CardTitle className="mb-4">Upload Contractor Documents</CardTitle>

                      <Row className="mb-4">
                        <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-3 col-form-label"
                        >
                        Document Name
                        </Label>
                        <Col sm={9}>
                          <Select
                              value={DocumentName}
                              name="contractor_documents"
                              options={docsDropdown}
                              onChange={(e) => setDocumentName(e)}
                              className="select2-selection"
                              defaultValue={docsDropdown}
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                  Is Expiry Date
                              </Label>
                              <Col sm={9}>
                                  <Input
                                      name="expiry"
                                      type="radio"
                                      onChange={e => handleExpiryRadio(e)}
                                      value='1'
                                      checked={ Expiry == 1 ? true : false }
                                  /> &nbsp;&nbsp;Yes
                                  &nbsp;&nbsp;<Input
                                      name="expiry"
                                      type="radio"
                                      onChange={e => handleExpiryRadio(e)}
                                      value='0'
                                      checked={ Expiry == 0 ? true : false }
                                  />&nbsp;&nbsp;No
                                  
                              </Col>
                            </Row>

                            { Expiry == 1  && 
                            <Row className="mb-4">
                              <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-3 col-form-label"
                              >
                                Expiry Date
                              </Label>
                              <Col sm={9}>
                              <InputGroup>
                                <Flatpickr
                                    name="expiry_date"
                                    type="text"
                                    className="form-control d-block"
                                    placeholder="dd-mm-YYYY"
                                    onChange={(selectedDates, dateStr, instance) => {
                                        setExpiryDate(dateStr);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={DocumentExpiryDate}
                                    options={{
                                    altInput: true,
                                    altFormat: "d-m-Y",
                                    dateFormat: "Y-m-d",
                                    "locale": {
                                            "firstDayOfWeek": 1 // start week on Monday
                                        }
                                    }}
                                />
                                </InputGroup>
                              </Col>
                            </Row>
                            }
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-info"
                                    disabled={ModalLoading}
                                    onClick={uploadDocument}
                                  >
                                    Add
                                  </button>
                                </div>
                              </Col>
                            </Row>

                        </Col>

                        <Col md={4}>
                        <Row>
                        <CardTitle>Activity Log</CardTitle>
                            <div className="order_notes_div">
                                
                                <ul className="order_notes">
                                    { contractorActivityLog.map(activity => (
                                    <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                        {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                    </li>
                                    ))}
                                </ul>
                            
                            </div>
                        </Row>

                        </Col>
                    </Row>
                            
                    </ModalBody>
                </Modal>

                {/* Contractors Documents Modal */}

                {/* Documents modals */}

            <Modal isOpen={DocumentsModal} toggle={toggleDocumentsModal} size="xl" backdrop="static" scrollable style={{minHeight:300+'px'}}>
                <ModalHeader toggle={toggleDocumentsModal} tag="h4">
                  Contractor Document Details 
                </ModalHeader>
                <ModalBody>
                    {  ModalLoading ? <Spinners setLoading={setLoading} /> : "" }
                    <Row>
                        <Col xs={8}>

                        <CardTitle></CardTitle>
                            <TableContainer
                                columns={docsColumns}
                                data={DocumentsList}
                                isGlobalFilter={true}
                                showSearchBtn={true}
                                customPageSize={50}
                                className="custom-header-css"
                              
                            />

                        </Col>

                        <Col xs={4}>

                        <Row>
                            <div className="order_notes_div">
                                <CardTitle>Activity Log</CardTitle>
                                <ul className="order_notes">
                                    { DocumentsActivity.map(activity => (
                                    <li key={activity.id} rel={activity.id} className="note">
                                        <div dangerouslySetInnerHTML={{__html: activity.activity_for + '- '+activity.action }} className="note_content"></div>
                                        <p className="meta"><abbr className="exact-date" title={activity.created_at_tooltip}> {activity.action} on {activity.created_at} By,
                                        <strong>
                                        {activity.activity_by}
                                        </strong>
                                        </abbr></p>
                                    </li>
                                    ))}
                                </ul>
                            
                            </div>
                        </Row>
                        </Col>
                    </Row>
                    {/* } */}
                    <br/>
                    <br/>
                    <br/>
                </ModalBody>
            </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );


}

export default Contractors;